import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'a3lTruncate' })
export class TruncatePipe implements PipeTransform {
  /**
   * Create a new instance.
   */
  constructor() {
    //
  }

  /**
   * Truncate given string.
   *
   * @param {string} content
   * @param {number} limit
   * @param {string} more
   * @return string
   */
  transform(content: string, limit: number, more: string = '...'): string {
    if ((content || '').length <= limit || !limit) return content;

    return content.substring(0, limit) + more;
  }
}
