import {Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef} from "@angular/core";
import {Subscription} from "rxjs";
import {AuthuserQuery} from "@rex/auth/authuser.query";

@Directive({
  selector: '[rex-auth-subscription-plan]'
})
export class SubscriptionPlanDirective implements OnInit, OnDestroy {
  /**
   * @var {any}
   */
  @Input('rex-auth-subscription-plan')
  subscriptionPlan: any;

  /**
   * @var {boolean}
   */
  protected embedded: boolean = false;

  /**
   * @var {Subscription}
   */
  protected subscription: Subscription = Subscription.EMPTY;

  /**
   * Create a new instance.
   *
   * @param {AuthuserQuery} query
   * @param {TemplateRef} templateRef
   * @param {ViewContainerRef} viewContainer
   */
  constructor(private query: AuthuserQuery, private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef) {
    //
  }

  /**
   * Add the template content to the DOM.
   */
  ngOnInit() {
    this.subscription = this.query.value$.subscribe((user) => {
      if(!user) {
        this.viewContainer.clear();
        this.embedded = false;
        return;
      }

      const subscriptionPlan = Array.isArray(this.subscriptionPlan) ? this.subscriptionPlan : [this.subscriptionPlan];

      if (user && !this.embedded && (subscriptionPlan.indexOf(user.subscription_plan) != -1)) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.embedded = true;
      } else if (!user && this.embedded) {
        this.viewContainer.clear();
        this.embedded = false;
      }
    });
  }

  /**
   * Cleanup.
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
