import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import * as generator from 'secure-random-password';

import { Command } from '@a3l/core';

@Injectable()
export class GenerateStrongPasswordCommand extends Command<string> {
  /**
   * Create a new instance.
   */
  constructor() {
    super();
  }

  /**
   * Run the command.
   *
   * @return Observable<string>
   */
  run(): Observable<string> {
    const password = generator.randomPassword({ length: 10, characters: [generator.lower, generator.upper, generator.digits] });

    return of(password);
  }

  /**
   * Run the command.
   *
   * @return void
   */
  execute(): void {
    this.run().subscribe((password) => this._complete$.next(password));
  }
}
