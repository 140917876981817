export class Exception extends Error {
  /**
   * @var {string}
   */
  static readonly type: string = 'Exception';

  /**
   * @var {string}
   */
  public readonly type: string = Exception.type;

  /**
   * @param {any}
   */
  readonly error: any | null;

  /**
   * @param {string}
   */
  message: string;

  /**
   * Create a new instance.
   */
  constructor(message: string = '') {
    super();

    this.message = message;
  }
}
