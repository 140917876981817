import { Component, forwardRef, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import { Observable, Subscription } from 'rxjs';

import * as _ from 'lodash';

import { Control } from '@a3l/utilities';

import { DictionaryOfLanguagesQuery } from './dictionary-of-languages.query';
import {debounce, debounceTime, distinctUntilChanged, throttleTime} from "rxjs/operators";

@Component({
  selector: 'rex-languages-field',
  templateUrl: './languages.field.html',
  providers: [
    DictionaryOfLanguagesQuery,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LanguagesField),
      multi: true,
    },
    { provide: Control, useExisting: LanguagesField },
  ],
})
export class LanguagesField extends Control implements OnInit, OnDestroy, ControlValueAccessor {
  /**
   * @var {any}
   */
  value: any;

  /**
   * @var {Observable<any[]>}
   */
  items$: Observable<any[]> = this.query.value$;

  /**
   * @var {Observable<boolean>}
   */
  loading$: Observable<boolean> = this.query.pending$;

  /**
   * @var {SelectionModel<{ id: number; level: string }>}
   */
  selection: SelectionModel<{ id: number; level: string }> = new SelectionModel<any>(true, []);

  /**
   * @var {any}
   */
  protected propagateChange: any = () => {};

  /**
   * @var {Subscription}
   */
  protected subscription: Subscription = Subscription.EMPTY;

  /**
   * Create a new instance.
   *
   * @param {DictionaryOfLanguagesQuery} query
   */
  constructor(private query: DictionaryOfLanguagesQuery) {
    super();
  }

  /**
   * Initialization.
   */
  ngOnInit() {
    this.query.execute();

    this.subscription = this.selection.changed.pipe(
      distinctUntilChanged(),
      debounceTime(200),
      throttleTime(200),
    ).subscribe((response) => {
      const value = this.selection.selected.reduce((acc, { id, level }) => ((acc[id] = { level }), acc), {});
      this.propagateChange((this.value = _.isEmpty(value) ? [] : value));
    });
  }

  /**
   * Write a new value from the form model.
   *
   * @param {any} value
   * @return void
   */
  writeValue(value: any): void {
    if (!value) {
      this.value = [];
      this.selection.clear();
    } else if(value ) {
      this.value = value;
      let selections = [];
      Object.keys(this.value).forEach((key) => selections.push({ id: key, ...value[key] }));
      this.selection.select(...selections);
    }

  }

  /**
   * Register handler.
   *
   * @param {any} fn
   * @return void
   */
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  /**
   * Register handler.
   *
   * @param {any} fn
   * @return void
   */
  registerOnTouched(fn: any): void {}

  /**
   * Initialization.
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
