<div class="flex justify-between items-end">
  <p-calendar
    class="a3l-datetime items-end"
    [placeholder]="placeholder"
    [(ngModel)]="value"
    [showIcon]="false"
    [icon]="'a3l-ui-icon-calendar'"
    (onFocus)="focusin()"
    (onClose)="focusout()"
    (onClickOutside)="focusout()"
    (onSelect)="change($event)"
    [showTime]="false"
    [view]="'month'"
    dateFormat="mm/yy"
    #picker
  >
  </p-calendar>

  <ng-container *ngIf="showPresent">
    <a3l-ui-checkbox [scale]="0.7" class="text-xs -mt-4 pr-4" (checkedChange)="setPresent($event)" [checked]="this.isPresent" translate><span class="whitespace-nowrap font-medium">Do dziś</span></a3l-ui-checkbox>
  </ng-container>
</div>
