import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { take, map } from 'rxjs/operators';

import { Store } from '@ngrx/store';

import { CookieDB } from '@a3l/core';

import { State, getLoggedInUser } from '@rex/state';

@Injectable({ providedIn: 'root' })
export class NotLoggedInGuard implements CanActivate {
  /**
   * Create a new instance.
   *
   * @param {Router} router
   * @param {Store<State>} store
   */
  constructor(private router: Router, private store: Store<State>) {
    //
  }

  /**
   * Decide if a route can be activated.
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @return Observable<any>
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    CookieDB.put('intended', state.url);

    return this.store.select(getLoggedInUser).pipe(
      take(1),
      map((user) => {
        if (window.innerWidth < 992) return this.router.parseUrl('/e/mobiles-are-temporarily-unsupported'); // TMP

        if (!user) return true;

        return this.router.parseUrl('/dashboard')
      })
    );
  }
}
