import { trigger, transition, animateChild, query, animate, style, state } from '@angular/animations';

export class AnimationCurves {
  static STANDARD_CURVE = 'cubic-bezier(0.4,0.0,0.2,1)';
  static DECELERATION_CURVE = 'cubic-bezier(0.0,0.0,0.2,1)';
  static ACCELERATION_CURVE = 'cubic-bezier(0.4,0.0,1,1)';
  static SHARP_CURVE = 'cubic-bezier(0.4,0.0,0.6,1)';
}

export class AnimationDurations {
  static COMPLEX = '375ms';
  static ENTERING = '225ms';
  static EXITING = '195ms';
}

export const ngIfAnimation = trigger('ngIfAnimation', [transition(':enter, :leave', [query('@*', animateChild(), { optional: true })])]);

export function fadeInOut(inDuration = 200, outDuration = null) {
  return trigger('fadeInOut', [
    state('void', style({ opacity: 0 })),
    state('*', style({ opacity: 1 })),
    transition('void => *', animate(inDuration)),
    transition('* => void', animate(outDuration === null ? inDuration : outDuration)),
  ]);
}

export function slideInOut(duration = 300) {
  return trigger('slideInOut', [
    state('*', style({ height: '*', opacity: 1 })),
    transition('void => *', [style({ height: 0, opacity: 0 }), animate(duration)]),
    transition('* => void', [animate(duration, style({ height: 0, opacity: 0 }))]),
  ]);
}
