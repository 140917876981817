import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import * as _ from 'lodash';

import { TranslateService } from '@ngx-translate/core';

import { Query } from '@a3l/core';
import { ApiService } from '@a3l/api';

@Injectable({ providedIn: 'root' })
export class DictionaryOfLanguagesQuery extends Query<any> {
  /**
   * Create a new instance.
   *
   * @param {ApiService} service
   * @param {TranslateService} translator
   */
  constructor(private api: ApiService, private translator: TranslateService) {
    super();
  }

  /**
   * Run the query.
   *
   * @return void
   */
  execute(): void {
    this._pending$.next(true);

    this.api
      .get('/dictionaries/languages')
      .pipe(
        map((languages) =>
          languages.map((item) => {
            const { content } = item;

            return { ...item, content };
          })
        ),
        map((languages) => {
          const mappers = {
            pl: ['pl', 'en', 'cs', 'sk', 'hu'],
            en: ['en', 'pl', 'cs', 'sk', 'hu'],
            cs: ['cs', 'sk', 'en', 'pl', 'hu'],
            sk: ['sk', 'cs', 'en', 'pl', 'hu'],
            hu: ['hu', 'en', 'pl', 'cs', 'sk'],
          };

          const mapper = mappers[this.translator.currentLang];

          if (mapper == null) return _.sortBy(languages, ['content']);

          const languagesByMapper = [];
          for (let i = 0, x = mapper.length; i < x; i++) {
            const index = languages.findIndex(({ key }) => key == mapper[i]);

            if (index == -1) continue;

            languagesByMapper.push(...languages.splice(index, 1));
          }

          return [...languagesByMapper, ..._.sortBy(languages, ['content'])];
        }),
        map((languages) => {
          const index = languages.findIndex(({ key }) => key == 'other');

          if (index == -1) return languages;

          const [other] = languages.splice(index, 1);

          return [...languages, other];
        })
      )
      .subscribe((values) => {
        this._pending$.next(false);

        this._value$.next(values);
      });
  }
}
