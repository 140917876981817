import { Inject, Injectable } from '@angular/core';
import { Title as HTMLTitle } from '@angular/platform-browser';
import { BehaviorSubject, Observable } from 'rxjs';

import { CONFIG, Config } from './config';

@Injectable({ providedIn: 'root' })
export class Title {
  /**
   * @param {BehaviorSubject<string>}
   */
  protected _value$: BehaviorSubject<string> = new BehaviorSubject(null);

  /**
   * @var {Observable<string>}
   */
  value$: Observable<string> = this._value$.asObservable();

  /**
   * Create a new instance.
   *
   * @param {HTMLTitle} tag
   * @param {Config} config
   */
  constructor(private tag: HTMLTitle, @Inject(CONFIG) private config: Config) {
    //
  }

  /**
   * Set the title value.
   *
   * @param {string} title
   * @return void
   */
  set(value: string): void {
    this._value$.next(value);

    this.tag.setTitle(value ? `${value} | ${this.config.title}` : this.config.title);
  }
}
