import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import * as moment from 'moment';

import { TranslateService } from '@ngx-translate/core';

import { CookieDB } from '@a3l/core';

@Injectable({ providedIn: 'root' })
export class LanguageGuard implements CanActivate {
  /**
   * Create a new instance.
   *
   * @param {TranslateService} translator
   */
  constructor(private translator: TranslateService) {
    //
  }

  /**
   * Decide if a route can be activated.
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @return any
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    const { lang } = route.queryParams;

    if (!lang) return true;

    CookieDB.put('language', lang);

    this.translator.use(lang);

    moment.locale(lang);

    return true;
  }
}
