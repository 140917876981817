// @dynamic
export class MemoryDB {
  /**
   * @var {any}
   */
  protected static state: any = {};

  /**
   * Store an item in the state.
   *
   * @param {string} key
   * @param {any} value
   * @return void
   */
  static set(key: string, value: any): void {
    this.state[key] = value;
  }

  /**
   * Retrieve an item from the state by key.
   *
   * @param {string} key
   * @return any
   */
  static get<T>(key: string): T {
    return this.state[key];
  }

  /**
   * Remove items from the state.
   *
   * @param {Array<string>} keys
   * @return void
   */
  static forget(...keys: Array<string>): void {
    keys.forEach((key) => delete this.state[key]);
  }
}
