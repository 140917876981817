import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {CdkConnectedOverlay} from "@angular/cdk/overlay";
import {SkillTypeEnum} from "@rex/skills/enums/skill-type.enum";
import {SkillsCommand} from "@rex/skills/commands/skills.command";
import {IndustriesCommand} from "@rex/industries/commands/industries.command";

@Component({
  selector: 'rex-cv-filter-component',
  templateUrl: './cv-filter.component.html',
  styleUrls: ['./cv-filter.component.scss'],
  providers: [
    SkillsCommand,
    IndustriesCommand
  ]
})
export class CvFilterComponent implements OnInit {
  @ViewChild('softSkills') softSkills: ElementRef;
  @ViewChild('hardSkills') hardSkills: ElementRef;
  @ViewChild('industries') industries: ElementRef;
  @ViewChild('localization') localization: ElementRef;
  @ViewChild('salary') salary: ElementRef;
  @ViewChild('availability') availability: ElementRef;
  @ViewChild('types_of_agreement') types_of_agreement: ElementRef;

  @ViewChild('leftColumn') leftColumn: ElementRef;
  @ViewChild('cdkConnectedOverlay') cdkConnectedOverlay: CdkConnectedOverlay;

  public isOpened = false;
  public filterFocus = 'soft_skills';
  @Output() changed: EventEmitter<any> = new EventEmitter();

  public softSkillsSet: any;
  public hardSkillsSet: any;
  public industriesSet: any;

  public form = new FormGroup({
    skills: new FormControl([]),
    hard_skills: new FormControl([]),
    industries: new FormControl([]),
    localization: new FormControl([]),
    salary: new FormControl(null),
    availability: new FormControl(null),
    types_of_agreement: new FormControl([]),
  })

  constructor(
    private skillsCommand: SkillsCommand,
    private industriesCommand: IndustriesCommand,
  ) {
  }

  ngOnInit() {
    this.skillsCommand.list(SkillTypeEnum.SOFT_SKILLS_UNIFIED).subscribe((response) => this.softSkillsSet = response.skills);
    this.skillsCommand.list(SkillTypeEnum.HARD_SKILLS_UNIFIED).subscribe((response) => this.hardSkillsSet = response.skills);
    this.industriesCommand.list().subscribe((response) => this.industriesSet = response.industries);
  }

  switchState() {
    this.isOpened = !this.isOpened;
  }

  clearFilters()
  {
    this.form.clear();
    this.applyFilters()
  }

  changeFocus(state) {
    let element;
    switch(state) {
      case 'soft_skills':
        element = this.softSkills; break;
      case 'hard_skills':
        element = this.hardSkills; break;
      case 'industries':
        element = this.industries; break;
      case 'localization':
        element = this.localization; break;
      case 'salary':
        element = this.salary; break;
      case 'availability':
        element = this.availability; break;
      case 'types_of_agreement':
        element = this.types_of_agreement; break;
    }

    this.leftColumn.nativeElement.scrollTo({ top: element.nativeElement.offsetParent.offsetTop - 40, behavior: 'smooth' });
    this.filterFocus = state;
  }

  getQuickAccessClassRules(access) {
    return {
      'cursor-pointer': true,
      'text-xs': true,
      'mb-5': true,
      'pr-5': true,
      'text-black': this.filterFocus == access,
      'text-gray': this.filterFocus != access,
      'font-bold': this.filterFocus == access,
    }
  }

  applyFilters()
  {
    this.changed.emit(this.form.value);
    this.isOpened = false;
  }

  cancel()
  {
    this.isOpened = false;
  }
}
