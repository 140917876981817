import { Component, Directive, forwardRef, Input, ContentChildren, QueryList, HostListener, ChangeDetectionStrategy, ChangeDetectorRef, Optional, OnInit, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { Control } from './control';

let uniqueId = 0;

@Component({
  selector: 'a3l-ui-radio-group',
  template: '<ng-content></ng-content>',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioGroupComponent),
      multi: true,
    },
    { provide: Control, useExisting: RadioGroupComponent },
  ],
})
export class RadioGroupComponent extends Control implements ControlValueAccessor {
  /**
   * @var {any}
   */
  @Input()
  get value(): any {
    return this._value;
  }
  set value(newValue: any) {
    this._value = newValue;

    this.propagateChange(this.value);

    this.updateSelectedRadioFromValue();
  }

  /**
   * @var {QueryList<RadioItemComponent>}
   */
  @ContentChildren(forwardRef(() => RadioItemComponent), { descendants: true }) //prettier-ignore
  radios: QueryList<RadioItemComponent>;

  /**
   * @var {boolean}
   */
  get canShowValidationInTooltip(): boolean {
    return false;
  }

  /**
   * @var {any}
   */
  private _value: any;

  /**
   * @var {any}
   */
  protected propagateChange: any = () => {};

  /**
   * Create a new instance.
   */
  constructor() {
    super();
  }

  /**
   * Listen for child inputs changes.
   */
  @HostListener('change', ['$event'])
  onChange($event) {
    $event.stopPropagation();

    this.value = $event.target.value;
  }

  /**
   * Write a new value from the form model.
   *
   * @param {any} value
   * @return void
   */
  writeValue(value: any): void {
    this.value = value;
  }

  /**
   * Register handler.
   *
   * @param {any} fn
   * @return void
   */
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  /**
   * Register handler.
   *
   * @param {any} fn
   * @return void
   */
  registerOnTouched(fn: any): void {}

  /**
   * Updates the `selected` radio button from the internal _value state..
   *
   * @return void
   */
  protected updateSelectedRadioFromValue(): void {
    if (!this.radios) {
      return;
    }

    this.radios.forEach((radio) => (radio.checked = radio.value == this.value));
  }
}

@Component({
  selector: 'a3l-ui-radio-item',
  templateUrl: './radio-item.component.html',
  styleUrls: ['./radio-item.component.scss'],
  host: {
    class: 'a3l-ui-radio-item',
    '[class.a3l-ui-radio-item--checked]': 'checked',
    '[class.a3l-ui-radio-item--disabled]': 'disabled',
    '[class.a3l-ui-radio-item--focused]': 'focused',
  },
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadioItemComponent implements OnInit {
  /**
   * @var {any}
   */
  @Input()
  value: any;

  public focused: boolean = false;
  /**
   * @var {RadioGroupComponent}
   */
  group: RadioGroupComponent;

  focusin() {
    this.focused = true;
  }

  focusout() {
    this.focused = false;
  }

  /**
   * @var {boolean}
   */
  @Input()
  get checked(): boolean {
    return this._checked;
  }
  set checked(checked: boolean) {
    this._checked = checked;

    this.changeDetectorRef.markForCheck();
  }

  /**
   * @var {boolean}
   */
  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(checked: boolean) {
    this._disabled = checked;
  }


  /**
   * @var {string}
   */
  uniqueId: string = `form-radio-${++uniqueId}`;

  /**
   * @var {boolean}
   */
  _checked: boolean = false;

  /**
   * @var {boolean}
   */
  _disabled: boolean = false;

  /**
   * Create a new instance.
   *
   * @param {ChangeDetectorRef} changeDetectorRef
   */
  constructor(@Optional() group: RadioGroupComponent, private changeDetectorRef: ChangeDetectorRef) {
    this.group = group;
  }

  /**
   * Initialization.
   */
  ngOnInit() {
    this.group && (this.checked = this.group.value == this.value);
  }
}

@Directive({
  selector: 'a3l-ui-radio-item[outlined]',
  host: {
    class: 'a3l-ui-radio-item--outlined',
  },
})
export class RadioItemAsOutlinedDirective {
  //
}
