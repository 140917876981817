import { Directive, ElementRef, OnInit, Output, EventEmitter } from '@angular/core';

import {Localization, PlaceToLocalizationConverter} from "./localizator";
import {GoogleMap} from "../../../../../libs/core/src/lib/GoogleMap";

@Directive({ selector: '[rex-candidate-wizard-localization-searcher]' })
export class LocalizationSearcher implements OnInit {
  /**
   * @var {EventEmitter<Localization[]>}
   */
  @Output()
  done: EventEmitter<Localization[]> = new EventEmitter();

  /**
   * Create a new instance.
   *
   * @param {ElementRef} elementRef
   *
   * @param {GoogleMap} googleMap
   */
  constructor(private elementRef: ElementRef, private googleMap: GoogleMap) {
    //
  }

  /**
   * Initialization.
   */
  ngOnInit() {
    this.googleMap.loader.load().then(() => {
      const google = (window as any).google;

      const autocomplete = new google.maps.places.Autocomplete(this.elementRef.nativeElement, { types: ['(regions)'] });

      autocomplete.addListener('place_changed', () => {
        const { address_components: places } = autocomplete.getPlace();

        const localizations = places.filter(PlaceToLocalizationConverter.convertable).map(PlaceToLocalizationConverter.convert);

        this.done.emit(localizations);
      });
    });
  }
}
