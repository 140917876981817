import { Pipe, PipeTransform, Inject } from '@angular/core';
import { Observable } from 'rxjs';

import { User } from './user';

import { Store } from '@ngrx/store';

import { State, getLoggedInUser } from '@rex/state';

@Pipe({ name: 'rexAuthuser' })
export class AuthuserPipe implements PipeTransform {
  /**
   * Create a new instance.
   *
   * @param {Store<State>} store
   */
  constructor(private store: Store<State>) {
    //
  }

  /**
   * Return authenticated user.
   *
   * @return Observable<User>
   */
  transform(): Observable<User> {
    return this.store.select(getLoggedInUser);
  }
}
