<a3l-ui-spinner size="30" class="mx-auto my-6 block" *ngIf="loading$ | async"></a3l-ui-spinner>
<ng-container *ngIf="!(loading$ | async)">
  <ng-container *ngIf="items$ | async as items">
    <ng-template let-c [ngTemplateOutlet]="t" [ngTemplateOutletContext]="{ $implicit: { limit: 5, min: 5, max: items.length } }" #t>
      <ul class="m-0 p-0 list-none">
        <li class="mb-2 font-medium" *ngFor="let item of items$ | async | slice: 0:c.limit">
          <rex-languages-item-field [id]="item.id" [content]="item.content" [selection]="selection">{{ item.content}}</rex-languages-item-field>
        </li>
      </ul>
      <div class="mt-2 text-center" *ngIf="c.max > c.limit">
        <button type="button" class="font-semibold text-sm text-gray-dark hover:text-primary" (click)="c.limit = c.max">
          <span class="mr-1" translate>Rozwiń</span>
          <span>(+{{ items.length - c.limit }})</span>
        </button>
      </div>
      <div class="mt-2 text-center" *ngIf="c.limit == c.max && c.max > c.min">
        <button type="button" class="font-semibold text-sm text-gray-dark hover:text-primary" (click)="c.limit = 5" translate>Zwiń</button>
      </div>
    </ng-template>
  </ng-container>
</ng-container>
