import {Component, Input} from "@angular/core";

@Component({
  selector: 'rex-star-rating',
  templateUrl: './star-rating.component.html',
  styleUrls: ['./star-rating.component.scss']
})
export class StarRatingComponent {
  @Input() rating: number;

  @Input() maxStars: number = 5;

  get stars() {
    return Array(Math.floor(this.rating)).fill(0);
  }

  get emptyStars() {
    return Array(Math.floor(this.maxStars - this.rating)).fill(0);
  }
}
