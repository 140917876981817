import { Directive, HostListener, Input, ElementRef, ViewContainerRef, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { Overlay, OverlayRef, OverlayConfig } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { Subscription, merge } from 'rxjs';

import { DropdownComponent } from './dropdown.component';

export const UI_DROPDOWN_PANEL_CLASS = 'cdk-a3l-ui-dropdown-pane';

@Directive({ selector: '[a3l-ui-dropdown]' })
export class DropdownDirective implements OnInit, OnDestroy {
  /**
   * @var {DropdownComponent}
   */
  @Input('a3l-ui-dropdown')
  dropdown: DropdownComponent;

  /**
   * @var {'start' | 'below'}
   */
  @Input('a3l-ui-dropdown-position')
  position: 'start' | 'below' = 'start';

  /**
   * @var {OverlayRef}
   */
  private overlayRef: OverlayRef | null;

  /**
   * @var {Subscription}
   */
  private closeSubscription: Subscription = Subscription.EMPTY;

  /**
   * @var {Subscription}
   */
  private dropdownDestroySubscription: Subscription = Subscription.EMPTY;

  /**
   * Create a new instance.
   *
   * @param {Overlay} overlay
   * @param {ElementRef} elementRef
   * @param {ViewContainerRef} viewContainerRef
   */
  constructor(private overlay: Overlay, private elementRef: ElementRef, private renderer: Renderer2, private viewContainerRef: ViewContainerRef) {
    //
  }

  /**
   * Initialization.
   *
   * @return void
   */
  ngOnInit() {
    this.dropdownDestroySubscription = this.dropdown.afterDestroyed().subscribe(this.hide.bind(this));
  }

  /**
   * Listen for click event and toggle the dropdown.
   *
   * @return void
   */
  @HostListener('click', ['$event'])
  onClick(event: MouseEvent): void {
    event.preventDefault();

    this.show();
  }

  /**
   * Show the dropdown.
   *
   * @return void
   */
  show(): void {
    const positions: any = [
      {
        originX: 'start',
        originY: this.position == 'start' ? 'top' : 'bottom',
        overlayX: 'start',
        overlayY: 'top',
      },
    ];

    const strategy = this.overlay.position().flexibleConnectedTo(this.elementRef).withPositions(positions).withPush(true);

    const overlayConfig: OverlayConfig = {
      positionStrategy: strategy,
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
      disposeOnNavigation: true,
      panelClass: UI_DROPDOWN_PANEL_CLASS,
      scrollStrategy: this.overlay.scrollStrategies.close(),
    };

    this.overlayRef = this.overlay.create(overlayConfig);

    this.overlayRef.attach(new TemplatePortal(this.dropdown.templateRef, this.viewContainerRef));

    this.closeSubscription = merge(this.overlayRef.backdropClick(), this.overlayRef.detachments()).subscribe(this.hide.bind(this));

    if (this.dropdown.positionOrigin) {
      const rect = this.dropdown.positionOrigin.elementRef.nativeElement.getBoundingClientRect();

      this.renderer.setStyle(this.overlayRef.hostElement, 'marginTop', `-${rect.top}px`);
      this.renderer.setStyle(this.overlayRef.hostElement, 'marginLeft', `-${rect.left}px`);
    }

    this.dropdown.show(this.elementRef);
  }

  /**
   * Hide the dropdown.
   *
   * @return void
   */
  hide(): void {
    this.dropdown.hide();

    this.closeSubscription.unsubscribe();

    this.overlayRef && this.overlayRef.dispose();
  }

  /**
   * Dispose the dropdown when destroyed.
   */
  ngOnDestroy() {
    this.hide();

    this.dropdownDestroySubscription.unsubscribe();
  }
}
