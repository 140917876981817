import { HttpException } from './exception';

import { HttpResponse } from './response';

export class HttpForbiddenException extends HttpException {
  /**
   * @var {string}
   */
  static readonly type: string = 'HttpForbiddenException';

  /**
   * @var {string}
   */
  public readonly type: string = HttpForbiddenException.type;

  /**
   * @param {string}
   */
  static readonly status: number = HttpResponse.FORBIDDEN;
}
