import { Component, Input, Optional, HostListener } from '@angular/core';

import { Sorter } from './sorter';

import { DatasetTableColumn } from './dataset-table-column';

@Component({
  selector: 'a3l-dataset-table-hcell, th[a3l-dataset-table-hcell]',
  template: '<ng-content></ng-content>',
  host: {
    class: 'a3l-dataset-table-hcell',
    '[class.a3l-dataset-table-hcell--sticky]': 'sticky',
    '[class.a3l-dataset-table-hcell--interactive]': 'interactive',
  },
})
export class DatasetTableHCellPresenter {
  /**
   * @var {boolean}
   */
  @Input()
  get sticky(): boolean {
    return this._sticky;
  }
  set sticky(value: boolean) {
    this._sticky = value !== false;
  }

  /**
   * @var {boolean}
   */
  @Input('sortable')
  get sortable(): boolean {
    return this._sortable;
  }
  set sortable(value: boolean) {
    this._sortable = value !== false;
  }

  /**
   * @var {string|null}
   */
  @Input('sorterLabel')
  get sorterLabel(): string|null {
    return this._sorterLabel;
  }
  set sorterLabel(value: string|null) {
    this._sorterLabel = value;
  }

  /**
   * @var {boolean}
   */
  get interactive(): boolean {
    return this.sortable;
  }

  /**
   * @var {string}
   */
  protected get key(): string {
    return this.column?.name;
  }

  /**
   * @var {boolean}
   */
  protected _sticky: boolean = false;

  /**
   * @var {boolean}
   */
  protected _sortable: boolean = false;

  /**
   * @var {string|null}
   */
  protected _sorterLabel: string = null;

  /**
   * Create a new instance.
   *
   * @param {Sorter} sorter
   * @param {DatasetTableColumn} column
   */
  constructor(@Optional() protected sorter: Sorter, @Optional() protected column: DatasetTableColumn) {
    //
  }

  /**
   * Handle the click event.
   *
   * @return void
   */
  @HostListener('click')
  onDOMClick(): void {
    this.sorter.sort(this.key);
  }
}
