import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

const NUMBER_OF_FIRST_PAGE = 1;

const range = (start, stop, step = 1) => Array(Math.ceil((stop - start) / step)).fill(start).map((x, y) => x + y * step); // prettier-ignore

@Component({
  selector: 'a3l-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
  host: {
    class: 'a3l-paginator',
  },
  encapsulation: ViewEncapsulation.None,
})
export class Paginator {
  /**
   * @var {number}
   */
  @Input()
  limit: number = 8;

  /**
   * @var {number}
   */
  @Input()
  total: number = 0;

  /**
   * @var {number}
   */
  get page(): number {
    return this._page;
  }
  set page(value: number) {
    this.pageChange.emit((this._page = value));
  }

  /**
   * @var {EventEmitter<number>}
   */
  @Output()
  pageChange: EventEmitter<number> = new EventEmitter();

  /**
   * @var {number}
   */
  protected _page: number = NUMBER_OF_FIRST_PAGE;

  /**
   * Create a new instance.
   */
  constructor() {
    //
  }

  /**
   * Update the value.
   *
   * @param {string} value
   * @return void
   */
  update(value: string): void {
    if (value === null || value == '') return;

    const maybePage = parseInt(value);

    if (maybePage < 1) {
      this.page = 1;

      return;
    }

    if (maybePage > this.total) {
      this.page = this.total;

      return;
    }

    this.page = maybePage;
  }

  /**
   * Clear the value.
   *
   * @return void
   */
  clear(): void {
    this.page = NUMBER_OF_FIRST_PAGE;
  }
}
