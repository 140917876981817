import {Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {Control} from "../control";
import {CookieService} from "ngx-cookie-service";

@Component({
  selector: 'rex-switcher-component',
  templateUrl: './switcher.component.html',
  styleUrls: ['./switcher.component.scss'],
  host: {
    class: 'rex-switcher-component',
  },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SwitcherComponent),
      multi: true,
    },
    {provide: Control, useExisting: SwitcherComponent},
  ],
  encapsulation: ViewEncapsulation.None,
})
export class SwitcherComponent extends Control implements ControlValueAccessor, OnInit {

  public value:boolean = false;

  defaultValue:boolean = true;

  @Input('label')
  label:string;

  @Output() changed: EventEmitter<any> = new EventEmitter();

  constructor(private cookieService: CookieService) {
    super();
  }

  ngOnInit() {
    if(this.cookieService.check(this.label)) {
      this.value = this.cookieService.get(this.label) === 'true';
    } else {
      this.value = this.defaultValue;
    }
    this.changed.emit(this.value);
    this.propagateChange(this.value);
  }

  change() {
    this.value = !this.value;

    let date = new Date();
    date.setHours(date.getHours() + 8);
    this.cookieService.set(this.label, String(this.value), date);
    this.changed.emit(this.value);
    this.propagateChange(this.value);
  }

  /**
   * @var {any}
   */
  protected propagateChange: any = () => {
  };

  writeValue(obj: any) {
    this.value = obj;
  }

  /**
   * Register handler.
   *
   * @param {any} fn
   * @return void
   */
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  /**
   * Register handler.
   *
   * @param {any} fn
   * @return void
   */
  registerOnTouched(fn: any): void {
  }
}
