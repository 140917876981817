import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from "@angular/core";
import {FormGroup} from "@angular/forms";

interface AutoCompleteCompleteEvent {
  originalEvent: Event;
  query: string;
}

@Component({
  selector: 'rex-skill-item',
  templateUrl: 'skill.item.html',
  styleUrls: ['skill.item.scss', '../rate-input.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SkillItem implements OnInit {
  /**
   * @var {any}
   */
  @Input()
  suggestions: any = [];

  filteredSuggestions: any[] | undefined;


  @Input() form: FormGroup;
  @Output() itemChangedEvent = new EventEmitter<FormGroup>();

  ngOnInit() {
    this.form.valueChanges.subscribe((values) => {
      this.itemChangedEvent.emit(this.form);
    })
  }

  filterSuggestions(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < (this.suggestions as any[]).length; i++) {
      let suggestion = (this.suggestions as any[])[i];

      if (suggestion && suggestion.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(suggestion);
      }
    }

    this.filteredSuggestions = filtered;
  }
}
