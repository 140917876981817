import { Directive, Input, HostListener, Output, EventEmitter } from '@angular/core';
import {FormGroup, AbstractControl, FormArray, ValidationErrors} from '@angular/forms';

declare module '@angular/forms' {
  export interface AbstractControl {
    isEmpty(): boolean;

    isValid(): boolean;

    validate(): boolean;

    hasErrors(): boolean;

    setValidationErrors(errors: { [key: string]: any }): void;

    getValidationErrorsHtmlMessage(key : string): string;

    clear(): void;
  }
}

AbstractControl.prototype.isEmpty = function (): boolean {
  if (this instanceof FormGroup || this instanceof FormArray) {
    for (const name of Object.keys(this.controls)) {
      if (!this.get(name).isEmpty()) {
        return false;
      }
    }

    return true;
  }

  return !this.value;
};

AbstractControl.prototype.isValid = function (): boolean {
  if (this instanceof FormGroup || this instanceof FormArray) {
    let isValid = true;

    for (const name of Object.keys(this.controls)) {
      const control = this.get(name);

      if (!control.isValid()) {
        isValid = false;
      }
    }

    return isValid && (this.status == 'DISABLED' ? true : this.valid);
  }

  return this.status == 'DISABLED' ? true : this.valid;
};

AbstractControl.prototype.validate = function (): boolean {
  if (this instanceof FormGroup || this instanceof FormArray) {
    let isValid = true;

    for (const name of Object.keys(this.controls)) {
      const control = this.get(name);

      control.markAsTouched();
      control.markAsPristine();

      if (!control.isValid()) {
        control.markAsDirty();

        isValid = false;
      }
    }

    return isValid && (this.status == 'DISABLED' ? true : this.valid);
  }

  return this.status == 'DISABLED' ? true : this.valid;
};

AbstractControl.prototype.hasErrors = function (): boolean {
  if (this instanceof FormGroup || this instanceof FormArray) {
    for (const name of Object.keys(this.controls)) {
      const control = this.get(name);

      if (control.hasErrors()) {
        return true;
      }
    }

    return false;
  }

  return this.hasError();
};

AbstractControl.prototype.setValidationErrors = function (errors = {}): void {
  Object.keys(errors).forEach((key) => {
    let error = errors[key];

    if (Array.isArray(errors[key])) {
      error = errors[key][0];
    }

    const control = this.get(key);

    if (!control) return;

    control.markAsDirty();
    control.markAsTouched();

    control.setErrors({ [key]: errors });
  });
};

AbstractControl.prototype.getValidationErrorsHtmlMessage = function (key= '') {
  const control = this.get(key);

  if (control === null) {
    return 'validation.' + key;
  }

  const errors = control.getError(key);
  let errorsMessage = ''

  if(errors === true) {
    return 'validation.' + key;
  }

  errors[key].forEach(function (value) {
    errorsMessage +=  value + '<br>';
  });

  return errorsMessage;
}

AbstractControl.prototype.clear = function (): void {
  this.reset();
  this.markAsPristine();
  this.markAsUntouched();
  this.updateValueAndValidity();
};

@Directive({
  selector: '[formGroup]',
})
export class FormDirective {
  /**
   * @var {FormGroup}
   */
  @Input('formGroup')
  form: FormGroup;

  /**
   * @var {EventEmitter<void>}
   */
  @Output()
  onSubmit: EventEmitter<void> = new EventEmitter();

  /**
   * Handle ngSubmit event on form.
   *
   * @return void
   */
  @HostListener('ngSubmit') onNgSubmit() {
    if (this.form.validate()) {
      this.onSubmit.emit();
    }

    return false;
  }

  /**
   * Create a new instance.
   */
  constructor() {
    //
  }
}
