import { Observable, BehaviorSubject, Subject, of } from 'rxjs';

export abstract class Query<T = any> {
  /**
   * @var {Subject<any>}
   */
  protected _errors$: Subject<any> = new Subject<any>();

  /**
   * @var {BehaviorSubject<T>}
   */
  protected _value$: BehaviorSubject<T> = new BehaviorSubject<T>(null);

  /**
   * @var {BehaviorSubject<boolean>}
   */
  protected _pending$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /**
   * @var {Observable<T>}
   */
  value$: Observable<T> = this._value$.asObservable();

  /**
   * @var {Observable<any>}
   */
  errors$: Observable<any> = this._errors$.asObservable();

  /**
   * @var {Observable<boolean>}
   */
  pending$: Observable<boolean> = this._pending$.asObservable();

  /**
   * Run the query.
   *
   * @param {any} citeria
   * @return Observable<T>
   */
  run(...citeria: any): Observable<T> {
    return of();
  }

  /**
   * Run the query.
   *
   * @param {any} citeria
   * @return void
   */
  execute(...citeria: any): void {
    //
  }
}
