import { Injectable, TemplateRef } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class Portal {
  /**
   * param {any}
   */
  protected targets: any = {};

  /**
   * Create a new instance.
   */
  constructor() {
    //
  }

  /**
   * Register new portal.
   *
   * @param {string} name
   * @param {any} component
   * @return void
   */
  register(name: string, component: any): void {
    if (this.targets.hasOwnProperty(name)) return;

    this.targets[name] = component;
  }

  /**
   * Attach a template to the portal.
   *
   * @param {string} to
   * @param {TemplateRef<any>} template
   * @return void
   */
  attach(to: string, template: TemplateRef<any>): void {
    if (!this.targets.hasOwnProperty(to)) return;

    this.targets[to].attach(template);
  }

  /**
   * Detach a template from the portal.
   *
   * @param {string} to
   * @return void
   */
  detach(to: string): void {
    if (!this.targets.hasOwnProperty(to)) return;

    this.targets[to].attach();
  }

  /**
   * Unregister the portal.
   *
   * @param {string} name
   * @return void
   */
  unregister(name: string): void {
    if (!this.targets.hasOwnProperty(name)) return;

    delete this.targets[name];
  }
}
