import { Specification } from '@a3l/core';
import {OrganizationUserStatusEnum} from "@rex/organization/enums/organization-user-status.enum";

export class RecruiterIsBlockedInOrganizationSpecification extends Specification<any> {
  organizationUserStatusEnum: typeof OrganizationUserStatusEnum = OrganizationUserStatusEnum;

  isSatisfiedBy(recruiter: any): boolean {
    if (recruiter.organization) {
      return  recruiter.organization.status == this.organizationUserStatusEnum.BLOCKED || recruiter.organization.is_blocked;
    } else {
      return false;
    }
  }
}
