import {Component, Inject, OnDestroy, OnInit} from "@angular/core";
import {FormControl, FormGroup} from "@angular/forms";
import {Subscription} from "rxjs";
import {DialogRef, UI_DIALOG_DATA} from "@a3l/utilities";
import {TranslateService} from "@ngx-translate/core";

@Component({
  templateUrl: './blind-cv-config.dialog.html'
})
export class BlindCvConfigDialog implements OnInit, OnDestroy {
  /**
   * @var {FormGroup}
   */
  form: FormGroup = new FormGroup({
    show_company_data: new FormControl(true),
    show_availability_data: new FormControl(true),
    show_blind_cv: new FormControl(true),
    show_financial_expectations: new FormControl(true),
    language: new FormControl(this.translator.currentLang),
  });

  changeLanguage(language) {
    this.form.controls['language'].patchValue(language);
  }
  /**
   * @var {Subscription}
   */
  protected subscription: Subscription = new Subscription();

  constructor(
    @Inject(UI_DIALOG_DATA) public blindSettingsData = null,
    private dialogRef: DialogRef<BlindCvConfigDialog>,
    private translator: TranslateService
  ) {

    if (blindSettingsData && Object.keys(blindSettingsData).length) {
      this.form.get('show_company_data').patchValue(this.blindSettingsData.showCompanyData);
      this.form.get('show_availability_data').patchValue(this.blindSettingsData.showAvailabilityData);
      this.form.get('show_financial_expectations').patchValue(this.blindSettingsData.showFinancialExpectations);
    }
  }

  ngOnInit() {

  }


  /**
   * Close the dialog.
   *
   * @return void
   */
  close(): void {
    this.dialogRef.close(
      {
        show_company_data: this.form.get('show_company_data').value,
        show_availability_data: this.form.get('show_availability_data').value,
        show_blind_cv: this.form.get('show_blind_cv').value,
        show_financial_expectations: this.form.get('show_financial_expectations').value,
        language: this.form.get('language').value
      }
    );
  }

  /**
   * Cleanup.
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
