import {Component, Input} from "@angular/core";
import {GroupableComponentInterface} from "../../../../../../../libs/dataset/src/lib/GroupableComponentInterface";
import {OrganizationUserStatusEnum} from "@rex/organization/enums/organization-user-status.enum";

@Component({
  templateUrl: './order-request-groupable.component.html',
})
export class OrderRequestGroupableComponent implements GroupableComponentInterface {

  @Input() public item: any;
  @Input() public column: any;
  @Input() public view: any;
  @Input() public entity: any;

  additionalData() {
    return {
      order_request_id: this.item.order_request_id
    };
  }

  dateOlderThan30Days(date) {
    let monthAgo = new Date(new Date().setDate((new Date()).getDate() - 30));
    return (new Date(date)).getTime() < monthAgo.getTime();
  }
}
