import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

import { CookieDB } from '@a3l/core';

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {
  /**
   * Create a new instance.
   */
  constructor() {
    //
  }

  /**
   * Intercept the http request.
   *
   * @param {HttpRequest<any>} request
   * @param {HttpHandler} next
   * @return {Observable<HttpEvent<any>>}
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const language = CookieDB.get('language');

    if (language) {
      request = request.clone({ headers: request.headers.set('X-LANGUAGE', language) });
    }

    return next.handle(request);
  }
}
