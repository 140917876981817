import {SkillsField} from "@rex/common/skills/skills.field";
import {Component, forwardRef, ViewEncapsulation} from "@angular/core";
import {NG_VALUE_ACCESSOR} from "@angular/forms";
import {Control} from "@a3l/utilities";
import {Dispatcher} from "@a3l/core";
import {SkillForm} from "@rex/common/skills/skill.form";
import {SkillsCommand} from "@rex/skills/commands/skills.command";
import {SkillTypeEnum} from "@rex/skills/enums/skill-type.enum";
import {CvParserCandidateHardSkillsEvent} from "@rex/shared/recruitments/events/cv-parser-candidate-hard-skills.event";
import {CvParserRecruitmentSkillsEvent} from "@rex/shared/recruitments/events/cv-parser-recruitment-skills.event";
import {
  CvParserRecruitmentHardSkillsEvent
} from "@rex/shared/recruitments/events/cv-parser-recruitment-hard-skills.event";

@Component({
  selector: 'rex-hard-skills-field',
  templateUrl: 'skills.field.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => HardSkillsField),
      multi: true,
    },
    { provide: Control, useExisting: HardSkillsField },
    SkillsCommand
  ],
  encapsulation: ViewEncapsulation.None
})
export class HardSkillsField extends SkillsField {
  addNewButtonText = 'candidate::panel.wizard.navigate.add_new_hard_skill';
  titleText = 'candidate::panel.wizard.title.hard_skills';
  infoText = 'messages.hard_skills_info'

  constructor(
    protected dispatcher: Dispatcher,
    protected skillForm: SkillForm,
    protected skillsCommand: SkillsCommand,
  ) {
    super(dispatcher, skillForm, skillsCommand);
    this.skillsCommand.list(SkillTypeEnum.HARD_SKILLS_UNIFIED).subscribe((response) => this.suggestions = response.skills);
  }

  ngOnInit() {
    super.ngOnInit();

    let parse = ({value, disabled}) => {
      if(value === null) {
        this.value = [];
      } else if(value) {
        value.forEach((hardSkills) => {
          this.value.push(this.skillForm.formFromArray(hardSkills));
        });
      }

      this.propagateChange(this.value);
      disabled ? this.isLoading = true : this.isLoading = false;
    }

    this.dispatcher.listen(CvParserCandidateHardSkillsEvent).subscribe((values) => parse(values));
    this.dispatcher.listen(CvParserRecruitmentHardSkillsEvent).subscribe((values) => parse(values));
  }
}
