<form [formGroup]="form" [ngClass]="{ 'as-unclickable': processing$ | async }">
  <a3l-ui-dialog-header>
    <h2 translate>evaluation::panel.modal.title</h2>
  </a3l-ui-dialog-header>
  <a3l-ui-dialog-content>
    <a3l-ui-form-group class="mb-2" [forceShowError]="true">
      <label a3l-ui-label translate>evaluation::panel.modal.rate_label</label>
      <rex-evaluation-choice-rating [iconClass]="this.getIconClass()" [suggestions]="this.getChoiceSuggestions()" formControlName="choice_answers"></rex-evaluation-choice-rating>
      <ng-template a3l-ui-error-message let-key="key" *ngIf="form.controls['choice_answers'].errors && form.controls['choice_answers'].errors.autocomplete">{{ 'Musisz uzupełnić wszystkie elementy' | translate }}</ng-template>
    </a3l-ui-form-group>
    <a3l-ui-form-group class="mb-2">
      <label a3l-ui-label translate>evaluation::panel.modal.note_label</label>
      <quill-editor a3l-ui-input [placeholder]="'Wpisz' | translate" formControlName="note"></quill-editor>
    </a3l-ui-form-group>
  </a3l-ui-dialog-content>
  <a3l-ui-dialog-footer class="text-center">
    <button class="w-full" type="submit" a3l-ui-button color="primary" [processing]="processing$ | async" (click)="process()">Zapisz</button>
    <button type="button" class="mt-7 as-link no-underline text-gray text-sm hover:text-primary" (click)="close()" translate>Anuluj</button>
  </a3l-ui-dialog-footer>
</form>
