import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Store } from '@ngrx/store';

import { Query } from '@a3l/core';

import { State, getLoggedInUser } from '@rex/state';

@Injectable({ providedIn: 'root' })
export class AuthuserQuery extends Query<any> {
  /**
   * @var {Observable<any>}
   */
  value$: Observable<any> = this.store.select(getLoggedInUser);

  /**
   * Create a new instance.
   *
   * @param {Store<State>} store
   */
  constructor(private store: Store<State>) {
    super();
  }

  /**
   * Run the query.
   *
   * @param {any} citeria
   * @return void
   */
  execute(citeria?: any): void {
    //
  }
}
