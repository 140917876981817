import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {CookieDB} from "@a3l/core";
import {RecruiterStatus} from "@rex/core/recruiter/enums/status";
import {AuthuserQuery} from "@rex/auth";

@Component({
  templateUrl: './simple.layout.html',
  styleUrls: ['./simple.layout.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SimpleLayout implements OnInit {
  hasParentAuthUser: boolean = false;

  public recruiterStatus: typeof RecruiterStatus = RecruiterStatus;

  /**
   * @var {boolean}
   */
  get mobileable() {
    return window.innerWidth < 992;
  }

  /**
   * Create a new instance.
   */
  constructor(private query: AuthuserQuery) {
  }

  statusesAllowedToAddOrganization = [this.recruiterStatus.ACTIVE, this.recruiterStatus.INACTIVE, this.recruiterStatus.WAITING, this.recruiterStatus.UNKNOWN]

  showUserDropdown = false;

  ngOnInit() {
    this.query.value$.subscribe((user) => {
      if (user && user.organizations) {
        this.showUserDropdown = user.organizations.length > 1 ?? this.statusesAllowedToAddOrganization.indexOf(user.status) != -1;
      }
    });

  }

  /**
   * Initialization.
   */
  ngAfterContentInit() {
    this.hasParentAuthUser = CookieDB.get('parent_access_token');
  }
}
