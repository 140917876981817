import {Component, Input} from "@angular/core";

@Component({
  selector: 'rex-user-id',
  templateUrl: './user-id.component.html'
})
export class UserIdComponent {
  @Input()
  public user: any

}
