import { HttpException } from './exception';

import { HttpResponse } from './response';

export class HttpBadRequestException extends HttpException {
  /**
   * @var {string}
   */
  static readonly type: string = 'HttpBadRequestException';

  /**
   * @var {string}
   */
  public readonly type: string = HttpBadRequestException.type;

  /**
   * @param {string}
   */
  static readonly status: number = HttpResponse.BAD_REQUEST;
}
