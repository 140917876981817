import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ScrollbarDirective } from './scrollbar.directive';
import { ScrollbarComponent } from './scrollbar.component';

const PUBLIC_API = [ScrollbarDirective, ScrollbarComponent];

@NgModule({
  declarations: [...PUBLIC_API],
  imports: [CommonModule],
  exports: [...PUBLIC_API],
})
export class ScrollbarModule {}
