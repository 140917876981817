import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[autofocus]',
})
export class AutofocusDirective implements OnInit {
  /**
   * @var {boolean}
   */
  @Input()
  set autofocus(condition: boolean) {
    this.focus = condition !== false;
  }

  /**
   * @var {boolean}
   */
  protected focus: boolean = true;

  /**
   * Create a new instance.
   *
   * @param {ElementRef} elementRef
   */
  constructor(private elementRef: ElementRef) {}

  /**
   * Initialization.
   *
   * @param {ElementRef} elementRef
   */
  ngOnInit() {
    if (!this.focus) return;

    setTimeout(() => this.elementRef.nativeElement.focus());
  }
}
