import {Specification} from "@a3l/core";
import {OrganizationUserSourceEnum} from "@rex/organization/enums/organization-user-source.enum";

export class IsInvitedOrganizationUserSpecification extends Specification<any> {
  organizationUserSourceEnum: typeof OrganizationUserSourceEnum = OrganizationUserSourceEnum;

  isSatisfiedBy(user: any): boolean {
    const { organization } = user;

    return organization && organization.source === this.organizationUserSourceEnum.INVITATION;
  }
}
