import {Control} from "@a3l/utilities";
import {Component, EventEmitter, forwardRef, OnDestroy, OnInit, Output, ViewEncapsulation} from "@angular/core";
import {ControlValueAccessor, FormGroup, NG_VALUE_ACCESSOR} from "@angular/forms";
import {Observable, Subscription} from "rxjs";
import {Dispatcher} from "@a3l/core";
import {IndustryForm} from "@rex/common/industries/industry.form";
import {DictionaryArrayOfIndustriesQuery} from "@rex/common/dictionary-array-of-industries.query";
import {CvParserCandidateIndustriesEvent} from "@rex/shared/recruitments/events/cv-parser-candidate-industries.event";
import {IndustriesQuery} from "@rex/common/industries.query";

@Component({
  selector: 'rex-industries-field',
  templateUrl: 'industries.field.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IndustriesField),
      multi: true,
    },
    { provide: Control, useExisting: IndustriesField }
  ],
  encapsulation: ViewEncapsulation.None
})
export class IndustriesField extends Control implements OnInit, OnDestroy, ControlValueAccessor {
  isValid = true;
  isLoading = false;

  /**
   * @var {any}
   */
  value: any = [];

  /**
   * @var {Observable<any[]>}
   */
  industriesDictionary$: Observable<any[]> = this.industriesQuery.value$;

  /**
   * @var {any}
   */
  protected propagateChange: any = () => {};

  /**
   * @var {Subscription}
   */
  protected subscription: Subscription = Subscription.EMPTY;

  constructor(
    protected dispatcher: Dispatcher,
    protected industryForm: IndustryForm,
    private industriesQuery: IndustriesQuery,
  ) {
    super();
  }

  addNew() {
    if (this.value.length) this.validate();

    if (!this.isValid) return;

    this.value.push(this.industryForm.emptyForm());

    this.propagateChange(this.formattedValues());
  }

  validate()
  {
    this.value.forEach((formGroup: FormGroup) => {
      this.isValid = formGroup.validate();

      if (!this.isValid) return;
    });
  }

  removePosition(uuid) {
    this.value.splice(this.getItemIndexByUuid(uuid), 1);
    this.propagateChange(this.formattedValues())
    this.validate();
  }

  getItemIndexByUuid(uuid) {
    let foundIndex = undefined;
    this.value.forEach((item, index) => {
      if(item.controls['uuid'].value == uuid) {
        foundIndex = index;
      }
    });

    return foundIndex;
  }

  update($event) {
    this.value[this.getItemIndexByUuid($event.controls['uuid'].value)] = $event;
    this.propagateChange(this.formattedValues())
  }

  formattedValues()
  {
    let newValues = [];

    this.value.forEach((item: FormGroup) => {
      if (!item.get('value').value) {
        return;
      }

      newValues.push(item.get('value').value);

    });

    return newValues;
  }

  identify(index, item)
  {
    return item.controls['uuid'].value;
  }

  ngOnInit() {
    this.industriesQuery.execute();

    this.dispatcher.listen(CvParserCandidateIndustriesEvent).subscribe(({value, disabled}) => {
      if(value === null) {
        this.value = [];
      } else if(value) {
        value.forEach((industry: string) => {
          this.value.push(this.industryForm.formFromIndustryName(industry));
        });
      }

      this.propagateChange(this.formattedValues());

      disabled ? this.isLoading = true : this.isLoading = false;
    });
  }

  /**
   * Write a new value from the form model.
   *
   * @param {any} value
   * @return void
   */
  writeValue(value: any): void {
    if (!value || value.length == 0) return;
    this.value = value.map((item) => this.industryForm.formFromIndustryName(item));
  }

  /**
   * Register handler.
   *
   * @param {any} fn
   * @return void
   */
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  /**
   * Register handler.
   *
   * @param {any} fn
   * @return void
   */
  registerOnTouched(fn: any): void {}

  /**
   * Initialization.
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
