import { Component, ViewEncapsulation, Input } from '@angular/core';

@Component({
  selector: '[a3l-dataset-filter-presenter]',
  template: '<ng-content></ng-content>',
  host: {
    class: 'a3l-dataset-filter',
    '[class.a3l-dataset-filter--active]': 'active',
    '[class.a3l-dataset-filter--important]': 'important',
  },
  styleUrls: ['./filter.presenter.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FilterPresenter {
  /**
   * @var {boolean}
   */
  @Input('a3l-dataset-filter-presenter-active')
  get active(): boolean {
    return this._active;
  }
  set active(value: boolean) {
    this._active = value !== false;
  }

  /**
   * @var {boolean}
   */
  @Input('a3l-dataset-filter-presenter-important')
  get important(): boolean {
    return this._important;
  }
  set important(value: boolean) {
    this._important = value !== false;
  }

  /**
   * @var {boolean}
   */
  _active: boolean = false;

  /**
   * @var {boolean}
   */
  _important: boolean = false;

  /**
   * Create a new instance.
   */
  constructor() {
    //
  }
}
