import { Directive, Input, TemplateRef, ViewContainerRef, EmbeddedViewRef } from '@angular/core';

@Directive({ selector: '[a3l-dataset-filter]' })
export class FilterDirective {
  /**
   * @var {string}
   */
  @Input('a3l-dataset-filter')
  key: string = null;

  /**
   * @var {boolean}
   */
  @Input('a3l-dataset-filter-nonzero')
  get nonzero(): boolean {
    return this._nonzero;
  }
  set nonzero(value: boolean) {
    this._nonzero = value !== false;
  }

  /**
   * @var {boolean}
   */
  protected embedded: boolean = false;

  /**
   * @var {EmbeddedViewRef<any>}
   */
  protected viewRef: EmbeddedViewRef<any> = null;

  /**
   * @var {boolean}
   */
  protected _nonzero: boolean = false;

  /**
   * Create a new instance.
   *
   * @param {TemplateRef} templateRef
   * @param {ViewContainerRef} viewContainerRef
   */
  constructor(protected templateRef: TemplateRef<any>, protected viewContainerRef: ViewContainerRef) {
    //
  }

  /**
   * Create the view.
   *
   * @param {number} count
   * @return void
   */
  protected resolve(count: number = 0): void {
    if (this.embedded && this.viewRef) {
      this.viewRef.context.count = count;

      return;
    }

    this.embedded = true;

    this.viewContainerRef.clear();

    this.viewRef = this.viewContainerRef.createEmbeddedView(this.templateRef, { count, loading: false });
  }

  /**
   * Clear the view.
   *
   * @return void
   */
  protected clear(): void {
    this.embedded = false;

    this.viewContainerRef.clear();
  }
}
