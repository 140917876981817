import { Observable } from 'rxjs';

export type LocalizationType = 'city' | 'district' | 'voivodeship' | 'country';

export interface LocalizationGeometry {
  lat: string;
  lng: string;
  east?: string;
  north?: string;
  south?: string;
  west?: string;
}

export class Localization {
  /**
   * Create a new instance.
   *
   * @param {string} name
   * @param {LocalizationType} type
   * @param {boolean} bounded
   * @param {LocalizationGeometry} geometry
   */
  constructor(public name: string, public type: LocalizationType, public bounded: boolean = false, public geometry: LocalizationGeometry = null) {
    //
  }

  /**
   * Set the bounded attribute.
   *
   * @return Observable<any>
   */
  geocode(): Observable<any> {
    const google = (window as any).google;

    const geocoder = new google.maps.Geocoder();

    return new Observable((observer) => {
      geocoder.geocode({ address: this.name }, (results, status) => {
        if (status != 'OK') return observer.next();

        const { geometry } = JSON.parse(JSON.stringify(results[0]));

        this.geometry = {
          lat: geometry.location.lat,
          lng: geometry.location.lng,
        };

        if(geometry.viewport) {
          this.geometry = {...this.geometry, ...{
              east: geometry.viewport.east,
              north: geometry.viewport.north,
              south: geometry.viewport.south,
              west: geometry.viewport.west
            }
          };
        }

        if(geometry.bounds) {
          this.geometry = {...this.geometry, ...{
              east: geometry.bounds.east,
              north: geometry.bounds.north,
              south: geometry.bounds.south,
              west: geometry.bounds.west
            }
          };
        }

        observer.next();
      });
    });
  }

  /**
   * Set the bounded attribute.
   *
   * @param {boolean} bounded
   * @return void
   */
  markBoundedAs(bounded: boolean = false): void {
    this.bounded = bounded;
  }
}
