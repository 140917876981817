import { Specification } from '@a3l/core';

export class AccountNeedsActivationSpecification extends Specification<any> {
  isSatisfiedBy(candidate: any): boolean {
    const { role } = candidate;

    if (role == 'superadmin' || role == 'leader') return false;

    const { status, agreements } = candidate;

    const anyPendingAgreement = !!agreements.find(({ status }) => status == 'waiting');

    return status == null && anyPendingAgreement;
  }
}
