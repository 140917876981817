import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import {
  HttpBadRequestException,
  HttpForbiddenException,
  HttpNotFoundException,
  HttpUnauthorizedException,
  HttpUnprocessableEntityException,
  HttpInternalServerErrorException,
} from '@a3l/core';

export const EXCEPTIONS = [
  HttpBadRequestException,
  HttpForbiddenException,
  HttpNotFoundException,
  HttpUnauthorizedException,
  HttpUnprocessableEntityException,
  HttpInternalServerErrorException,
];

@Injectable()
export class ErrorsInterceptor implements HttpInterceptor {
  /**
   * Intercept the http request.
   *
   * @param {HttpRequest<any>} request
   * @param {HttpHandler} next
   * @return {Observable<HttpEvent<any>>}
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((response: HttpErrorResponse) => {
        if (response == null) return throwError(response);

        const { status, error } = response;

        const index = EXCEPTIONS.findIndex((e) => e.status == status);
        if (index !== -1) {
          return throwError(new EXCEPTIONS[index](error.message, error));
        }

        return throwError(response);
      })
    );
  }
}
