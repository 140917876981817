import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { take, map } from 'rxjs/operators';

import { Query } from '@a3l/core';

import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class CountriesQuery extends Query<any> {
  /**
   * @var {Observable<any>}
   */
  value$: Observable<any> = this.translator.get('countries').pipe(
    take(1),
    map((countries) => {
      Object.keys(countries).map((key) => (countries[key] = typeof countries[key] === 'function' ? countries[key]() : countries[key]));

      return countries;
    })
  );

  /**
   * Create a new instance.
   *
   * @param {TranslateService} translator
   */
  constructor(private translator: TranslateService) {
    super();
  }

  /**
   * Run the query.
   *
   * @return void
   */
  execute(): void {
    //
  }
}
