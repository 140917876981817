export abstract class StorageDB {
  /**
   * @var {Storage}
   */
  protected static storage: Storage;

  /**
   * @var {string}
   */
  protected static readonly PREFIX: string;

  /**
   * Store an item in the storage.
   *
   * @param {string} key
   * @param {any} value
   * @return void
   */
  static put(key: string, value: any): void {
    this.storage.setItem(this.getKey(key), JSON.stringify(value));
  }

  /**
   * Determine if an item exists in the cache.
   *
   * @param {string} key
   * @return boolean
   */
  static has(key: string): boolean {
    if (this.get(key) === null) {
      return false;
    }

    return true;
  }

  /**
   * Retrieve an item from the storage by key
   *
   * @param {string} key
   * @param {any} fallback
   * @return any
   */
  static get<T>(key: string, fallback: any = null): T {
    const item = this.storage.getItem(this.getKey(key));

    if (!item) return fallback;

    return JSON.parse(item);
  }

  /**
   * Remove items from the storage.
   *
   * @param {Array<string>} keys
   * @return void
   */
  static forget(...keys: Array<string>): void {
    keys.forEach((key) => this.storage.removeItem(this.getKey(key)));
  }

  /**
   * Get the prefixed key.
   *
   * @param {string} key
   * @return string
   */
  protected static getKey(key: string): string {
    return `${this.PREFIX}_${key}`;
  }
}
