import * as Cookie from 'js-cookie';

// @dynamic
export class CookieDB {
  /**
   * @var {string}
   */
  protected static readonly PREFIX: string = 'a3l';

  /**
   * Store an item in the storage.
   *
   * @param {string} name
   * @param {string} value
   * @param {any} options
   * @return void
   */
  static put(name: string, value: any, options: any = {}): void {
    Cookie.set(this.getKey(name), value, options);
  }

  /**
   * Retrieve an item from the storage by key.
   *
   * @param {string} key
   * @param {any} fallback
   * @return any
   */
  static get(name: string, fallback: any = null): any {
    return Cookie.get(this.getKey(name)) || fallback;
  }

  /**
   * Retrieve and delate an item from the storage by key.
   *
   * @param {string} key
   * @param {any} fallback
   * @return any
   */
  static pull(name: string, fallback: any = null): any {
    const value = CookieDB.get(name, fallback);

    CookieDB.forget(name);

    return value;
  }

  /**
   * Remove items from the storage.
   *
   * @param {string} name
   * @param {any} options
   * @return void
   */
  static forget(name: string, options: any = {}): void {
    Cookie.remove(this.getKey(name), options);
  }

  /**
   * Get the prefixed key.
   *
   * @param {string} name
   * @return string
   */
  protected static getKey(name: string): string {
    return `${this.PREFIX}_${name}`;
  }
}
