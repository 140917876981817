import { Exception } from './exception';

export class FileUploadMaxSizeException extends Exception {
  /**
   * @var {string}
   */
  static readonly type = 'FileUploadMaxSizeException';

  /**
   * @var {string}
   */
  public readonly type = FileUploadMaxSizeException.type;
}
