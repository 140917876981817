import { createSelector } from '@ngrx/store';

import { State } from './state';

import * as fromLoggedInUser from './logged-in-user';

/**
 * Logged in user selectors.
 */
export const getLoggedInUserState = (state: State) => state.loggedInUser;

export const getLoggedInUser = createSelector(getLoggedInUserState, fromLoggedInUser.getLoggedInUser);

/**
 * Notifications selectors.
 */
import * as fromNotifications from './notifications';

export const getNotificationsState = (state: State) => state.notifications;

export const { selectAll: getAllNotifications } = fromNotifications.adapter.getSelectors(getNotificationsState);

export const getUnreadNotificationsCount = createSelector(getAllNotifications, (entities) => entities.reduce((sum, item) => sum + (item.read_at == null ? 1 : 0), 0));

export const getTotalNotificationsCount = createSelector(getAllNotifications, (entities) => entities.length);
