import {Injectable} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {uuid} from "@a3l/utilities";

@Injectable({providedIn: 'root'})
export class LanguageSkillForm {
  public emptyForm = (params = null) => {
    return new FormGroup({
      uuid: new FormControl(uuid()),
      id: new FormControl(null, Validators.required),
      level: new FormControl(null, Validators.required),
    });
  }

  public formFromArray = (data) => {
    let form = this.emptyForm();

    if (data instanceof FormGroup) {
      return data;
    } else {
      form.controls['uuid'].setValue(data.uuid ? data.uuid : uuid());
      form.controls['id'].setValue(data.id);
      form.controls['level'].setValue(data.attributes ? data.attributes.level : data.level);

      return form;
    }
  }
}
