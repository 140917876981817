import {Directive, EventEmitter, HostListener, Input, Output} from '@angular/core';
import {FileDownloadCommand} from "@rex/common/file-download.command";
import {Dialog} from "@a3l/utilities";
import {BlindCvConfigDialog} from "@rex/pages/panel/candidates/wizard/dialog/blind-cv-config.dialog";

@Directive({
  selector: '[rex-file-download]',
  host: {
    class: 'a3l-rex-file-download',
  },
})
export class FileDownloadDirective {
  /**
   * @var {any}
   */
  @Input('rex-file-download')
  media: any;

  /**
   * @var boolean
   */
  @Input('rex-file-preview')
  preview: boolean = false;

  /**
   * @var {EventEmitter<boolean>}
   */
  @Output()
  loading: EventEmitter<boolean> = new EventEmitter();


  /**
   * Create a new instance.
   *
   * @param {FileDownloadCommand} downloadCommand
   * @param {Dialog} dialog
   */
  constructor(private downloadCommand: FileDownloadCommand, private dialog: Dialog) {
    //
  }

  /**
   * Handle the click event.
   *
   * @param {MouseEvent} event
   * @return void
   */
  @HostListener('click', ['$event'])
  onClick(event: MouseEvent): void {
    this.downloadCommand.pending$.subscribe((value: boolean) => this.loading.emit(value));

    if(this.media.generateBlindCv) {
        if (this.media.blind_settings) {
          const refBlindCvConfigDialog = this.dialog.open(BlindCvConfigDialog, this.media.blind_settings);

          refBlindCvConfigDialog.lock();

          refBlindCvConfigDialog.afterClosedWithResult().subscribe((configData) => {
            this.media['blind_settings'] = configData;

            this.downloadCommand.generateBlindCv(this.media, this.preview);
          });
        } else {
          this.downloadCommand.generateBlindCv(this.media, this.preview);
        }
    }
    else {
      this.downloadCommand.execute({id: this.media.id, filename: this.media.file_name, preview: this.preview});
    }

    this.downloadCommand.complete$.subscribe(() => this.loading.emit(false));
  }
}
