import {Control} from "@a3l/utilities";
import {Component, forwardRef, OnInit, ViewEncapsulation} from "@angular/core";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {Observable} from "rxjs";
import {SelectionModel} from "@angular/cdk/collections";
import {OrganizationsOnPlatformQuery} from "@rex/common/organizations-on-platform.query";
import {TranslateService} from "@ngx-translate/core";
import {IDropdownSettings} from "ng-multiselect-dropdown";

@Component({
  selector: 'rex-organizations-multiselect-field',
  templateUrl: './organizations-multiselect.field.html',
  styleUrls: ['./organizations-multiselect.field.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OrganizationsMultiselectField),
      multi: true,
    },
    OrganizationsOnPlatformQuery,
    { provide: Control, useExisting: OrganizationsMultiselectField },
  ]
})
export class OrganizationsMultiselectField extends Control implements OnInit, ControlValueAccessor {
  /**
   * @var {any}
   */
  value: any;

  /**
   * @var {Observable<any[]>}
   */
  items$: Observable<any[]> = this.query.value$;

  /**
   * @var {Observable<boolean>}
   */
  loading$: Observable<boolean> = this.query.pending$;

  /**
   * @var {SelectionModel<any>}
   */
  selection: SelectionModel<any> = new SelectionModel<any>(true, []);

  /**
   * @var {any}
   */
  protected propagateChange: any = () => {};

  constructor(private query: OrganizationsOnPlatformQuery, private translator: TranslateService) {
    super();
  }

  multiselectSettings:IDropdownSettings={};
  selectedItems = [];

  /**
   * Initialization.
   */
  ngOnInit() {
    this.query.execute();
  }

  getMultiSelectSetting() {
    return  this.multiselectSettings = {
      idField: 'id',
      textField: 'name',
      enableCheckAll: false,
      allowSearchFilter: true,
      searchPlaceholderText: this.translator.instant('Szukaj'),
    };
  }

  getSelectedItems(items) {
    const selectedItemsIds = this.selection.selected;

    if (this.selectedItems.length === 0) {
      items.filter(({id}) => selectedItemsIds.includes(id)).forEach(item => this.selectedItems.push({id: item.id, name: item.name}));
    }

    return this.selectedItems;
  }

  /**
   * Toggle the selection.
   *
   * @param {any} item
   * @return void
   */
  toggle(item: any): void {
    if (this.selection.selected.length >= 3 && !this.selection.isSelected(item.id)) return;

    if (this.selection.selected.length >= 3 && this.selection.isSelected(item.id)) {
      this.selection.toggle(item.id);

      this.propagateChange((this.value = this.selection.selected));

      return;
    }

    this.selection.toggle(item.id);

    this.propagateChange((this.value = this.selection.selected));
  }

  /**
   * Write a new value from the form model.
   *
   * @param {any} value
   * @return void
   */
  writeValue(value: any): void {
    this.value = value;

    if (!this.value) return;

    this.selection.select(...this.value);
  }

  /**
   * Register handler.
   *
   * @param {any} fn
   * @return void
   */
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  /**
   * Register handler.
   *
   * @param {any} fn
   * @return void
   */
  registerOnTouched(fn: any): void {}
}

