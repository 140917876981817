import { Directive, HostListener, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[a3l-layout-sidenav-item-handler]',
  host: {
    class: 'a3l-layout-sidenav-item-handler',
  },
})
export class LayoutSidenavItemHandler {
  /**
   * @var {EventEmitter<void>}
   */
  @Output()
  change: EventEmitter<void> = new EventEmitter();

  /**
   * Create a new instance.
   */
  constructor() {
    //
  }

  /**
   * Handle the click event.
   *
   * @return void
   */
  @HostListener('click')
  onClick(): void {
    this.change.emit();
  }
}
