import { Component, ViewEncapsulation, Input } from '@angular/core';

@Component({
  selector: '[a3l-dataset-lens]',
  template: '<ng-content></ng-content>',
  host: {
    class: 'a3l-dataset-lens',
    '[class.a3l-dataset-lens-default]': "addColors && !key",
    '[class.a3l-dataset-lens-invitation]': "addColors && key == 'invitation'",
    '[class.a3l-dataset-lens-verification]': "addColors && key == 'verification'",
    '[class.a3l-dataset-lens-presentation]': "addColors && key == 'presentation'",
    '[class.a3l-dataset-lens-meeting]': "addColors && key == 'meeting'",
    '[class.a3l-dataset-lens-rejected]': "addColors && key == 'rejected'",
    '[class.a3l-dataset-lens-hired]': "addColors && key == 'hired'",
    '[class.a3l-dataset-lens-resigned]': "addColors && key == 'resigned'",
    '[class.a3l-dataset-lens-unassigned]': "addColors && key == 'unassigned'",
    '[class.a3l-dataset-lens--active]': 'active',
    '[class.a3l-dataset-lens--important]': 'item.important || important',
  },
  styleUrls: ['./lens.presenter.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LensPresenter {

  /**
   * @var {boolean}
   */
  @Input('a3l-dataset-lens-colors')
  addColors: boolean = false;

  /**
   * @var {string}
   */
  @Input('a3l-dataset-lens-key')
  key: string = null;

  /**
   * @var {any}
   */
  @Input('a3l-dataset-lens')
  item: any = {};

  /**
   * @var {boolean}
   */
  @Input('a3l-dataset-lens-active')
  get active(): boolean {
    return this._active;
  }
  set active(value: boolean) {
    this._active = value !== false;
  }

  /**
   * @var {boolean}
   */
  @Input('a3l-dataset-lens-important')
  get important(): boolean {
    return this._important;
  }
  set important(value: boolean) {
    this._important = value !== false;
  }

  /**
   * @var {boolean}
   */
  _active: boolean = false;

  /**
   * @var {boolean}
   */
  _important: boolean = false;

  /**
   * Create a new instance.
   */
  constructor() {
    //
  }
}
