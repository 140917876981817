import { Component, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';

import { Control } from '@a3l/utilities';

import { DictionaryOfAgreementsQuery } from './dictionary-of-agreements.query';

@Component({
  selector: 'rex-area-field',
  templateUrl: './area.field.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AreaField),
      multi: true,
    },
    { provide: Control, useExisting: AreaField },
    DictionaryOfAgreementsQuery,
  ],
})
export class AreaField extends Control implements ControlValueAccessor {
  /**
   * @var {any}
   */
  value: any;

  /**
   * @var {SelectionModel<any>}
   */
  selection: SelectionModel<any> = new SelectionModel<any>(true, []);

  /**
   * @var {any}
   */
  protected propagateChange: any = () => {};

  /**
   * Toggle the selection.
   *
   * @param {any} item
   * @return void
   */
  toggle(item: any): void {
    if (this.selection.isSelected(item)) {
      this.selection.toggle(item);

      this.propagateChange((this.value = this.selection.selected));

      return;
    }

    this.selection.toggle(item);

    this.propagateChange((this.value = this.selection.selected));
  }

  /**
   * Write a new value from the form model.
   *
   * @param {any} value
   * @return void
   */
  writeValue(value: any): void {
    this.value = value;

    if (!this.value) return;

    this.selection.select(...this.value);
  }

  /**
   * Register handler.
   *
   * @param {any} fn
   * @return void
   */
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  /**
   * Register handler.
   *
   * @param {any} fn
   * @return void
   */
  registerOnTouched(fn: any): void {}
}
