import {Component, ElementRef, forwardRef, Injector, Input, ViewContainerRef, ViewEncapsulation} from "@angular/core";
import {Control} from './control';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {Overlay} from "@angular/cdk/overlay";
import {Subscription} from "rxjs";
import {CalendarService} from "../services/calendar.service";

@Component({
  selector: 'a3l-ui-datetime',
  templateUrl: './datetime.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatetimeComponent),
      multi: true,
    },
    { provide: Control, useExisting: DatetimeComponent },
  ],
  host: {
    class: 'a3l-ui-datetime',
    '(onFocus)': 'focused = true',
    '(onBlur)': 'focused = false',
  },
  encapsulation: ViewEncapsulation.None,
})
export class DatetimeComponent extends Control implements ControlValueAccessor {

  oldDate: any;

  /**
   * @var {any}
   */
  @Input()
  value: any;

  /**
   * @var {string}
   */
  @Input()
  placeholder: string;


  /**
   * @var {boolean}
   */
  @Input()
  showTime: boolean = false;

  /**
   * @var {boolean}
   */
  @Input()
  resetTimeOnDateChange: boolean = false;

  subscription: Subscription;
  /**
   * Create a new instance.
   *
   * @param {CalendarService} calendarService
   * @param {Overlay} overlay
   * @param {Injector} injector
   * @param {ElementRef} elementRef
   * @param {ViewContainerRef} viewContainerRef
   */
  constructor(private calendarService: CalendarService, private overlay: Overlay, private injector: Injector, private elementRef: ElementRef, private viewContainerRef: ViewContainerRef) {
    super();

    this.calendarService.setTranslation();
  }

  /**
   * @var {any}
   */
  protected propagateChange: any = () => {};

  /**
   * @var {boolean}
   */
  get canShowValidationMessage(): boolean {
    return this.focused;
  }

  /**
   * Focus on the input.
   *
   * @return void
   */
  focusin(): void {
    this.focused = true;
  }

  /**
   * Focusout of the input.
   *
   * @return void
   */
  focusout(): void {
    this.focused = false;
  }

  /**
   * Write a new value from the form model.
   *
   * @param {Date} value
   * @return void
   */
  writeValue(value: Date): void {
    this.value = this.resetTimeOnDateChange ? this.resetTime(value) : value;

    if(this.value) {
      let formattedValue = new Date(new Date(this.value).getTime() - (new Date(this.value).getTimezoneOffset() * 60000)).toISOString();
      this.propagateChange(formattedValue);
    }
  }

  writeInputValue(): void {
    if (this.value) {
      this.writeValue(this.value);
    }
  }

  resetTime(newDate) {
    let date = newDate;

    if (!date) {
      return null;
    }

    if (!this.oldDate && newDate) {
      date = newDate.setHours(8, 0, 0);
    }

    if (this.oldDate && newDate && (this.oldDate.toDateString() !== newDate.toDateString())) {
      date = newDate.setHours(8, 0, 0);
    }

    this.oldDate = new Date(date);

    return new Date(date);
  }

  /**
   * Register handler.
   *
   * @param {any} fn
   * @return void
   */
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  /**
   * Register handler.
   *
   * @param {any} fn
   * @return void
   */
  registerOnTouched(fn: any): void {}
}
