<ng-container [ngSwitch]="icon">
  <ng-container *ngSwitchCase="'google'">
    <img src="/assets/icons/google.svg" alt="Icon" />
  </ng-container>
  <ng-container *ngSwitchCase="'linkedin'">
    <img src="/assets/icons/linkedin.svg" alt="Icon" />
  </ng-container>
  <ng-container *ngSwitchCase="'facebook'">
    <img src="/assets/icons/facebook.svg" alt="Icon" />
  </ng-container>
  <ng-container *ngSwitchCase="'like'">
    <img src="/assets/icons/like.svg" alt="Icon" />
  </ng-container>
  <ng-container *ngSwitchCase="'perfect-match'">
    <img src="/assets/icons/perfect-match.svg" alt="Icon" />
  </ng-container>
  <ng-container *ngSwitchCase="'score'">
    <img src="/assets/icons/score.svg" alt="Icon" />
  </ng-container>
  <ng-container *ngSwitchCase="'zooms'">
    <img src="/assets/icons/zooms.svg" alt="Icon" />
  </ng-container>
</ng-container>
