import {Event} from "@a3l/core";

export class CvParserCandidateEducationEvent extends Event {
  /**
   * @var {string}
   */
  static readonly type = 'CvParserCandidateEducationEvent';

  /**
   * @var {string}
   */
  public readonly type = CvParserCandidateEducationEvent.type;


  constructor(public value: any, public disabled: boolean ) {
    super();
  }
}
