import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';

import { FileDownloadCommand } from './file-download.command';
import {$e} from "@angular/compiler/src/chars";

@Component({
  selector: 'rex-file-presenter',
  templateUrl: './file.presenter.html',
  styleUrls: ['./file.presenter.scss'],
  host: {
    class: 'rex-file-presenter',
  },
  encapsulation: ViewEncapsulation.None,
  providers: [FileDownloadCommand],
})
export class FilePresenter {
  /**
   * @var {Observable<boolean>}
   */
  readonly downloading$: Observable<boolean> = this.downloadCommand.pending$;

  /**
   * @var {any}
   */
  @Input()
  file: any;

  processing: boolean = false;

  /**
   * @var {boolean}
   */
  @Input()
  get compact(): boolean {
    return this._compact;
  }
  set compact(value: boolean) {
    this._compact = value !== false;
  }

  /**
   * @var {boolean}
   */
  @Input()
  get previewable(): boolean {
    return this._previewable;
  }
  set previewable(value: boolean) {
    this._previewable = value !== false;
  }

  /**
   * @var {boolean}
   */
  @Input()
  get downloadable(): boolean {
    return this._downloadable;
  }
  set downloadable(value: boolean) {
    this._downloadable = value !== false;
  }

  progress($event) {
    this.processing = $event;
  }

  /**
   * @var {boolean}
   */
  protected _compact: boolean = false;

  /**
   * @var {boolean}
   */
  protected _previewable: boolean = false;

  /**
   * @var {boolean}
   */
  protected _downloadable: boolean = false;

  /**
   * Create a new instance.
   *
   * @param {FileDownloadCommand} downloadCommand
   */
  constructor(private downloadCommand: FileDownloadCommand) {
    //
  }
}
