import {ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR} from "@angular/forms";
import {Component, forwardRef, Input, OnInit, ViewEncapsulation} from "@angular/core";
import {Control} from "@a3l/utilities";
import {autocompleteRequiredValidator} from "@rex/validators";

interface AutoCompleteCompleteEvent {
  originalEvent: Event;
  query: string;
}

@Component({
  selector: 'rex-evaluation-choice-rating',
  styleUrls: ['./evaluation-choice-rating.component.scss'],
  templateUrl: './evaluation-choice-rating.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EvaluationChoiceRatingComponent),
      multi: true,
    },
    {
      provide: Control,
      useExisting: EvaluationChoiceRatingComponent
    }
  ],
  host: {
    class: 'a3l-ui-autocomplete-rating',
  },
  encapsulation: ViewEncapsulation.None
})
export class EvaluationChoiceRatingComponent extends Control implements ControlValueAccessor {
  @Input()
  values: any[] = [];


  /**
   * @var {string}
   */
  @Input()
  placeholder: string;

  /**
   * @var {any}
   */
  @Input()
  suggestions: any = [];

  /**
   * @var {string}
   */
  @Input()
  iconClass: string  = null;

  filteredSuggestions: any[] | undefined;

  editedData: any;
  current: any;

  /**
   * @var {FormGroup}
   */
  newItemForm: FormGroup = new FormGroup({
    value: new FormControl(null),
    rate: new FormControl(null),
  });

  /**
   * @var {any}
   */
  protected propagateChange: any = () => {};

  /**
   * Focus on the input.
   *
   * @return void
   */
  focusin(): void {
    this.focused = false;
  }

  /**
   * Focusout of the input.
   *
   * @return void
   */
  focusout(): void {
    this.focused = false;
  }

  /**
   * Write a new value from the form model.
   *
   * @param {any} values
   * @return void
   */
  writeValue(values: any): void {
    this.values = values;

    this.propagateChange(this.values);
  }

  /**
   * Register handler.
   *
   * @param {any} fn
   * @return void
   */
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  /**
   * Register handler.
   *
   * @param {any} fn
   * @return void
   */
  registerOnTouched(fn: any): void {}

  filterSuggestions(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < (this.suggestions as any[]).length; i++) {
      let suggestion = (this.suggestions as any[])[i];

      if (suggestion.base_text.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(suggestion.base_text);
      }
    }

    this.filteredSuggestions = filtered;
  }

  setEditData(data) {
    this.editedData = data;
  }

  saveRate() {
    this.writeValue(this.values);
  }

  delete(data) {
    this.values = this.values.filter(item => item.value !== data.value);
    this.writeValue(this.values);

    data.id  = data.choice_id;
    data.identify_key = data.key;

    this.suggestions.push(data);
  }

  addData() {
    if (!this.newItemForm.get('value').value) {
      this.newItemForm.clear();
      return;
    }

    for (let i = 0; i < (this.suggestions as any[]).length; i++) {
      let suggestion = (this.suggestions as any[])[i];

      if (suggestion.base_text.toLowerCase().indexOf(this.newItemForm.get('value').value.toLowerCase()) == 0) {
        let newValue = { choice_id: suggestion.id, key: suggestion.identify_key, value: this.newItemForm.get('value').value, rate: this.newItemForm.get('rate').value, base_text: suggestion.base_text};

        this.values.push(newValue);
        this.writeValue(this.values);

        this.suggestions = this.suggestions.filter(item => item != suggestion);
      }
    }

    this.newItemForm.clear();
  }
}
