import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { A11yModule } from '@angular/cdk/a11y';
import { PortalModule } from '@angular/cdk/portal';
import { OverlayModule } from '@angular/cdk/overlay';

import { CoreModule } from '../core';

import { SnackBarComponent } from './snack-bar.component';
import { SnackBarContainerComponent } from './snack-bar-container.component';

import { SnackBarIconDirective } from './snack-bar-icon.directive';
import { SnackBarContentComponent } from './snack-bar-content.component';
import { SnackBarCloseButtonDirective } from './snack-bar-close-button.directive';

const PUBLIC_API = [SnackBarIconDirective, SnackBarContentComponent, SnackBarCloseButtonDirective];

@NgModule({
    declarations: [...PUBLIC_API, SnackBarComponent, SnackBarContainerComponent],
    imports: [CommonModule, PortalModule, OverlayModule, A11yModule, CoreModule],
    exports: [...PUBLIC_API]
})
export class SnackBarModule {}
