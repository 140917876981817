import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[click.prevent]',
})
export class ClickPreventDirective {
  /**
   * @var EventEmitter<Event>
   */
  @Output('click.prevent')
  click: EventEmitter<Event> = new EventEmitter<Event>();

  /**
   * Listen for click event on host.
   *
   * @param {MouseEvent} event
   * @return void
   */
  @HostListener('click', ['$event'])
  onClick(event: MouseEvent): void {
    event.preventDefault();

    this.click.emit(event);
  }

  /**
   * Create a new instance.
   */
  constructor() {}
}
