import { Injectable } from '@angular/core';
import { Observable, zip } from 'rxjs';
import { map } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';

import { Query } from '@a3l/core';
import { ApiService } from '@a3l/api';

import { AuthuserQuery } from '@rex/auth';

@Injectable({ providedIn: 'root' })
export class CountryQuery extends Query<any> {
  /**
   * @var {Observable<any>}
   */
  value$: Observable<any> = zip(this.api.get('/country-code-by-ip'), this.query.value$).pipe(
    map(([{ code }]: [any, any]) => {
      // if (organization) return organization.company_country;

      if (code) return code.toLowerCase();

      return this.translator.currentLang;
    })
  );

  /**
   * Create a new instance.
   *
   * @param {ApiService} api
   * @param {AuthuserQuery} query
   * @param {TranslateService} translator
   */
  constructor(private api: ApiService, private query: AuthuserQuery, private translator: TranslateService) {
    super();
  }

  /**
   * Run the query.
   *
   * @return void
   */
  execute(): void {
    //
  }
}
