import { NgModule, ModuleWithProviders } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CommonModule } from '@angular/common';

import { AuthuserPipe } from './authuser.pipe';

import { RoleDirective } from './role.directive';
import { RoleInOrganizationDirective } from "@rex/auth/role-in-organization.directive";
import { StatusDirective } from "@rex/auth/status.directive";

import { LoggedInDirective } from './logged-in.directive';
import { NotLoggedInDirective } from './not-logged-in.directive';

import { TokenInterceptor } from './token.interceptor';
import {RoleInClientCompanyDirective} from "@rex/auth/role-in-client-company.directive";
import {IsAntalDirective} from "@rex/auth/is-antal.directive";
import { IsNotAntalDirective } from '@rex/auth/is-not-antal.directive';
import {SubscriptionPlanDirective} from "@rex/auth/subscription-plan.directive";

const PUBLIC_API = [AuthuserPipe, RoleDirective, RoleInOrganizationDirective, RoleInClientCompanyDirective, StatusDirective, LoggedInDirective, NotLoggedInDirective, IsAntalDirective, IsNotAntalDirective, SubscriptionPlanDirective];

@NgModule({
  declarations: [...PUBLIC_API],
  imports: [CommonModule],
  exports: [...PUBLIC_API],
})
export class AuthModule {
  public static forRoot(): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [{ provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true }],
    };
  }
}
