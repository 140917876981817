import { Directive } from '@angular/core';

@Directive({ selector: '[a3l-ui-form-addon]' })
export class AddonDirective {
  /**
   * Create a new instance.
   */
  constructor() {
    //
  }
}
