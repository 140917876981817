import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class DisableRequestCacheInterceptor implements HttpInterceptor {
  /**
   * Create a new instance.
   */
  constructor() {
    //
  }

  /**
   * Intercept the http request.
   *
   * @param {HttpRequest<any>} request
   * @param {HttpHandler} next
   * @return {Observable<HttpEvent<any>>}
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({
      setHeaders: {
        Expires: '0',
        Pragma: 'no-cache',
        'Cache-Control': 'no-cache',
      },
    });

    return next.handle(request);
  }
}
