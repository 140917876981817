import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from "@angular/core";
import {FormControl, FormGroup} from "@angular/forms";
import {DictionaryOfIndustriesQuery} from "@rex/common/dictionary-of-industries.query";

@Component({
 selector: 'rex-recruiter-filter-component',
 templateUrl: './recruiter-filter.component.html',
 styleUrls: ['./recruiter-filter.component.scss'],
  providers: [
  ]
})
export class RecruiterFilterComponent implements OnInit {
  @ViewChild('industries') industries: ElementRef;
  @ViewChild('experience') experience: ElementRef;
  @ViewChild('localization') localization: ElementRef;

  @ViewChild('leftColumn') leftColumn: ElementRef;

  public isOpened = false;
  public filterFocus = 'industries';

  @Output() changed: EventEmitter<any> = new EventEmitter();

  public form = new FormGroup({
    industries: new FormControl([]),
    experience: new FormControl(null),
    localization: new FormControl([]),
  })

  constructor(
  ) {
  }

  ngOnInit() {

  }

  switchState() {
    this.isOpened = !this.isOpened;
  }

  clearFilters()
  {
    this.form.clear();
    this.applyFilters();
  }

  changeFocus(state) {
    let element;

    switch (state) {
      case 'industries':
        element = this.industries; break;
      case 'experience':
        element = this.experience; break;
      case 'localization':
        element = this.localization; break;
    }

    this.leftColumn.nativeElement.scrollTo({
      top: element.nativeElement.offsetParent.offsetTop - 40,
      behavior: 'smooth'
    });

    this.filterFocus = state;
  }

  applyFilters()
  {
    this.changed.emit(this.form.value);
    this.isOpened = false;
  }

  cancel()
  {
    this.isOpened = false;
  }

  getQuickAccessClassRules(access) {
    return {
      'cursor-pointer': true,
      'text-xs': true,
      'mb-5': true,
      'pr-5': true,
      'text-black': this.filterFocus == access,
      'text-gray': this.filterFocus != access,
      'font-bold': this.filterFocus == access,
    }
  }
}
