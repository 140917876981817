import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';

import { CountriesQuery } from './countries.query';

@Pipe({ name: 'rexCountries' })
export class CountriesPipe implements PipeTransform {
  /**
   * Create a new instance.
   *
   * @param {CountriesQuery} query
   */
  constructor(private query: CountriesQuery) {
    //
  }

  /**
   * Return countires list.
   *
   * @return Observable<any[]>
   */
  transform(): Observable<any[]> {
    return this.query.value$;
  }
}
