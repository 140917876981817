import { Exception } from '../exception';

export class HttpException extends Exception {
  /**
   * @var {string}
   */
  static readonly type: string = 'HttpException';

  /**
   * @var {string}
   */
  public readonly type: string = HttpException.type;

  /**
   * @param {number}
   */
  static readonly status: number = null;

  /**
   * @param {any}
   */
  data: any;

  /**
   * Create a new instance.
   */
  constructor(message: string = '', data: any = {}) {
    super(message);

    this.data = data;
  }
}
