<ng-container *ngIf="this.values && this.values.length">
  <div class="mb-3">
    <a3l-ui-token *ngFor="let data of this.values" [color]="'white'" [size]="data.level">
      {{ data.value }}
      <span class="pi pi-pencil text-xxs ml-3 cursor-pointer" (click)="op.toggle($event); setEditData(data)"></span>
      <span class="p-autocomplete-token-icon pi pi-times-circle text-xxs ml-1" (click)="delete(data)"></span>
    </a3l-ui-token>
  </div>
</ng-container>

<form [formGroup]="newItemForm">
  <div class="p-inputgroup">
    <p-autoComplete
      formControlName="value"
      class="text-input w-full"
      type="text"
      [placeholder]="this.placeholder || 'Wpisz' | translate"
      [suggestions]="filteredSuggestions"
      [(ngModel)]="this.current"
      (completeMethod)="filterSuggestions($event)">
    </p-autoComplete>
    <p-rating formControlName="rate" class="inline-flex" [cancel]="false" [stars]="5" (onRate)="addData(); "></p-rating>
  </div>
</form>

<p-overlayPanel #op>
  <ng-container *ngIf="this.editedData">
    <strong class="mb-3 block text-md">{{ this.editedData.name }}</strong>
    <p-rating [ngModel]="this.editedData.level" [cancel]="false" (onRate)="saveRate($event); op.hide()" [stars]="5"></p-rating>
  </ng-container>
</p-overlayPanel>

