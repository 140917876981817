import { Pipe, PipeTransform } from '@angular/core';

import * as moment from 'moment';

@Pipe({ name: 'a3lDatetime' })
export class DatetimePipe implements PipeTransform {
  /**
   * Create a new instance.
   */
  constructor() {
    //
  }

  /**
   * Transform the given date to the relative time format.
   *
   * @param {string} value
   * @param {string} format
   * @return string
   */
  transform(value: string, format: string = 'DD.MM.YYYY'): string {
    const datetime = moment(value);

    if (!datetime.isValid()) return value;

    if (format == 'ago') {
      return moment(value).fromNow();
    }

    if (format == 'fromNow') {
      return moment(value).fromNow(true);
    }

    return datetime.format(format);
  }
}
