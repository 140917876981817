import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

export const emailValidator: ValidatorFn = (control: AbstractControl): ValidationErrors => {
  const v: string = control.value;
  /* tslint:disable */
  return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) ? null : { 'email': true };
  /* tslint:enable */
};

export const autocompleteRequiredValidator: ValidatorFn = (control: AbstractControl): ValidationErrors => {
  const v: string = control.value;

  return v && v.length ? null : { 'autocomplete': true };
}

export const urlValidator: ValidatorFn = (control: AbstractControl): ValidationErrors => {
  const urlRegex = new RegExp(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()!@:%_\+.,~#?&\/\/=]*)/m);
  const value: string = control.value;

  return (!value || value && urlRegex.test(value)) ? null : { 'url_regex' : true };
}

export function validateChoices(requiredChoices = 0): ValidatorFn {
  return (control:AbstractControl) : ValidationErrors | null => {
    let value = control.value;

    if (value && value.length < requiredChoices) {
      return { 'autocomplete': true };
    }

    return null;
  }
}

export function validateAutocompleteMax(maxInputs = 3): ValidatorFn {
  return (control:AbstractControl) : ValidationErrors | null => {
    let value = control.value;

    if (value && value.length > maxInputs) {
      return { 'autocomplete': true };
    }

    return null;
  }
}

