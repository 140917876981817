<ng-container [ngSwitch]="column.key">
  <ng-container *ngSwitchCase="'actions'">
    <div class="flex gap-1">
      <ng-template
        [rex-order-request-dataset-action]="action"
        [rex-order-request-dataset-action-item]="item"
        [rex-order-request-dataset-action-view]="view"
        [rex-order-request-dataset-action-entity]="entity"
        *ngFor="let action of item.actions"
      ></ng-template>
    </div>
  </ng-container>
  <ng-container a3l-dataset-table-dcell *ngSwitchCase="'recruitment_name'">
    <strong>{{ item.name}}</strong>
  </ng-container>
  <ng-container a3l-dataset-table-dcell *ngSwitchCase="'contact_person'">
    <ng-container *ngIf="item.contact_person as contactPerson; else empty">
        <rex-contact-person-component [contactPerson]="contactPerson"></rex-contact-person-component>
    </ng-container>
  </ng-container>
  <ng-container a3l-dataset-table-dcell *ngSwitchCase="'recruitment_vacancies'">
    <strong>{{ item.vacancies }}</strong>
  </ng-container>
  <ng-container a3l-dataset-table-dcell *ngSwitchCase="'candidates_count'">
    <rex-recruiter-candidate-column [item]="item" [view]="view"></rex-recruiter-candidate-column>
  </ng-container>
  <ng-container a3l-dataset-table-dcell *ngSwitchCase="'recruitment_published_at'">
    <span *ngIf="item.published_at as published_at; else empty">{{ published_at | a3lDatetime:'DD.MM.YYYY HH:mm' }}</span>
  </ng-container>
</ng-container>
<ng-template #empty>---</ng-template>
