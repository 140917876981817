import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'a3lMailto' })
export class MailtoPipe implements PipeTransform {
  /**
   * Create a new instance.
   */
  constructor() {
    //
  }

  /**
   * Transform the given email.
   *
   * @param {string} value
   * @return string
   */
  transform(value: string): string {
    return `mailto:${value}`;
  }
}
