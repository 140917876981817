import { Exception } from '@a3l/core';

export class AgreementIsInSigningProcessException extends Exception {
  /**
   * @var {string}
   */
  static readonly type = 'AgreementIsInSigningProcessException';

  /**
   * @var {string}
   */
  public readonly type = AgreementIsInSigningProcessException.type;
}
