import {Component} from '@angular/core';
import {UpdateService} from "@rex/UpdateService";
import {ListenActionForEvaluationService} from "@rex/evaluations/services/listen-action-for-evaluation.service";

@Component({
  selector: 'rex-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  /**
   * Create a new instance.
   */
  constructor(
    private sw: UpdateService,
    private listenActionForEvaluationService: ListenActionForEvaluationService
  ) {
    this.sw.checkForUpdates();
    this.listenActionForEvaluationService.runEvaluationRequestActions();
  }
}
