import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import {NgModule, APP_INITIALIZER, Injector} from '@angular/core';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import {TranslateModule, TranslateLoader, TranslateCompiler, TranslateService} from '@ngx-translate/core';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';

import { ApiModule } from '@a3l/api';
import { LayoutModule } from '@a3l/layout';
import { UtilitiesModule } from '@a3l/utilities';
import { BreadcrumbModule } from '@a3l/breadcrumb';
import {CookieDB, DisableRequestCacheInterceptor} from '@a3l/core';

import { environment } from '@rex/environments/environment';

import { CommonModule } from '@rex/common';

import { AppComponent } from './app.component';
import { SimpleLayout } from './simple.layout';

import { LanguageInterceptor } from './language.interceptor';
import { HttpTranslationsLoaderFactory } from './translate-http-loader';

import { routes } from './routes';

import { reducer, effects } from './state';

import { Initializer, initialize } from './initializer';

import { AuthModule } from './auth';
import { ErrorsModule } from './errors';
import { TutorialModule } from './tutorial';
import {XdebugInterceptor} from "../../../../libs/core/src/lib/http/xdebug.interceptor";
import { ServiceWorkerModule } from '@angular/service-worker';
import { UpdateService } from "@rex/UpdateService";
import {LOCATION_INITIALIZED} from "@angular/common";
import * as moment from "moment/moment";
import {NgxMaskModule} from "ngx-mask";
import {CookieService} from "ngx-cookie-service";
import {ListenActionForEvaluationService} from "@rex/evaluations/services/listen-action-for-evaluation.service";

export function appInitializerFactory(translate: TranslateService, injector: Injector) {
  return () => new Promise<any>((resolve: any) => {
    const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
    locationInitialized.then(() => {
      const langToSet = CookieDB.get('language', environment.app.language);
      translate.setDefaultLang(langToSet);
      moment.locale(langToSet);
      moment.updateLocale('pl', {
        relativeTime: {
          y: '1 rok'
        }
      })
      translate.use(langToSet).subscribe(() => {
      }, err => {
      }, () => {
        resolve(null);
      });
    });
  });
}

@NgModule({
  declarations: [AppComponent, SimpleLayout],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,

    /**
     * Import router directives and providers.
     */
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy', scrollPositionRestoration: 'enabled' }),

    /**
     * StoreModule.forRoot is imported once in the root module, accepting a reducer
     * function or object map of reducer functions.
     */
    StoreModule.forRoot(reducer),

    /**
     * EffectsModule.forRoot() is imported once in the root module and
     * sets up the effects class to be initialized immediately when the
     * application starts.
     */
    EffectsModule.forRoot(effects),

    /**
     * Import the language translations.
     */
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpTranslationsLoaderFactory,
        deps: [HttpClient],
      },
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler,
      },
      useDefaultLang: true,
      defaultLanguage: environment.app.language,
    }),

    CommonModule,

    ApiModule.forRoot({ endpoint: environment.api.endpoint }),

    LayoutModule.forRoot({ title: environment.app.name }),

    BreadcrumbModule.forRoot(),

    UtilitiesModule.forRoot(),

    AuthModule.forRoot(),

    ErrorsModule.forRoot(),

    NgxMaskModule.forRoot(),

    TutorialModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: LanguageInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: DisableRequestCacheInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: XdebugInterceptor, multi: true },
    Initializer,
    UpdateService,
    ListenActionForEvaluationService,
    CookieService,
    {
      provide: APP_INITIALIZER,
      useFactory: initialize,
      deps: [Initializer],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
