export interface Identifiable {
  id?: number | string;
}

export interface IdentifiableByUuid {
  uuid?: string;
}

export interface IdentifiableEvaluation {
  recommendation_id?: number|string;
  id?: number | string;

}
