import { Observable, Subject, BehaviorSubject, of } from 'rxjs';

export abstract class Command<T = any> {
  /**
   * @var {Subject<any>}
   */
  protected _errors$: Subject<any> = new Subject<any>();

  /**
   * @var {BehaviorSubject<boolean>}
   */
  protected _pending$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /**
   * @var {Subject<T>}
   */
  protected _complete$: Subject<T> = new Subject<T>();

  /**
   * @var {Observable<any>}
   */
  errors$: Observable<any> = this._errors$.asObservable();

  /**
   * @var {Observable<boolean>}
   */
  pending$: Observable<boolean> = this._pending$.asObservable();

  /**
   * @var {Observable<any>}
   */
  complete$: Observable<any> = this._complete$.asObservable();

  /**
   * Run the query.
   *
   * @param {any} model
   * @return Observable<T>
   */
  run(...model: any): Observable<T> {
    return of();
  }

  /**
   * Run the query.
   *
   * @param {any} model
   * @return void
   */
  execute(...model: any): void {
    //
  }
}
