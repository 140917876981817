import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { take, map } from 'rxjs/operators';

import { Store } from '@ngrx/store';

import { State, getLoggedInUser } from '@rex/state';

import {
  HasActivatedAccountSpecification,
  AccountIsBlockedException,
  AccountIsBlockedSpecification,
  HasValidPasswordSpecification,
  AccountNeedsActivationException,
  AccountNeedsActivationSpecification,
  AgreementIsInSigningProcessSpecification,
  AgreementIsInSigningProcessException,
} from '@rex/auth';
import {AccountIsRejectedException} from "@rex/auth/account-is-rejected.exception";
import {AccountIsRejectedSpecification} from "@rex/auth/account-is-rejected.specification";
import {
  IsInvitedOrganizationUserSpecification
} from "@rex/organization/specyfications/is-invited-organization-user.specification";
import {
  AccountNeedsVerificationByOrganizationSpecification
} from "@rex/organization/organization-onboarding/specifications/account-needs-verification-by-organization.specification";
import {
  AccountNeedsVerificationByOrganizationException
} from "@rex/organization/organization-onboarding/exceptions/account-needs-verification-by-organization.exception";
import {
  AccountActiveInOrganizationSpecification
} from "@rex/organization/organization-onboarding/specifications/account-active-in-organization.specification";
import {
  RecruiterIsBlockedInOrganizationSpecification
} from "@rex/auth/recruiter-is-blocked-in-organization.specification";
import {RecruiterIsBlockedInOrganizationException} from "@rex/auth/recruiter-is-blocked-in-organization.exception";
import {ClientCompanyIsBlockedException} from "@rex/auth/client-company-is-blocked.exception";
import {ClientCompanyIsBlockedSpecification} from "@rex/auth/client-company-is-blocked.specification";

@Injectable({ providedIn: 'root' })
export class HasActivatedAccountGuard implements CanActivate {
  /**
   * Create a new instance.
   *
   * @param {Router} router
   * @param {Store<State>} store
   */
  constructor(private router: Router, private store: Store<State>) {
    //
  }

  /**
   * Decide if a route can be activated.
   *
   * @return Observable<any>
   */
  canActivate(): Observable<any> {
    return this.store.select(getLoggedInUser).pipe(
      take(1),
      map((user) => {
        if (!new HasValidPasswordSpecification().isSatisfiedBy(user)) {
          return this.router.parseUrl('/password-needs-to-be-updated');
        }

        if (new RecruiterIsBlockedInOrganizationSpecification().isSatisfiedBy(user)) {
          throw new RecruiterIsBlockedInOrganizationException();
        }

        if (new ClientCompanyIsBlockedSpecification().isSatisfiedBy(user)) {
          throw new ClientCompanyIsBlockedException();
        }

        if (new AccountIsBlockedSpecification().isSatisfiedBy(user)) {
          throw new AccountIsBlockedException();
        }

        if (new AccountIsRejectedSpecification().isSatisfiedBy(user)) {
          throw new AccountIsRejectedException();
        }

        if (new AccountNeedsActivationSpecification().isSatisfiedBy(user)) {
          throw new AccountNeedsActivationException();
        }

        if (new AccountNeedsVerificationByOrganizationSpecification().isSatisfiedBy(user)) {
          throw new AccountNeedsVerificationByOrganizationException();
        }

        if (new AccountActiveInOrganizationSpecification().isSatisfiedBy(user)) {
          return true;
        }
        if (new HasActivatedAccountSpecification().isSatisfiedBy(user)) {
          return true;
        }

        if (new IsInvitedOrganizationUserSpecification().isSatisfiedBy(user)) {
          return this.router.parseUrl('/organization-onboarding');
        }

        return this.router.parseUrl('/onboarding');
      })
    );
  }
}
