import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { LayoutComponent } from './layout.component';

import { LayoutTopbarComponent } from './layout-topbar.component';
import { LayoutMainComponent } from './layout-main.component';
import { LayoutFooterComponent } from './layout-footer.component';
import { LayoutCopyrightsComponent } from './layout-copyrights.component';

import { LayoutSidenav } from './layout-sidenav';
import { LayoutSidenavItem } from './layout-sidenav-item';
import { LayoutSidenavItemIcon } from './layout-sidenav-item-icon';
import { LayoutSidenavItemLabel } from './layout-sidenav-item-label';
import { LayoutSidenavItemHandler } from './layout-sidenav-item-handler';
import { LayoutSidenavItemSubmenu } from './layout-sidenav-item-submenu';
import { LayoutSidenavItemSubmenuContent } from './layout-sidenav-item-submenu-content';

import { LayoutTitleDirective } from './layout-title.directive';
import { LayoutAsTitleDirective } from './layout-as-title.directive';

import { CONFIG, Config } from './config';

const PUBLIC_API = [
  LayoutComponent,
  LayoutTitleDirective,
  LayoutAsTitleDirective,
  LayoutTopbarComponent,
  LayoutMainComponent,
  LayoutFooterComponent,
  LayoutCopyrightsComponent,
  LayoutSidenav,
  LayoutSidenavItem,
  LayoutSidenavItemIcon,
  LayoutSidenavItemLabel,
  LayoutSidenavItemHandler,
  LayoutSidenavItemSubmenu,
  LayoutSidenavItemSubmenuContent,
];

@NgModule({
  declarations: [...PUBLIC_API],
  imports: [CommonModule, RouterModule],
  exports: [...PUBLIC_API],
})
export class LayoutModule {
  public static forRoot(config: Config): ModuleWithProviders<LayoutModule> {
    return {
      ngModule: LayoutModule,
      providers: [{ provide: CONFIG, useValue: config }],
    };
  }
}
