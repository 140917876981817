<a3l-ui-spinner size="30" class="mx-auto my-6 block" *ngIf="loading$ | async"></a3l-ui-spinner>

<ng-container *ngIf="!(loading$ | async)">
  <ng-container *ngIf="dictionaryItems$ | async as dictionaryItems">
    <!--  PILLS -->
    <ng-container *ngIf="this.values && this.formattedValues">
      <div class="mb-3">
        <a3l-ui-token *ngFor="let data of this.formattedValues" [color]="'white'" [size]="data.rate">
          {{ data.value }}
          <span class="pi pi-pencil text-xxs ml-3 cursor-pointer" (click)="opLanguage.toggle($event); setEditData(data)"></span>
          <span class="p-autocomplete-token-icon pi pi-times-circle text-xxs ml-1" (click)="delete(data)"></span>
        </a3l-ui-token>
      </div>
    </ng-container>

    <!--  INPUT -->
    <form [formGroup]="languageItemForm">
      <div class="p-inputgroup">
        <p-autoComplete
          formControlName="value"
          class="text-input w-full"
          type="text"
          [placeholder]="'Wpisz' | translate"
          [suggestions]="filteredSuggestions"
          [(ngModel)]="this.current"
          (completeMethod)="filterSuggestions($event)"
          (onFocus)="!autocomplete.value && autocomplete.handleDropdownClick($event)" dropdownMode="current" #autocomplete
        >
        </p-autoComplete>
        <p-rating formControlName="rate" class="inline-flex tooltip-language-level" [cancel]="false" [stars]="7" (onRate)="addData(); "></p-rating>
      </div>
    </form>

    <!--  EDIT MODAL  -->
    <p-overlayPanel #opLanguage>
      <ng-container *ngIf="this.editedData">
        <strong class="mb-3 block text-md">{{ this.editedData.value }}</strong>
        <p-rating [(ngModel)]="this.editedData.rate" [cancel]="false" (onRate)="saveRate($event); opLanguage.hide()" [stars]="7"></p-rating>
      </ng-container>
    </p-overlayPanel>
  </ng-container>
</ng-container>
