import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { DialogRef, UI_DIALOG_DATA } from '@a3l/utilities';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { TutorialAndQuizService } from '../pages/tutorial-and-quiz/tutorial-and-quiz.service';

import { environment } from '@rex/environments/environment';

@Component({
  templateUrl: './tutorial.dialog.html',
  styleUrls: ['./tutorial.dialog.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TutorialDialog implements OnInit {
  /**
   * @var {number}
   */
  mode: 'tutorial' | 'tutorialWithQuiz' = 'tutorial';

  /**
   * @var {number}
   */
  type: 'RODO' | 'recruitment' | 'payments' = 'RODO';

  /**
   * @var {number}
   */
  tutorialName: string = 'RODO';

  /**
   * @var {number}
   */
  currentLang: string = 'pl';

  /**
   * @var {number}
   */
  lastSlide: number = 17;

  /**
   * @var {any}
   */
  doneSteps = {
    tutorialRODO: false,
    tutorialRODOStep: false,
    tutorialRecruitment: false,
    tutorialRecruitmentStep: false,
    tutorialPayments: false,
    tutorialPaymentsStep: false,
    quizRodoStep: false,
  };

  /**
   * @var {Subscription}
   */
  protected subscription: Subscription = Subscription.EMPTY;

  /**
   * @var {number}
   */
  slide: number = 1;

  /**
   * Create a new instance.
   *
   * @param {DialogRef<TutorialDialog>} dialogRef
   * @param {TutorialAndQuizService} tutorialAndQUizService
   * @param {Router} router
   */
  constructor(
    @Inject(UI_DIALOG_DATA) private dto,
    private dialogRef: DialogRef<TutorialDialog>,
    private tutorialAndQUizService: TutorialAndQuizService,
    private router: Router,
    private translator: TranslateService
  ) {
    //
  }

  /**
   * Initialization.
   */
  ngOnInit() {
    this.mode = this.dto.mode;
    this.type = this.dto.type;

    this.subscription = this.tutorialAndQUizService.sharedDoneSteps.subscribe((value) => (this.doneSteps = value));

    this.setLastSlideValue();
    this.currentLang = this.translator.currentLang;
  }

  setLastSlideValue() {
    if (this.type === 'RODO') {
      this.tutorialName = this.translator.instant('RODO');
      if (['cs', 'hu', 'sk'].includes(this.translator.currentLang)) {
        this.lastSlide = 18;
      } else {
        this.lastSlide = 17;
      }
    } else if (this.type === 'recruitment') {
      this.tutorialName = this.translator.instant('Proces rekrutacyjny');
      if (['cs', 'hu', 'sk', 'en'].includes(this.translator.currentLang)) {
        this.lastSlide = 29;
      } else {
        this.lastSlide = 30;
      }
    } else if (this.type === 'payments') {
      this.tutorialName = this.translator.instant('Proces płatności');
      if (['cs', 'hu', 'sk', 'en'].includes(this.translator.currentLang)) {
        this.lastSlide = 4;
      } else {
        this.lastSlide = 5;
      }
    }
  }

  /**
   * Cleanup.
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  /**
   * Close the dialog.
   *
   * @return void
   */
  close(): void {
    this.dialogRef.close();
  }

  /**
   * Finish tutorial.
   *
   * @return void
   */
  finish(): void {
    switch (this.type) {
      case 'RODO':
        this.tutorialAndQUizService.completeStep({
          tutorialRODO: true,
          tutorialRODOStep: true,
        });
        this.type = 'payments';
        this.setLastSlideValue();
        this.slide = 1;
        break;
      case 'recruitment':
        this.tutorialAndQUizService.completeStep({
          tutorialRecruitment: true,
          tutorialRecruitmentStep: true,
        });
        this.type = 'payments';
        this.setLastSlideValue();
        this.slide = 1;
        break;
      case 'payments':
        this.tutorialAndQUizService.completeStep({
          tutorialPayments: true,
          tutorialPaymentsStep: true,
        });
        if (this.mode === 'tutorialWithQuiz') {
          this.router.navigate(['/tutorial-and-quiz/quiz-rodo']);
        }
        break;
    }
  }

  /**
   * Previous slide.
   *
   * @return void
   */
  previousSlide(): void {
    this.slide--;
  }

  /**
   * Next slide.
   *
   * @return void
   */
  nextSlide(): void {
    if (this.slide === this.lastSlide) {
      this.finish();
    } else {
      this.slide++;
    }
  }

  /**
   * Return slide.
   *
   * @return string
   */
  getSlide(): string {
    return `background-image:url(${environment.api.assets}/tutorial/${this.translator.currentLang}/${this.type}/slide${this.slide}.png)`;
  }

  /**
   * Return display custom slide flag.
   *
   * @return boolean
   */
  displayCustomSlide(): boolean {
    return (this.slide === 30 && this.type === 'recruitment' && this.translator.currentLang === 'pl') || (this.slide === 29 && this.type === 'recruitment' && this.translator.currentLang !== 'pl');
  }

  /**
   * Return display custom slide flag.
   *
   * @return boolean
   */
  displayCustomRodoSlide(): boolean {
    return this.slide === 16 && this.type === 'RODO';
  }

  /**
   * Return display standard slide flag.
   *
   * @return boolean
   */
  displayStandardSlide(): boolean {
    if (this.slide === 30 && this.type === 'recruitment' && this.translator.currentLang === 'pl') {
      return false;
    }

    if (this.slide === 29 && this.type === 'recruitment' && this.translator.currentLang !== 'pl') {
      return false;
    }

    if (this.slide === 16 && this.type === 'RODO') {
      return false;
    }
    return true;
  }
}
