import { Component, Input } from '@angular/core';

@Component({
  selector: 'a3l-dataset-table-dcell, td[a3l-dataset-table-dcell]',
  template: '<ng-content></ng-content>',
  host: {
    class: 'a3l-dataset-table-dcell',
    '[class.a3l-dataset-table-dcell--sticky]': 'sticky',
  },
})
export class DatasetTableDCellPresenter {
  /**
   * @var {boolean}
   */
  @Input()
  get sticky(): boolean {
    return this._sticky;
  }
  set sticky(value: boolean) {
    this._sticky = value !== false;
  }

  /**
   * @var {boolean}
   */
  protected _sticky: boolean;

  /**
   * Create a new instance.
   */
  constructor() {
    //
  }
}
