import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@rex/environments/environment';

@Injectable()
export class XdebugInterceptor implements HttpInterceptor {
  /**
   * Create a new instance.
   */
  constructor() {
    //
  }

  /**
   * Intercept the http request.
   *
   * @param {HttpRequest<any>} request
   * @param {HttpHandler} next
   * @return {Observable<HttpEvent<any>>}
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(environment.xdebug) {
      request = request.clone({
        setParams: {
          XDEBUG_SESSION_START: 'PHPSTORM'
        },
      });
    }

    return next.handle(request);
  }
}
