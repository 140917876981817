import {GroupableComponentInterface} from "../../../../../../libs/dataset/src/lib/GroupableComponentInterface";
import {Component, Input} from "@angular/core";

@Component({
  templateUrl:'./client-company-groupable.component.html'
})
export class ClientCompanyGroupableComponent implements GroupableComponentInterface {
  @Input() public item: any;
  @Input() public column: any;
  @Input() public view: any;
  @Input() public entity: any;

  additionalData() {
    return {
      client_company_id: this.item.client_company_id
    };
  }
}
