import { Component, HostListener, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import {Dispatcher} from "@a3l/core";
import {CvParserRecruitmentLanguagesEvent} from "@rex/shared/recruitments/events/cv-parser-recruitment-languages.event";
import {CvParserCandidateLanguagesEvent} from "@rex/shared/recruitments/events/cv-parser-candidate-languages.event";

@Component({
  selector: 'rex-languages-item-field',
  templateUrl: './languages-item-field.component.html',
  styleUrls: ['./languages-item-field.component.scss'],
  host: {
    class: 'rex-languages-item-field',
    '[class.rex-languages-item-field--checked]': 'checked',
    '[class.rex-languages-item-field--checked-without-level]': 'checked && level == null',
  },
  encapsulation: ViewEncapsulation.None,
})
export class LanguagesItemFieldComponent implements OnInit {
  /**
   * @var {number}
   */
  @Input()
  id: number;

  /**
   * @var {SelectionModel<{ id: number; level: string }>}
   */
  @Input()
  selection: SelectionModel<{ id: number; level: string }>;

  /**
   * @var {string}
   */
  level: string = null;

  /**
   * @var {boolean}
   */
  checked: boolean = false;

  /**
   * Create a new instance.
   */
  constructor(private dispatcher: Dispatcher) {
    //
  }

  /**
   * Initialization.
   */
  ngOnInit() {
      this.setLanguage();

      this.dispatcher.listen(CvParserRecruitmentLanguagesEvent).subscribe(({value, disabled}) => {
        this.setLanguage()
      });

      this.dispatcher.listen(CvParserCandidateLanguagesEvent).subscribe(({value, disabled}) => {
        this.setLanguage();
      });
    }

  setLanguage()
  {
    const selected = this.selection.selected.find(({ id }) => id == this.id);

    this.level = selected ? selected.level : null;

    this.checked = !!selected;

  }
  /**
   * Handle the toggle action.
   *
   * @param {MouseEvent} event
   * @return void
   */
  @HostListener('click', ['$event'])
  toggle(event: MouseEvent): void {
    event.preventDefault();

    this.level = null;
    this.checked = !this.checked;

    if (this.checked) return;

    const selected = this.selection.selected.find(({ id }) => id == this.id);

    this.selection.deselect(selected);
  }

  /**
   * Handle the level update.
   *
   * @return void
   */
  addLevel(): void {
    const selected = this.selection.selected.find(({ id }) => id == this.id);

    this.selection.deselect(selected);

    this.selection.select({ id: this.id, level: this.level });
  }
}
