import { Pipe, PipeTransform } from '@angular/core';

import * as prettyBytes from 'pretty-bytes';

@Pipe({ name: 'a3lPrettyBytes' })
export class PrettyBytesPipe implements PipeTransform {
  /**
   * Transform the given string.
   *
   * @param {any} value
   * @return any[]
   */
  transform(value: any): any {
    return prettyBytes(value);
  }
}
