import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { take, map } from 'rxjs/operators';

import { Store } from '@ngrx/store';

import { State, getLoggedInUser } from '@rex/state';

@Injectable({ providedIn: 'root' })
export class IsGuestGuard implements CanActivate {
  /**
   * Create a new instance.
   *
   * @param {Router} router
   * @param {Store<State>} store
   */
  constructor(private router: Router, private store: Store<State>) {
    //
  }

  /**
   * Decide if a route can be activated.
   *
   * @return Observable<any>
   */
  canActivate(): Observable<any> {
    return this.store.select(getLoggedInUser).pipe(
      take(1),
      map((user) => {
        if (user) return this.router.parseUrl('/dashboard');

        return true;
      })
    );
  }
}
