import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from "@angular/core";
import {CdkConnectedOverlay} from "@angular/cdk/overlay";
import {FormControl, FormGroup} from "@angular/forms";
import {JobAdvertisementStatusEnum} from "@rex/job-advertisement/enums/job-advertisement-status.enum";

@Component({
  selector: 'rex-job-offer-filter-component',
  templateUrl: './job-offer-filter.component.html',
  styleUrls: ['./filter.component.scss'],
  providers: [

  ]
})
export class JobOfferFilterComponent implements OnInit {
  @ViewChild('status') status: ElementRef;

  @ViewChild('leftColumn') leftColumn: ElementRef;
  @ViewChild('cdkConnectedOverlay') cdkConnectedOverlay: CdkConnectedOverlay;

  public isOpened = false;
  public filterFocus = 'status';
  public readonly jobAdStatusEnum = JobAdvertisementStatusEnum;

  @Output() changed: EventEmitter<any> = new EventEmitter();

  public form = new FormGroup({
    status: new FormControl(null),
  });

  ngOnInit() {

  }

  switchState() {
    this.isOpened = !this.isOpened;
  }

  clearFilters()
  {
    this.form.clear();
    this.applyFilters()
  }

  changeFocus(state) {
    let element;

    switch(state) {
      case 'status':
        element = this.status; break;
    }

    this.leftColumn.nativeElement.scrollTo({ top: element.nativeElement.offsetParent.offsetTop - 40, behavior: 'smooth' });
    this.filterFocus = state;
  }

  getQuickAccessClassRules(access) {
    return {
      'cursor-pointer': true,
      'text-xs': true,
      'mb-5': true,
      'pr-5': true,
      'text-black': this.filterFocus == access,
      'text-gray': this.filterFocus != access,
      'font-bold': this.filterFocus == access,
    }
  }

  applyFilters()
  {
    this.changed.emit(this.form.value);
    this.isOpened = false;
  }

  cancel()
  {
    this.isOpened = false;
  }
}
