import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Injectable} from "@angular/core";
import {uuid} from "@a3l/utilities";
import {
  LocalizedContentFieldCreator
} from "../../../../../../libs/utilities/src/lib/form/localized-content/localized-content-field-creator";
import {TranslateService} from "@ngx-translate/core";
import {LocalizedContent} from "../../../../../../libs/utilities/src/lib/form/localized-content/localized-content";
import {
  LocalizedContentField
} from "../../../../../../libs/utilities/src/lib/form/localized-content/localized-content-field";

@Injectable({providedIn: 'root'})
export class EducationForm {

  constructor(
    private translator: TranslateService
  ) {
  }

  public emptyForm = (params = null) => {
    return new FormGroup({
      uuid: new FormControl(uuid()),
      name: new FormControl(null, Validators.required),
      field: new FormControl(null, Validators.required),
      start_date: new FormControl(null),
      end_date: new FormControl(null),
    });
  }

  public formFromArray = (array) => {
    let form = this.emptyForm(array.parameters);
    let field = new LocalizedContentFieldCreator(array.field).getArray();
    let name = new LocalizedContentFieldCreator(array.name).getArray();
    form.controls['uuid'].setValue(array.uuid ? array.uuid : uuid());
    form.controls['name'].setValue(name);
    form.controls['field'].setValue(field);
    form.controls['start_date'].setValue(array.start_date);
    form.controls['end_date'].setValue(array.end_date);

    return form;
  }

}
