import { Injectable } from '@angular/core';
import { map, filter } from 'rxjs/operators';

import * as moment from 'moment';

import { TranslateService } from '@ngx-translate/core';

import { Actions, ofType, createEffect } from '@ngrx/effects';

import { CookieDB } from '@a3l/core';

import { LoggedInUser } from './logged-in-user';
import { Notifications } from './notifications';

@Injectable()
export class Effects {
  // prettier-ignore
  language$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoggedInUser.loaded),
      map(({ user }) => user || {}),
      map(({ language }) => {
        this.translator.use(CookieDB.get('language', language || this.translator.getDefaultLang()));

        moment.locale(CookieDB.get('language', language || this.translator.getDefaultLang()));
      })
    ), { dispatch: false }
  );

  notifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoggedInUser.loaded),
      filter(({ user }) => user != null),
      map(() => Notifications.load())
    )
  );

  constructor(private actions$: Actions, private translator: TranslateService) {
    //
  }
}

import { Effects as NotificationsEffects } from './notifications';

export const effects = [Effects, NotificationsEffects];
