import { SwUpdate } from '@angular/service-worker';
import { interval } from 'rxjs';
import {Injectable} from "@angular/core";

@Injectable()
export class UpdateService {

  constructor(public updates: SwUpdate) {
    if (updates.isEnabled) {
      interval(1000 * 2).subscribe(() => updates.checkForUpdate()
        .then(() => {

        }));
    }
  }

  public checkForUpdates(): void {
    this.updates.available.subscribe(event => this.promptUser());
  }

  private promptUser(): void {
    console.log('updating to new version');
    this.updates.activateUpdate().then(() => document.location.reload());
  }
}
