<div [class]="{'bg-gray-light': !isOpened }" class="recruiter-filter flex justify-between text-sm font-medium items-center px-4" cdkOverlayOrigin #trigger="cdkOverlayOrigin" (click)="switchState()">
  <a3l-ui-icon icon="filter"></a3l-ui-icon>
  <span translate class="mx-3">Filtry</span>
  <a3l-ui-icon icon="chevron-up" class="text-xxxs" *ngIf="isOpened"></a3l-ui-icon>
  <a3l-ui-icon icon="chevron-down" class="text-xxxs" *ngIf="!isOpened"></a3l-ui-icon>
</div>
<ng-template
  cdkConnectedOverlay
  #cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isOpened"
>
  <div class="recruiter-filter__overlay p-5 bg-white rounded-xl z-10">
    <div class="flex justify-between">
      <form [formGroup]="form" class="recruiter-filter__left-column" #leftColumn>
        <div class="flex justify-between items-center mb-5">
          <h5 translate class="m-0">Filtry</h5>
          <button class="mx-1" type="button" a3l-ui-button icon small (click)="clearFilters()">
            <a3l-ui-icon icon="trash" class="text-xxs"></a3l-ui-icon>
          </button>
        </div>
        <a3l-ui-form-group class="mb-2" id="industries">
          <label a3l-ui-label translate #industries>candidate::panel.wizard.title.industries</label>
          <rex-industries-multiselect-field class="mt-4 block text-md" formControlName="industries"></rex-industries-multiselect-field>
        </a3l-ui-form-group>
        <hr class="recruiter-filter__hr my-4 border border-solid border-gray-light">
        <a3l-ui-form-group id="experience">
          <label a3l-ui-label translate #experience>Doświadczenie</label>
          <a3l-ui-range-input formControlName="experience"></a3l-ui-range-input>
        </a3l-ui-form-group>
        <hr class="cv-filter__hr my-4 border border-solid border-gray-light">
        <a3l-ui-form-group class="mb-2 " id="localization">
          <label a3l-ui-label translate #localization>candidate::panel.wizard.title.localization</label>
          <rex-single-localization-field formControlName="localization"></rex-single-localization-field>
        </a3l-ui-form-group>
      </form>
      <div class="recruiter-filter__right-column ml-8 bg-gray-light p-5 rounded-xl pb-10">
        <span class="uppercase font-bold text-xxxs" translate >Szybki dostęp</span>
        <p translate [class]="getQuickAccessClassRules('industries')" (click)="changeFocus('industries')">candidate::panel.wizard.title.industries</p>
        <p translate [class]="getQuickAccessClassRules('experience')" (click)="changeFocus('experience')">Doświadczenie</p>
        <p translate [class]="getQuickAccessClassRules('localization')" (click)="changeFocus('localization')">candidate::panel.wizard.title.localization</p>
      </div>
    </div>
    <div class="cv-filter__bottom-row mt-4">
      <button a3l-ui-button [color]="'primary'" [small]="true" class="text-sm" (click)="applyFilters()" translate>Zastosuj filtr</button>
      <button a3l-ui-button [small]="true" class="ml-2 text-sm" translate (click)="cancel()">Anuluj</button>
    </div>
  </div>
</ng-template>
