import {Component} from "@angular/core";
import {DialogRef} from "@a3l/utilities";

@Component({
  templateUrl: './auto-translate-languages-helper.dialog.html',
})
export class AutoTranslateLanguagesHelperDialog {
  /**
   * Create a new instance.
   *
   * @param {DialogRef<AutoTranslateLanguagesHelperDialog>} dialogRef
   */
  constructor(
    private dialogRef: DialogRef<AutoTranslateLanguagesHelperDialog>
    ) {
  }

  /**
   * Close the dialog.
   *
   * @return void
   */
  close(): void {
    this.dialogRef.close();
  }
}
