import { Directive, HostListener } from '@angular/core';

import { Dialog } from '@a3l/utilities';

import { TutorialDialog } from './tutorial.dialog';

@Directive({ selector: '[rex-tutorial-open]' })
export class TutorialOpenDirective {
  /**
   * Create a new instance.
   *
   * @param {Dialog} dialog
   */
  constructor(private dialog: Dialog) {
    //
  }

  /**
   * Handle the click event.
   *
   * @param {MouseEvent} event
   * @return void
   */
  @HostListener('click', ['$event'])
  onClick(event: MouseEvent): void {
    this.dialog.open(TutorialDialog, { mode: 'tutorial', type: 'RODO' }, { class: 'rex-tutorial-dialog' });
  }
}
