import { HttpException } from './exception';

import { HttpResponse } from './response';

export class HttpUnauthorizedException extends HttpException {
  /**
   * @var {string}
   */
  static readonly type: string = 'HttpUnauthorizedException';

  /**
   * @var {string}
   */
  public readonly type: string = HttpUnauthorizedException.type;

  /**
   * @param {string}
   */
  static readonly status: number = HttpResponse.UNAUTHORIZED;
}
