import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PortalModule } from '@angular/cdk/portal';
import { OverlayModule } from '@angular/cdk/overlay';

import { MenuComponent } from './menu.component';

import { MenuDirective } from './menu.directive';
import { MenuItemDirective } from './menu-item.directive';

const PUBLIC_API = [MenuComponent, MenuDirective, MenuItemDirective];

@NgModule({
    declarations: [...PUBLIC_API],
    imports: [CommonModule, PortalModule, OverlayModule],
    exports: [...PUBLIC_API]
})
export class MenuModule {}
