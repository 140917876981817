import {Injectable} from "@angular/core";
import {Dispatcher} from "@a3l/core";
import {Dialog} from "@a3l/utilities";
import {SaveEvaluationAnswersEvent} from "@rex/evaluations/events/save-evaluation-answers.event";
import {CreateEvaluationDialog} from "@rex/evaluations/dialogs/create-evaluation.dialog";

@Injectable()
export class ListenActionForEvaluationService {
  /**
   * Create a new instance.
   */
  constructor(
    private dispatcher: Dispatcher,
    private dialog: Dialog
  ) {}

  public runEvaluationRequestActions()
  {
    this.dispatcher.listen(SaveEvaluationAnswersEvent).subscribe(({data}) => {
      this.dialog.open(CreateEvaluationDialog, data);
    });
  }
}
