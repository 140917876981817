import {Specification} from "@a3l/core";
import {OrganizationUserStatusEnum} from "@rex/organization/enums/organization-user-status.enum";

export class AccountNeedsVerificationByOrganizationSpecification extends Specification<any> {
  organizationUserStatusEnum: typeof OrganizationUserStatusEnum = OrganizationUserStatusEnum;

  isSatisfiedBy(candidate: any): boolean {
    const { role, organization } = candidate;

    return organization && organization.status === this.organizationUserStatusEnum.VERIFIED
  }
}
