import { Directive, TemplateRef } from '@angular/core';

@Directive({
  selector: '[a3l-ui-error-message]',
})
export class ErrorMessageDirective {
  /**
   * Create a new instance.
   *
   * @param {TemplateRef<any>} TemplateRef
   */
  constructor(public templateRef: TemplateRef<any>) {
    //
  }
}
