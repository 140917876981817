import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import * as _ from 'lodash';
import * as queryString from 'query-string';

export interface ApiResponse {
  data: any;
}

@Injectable()
export class ApiService {
  /**
   * Create a new instance.
   *
   * @param {string} url
   * @param {HttpClient} httpClient
   */
  constructor(private endpoint: string, private httpClient: HttpClient) {
    //
  }

  /**
   * Create a request.
   *
   * @param {url} string
   * @param {any} parameters
   * @param {any} options
   * @return Observable<any>
   */
  get(url: string, parameters: any = {}, options: any = {}): Observable<any> {
    // Convert the nested objects to a JSON string
    for (const key in parameters) {
      if (!_.isObject(parameters[key])) continue;

      for (const subkey in parameters[key]) {
        parameters[`${key}[${subkey}]`] = parameters[key][subkey];
      }

      delete parameters[key];
    }

    const connector = url.indexOf('?') != -1 ? '&' : '?';

    return this.httpClient.get(`${this.endpoint + url}${connector}${queryString.stringify(parameters, { arrayFormat: 'bracket' })}`, options);
  }

  /**
   * Create a request.
   *
   * @param {url} string
   * @param {any} body
   * @return Observable<any>
   */
  put(url: string, body: any = null): Observable<any> {
    return this.httpClient.put(this.endpoint + url, body);
  }

  /**
   * Create a request.
   *
   * @param {url} string
   * @param {any} body
   * @return Observable<any>
   */
  patch(url: string, body: any = null): Observable<any> {
    return this.httpClient.patch(this.endpoint + url, body);
  }

  /**
   * Create a request.
   *
   * @param url
   * @param {any} body
   * @param options
   * @return Observable<any>
   */
  post(url: string, body: any = null, options?: {}): Observable<any> {
    return this.httpClient.post(this.endpoint + url, body, options);
  }

  /**
   * Create a request.
   *
   * @param {url} string
   * @param {any} body
   * @return Observable<any>
   */
  delete(url: string, body: any = null): Observable<any> {
    return this.httpClient.delete(this.endpoint + url);
  }
}
