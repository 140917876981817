import { Component, Input, ViewEncapsulation } from '@angular/core';
import {SubscriptionPlanEnum} from "@rex/core/subscription-plan.enum";

@Component({
  selector: 'rex-recruiter-plan',
  templateUrl: './recruiter-plan.component.html',
  styleUrls: ['./recruiter-plan.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RecruiterPlanComponent {
  @Input()
  user: any;

  protected readonly SubscriptionPlanEnum = SubscriptionPlanEnum;
}
