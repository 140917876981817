import { Directive, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { Title } from './title';

@Directive({ selector: '[a3l-layout-title]' })
export class LayoutTitleDirective implements OnInit, OnDestroy {
  /**
   * @var {Subscription}
   */
  protected subscription: Subscription = Subscription.EMPTY;

  /**
   * Create a new instance.
   *
   * @param {Title} title
   * @param {ElementRef} elementRef
   */
  constructor(private title: Title, private elementRef: ElementRef) {
    //
  }

  /**
   * Set the page title.
   */
  ngOnInit() {
    this.subscription = this.title.value$.subscribe((value) => (this.elementRef.nativeElement.innerHTML = value));
  }

  /**
   * Cleanup.
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
