<!--<input type="checkbox" [id]="id" [checked]="checked" [disabled]="disabled" (change)="toggle()" />-->
<!--<span class="a3l-ui-checkbox__indicator">-->
<!--  <a3l-ui-icon icon="check"></a3l-ui-icon>-->
<!--</span>-->
<!--<label [attr.for]="id"></label>-->
<!--<ng-content></ng-content>-->

<a3l-ui-form-group class="outline bg-white {{additionalClass}}" (click)="toggle()">
  <label a3l-ui-label translate><ng-content></ng-content></label>
  <div class="flex items-center">
    <input type="checkbox" [id]="id" [checked]="checked" [disabled]="disabled" />
    <span class="a3l-ui-simple-checkbox__indicator">
      <a3l-ui-icon icon="check"></a3l-ui-icon>
    </span>
    <span class="a3l-ui-simple-checkbox__label" translate>Tak</span>
  </div>
</a3l-ui-form-group>
