import {
  Component,
  Input,
  QueryList,
  forwardRef,
  ContentChildren,
  ViewEncapsulation,
  ElementRef,
  ViewChild,
  ViewContainerRef,
  TemplateRef,
  OnDestroy,
  HostListener,
  Injector,
  OnInit,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Overlay, OverlayRef, OverlayConfig, VerticalConnectionPos, HorizontalConnectionPos } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { ActiveDescendantKeyManager, Highlightable } from '@angular/cdk/a11y';
import { Subscription } from 'rxjs';

import { nextTick } from '../utils';

import { Control } from './control';

@Component({
  selector: 'a3l-ui-selectpicker-option',
  template: '<ng-content></ng-content>',
  host: {
    class: 'a3l-ui-selectpicker-option',
    '[class.a3l-ui-selectpicker-option--active]': 'active',
  },
})
export class SelectpickerOptionComponent implements Highlightable {
  /**
   * @var {any}
   */
  @Input()
  value: any;

  /**
   * @var {boolean}
   */
  active: boolean = false;

  /**
   * @var {string}
   */
  get label(): string {
    return (this.elementRef.nativeElement.textContent || '').trim();
  }

  /**
   * Create a new instance.
   *
   * @param {ElementRef<HTMLElement>} elementRef
   */
  constructor(protected elementRef: ElementRef<HTMLElement>) {
    //
  }

  /**
   * Applies the styles for an active item to this item.
   *
   * @return void
   */
  setActiveStyles(): void {
    this.active = true;
  }

  /**
   * Applies the styles for an inactive item to this item.
   *
   * @return void
   */
  setInactiveStyles(): void {
    this.active = false;
  }
}

@Component({
  selector: 'a3l-ui-selectpicker',
  templateUrl: './selectpicker.component.html',
  styleUrls: ['./selectpicker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectpickerComponent),
      multi: true,
    },
    { provide: Control, useExisting: SelectpickerComponent },
  ],
  host: {
    class: 'a3l-ui-selectpicker',
    '(mouseover)': '_canShowValidationInTooltip = true',
    '(mouseleave)': '_canShowValidationInTooltip = false',
  },
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('panel', [
      state('void', style({ opacity: 0 })),
      state('enter', style({ opacity: 1 })),
      transition('void => *', animate('170ms linear', style({ opacity: 1 }))),
      transition('* => void', animate('100ms 25ms linear', style({ opacity: 0 }))),
    ]),
  ],
})
export class SelectpickerComponent extends Control implements OnInit, OnDestroy, ControlValueAccessor {
  /**
   * @var {any}
   */
  @Input()
  get value(): any {
    return this._value;
  }
  set value(value: any) {
    this._value = value;

    if (!this.selectables) return;

    const option = this.selectables.find((option) => option.value === this.value);

    option && (this.query = option.label);
  }

  /**
   * @var {string}
   */
  @Input()
  placeholder: string;

  /**
   * @var {Control}
   */
  @ViewChild(Control, { static: false })
  control: Control;

  /**
   * @var {TemplateRef<any>}
   */
  @ViewChild('pane', { static: false })
  pane: TemplateRef<any>;

  /**
   * @var {ElementRef}
   */
  @ViewChild('panel', { static: false })
  panel: ElementRef;

  /**
   * @var {QueryList<SelectpickerOptionComponent>}
   */
  @ContentChildren(SelectpickerOptionComponent, { descendants: true })
  selectables!: QueryList<SelectpickerOptionComponent>;

  /**
   * @var {any[]}
   */
  get options(): any[] {
    const pattern = new RegExp('^' + (this.query || ''), 'i');

    return this.selectables.filter(({ label }) => label.match(pattern) !== null);
  }

  /**
   * @var {boolean}
   */
  get canShowValidationInTooltip(): boolean {
    return this._canShowValidationInTooltip;
  }

  /**
   * @var {'void' | 'enter'}
   */
  state: 'void' | 'enter' = 'void';

  /**
   * @var {string}
   */
  query: string;

  /**
   * @var {boolean}
   */
  opened: boolean;

  /**
   * @var {boolean}
   */
  get empty(): boolean {
    return !this.value;
  }

  /**
   * @var {boolean}
   */
  get emptyQuery(): boolean {
    return !this.query;
  }

  /**
   * @var {boolean}
   */
  get locked(): boolean {
    if (this.ngControl === undefined) return false;

    return this.ngControl && this.ngControl.disabled;
  }

  /**
   * @var {any}
   */
  protected _value: any;

  /**
   * @var {NgControl}
   */
  protected ngControl: NgControl;

  /**
   * @var {OverlayRef}
   */
  protected overlayRef: OverlayRef | null;

  /**
   * @var {ActiveDescendantKeyManager<SelectpickerOptionComponent>}
   */
  protected keyManager: ActiveDescendantKeyManager<SelectpickerOptionComponent>;

  /**
   * @var {any}
   */
  protected propagateChange: any = () => {};

  /**
   * @var {Subscription}
   */
  protected closeSubscription: Subscription = Subscription.EMPTY;

  /**
   * @var {Subscription}
   */
  protected keyManagerSubscription: Subscription = Subscription.EMPTY;

  /**
   * @var {boolean}
   */
  protected _canShowValidationInTooltip: boolean = false;

  /**
   * Create a new instance.
   *
   * @param {Overlay} overlay
   * @param {Injector} injector
   * @param {ElementRef} elementRef
   * @param {ViewContainerRef} viewContainerRef
   */
  constructor(private overlay: Overlay, private injector: Injector, private elementRef: ElementRef, private viewContainerRef: ViewContainerRef) {
    super();
  }

  /**
   * Initialize.
   */
  ngOnInit() {
    this.ngControl = this.injector.get(NgControl);
  }

  /**
   * Open the overlay panel.
   *
   * @return void
   */
  open(): void {
    if (this.opened || this.locked) return;

    this.opened = true;
    this.focused = true;

    this.control.focusin();

    let [originX, originFallbackX]: HorizontalConnectionPos[] = ['start', 'end'];

    let [overlayY, overlayFallbackY]: VerticalConnectionPos[] = ['top', 'bottom'];

    let offsetY = this.elementRef.nativeElement.offsetHeight;
    let [originY, originFallbackY] = [overlayY, overlayFallbackY];
    let [overlayX, overlayFallbackX] = [originX, originFallbackX];

    const strategy = this.overlay
      .position()
      .flexibleConnectedTo(this.elementRef)
      .withLockedPosition()
      .withPositions([
        { originX, originY, overlayX, overlayY, offsetY },
        { originX: originFallbackX, originY, overlayX: overlayFallbackX, overlayY, offsetY },
        {
          originX,
          originY: originFallbackY,
          overlayX,
          overlayY: overlayFallbackY,
          offsetY: -offsetY,
        },
        {
          originX: originFallbackX,
          originY: originFallbackY,
          overlayX: overlayFallbackX,
          overlayY: overlayFallbackY,
          offsetY: -offsetY,
        },
      ]);

    const overlayConfig: OverlayConfig = {
      positionStrategy: strategy,
      width: this.elementRef.nativeElement.offsetWidth,
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
      disposeOnNavigation: true,
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
    };

    this.overlayRef = this.overlay.create(overlayConfig);

    this.overlayRef.attach(new TemplatePortal(this.pane, this.viewContainerRef));

    this.closeSubscription = this.overlayRef.backdropClick().subscribe(this.close.bind(this));

    this.state = 'enter';

    this.initKeyManager();
  }

  /**
   * Close the overlay panel.
   *
   * @return void
   */
  close(): void {
    if (!this.opened) return;

    this.opened = false;
    this.focused = false;

    this.state = 'void';

    const option = this.options.find((option) => option.value === this.value);

    this.propagateChange((this.value = option ? option.value : null));

    this.overlayRef && this.overlayRef.dispose();

    this.closeSubscription.unsubscribe();
    this.keyManagerSubscription.unsubscribe();
  }

  /**
   * Toggle the overlay panel open or closed.
   *
   * @return void
   */
  toggle(): void {
    this.opened ? this.close() : this.open();
  }

  /**
   * Handle the item selection.
   *
   * @param {SelectpickerOptionComponent} option
   * @return void
   */
  select(option: SelectpickerOptionComponent): void {
    this.propagateChange((this.value = option.value));

    this.control.focusout();
  }

  /**
   * Handle the 'keydown' event.
   *
   * @param {any} event
   * @return void
   */
  @HostListener('keydown', ['$event'])
  onDOMKeydown(event: KeyboardEvent): void {
    if (event.code == 'Enter' && this.keyManager.activeItem) {
      this.select(this.keyManager.activeItem);

      event.preventDefault();

      return;
    }

    this.keyManager.onKeydown(event);
  }

  /**
   * Handle the 'mousedown' event.
   *
   * @param {MouseEvent} event
   * @return void
   */
  @HostListener('mousedown', ['$event'])
  protected onDOMMousedown(event: MouseEvent): void {
    this.control.focusin();

    event.preventDefault();
    event.stopPropagation();
  }

  /**
   * Init key manager
   *
   * @return void
   */
  initKeyManager(): void {
    this.keyManagerSubscription.unsubscribe();

    this.keyManager = new ActiveDescendantKeyManager(this.options).withWrap();

    this.keyManagerSubscription = this.keyManager.change.subscribe(() => {
      const index = this.keyManager.activeItemIndex || 0;

      this.panel.nativeElement.scrollTop = ((index, height, current, maxHeight) => {
        const offset = index * height;

        if (offset < current) return offset;

        if (offset + height > current + maxHeight) {
          return Math.max(0, offset - maxHeight + height);
        }

        return current;
      })(index, 35, this.panel.nativeElement.scrollTop, this.panel.nativeElement.offsetHeight);
    });
  }

  /**
   * Reset the input.
   *
   * @return void
   */
  resetInput(): void {
    this.query = '';
  }
  /**
   * Write a new value from the form model.
   *
   * @param {any} value
   * @return void
   */
  writeValue(value: any): void {
    this.value = value;

    nextTick(() => (this.value = value));
  }

  /**
   * Register handler.
   *
   * @param {any} fn
   * @return void
   */
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  /**
   * Register handler.
   *
   * @param {any} fn
   * @return void
   */
  registerOnTouched(fn: any): void {}

  /**
   * Cleanup
   */
  ngOnDestroy() {
    this.close();
  }
}
