import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'rex-tutorial-go-to-step-button',
  templateUrl: './go-to-step-button.component.html',
  styleUrls: ['./go-to-step-button.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'rex-tutorial-go-to-step-button',
    '[class.rex-tutorial-go-to-step-button--current]': 'current || manualCurrent',
    '[class.rex-tutorial-go-to-step-button--done]': '!current && !manualCurrent && done',
    '[class.rex-tutorial-go-to-step-button--disabled]': 'disabled',
  },
})
export class GoToStepButtonComponent implements OnInit, OnDestroy {
  /**
   * @var {string}
   */
  @Input()
  routerLink: string;

  /**
   * @var {boolean}
   */
  @Input('as-done')
  done: boolean = false;

  /**
   * @var {boolean}
   */

  @Input()
  manualCurrent: boolean = false;

  /**
   * @var {boolean}
   */
  current: boolean = false;

  /**
   * @var {boolean}
   */
  @Input()
  disabled: boolean = true;

  /**
   * @var {Subscription}
   */
  protected subscription: Subscription = Subscription.EMPTY;

  /**
   * Create a new instance.
   *
   * @param {Router} router
   */
  constructor(private router: Router) {
    //
  }

  /**
   * Initialization.
   */
  ngOnInit() {
    this.current = this.router.url == this.routerLink;

    this.subscription = this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: any) => {
      this.current = event.url == this.routerLink;
    });
  }

  /**
   * Cleanup.
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
