<form [formGroup]="form" class="" id="language_skill_item">
  <div class="-mx-1 flex flex-col md:flex-row">
    <a3l-ui-form-group clear class="mx-1 mb-2 md:mb-0 flex-1">
      <a3l-ui-select [placeholder]="'Wybierz' | translate" formControlName="id">
        <a3l-ui-select-option [value]="language.id" *ngFor="let language of suggestions | a3lOrderBy:'id'"> {{ language.content }} </a3l-ui-select-option>
      </a3l-ui-select>
      <ng-template a3l-ui-error-message let-key="key">{{ 'validation.' + key | translate }}</ng-template>
    </a3l-ui-form-group>
    <a3l-ui-form-group clear class="mx-1 flex-1">
      <a3l-ui-select class="rex-languages-item-field__level" [searcher]="false" [badgeInfo]="getScoreBadge()" [placeholder]="'Wybierz poziom' | translate" formControlName="level">
        <a3l-ui-select-option value="A1">A1</a3l-ui-select-option>
        <a3l-ui-select-option value="A2">A2</a3l-ui-select-option>
        <a3l-ui-select-option value="B1">B1</a3l-ui-select-option>
        <a3l-ui-select-option value="B2">B2</a3l-ui-select-option>
        <a3l-ui-select-option value="C1">C1</a3l-ui-select-option>
        <a3l-ui-select-option value="C2">C2</a3l-ui-select-option>
        <a3l-ui-select-option value="native" translate>Język ojczysty</a3l-ui-select-option>
      </a3l-ui-select>
      <ng-template a3l-ui-error-message let-key="key">{{ 'validation.' + key | translate }}</ng-template>
    </a3l-ui-form-group>
  </div>
</form>
