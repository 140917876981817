import { Component, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';

import { Portal } from './portal';

@Component({
  selector: 'a3l-ui-portal-target',
  templateUrl: './portal-target.html',
  host: {
    class: 'a3l-ui-portal-target',
  },
})
export class PortalTarget implements OnInit, OnDestroy {
  /**
   * @var {string}
   */
  @Input()
  name: string;

  /**
   * @var {TemplateRef<any>}
   */
  templateRef: TemplateRef<any>;

  /**
   * Create a new instance.
   *
   * @param {Portal} portal
   */
  constructor(private portal: Portal) {
    //
  }

  /**
   * Initialization.
   */
  ngOnInit() {
    this.portal.register(this.name, this);
  }

  /**
   * Attach the template.
   *
   * @todo: ability to attach multiple templates
   *
   * @param {TemplateRef<any>} template
   * @return void
   */
  attach(template: TemplateRef<any>): void {
    this.templateRef = template;
  }

  /**
   * Detach the template.
   *
   * @return void
   */
  detach(): void {
    this.templateRef = null;
  }

  /**
   * Cleanup.
   */
  ngOnDestroy() {
    this.portal.unregister(this.name);
  }
}
