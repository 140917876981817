import { NgModule } from '@angular/core';
import {LocalizedWysiwygInputComponent} from "./localized-wysiwyg-input.component";
import {LocalizedInputComponent} from "./localized-input.component";
import {LocalizedLanguageSwitcherComponent} from "./localized-language-switcher.component";
import {LanguagesHelperDialog} from "./dialog/languages-helper.dialog";
import {CommonModule} from "@angular/common";
import {UtilitiesModule} from "@a3l/utilities";
import {TranslateModule} from "@ngx-translate/core";
import {LocalizedLanguageLabelComponent} from "./localized-language-label.component";
import {AutoTranslateLanguagesHelperDialog} from "./dialog/auto-translate-languages-helper.dialog";

const PUBLIC_API = [
  LocalizedWysiwygInputComponent,
  LocalizedInputComponent,
  LocalizedLanguageSwitcherComponent,
  LocalizedLanguageLabelComponent,
  LanguagesHelperDialog,
  AutoTranslateLanguagesHelperDialog
];


@NgModule({
  declarations: [...PUBLIC_API],
  imports: [CommonModule, UtilitiesModule, TranslateModule
  ],
  exports: [...PUBLIC_API],
})
export class LocalizedModule {}
