<div [class]="{'bg-gray-light': !isOpened }" class="rex-filter flex justify-between text-sm font-medium items-center px-4" cdkOverlayOrigin #trigger="cdkOverlayOrigin" (click)="switchState()">
  <a3l-ui-icon icon="filter"></a3l-ui-icon>
  <span translate class="mx-3">Filtry</span>
  <a3l-ui-icon icon="chevron-up" class="text-xxxs" *ngIf="isOpened"></a3l-ui-icon>
  <a3l-ui-icon icon="chevron-down" class="text-xxxs" *ngIf="!isOpened"></a3l-ui-icon>
</div>
<ng-template
  cdkConnectedOverlay
  #cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isOpened"
>
  <div class="rex-filter__overlay p-5 bg-white rounded-xl z-10">
    <div class="flex justify-between">
      <form [formGroup]="form" class="rex-filter__left-column" #leftColumn>
        <div class="flex justify-between items-center mb-5">
          <h5 translate class="m-0">Filtry</h5>
          <button class="mx-1" type="button" a3l-ui-button icon small (click)="clearFilters()">
            <a3l-ui-icon icon="trash" class="text-xxs"></a3l-ui-icon>
          </button>
        </div>
        <a3l-ui-form-group class="mb-2" id="status">
          <label a3l-ui-label translate #status>Status</label>
          <a3l-ui-select [placeholder]="'Wybierz' | translate" formControlName="status">
            <a3l-ui-select-option value="{{ jobAdStatusEnum.ACCEPTED }}">{{ 'jobadvertisement::index.enum.status.' + jobAdStatusEnum.ACCEPTED | translate }}</a3l-ui-select-option>
            <a3l-ui-select-option value="{{ jobAdStatusEnum.WAITING_FOR_ACCEPTANCE }}">{{ 'jobadvertisement::index.enum.status.' + jobAdStatusEnum.WAITING_FOR_ACCEPTANCE | translate }}</a3l-ui-select-option>
          </a3l-ui-select>
        </a3l-ui-form-group>
      </form>
      <div class="rex-filter__right-column ml-8 bg-gray-light p-5 rounded-xl pb-10">
        <span class="uppercase font-bold text-xxxs" translate >Szybki dostęp</span>
        <p translate class="pt-5" [class]="getQuickAccessClassRules('status')" (click)="changeFocus('status')">Status</p>
      </div>
    </div>
    <div class="cv-filter__bottom-row mt-4">
      <button a3l-ui-button [color]="'primary'" [small]="true" class="text-sm" (click)="applyFilters()" translate>Zastosuj filtr</button>
      <button a3l-ui-button [small]="true" class="ml-2 text-sm" translate (click)="cancel()">Anuluj</button>
    </div>
  </div>
</ng-template>
