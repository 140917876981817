import { ErrorHandler, Injectable, NgZone, Injector } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { SnackBar, SnackBarType, SnackBarPersistable } from '@a3l/utilities';
import { Exception, HttpNotFoundException, FileUploadMaxSizeException, FileUploadUnsupportedException } from '@a3l/core';

import { AccountIsBlockedException, AccountNeedsActivationException, AgreementIsInSigningProcessException } from '@rex/auth';
import { AccountIsRejectedException } from "@rex/auth/account-is-rejected.exception";

import { environment } from '@rex/environments/environment';
import * as Sentry from "@sentry/angular";
import {
  AccountNeedsVerificationByOrganizationException
} from "@rex/organization/organization-onboarding/exceptions/account-needs-verification-by-organization.exception";
import {RecruiterIsBlockedInOrganizationException} from "@rex/auth/recruiter-is-blocked-in-organization.exception";
import {CannotBeLoggedInException} from "@rex/auth/cannot-be-logged-in.exception";
import {ClientCompanyIsBlockedException} from "@rex/auth/client-company-is-blocked.exception";
import {AgreementNotCompletedException} from "@rex/auth/agreement-not-completed.exception";

@Injectable()
export class ErrorsHandler implements ErrorHandler {
  /**
   * @var {SnackBar}
   */
  get snackbar(): SnackBar {
    return this.injector.get(SnackBar);
  }

  /**
   * @var {TranslateService}
   */
  get translator(): TranslateService {
    return this.injector.get(TranslateService);
  }


  /**
   * @var {any[]}
   */
  protected handlers: any[] = [
    { exception: HttpNotFoundException, redirectTo: '/e/not-found' },
    { exception: AccountIsBlockedException, redirectTo: '/e/account-is-blocked' },
    { exception: RecruiterIsBlockedInOrganizationException, redirectTo: '/e/recruiter-is-blocked-in-organization' },
    { exception: ClientCompanyIsBlockedException, redirectTo: '/e/client-company-is-blocked' },
    { exception: AccountIsRejectedException, redirectTo: '/e/account-is-rejected' },
    { exception: AccountNeedsActivationException, redirectTo: '/e/account-needs-activation' },
    { exception: AccountNeedsVerificationByOrganizationException, redirectTo: '/e/account-needs-verification-by-organization' },
    { exception: AgreementIsInSigningProcessException, redirectTo: '/e/agreement-is-in-signing-process' },
    { exception: AgreementNotCompletedException, redirectTo: '/e/agreement-not-completed' },
    { exception: FileUploadMaxSizeException, message: 'error.upload_max_file_size' },
    { exception: FileUploadUnsupportedException, message: 'error.unsupported_file_extension' },
    { exception: CannotBeLoggedInException, redirectTo: '/e/cannot-be-logged-in' },
  ];

  /**
   * Create a new instance.
   *
   * @param {NgZone} ngZone
   * @param {Injector} injector
   */
  constructor(private ngZone: NgZone, private injector: Injector) {
    Sentry.createErrorHandler({
      showDialog: false,
    });
  }

  /**
   * Handle the error.
   *
   * @param {any} error
   * @return void
   */
  handleError(error: any): void {
    this.ngZone.run(() => this.handle(error.rejection || error));
  }

  /**
   * Handle the error.
   *
   * @param {any} error
   * @return void
   */
  protected handle(error: any): void {
    let message = error.message;

    if (!environment.production) {
      console.warn(error);
    }

    if (error instanceof Exception || error.type) {
      return this.handleCustomException(error, message);
    }
  }

  /**
   * Handle the custom error.
   *
   * @param {any} error
   * @param {string} message
   * @return void
   */
  protected handleCustomException(error: any, message: string): void {
    let handler: any = {};
    for (let i = 0, x = this.handlers.length; i < x; i++) {
      const item = this.handlers[i];

      handler = error instanceof item.exception || error.type == item.exception.type ? item : handler;
    }

    if (handler.redirectTo) {
      window.location.href = handler.redirectTo;
    }

    if (handler.message) {
      message = this.translator.instant(handler.message);
    }

    message && this.snackbar.open(message, SnackBarType.Error, SnackBarPersistable.Yes);
  }
}
