import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'a3l-ui-spinner',
  template: '',
  styleUrls: ['./spinner.component.scss'],
  host: {
    class: 'a3l-ui-spinner',
    '[class.a3l-ui-spinner--processing]': "color == 'processing'",
    '[class.a3l-ui-spinner--gray]': "color == 'gray'",
    '[style.height.px]': 'size',
    '[style.width.px]': 'size',
  },
  encapsulation: ViewEncapsulation.None,
})
export class SpinnerComponent {
  /**
   * @var {number}
   */
  @Input()
  size: number = 40;

  /**
   * @var {string}
   */
  @Input()
  color: null | 'processing' | 'gray' = null;


  /**
   * Create a new instance.
   */
  constructor() {
    //
  }
}
