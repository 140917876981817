import { Directive, Input, ContentChild } from '@angular/core';

import { DatasetTableDElement } from './dataset-table-delement';
import { DatasetTableHElement } from './dataset-table-helement';

@Directive({ selector: '[a3l-dataset-table-column]' })
export class DatasetTableColumn {
  /**
   * @var {string}
   */
  @Input('a3l-dataset-table-column')
  name: string;

  /**
   * @var {DatasetTableDElement}
   */
  @ContentChild(DatasetTableDElement, { static: true })
  cell: DatasetTableDElement;

  /**
   * @var {DatasetTableHElement}
   */
  @ContentChild(DatasetTableHElement, { static: true })
  header: DatasetTableHElement;

  /**
   * Create a new instance.
   */
  constructor() {
    //
  }
}
