import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { A11yModule } from '@angular/cdk/a11y';
import { PortalModule } from '@angular/cdk/portal';

import { TabComponent } from './tab.component';
import { TabsComponent } from './tabs.component';
import { TabsBarComponent } from './tabs-bar.component';

import { TabTitleDirective } from './tab-title.directive';
import {QuickTabComponent} from "./quick-tab.component";

const PUBLIC_API = [TabComponent, TabsComponent, TabTitleDirective, QuickTabComponent];

const PRIVATE_API = [TabsBarComponent];

@NgModule({
  declarations: [...PUBLIC_API, ...PRIVATE_API],
  imports: [CommonModule, A11yModule, PortalModule],
  exports: [...PUBLIC_API],
})
export class TabsModule {}
