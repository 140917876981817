import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PortalModule } from '@angular/cdk/portal';
import { OverlayModule } from '@angular/cdk/overlay';

import { PopoverComponent } from './popover.component';

import { PopoverDirective } from './popover.directive';
import { PopoverPositionOriginDirective } from './popover-position-origin.directive';

const PUBLIC_API = [PopoverComponent, PopoverDirective, PopoverPositionOriginDirective];

@NgModule({
    declarations: [...PUBLIC_API],
    imports: [CommonModule, PortalModule, OverlayModule],
    exports: [...PUBLIC_API]
})
export class PopoverModule {}
