import {Component, Input} from "@angular/core";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

@Component({
  selector: 'rex-strong-password-helper',
  templateUrl: './strong-password-helper.html'
})
export class StrongPasswordHelper {
  @Input()
  password: string;

  checkMinLengthMatch(passwordToCheck: string|null): boolean {
    return passwordToCheck && passwordToCheck.length >= 8
  }

  checkLowerAndUpperMatch(passwordToCheck: string|null): boolean {
    return passwordToCheck && /[a-z]/.test(passwordToCheck) && /[A-Z]/.test(passwordToCheck);
  }
}
