import { ActionReducerMap } from '@ngrx/store';

import { State } from './state';

import * as fromLoggedInUser from './logged-in-user';
import * as fromNotifications from './notifications';

export const reducer: ActionReducerMap<State> = {
  loggedInUser: fromLoggedInUser.reducer,
  notifications: fromNotifications.reducer,
};
