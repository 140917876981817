import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { Nl2brComponent } from './nl2br.component';

const PUBLIC_API = [Nl2brComponent];

@NgModule({
  declarations: [...PUBLIC_API],
  imports: [CommonModule],
  exports: [...PUBLIC_API],
})
export class WysiwygModule {}
