import {
  Component,
  ElementRef,
  Input,
  ViewChild,
  ViewEncapsulation,
  forwardRef,
  ContentChildren,
  QueryList,
  Renderer2,
  EventEmitter,
  Output,
  Optional,
  OnInit,
  OnDestroy,
  HostListener,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';

import { nextTick } from '../utils';

@Component({
  selector: 'a3l-ui-segment',
  templateUrl: './segment.component.html',
  styleUrls: ['./segment.component.scss'],
  host: {
    class: 'a3l-ui-segment',
  },
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SegmentComponent),
      multi: true,
    },
  ],
})
export class SegmentComponent implements ControlValueAccessor {
  /**
   * @var {any}
   */
  get value() {
    return this._value;
  }
  set value(value: any) {
    this.valueChange.emit((this._value = value));
  }

  /**
   * @var {EventEmitter<any>}
   */
  @Output()
  valueChange: EventEmitter<any> = new EventEmitter();

  /**
   * @var {ElementRef}
   */
  @ViewChild('indicator', { static: true })
  indicator: ElementRef;

  /**
   * @var {QueryList<SegmentButtonComponent>}
   */
  @ContentChildren(forwardRef(() => SegmentButtonComponent), { descendants: true }) //prettier-ignore
  buttons: QueryList<SegmentButtonComponent>;

  /**
   * @var {any}
   */
  protected propagateChange: any = () => {};

  /**
   * @var {any}
   */
  protected _value: any;

  /**
   * Create a new instance.
   *
   * @param {Renderer2} renderer
   */
  constructor(private renderer: Renderer2) {
    //
  }

  /**
   * Set the value.
   *
   * @param {any} value
   * @return void
   */
  change(value: any): void {
    this.propagateChange((this.value = value));

    nextTick(() => this.render());
  }

  /**
   * Write a new value from the form model.
   *
   * @param {any} value
   * @return void
   */
  writeValue(value: any): void {
    this.value = value;

    this.value && nextTick(() => this.render());
  }

  /**
   * Register handler.
   *
   * @param {any} fn
   * @return void
   */
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  /**
   * Register handler.
   *
   * @param {any} fn
   * @return void
   */
  registerOnTouched(fn: any): void {}

  /**
   * Set the visibility of the indicator.
   *
   * @return void
   */
  protected render(): void {
    const index = this.buttons.toArray().findIndex(({ value }) => value == this.value);

    if (index == -1) return;

    const { offsetWidth: width, offsetHeight: height } = this.buttons.get(index).elementRef.nativeElement;

    this.renderer.setStyle(this.indicator.nativeElement, 'width', `${width}px`);
    this.renderer.setStyle(this.indicator.nativeElement, 'height', `${height}px`);
    this.renderer.setStyle(this.indicator.nativeElement, 'transform', `translateX(${width * index}px)`);
  }
}

@Component({
  selector: 'a3l-ui-segment-button',
  template: '<ng-content></ng-content>',
  host: {
    class: 'a3l-ui-segment-button',
    '[class.a3l-ui-segment-button--active]': 'active',
  },
  encapsulation: ViewEncapsulation.None,
})
export class SegmentButtonComponent implements OnInit, OnDestroy {
  /**
   * @var {any}
   */
  @Input()
  value: any;

  /**
   * @var {boolean}
   */
  active: boolean = false;

  /**
   * @var {Subscription}
   */
  protected subscription: Subscription = Subscription.EMPTY;

  /**
   * Create a new instance.
   *
   * @param {ElementRef} elementRef
   * @param {SegmentComponent} segment
   */
  constructor(public elementRef: ElementRef, @Optional() private segment: SegmentComponent) {
    //
  }

  /**
   * Initialization.
   */
  ngOnInit() {
    this.active = this.value == this.segment.value;

    this.subscription = this.segment.valueChange.subscribe((value) => {
      this.active = this.value == value;
    });
  }

  /**
   * Handle the change.
   *
   * @param {MouseEvent} event
   * @return void
   */
  @HostListener('click', ['$event'])
  change(event: MouseEvent): void {
    event.preventDefault();

    this.segment.change(this.value);
  }

  /**
   * Cleanup.
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
