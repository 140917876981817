import { Directive, TemplateRef, ViewContainerRef, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';
import { startWith } from 'rxjs/operators';

@Directive({ selector: '[rex-is-language]' })
export class IsLanguageDirective implements OnInit, OnDestroy {
  /**
   * @var {any}
   */
  @Input('rex-is-language')
  languages: any;

  /**
   * @var {boolean}
   */
  protected embedded: boolean = false;

  /**
   * @var {Subscription}
   */
  protected subscription: Subscription = Subscription.EMPTY;

  /**
   * Create a new instance.
   *
   * @param {TranslateService} translator
   * @param {TemplateRef} templateRef
   * @param {ViewContainerRef} viewContainer
   */
  constructor(private translator: TranslateService, private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef) {
    //
  }

  /**
   * Add the template content to the DOM.
   */
  ngOnInit() {
    this.subscription = this.translator.onLangChange.pipe(startWith(this.translator.currentLang)).subscribe((language) => {
      const languages = Array.isArray(this.languages) ? this.languages : [this.languages];

      if (!this.embedded && languages.indexOf(language) != -1) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.embedded = true;
      } else if (this.embedded) {
        this.viewContainer.clear();
        this.embedded = false;
      }
    });
  }

  /**
   * Cleanup.
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
