import { Exception } from '@a3l/core';

export class RecruiterIsBlockedInOrganizationException extends Exception {
  /**
   * @var {string}
   */
  static readonly type = 'RecruiterIsBlockedInOrganizationException';

  /**
   * @var {string}
   */
  public readonly type = RecruiterIsBlockedInOrganizationException.type;
}
