import { Pipe, PipeTransform } from '@angular/core';

import slugify from 'slugify';

@Pipe({ name: 'a3lOrderBy' })
export class OrderByPipe implements PipeTransform {
  /**
   * Order given items by field.
   *
   * @param {any[]} items
   * @param {string} field
   * @param {'asc' | 'desc'} direction
   * @return any[]
   */
  transform(items: any[], field: string, direction: 'asc' | 'desc' = 'asc'): any[] {
    if (!items) return [];

    items.sort((itemA, itemB) => {
      let fieldA = itemA[field], fieldB = itemB[field]; // prettier-ignore

      if (typeof fieldA === 'string') {
        fieldA = slugify(fieldA);
      }

      if (typeof fieldB === 'string') {
        fieldB = slugify(fieldB);
      }

      return (fieldA > fieldB ? 1 : -1) * (direction == 'asc' ? 1 : -1);
    });

    return items;
  }
}
