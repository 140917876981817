import { Exception } from '@a3l/core';

export class CannotBeLoggedInException extends Exception {
  /**
   * @var {string}
   */
  static readonly type = 'CannotBeLoggedInException';

  /**
   * @var {string}
   */
  public readonly type = CannotBeLoggedInException.type;
}
