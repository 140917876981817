import { Component, ViewEncapsulation, TemplateRef, ViewChild, ContentChild } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Subject } from 'rxjs';

import { PopoverPositionOriginDirective } from './popover-position-origin.directive';

@Component({
  selector: 'a3l-ui-popover',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
  animations: [trigger('visibility', [state('void', style({ opacity: 0 })), state('enter', style({ opacity: 1 })), transition('* => void', animate('100ms 25ms linear', style({ opacity: 0 })))])],
})
export class PopoverComponent {
  /**
   * @var {TemplateRef<any>}
   */
  @ViewChild(TemplateRef)
  templateRef: TemplateRef<any>;

  /**
   * @var {ElementRef}
   */
  @ContentChild(PopoverPositionOriginDirective)
  positionOrigin: PopoverPositionOriginDirective;

  /**
   * @var {'void' | 'enter'}
   */
  state: 'void' | 'enter' = 'void';

  /**
   * @var {Subject<any>}
   */
  protected afterDestroy = new Subject<any>();

  /**
   * Create a new instance.
   */
  constructor() {
    //
  }

  /**
   * Show the popover.
   *
   * @return void
   */
  show(): void {
    this.state = 'enter';
  }

  /**
   * Hide the popover.
   *
   * @return void
   */
  hide(): void {
    this.state = 'void';
  }

  /**
   * Destroy the popover.
   *
   * @return void
   */
  destroy(): void {
    this.afterDestroy.next();
  }

  /**
   * Gets an observable that is notified when the popove is destroyed.
   *
   * @return Observable
   */
  afterDestroyed() {
    return this.afterDestroy.asObservable();
  }
}
