export class Token {
  /**
   * @var {any}
   */
  get decoded(): any {
    try {
      return JSON.parse(atob(this.value.split('.')[1]));
    } catch (e) {
      return {};
    }
  }

  /**
   * @var {string}
   */
  private value: string;

  /**
   * Create a new instance.
   *
   * @param {string} value
   */
  constructor(value: string) {
    this.value = value;
  }

  /**
   * Get the token's value.
   *
   * @return string
   */
  get(): string {
    return this.value;
  }

  /**
   * Returns whether the token exists.
   *
   * @return boolean
   */
  exists(): boolean {
    return !!this.value;
  }

  /**
   * Determine if the token has given claim.
   *
   * @param {string} key
   * @return boolean
   */
  hasClaim(key: string): boolean {
    return !!this.decoded[key];
  }

  /**
   * Get the claim for a given key.
   *
   * @param {string} key
   * @param {any} defaultValue
   * @return any
   */
  getClaim(key: string, defaultValue: any = null): any {
    if (!this.hasClaim(key)) return defaultValue;

    return this.decoded[key];
  }
}
