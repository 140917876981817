import { Directive, EventEmitter, Input, Output, ElementRef, OnInit, OnDestroy } from '@angular/core';
import { Subscription, fromEvent } from 'rxjs';
import { distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { Dispatcher } from '@a3l/core';
import { ClearSearchEvent } from '@rex/core/ClearSearch.event';

@Directive({ selector: '[a3l-querier]' })
export class Querier implements OnInit, OnDestroy {
  /**
   * @var {string}
   */
  @Input()
  get value(): string {
    return this._value;
  }
  set value(value: string) {
    this.valueChange.emit((this._value = value));
  }

  /**
   * @var {EventEmitter<string>}
   */
  @Output()
  valueChange: EventEmitter<string> = new EventEmitter();

  /**
   * @var {string}
   */
  protected _value: string;

  /**
   * @var {Subscription}
   */
  protected subscription: Subscription = Subscription.EMPTY;

  constructor(private elementRef: ElementRef, private dispatcher: Dispatcher) {
    //
  }

  /**
   * Initialization.
   */
  ngOnInit() {
    this.subscription = fromEvent(this.elementRef.nativeElement, 'input')
      .pipe(debounceTime(400), distinctUntilChanged())
      .subscribe((event: any) => {
        this.value = event.target.value;
      });

    this.dispatcher.listen(ClearSearchEvent).subscribe(() => {
      this._value = '';
      this.elementRef.nativeElement.querySelector('input').value = ''
    });
  }

  /**
   * Cleanup.
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
