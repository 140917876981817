import {Component, Input, ViewEncapsulation} from "@angular/core";

@Component({
  selector: 'a3l-ui-token',
  template: '<ng-content></ng-content>',
  styleUrls: ['./token.component.scss'],
  host: {
    class: 'a3l-ui-token',
    '[class.a3l-ui-token--white]': "color == 'white'",
    '[class.a3l-ui-token--1]': "size == '1'",
    '[class.a3l-ui-token--2]': "size == '2'",
    '[class.a3l-ui-token--3]': "size == '3'",
    '[class.a3l-ui-token--4]': "size == '4'",
    '[class.a3l-ui-token--5]': "size == '5'",
    '[class.a3l-ui-token--6]': "size == '6'",
    '[class.a3l-ui-token--7]': "size == '7'",
  },
  encapsulation: ViewEncapsulation.None
})
export class TokenComponent {
  /**
   * @var {string}
   */
  @Input()
  color: string = null;

  /**
   * @var {string}
   */
  @Input()
  size: string|number = null;

}
