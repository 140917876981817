import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {Component, ElementRef, forwardRef, Input, OnChanges, OnInit, ViewChild, ViewEncapsulation} from "@angular/core";
import {Control} from '../control';
import {nextTick} from "@a3l/utilities";

@Component({
  selector: 'a3l-ui-range-input',
  templateUrl: './range-input.component.html',
  styleUrls: ['./range-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RangeInputComponent),
      multi: true,
    },
    {
      provide: Control,
      useExisting: RangeInputComponent
    },
  ],
  host: {
    class: 'a3l-ui-range-component',
    '(focus)': 'focused = true',
    '(blur)': 'focused = false',
  }
})
export class RangeInputComponent extends Control implements ControlValueAccessor, OnInit {
  /**
   * @var {number}
   */
  min: number = 0;

  /**
   * @var {number}
   */
  max: number = 20;

  /**
   * @var {any}
   */
  options: any = { floor: 0, ceil: 0 };

  /**
   * @var {any}
   */
  @Input()
  value: any;

  ngOnInit() {
    const step = 1;

    this.options = { floor: (this.min), ceil: (this.max), step };
    this.propagateChange((this.value = { min: this.min, max: this.max }));
  }

  /**
   * @var {any}
   */
  protected propagateChange: any = () => {
  };

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  writeValue(value: any): void {

    this.value = value;

    if (value && value.min) {
      this.min = value.min;
    }

    if (value && value.max) {
      this.max = value.max;
    }

    nextTick(() => (this.value = value));
  }

  /**
   * Check if number is in range.
   *
   * @return void
   */
  clampMin(): void {
    if (this.min <= this.max) return;

    nextTick(() => (this.min = this.max));
  }

  /**
   * Check if number is in range.
   *
   * @return void
   */
  clampMax(): void {
    if (this.max >= this.min) return;

    nextTick(() => (this.max = this.min));
  }

  updateMax(max) {
    this.max = max;

    this.propagateChange((this.value = { min: this.min, max: this.max }));
  }

  updateMin(min) {
    this.min = min;

    this.propagateChange((this.value = { min: this.min, max: this.max }));
  }
}
