import { Component, ViewEncapsulation, Input } from '@angular/core';

@Component({
  selector: 'a[a3l-ui-button], button[a3l-ui-button]',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'a3l-ui-button',
    '[class]': "'a3l-ui-button--' + color",
    '[class.a3l-ui-button--lg]': 'large',
    '[class.a3l-ui-button--md]': 'medium',
    '[class.a3l-ui-button--sm]': 'small',
    '[class.a3l-ui-button--icon]': 'icon',
    '[class.a3l-ui-button--plain]': 'plain',
    '[class.a3l-ui-button--processing]': 'processing',
    '[class.a3l-ui-button--selected]': 'selected',
    '[class.a3l-ui-button--disabled]': 'disabled',
  },
})
export class Button {
  /**
   * @var {'default' | 'primary' | 'secondary' | 'accent' | 'gray'}
   */
  @Input()
  color: 'default' | 'primary' | 'secondary' | 'white' | 'accent' | 'gray' | 'gray-revert' | 'red' = 'default';

  @Input()
  selected: boolean = false;

  @Input()
  disabled: boolean = false;

  /**
   * @var {boolean}
   */
  @Input()
  processing: boolean = false;

  /**
   * @var {boolean}
   */
  @Input()
  get large(): boolean {
    return this._large;
  }
  set large(value: boolean) {
    this._large = value !== false;
  }

  /**
   * @var {boolean}
   */
  @Input()
  get medium(): boolean {
    return this._medium;
  }
  set medium(value: boolean) {
    this._medium = value !== false;
  }

  /**
   * @var {boolean}
   */
  @Input()
  get small(): boolean {
    return this._small;
  }
  set small(value: boolean) {
    this._small = value !== false;
  }

  /**
   * @var {boolean}
   */
  @Input()
  get icon(): boolean {
    return this._icon;
  }
  set icon(value: boolean) {
    this._icon = value !== false;
  }

  /**
   * @var {boolean}
   */
  @Input()
  get plain(): boolean {
    return this._plain;
  }
  set plain(value: boolean) {
    this._plain = value !== false;
  }

  /**
   * @var {boolean}
   */
  protected _large: boolean = false;

  /**
   * @var {boolean}
   */
  protected _medium: boolean = false;

  /**
   * @var {boolean}
   */
  protected _small: boolean = false;

  /**
   * @var {boolean}
   */
  protected _icon: boolean = false;

  /**
   * @var {boolean}
   */
  protected _plain: boolean = false;

  /**
   * Create a new instance.
   */
  constructor() {
    //
  }
}
