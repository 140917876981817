<div class="a3l-ui-select-input">
  <div class="a3l-ui-select-input__value" *ngIf="!empty">
    <div class="flexic justify-between">
      {{ viewValue }}
      <a3l-ui-badge color="gray" class="text-xs font-semibold" *ngIf="badgeInfo"><a3l-ui-icon icon="star-fill" class="mr-1"></a3l-ui-icon> {{ badgeInfo }}</a3l-ui-badge>
    </div>
  </div>
  <div class="a3l-ui-select-input__placeholder" *ngIf="empty">{{ placeholder || '\u00A0' }}</div>
  <div class="a3l-ui-select-input__icon flex items-center" *ngIf="!locked">
    <div *ngIf="empty; else elseBlock">
      <a3l-ui-icon icon="chevron-down" size="8"></a3l-ui-icon>
    </div>
    <ng-template #elseBlock>
      <a3l-ui-icon icon="cross" size="8"></a3l-ui-icon>
    </ng-template>
  </div>
</div>

<ng-template #pane>
  <ul class="a3l-ui-select-panel" [@panel]="state" #panel>
    <li
      *ngIf="searcher"
    >
      <a3l-ui-select-search-input (searchChanged)="inputChange($event)"></a3l-ui-select-search-input>
    </li>
    <li
      class="a3l-ui-select-panel__option"
      [class]="{ 'a3l-ui-select-panel__option--active': option.active }"
      (mousedown)="select(option); $event.preventDefault(); $event.stopPropagation()"
      *ngFor="let option of options"
    >
      {{ option.label }}
    </li>
  </ul>
</ng-template>

<ng-template>
  <ng-content></ng-content>
</ng-template>
