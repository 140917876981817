import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[a3l-ui-menu-item]',
  host: {
    class: 'a3l-ui-menu-item',
  },
})
export class MenuItemDirective {
  /**
   * @var {EventEmitter<void>}
   */
  @Output()
  clicked: EventEmitter<void> = new EventEmitter();

  /**
   * Create a new instance.
   */
  constructor() {
    //
  }

  /**
   * Handle click on the element.
   *
   * @return void
   */
  @HostListener('click')
  onDOMClick(): void {
    this.clicked.emit();
  }
}
