import { Directive, ElementRef, OnInit, OnChanges, SimpleChanges, AfterContentChecked } from '@angular/core';

import { Title } from './title';

@Directive({ selector: '[a3l-layout-as-title]' })
export class LayoutAsTitleDirective implements AfterContentChecked {
  /**
   * @var {string}
   */
  protected subtitle: string = null;

  /**
   * Create a new instance.
   *
   * @param {Title} title
   * @param {ElementRef} elementRef
   */
  constructor(private title: Title, private elementRef: ElementRef) {
    //
  }

  /**
   * Initialization.
   */
  ngAfterContentChecked() {
    const subtitle = (this.elementRef.nativeElement as HTMLElement).innerHTML.replace(/<[^>]*>/g, '');

    if (subtitle == null || subtitle == '' || this.subtitle == subtitle) {
      return;
    }

    this.title.set((this.subtitle = subtitle));
  }
}
