import {
  Component,
  Input,
  QueryList,
  forwardRef,
  ContentChildren,
  ViewEncapsulation,
  ElementRef,
  ViewChild,
  ViewContainerRef,
  TemplateRef,
  OnDestroy,
  HostListener,
  Directive,
  Injector,
  OnInit,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Overlay, OverlayRef, OverlayConfig, VerticalConnectionPos, HorizontalConnectionPos } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { ActiveDescendantKeyManager, Highlightable } from '@angular/cdk/a11y';
import { Subscription } from 'rxjs';
import * as cloneDeep from 'lodash/cloneDeep';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import {debounceTime, distinctUntilChanged, pairwise} from "rxjs/operators";

import { nextTick } from '../utils';

import { Control } from './control';

@Component({
  selector: 'a3l-ui-select-option',
  template: '<ng-content></ng-content>',
})
export class SelectOptionComponent implements Highlightable {
  /**
   * @var {any}
   */
  @Input()
  value: any;

  /**
   * @var {string}
   */
  get label(): string {
    return (this.elementRef.nativeElement.textContent || '').trim();
  }

  /**
   * @var {boolean}
   */
  active: boolean = false;

  /**
   * Create a new instance.
   *
   * @param {ElementRef<HTMLElement>} elementRef
   */
  constructor(protected elementRef: ElementRef<HTMLElement>) {
    //
  }

  /**
   * Applies the styles for an active item to this item.
   *
   * @return void
   */
  setActiveStyles(): void {
    this.active = true;
  }

  /**
   * Applies the styles for an inactive item to this item.
   *
   * @return void
   */
  setInactiveStyles(): void {
    this.active = false;
  }
}

@Component({
  selector: 'a3l-ui-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
    { provide: Control, useExisting: SelectComponent },
  ],
  host: {
    class: 'a3l-ui-select',
    '[class.a3l-ui-select--valid]': 'valid',
    '[class.a3l-ui-select--invalid]': '!valid',
    '[class.a3l-ui-select--locked]': 'locked',
    '[attr.tabindex]': '0',
    '(focus)': 'open()',
    '(mouseover)': '_canShowValidationInTooltip = true',
    '(mouseleave)': '_canShowValidationInTooltip = false',
  },
  encapsulation: ViewEncapsulation.None,
  animations: [trigger('panel', [state('void', style({ opacity: 0 })), state('enter', style({ opacity: 1 })), transition('* => void', animate('100ms 25ms linear', style({ opacity: 0 })))])],
})
export class SelectComponent extends Control implements OnInit, OnDestroy, ControlValueAccessor {
  /**
   * @var {any}
   */
  @Input()
  value: any;

  /**
   * @var {string}
   */
  @Input()
  placeholder: string;

  @Input()
  public searcher: boolean = false;

  @Input()
  badgeInfo: string = null;

  /**
   * @var {TemplateRef<any>}
   */
  @ViewChild('pane', { static: false })
  pane: TemplateRef<any>;

  /**
   * @var {ElementRef}
   */
  @ViewChild('panel', { static: false })
  panel: ElementRef;

  /**
   * @var {QueryList<SelectOptionComponent>}
   */
  @ContentChildren(SelectOptionComponent, { descendants: true })
  options!: QueryList<SelectOptionComponent>;

  protected initialOptions: any;

  /**
   * @var {'void' | 'enter'}
   */
  state: 'void' | 'enter' = 'void';

  /**
   * @var {boolean}
   */
  opened: boolean;

  /**
   * @var {string}
   */
  get viewValue(): string {
    if (!this.options) return null;

    const option = this.options.find((option) => option.value == this.value);

    if (option) return option.label;

    return null;
  }

  /**
   * @var {boolean}
   */
  get empty(): boolean {
    return this.value === undefined || this.value === null;
  }

  /**
   * @var {boolean}
   */
  get locked(): boolean {
    if (this.ngControl === undefined) return false;

    return this.ngControl && this.ngControl.disabled;
  }

  /**
   * @var {boolean}
   */
  get canShowValidationInTooltip(): boolean {
    return this._canShowValidationInTooltip;
  }

  /**
   * @var {NgControl}
   */
  protected ngControl: NgControl;

  /**
   * @var {OverlayRef}
   */
  protected overlayRef: OverlayRef | null;

  /**
   * @var {ActiveDescendantKeyManager<SelectOptionComponent>}
   */
  protected keyManager: ActiveDescendantKeyManager<SelectOptionComponent>;

  /**
   * @var {any}
   */
  protected propagateChange: any = () => {};

  /**
   * @var {Subscription}
   */
  protected closeSubscription: Subscription = Subscription.EMPTY;

  /**
   * @var {Subscription}
   */
  protected keyManagerSubscription: Subscription = Subscription.EMPTY;

  /**
   * @var {boolean}
   */
  protected _canShowValidationInTooltip: boolean = false;

  /**
   * Create a new instance.
   *
   * @param {Overlay} overlay
   * @param {Injector} injector
   * @param {ElementRef} elementRef
   * @param {ViewContainerRef} viewContainerRef
   */
  constructor(private overlay: Overlay, private injector: Injector, private elementRef: ElementRef, private viewContainerRef: ViewContainerRef) {
    super();
  }

  /**
   * Initialize.
   */
  ngOnInit() {
    this.ngControl = this.injector.get(NgControl);
  }

  /**
   * Open the overlay panel.
   *
   * @return void
   */
  open(): void {
    if (this.opened || this.locked) return;

    if(!this.initialOptions && this.searcher) {
      this.initialOptions = cloneDeep(this.options);
    }

    this.opened = true;
    this.focused = true;

    this.elementRef.nativeElement.focus();

    let [originX, originFallbackX]: HorizontalConnectionPos[] = ['start', 'end'];

    let [overlayY, overlayFallbackY]: VerticalConnectionPos[] = ['top', 'bottom'];

    let [originY, originFallbackY] = [overlayY, overlayFallbackY];
    let [overlayX, overlayFallbackX] = [originX, originFallbackX];
    let offsetY = 0;

    const strategy = this.overlay
      .position()
      .flexibleConnectedTo(this.elementRef)
      .withLockedPosition()
      .withPositions([
        { originX, originY, overlayX, overlayY, offsetY },
        { originX: originFallbackX, originY, overlayX: overlayFallbackX, overlayY, offsetY },
        {
          originX,
          originY: originFallbackY,
          overlayX,
          overlayY: overlayFallbackY,
          offsetY: -offsetY,
        },
        {
          originX: originFallbackX,
          originY: originFallbackY,
          overlayX: overlayFallbackX,
          overlayY: overlayFallbackY,
          offsetY: -offsetY,
        },
      ]);

    const overlayConfig: OverlayConfig = {
      positionStrategy: strategy,
      width: this.elementRef.nativeElement.offsetWidth,
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
      disposeOnNavigation: true,
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
    };

    this.overlayRef = this.overlay.create(overlayConfig);

    this.overlayRef.attach(new TemplatePortal(this.pane, this.viewContainerRef));

    this.closeSubscription = this.overlayRef.backdropClick().subscribe(this.close.bind(this));

    this.state = 'enter';

    this.keyManager = new ActiveDescendantKeyManager(this.options).withWrap();
    this.keyManagerSubscription = this.keyManager.change.subscribe(() => {
      const index = this.keyManager.activeItemIndex || 0;

      this.panel.nativeElement.scrollTop = ((index, height, current, maxHeight) => {
        const optionOffset = index * height;

        if (optionOffset < current) {
          return optionOffset;
        }

        if (optionOffset + height > current + maxHeight) {
          return Math.max(0, optionOffset - maxHeight + height);
        }

        return current;
      })(index, 35, this.panel.nativeElement.scrollTop, this.panel.nativeElement.offsetHeight);
    });
  }

  /**
   * Close the overlay panel.
   *
   * @return void
   */
  close(): void {
    if (!this.opened) return;

    this.elementRef.nativeElement.blur();

    if(this.searcher) {
      this.options = this.initialOptions;
    }

    this.opened = false;
    this.focused = false;

    this.state = 'void';

    this.overlayRef && this.overlayRef.dispose();

    this.closeSubscription.unsubscribe();
    this.keyManagerSubscription.unsubscribe();
  }

  /**
   * Toggle the overlay panel open or closed.
   *
   * @return void
   */
  toggle(): void {
    this.opened ? this.close() : this.open();
  }

  /**
   * Handle the item selection.
   *
   * @param {SelectOptionComponent} option
   * @return void
   */
  select(option: SelectOptionComponent): void {
    this.propagateChange((this.value = option.value));

    this.close();
  }

  /**
   * Handle the 'keydown' event.
   *
   * @param {any} event
   * @return void
   */
  @HostListener('keydown', ['$event'])
  onDOMKeydown(event: KeyboardEvent): void {
    if (event.code == 'Enter' && this.keyManager.activeItem) {
      this.select(this.keyManager.activeItem);

      event.preventDefault();

      return;
    }

    this.keyManager.onKeydown(event);
  }

  /**
   * Handle the 'mousedown' event.
   *
   * @param {MouseEvent} event
   * @return void
   */
  @HostListener('mousedown', ['$event'])
  protected onDOMMousedown(event: MouseEvent): void {
    this.toggle();

    event.preventDefault();
    event.stopPropagation();
  }

  protected inputChange(value) {
    if(value.length > 0) {
      let newOptions = cloneDeep(this.initialOptions)
      newOptions = newOptions.filter((item, index, array) => {
        return item.label.toLowerCase().includes(value.toLowerCase())
      });
      this.options = newOptions;
    } else {
      this.options = cloneDeep(this.initialOptions);
    }
  }

  /**
   * Write a new value from the form model.
   *
   * @param {any} value
   * @return void
   */
  writeValue(value: any): void {
    this.value = value;

    nextTick(() => (this.value = value));
  }

  /**
   * Register handler.
   *
   * @param {any} fn
   * @return void
   */
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  /**
   * Register handler.
   *
   * @param {any} fn
   * @return void
   */
  registerOnTouched(fn: any): void {}

  /**
   * Cleanup
   */
  ngOnDestroy() {
    this.close();
  }
}

@Directive({
  selector: 'a3l-ui-select[small]',
  host: {
    class: 'a3l-ui-select--small',
  },
})
export class SelectAsSmallDirective {
  //
}
