import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

import { SnackBarRef } from './ref';
import { SnackBarItem, SnackBarType } from './config';

const SPEED = 15.5;

@Component({
  selector: 'a3l-ui-snack-bar',
  templateUrl: './snack-bar.component.html',
  styleUrls: ['./snack-bar.component.scss'],
  host: {
    class: 'a3l-ui-snack-bar',
    '[class]': "'a3l-ui-snack-bar--' + type",
    '[@state]': '',
  },
  animations: [
    trigger('state', [
      state('void', style({ opacity: 0.2, transform: 'translateX(50%)' })),
      state('*', style({ opacity: 1, transform: 'translateX(0)' })),
      transition('void => *', animate(200)),
      transition('* => void', animate(200)),
    ]),
  ],
  encapsulation: ViewEncapsulation.None,
})
export class SnackBarComponent implements OnInit {
  /**
   * @var {SnackBarRef}
   */
  get ref(): SnackBarRef {
    return this.item.ref;
  }

  /**
   * @var {SnackBarType}
   */
  get type(): SnackBarType {
    return this.item.type;
  }

  /**
   * @var {string}
   */
  get message(): string {
    return this.item.message;
  }

  /**
   * @var {number}
   */
  get duration(): number {
    const duration = this.item.duration;

    if (duration) return duration;

    return Math.max(this.message.length / SPEED, 2.5) * 1000;
  }

  /**
   * @var {SnackBarItem}
   */
  @Input()
  item: SnackBarItem;

  /**
   * @var {EventEmitter<void>}
   */
  @Output()
  close: EventEmitter<void> = new EventEmitter();

  /**
   * @var {any}
   */
  protected closeTimeoutId: any = null;

  /**
   * Create a new instance.
   */
  constructor() {
    //
  }

  /**
   * Initialization.
   */
  ngOnInit() {
    this.closeTimeoutId = setTimeout(() => this.dismiss(), this.duration);
  }

  /**
   * Close the snack bar.
   *
   * @return void
   */
  dismiss(): void {
    clearTimeout(this.closeTimeoutId);

    this.ref.onClose.next();
    this.ref.onClose.complete();

    this.close.emit();
  }
}
