import { Directive } from '@angular/core';

@Directive({
  selector: '[a3l-ui-button-spinner]',
  host: {
    class: 'a3l-ui-button-spinner',
  },
})
export class ButtonSpinner {
  /**
   * Create a new instance.
   */
  constructor() {
    //
  }
}
