import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

import { BreadcrumbItem } from './breadcrumb-item';
import { InteractsWithDataset } from '@rex/datasets';

let uniqueId: number = 1;

@Injectable()
export class BreadcrumbCollection {
  /**
   * @var {Subject}
   */
  readonly items: BehaviorSubject<BreadcrumbItem[]> = new BehaviorSubject([]);

  /**
   * @var {BreadcrumbItem[]}
   */
  private stack: BreadcrumbItem[] = [];

  /**
   * Create a new instance.
   */
  constructor() {
    //
  }


  add(title: string, link: any, persistable: boolean, omittable: boolean = false, dataset: InteractsWithDataset = null): number {
    const id = uniqueId++;

    this.stack.push({ id, link, title, persistable, omittable, dataset });

    this.items.next(this.stack);

    return id;
  }

  /**
   * Update the item's attributes.
   *
   * @param {number} id
   * @param {any} changes
   * @return number
   */
  update(id: number, changes: any): void {
    const index = this.stack.findIndex((item) => item.id === id);

    if (index === -1) return;

    this.stack[index] = { ...this.stack[index], ...changes };

    this.items.next(this.stack);
  }

  /**
   * Remove the item.
   *
   * @param {number} id
   * @return void
   */
  remove(id: number): void {
    const index = this.stack.findIndex((item) => item.id === id);

    index != -1 && this.stack.splice(index, 1);

    this.items.next(this.stack);
  }

  /**
   * Get the item at the given index.
   *
   * @param {number} index
   * @return BreadcrumbItem
   */
  getAt(index: number): BreadcrumbItem {
    return this.stack[index];
  }

  /**
   * Get first item from the stack.
   *
   * @return BreadcrumbItem
   */
  first(): BreadcrumbItem {
    return this.stack[this.stack.length - 1];
  }

  /**
   * Find item by property and value.
   *
   * @param {string} property
   * @param {any} value
   * @return BreadcrumbItem
   */
  findBy(property: string, value: any): BreadcrumbItem {
    return this.stack.find((item) => item[property] == value);
  }

  /**
   * Remove the first item of the stack.
   *
   * @return void
   */
  pop(): void {
    this.stack.splice(-1, 1);

    this.items.next(this.stack);
  }

  /**
   * Get the number of items.
   *
   * @return number
   */
  count(): number {
    return this.stack.length;
  }

  /**
   * Get the all items list.
   *
   * @return Observable<BreadcrumbItem[]>
   */
  all(): Observable<BreadcrumbItem[]> {
    return this.items.asObservable();
  }
}
