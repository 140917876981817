export * from './config';
export * from './token';
export * from './dialog';
export * from './dialog-ref';
export * from './dialog-container.component';
export * from './dialog-footer.component';
export * from './dialog-content.component';
export * from './dialog-header.component';

export * from './dialog.module';
