<ng-container *ngIf="languages$ | async as languages">
  <div class="rex-language-switcher-toggler" [a3l-ui-dropdown]="dropdown">
    <a3l-ui-flag [code]="code" class="rex-language-switcher-toggler__flag"></a3l-ui-flag>
    <div class="rex-language-switcher-toggler__code">{{ code }}</div>
    <a3l-ui-icon icon="chevron-down" size="5"></a3l-ui-icon>
  </div>
  <a3l-ui-dropdown #dropdown class="rex-language-switcher-dropdown">
    <button class="rex-language-switcher-dropdown-item" (click)="dropdown.destroy()">
      <a3l-ui-flag class="rex-language-switcher-dropdown-item__flag" [code]="code"></a3l-ui-flag>
      <strong class="rex-language-switcher-dropdown-item__code">{{ code }}</strong>
      <span class="rex-language-switcher-dropdown-item__name">({{ 'languages.' + code | translate }})</span>
    </button>
    <ng-container *ngFor="let language of languages">
      <button class="rex-language-switcher-dropdown-item" (click)="dropdown.destroy(); process(language)" *ngIf="language != code">
        <a3l-ui-flag class="rex-language-switcher-dropdown-item__flag" [code]="language"></a3l-ui-flag>
        <strong class="rex-language-switcher-dropdown-item__code">{{ language }}</strong>
        <span class="rex-language-switcher-dropdown-item__name">({{'languages.' + language | translate }})</span>
      </button>
    </ng-container>
  </a3l-ui-dropdown>
</ng-container>
