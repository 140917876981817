import { Component, forwardRef, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable } from 'rxjs';
import { SelectionModel } from '@angular/cdk/collections';

import { Control } from '@a3l/utilities';

import { DictionaryOfAgreementsQuery } from './dictionary-of-agreements.query';

@Component({
  selector: 'rex-agreements-field',
  templateUrl: './agreements.field.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AgreementsField),
      multi: true,
    },
    { provide: Control, useExisting: AgreementsField },
    DictionaryOfAgreementsQuery,
  ],
})
export class AgreementsField extends Control implements OnInit, ControlValueAccessor {
  /**
   * @var {any}
   */
  value: any;

  /**
   * @var {Observable<any[]>}
   */
  items$: Observable<any[]> = this.query.value$;

  /**
   * @var {Observable<boolean>}
   */
  loading$: Observable<boolean> = this.query.pending$;

  /**
   * @var {SelectionModel<any>}
   */
  selection: SelectionModel<any> = new SelectionModel<any>(true, []);

  /**
   * @var {any}
   */
  protected propagateChange: any = () => {};

  /**
   * Create a new instance.
   *
   * @param {DictionaryOfAgreementsQuery} query
   */
  constructor(private query: DictionaryOfAgreementsQuery) {
    super();
  }

  /**
   * Initialization.
   */
  ngOnInit() {
    this.query.execute();
  }

  /**
   * Toggle the selection.
   *
   * @param {any} item
   * @return void
   */
  toggle(item: any): void {
    if (this.selection.isSelected(item)) {
      this.selection.toggle(item);

      this.propagateChange((this.value = this.selection.selected));

      return;
    }

    this.selection.toggle(item);

    this.propagateChange((this.value = this.selection.selected));
  }

  /**
   * Write a new value from the form model.
   *
   * @param {any} value
   * @return void
   */
  writeValue(value: any): void {
    this.value = value;

    if (!this.value || this.value.length == 0) this.selection.clear();
    if(this.value) this.selection.select(...this.value);
  }

  /**
   * Register handler.
   *
   * @param {any} fn
   * @return void
   */
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  /**
   * Register handler.
   *
   * @param {any} fn
   * @return void
   */
  registerOnTouched(fn: any): void {}
}
