import { Directive } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Directive({ selector: '[a3l-dataset-filterer]' })
export class Filterer {
  /**
   * @var {any}
   */
  get value(): any {
    return this._value;
  }

  /**
   * @var {BehaviorSubject<any>}
   */
  _valueChange: BehaviorSubject<any> = new BehaviorSubject(null);

  /**
   * @var {Observable<boolean>}
   */
  readonly valueChange: Observable<boolean> = this._valueChange.asObservable();

  /**
   * @var {any}
   */
  protected _value: any = {};

  /**
   * @var {any}
   */
  protected timeout: any = null;

  /**
   * Create a new instance.
   */
  constructor() {
    //
  }

  /**
   * Get the value by the given key.
   *
   * @param {string} key
   * @param {any} fallback
   * @return any
   */
  get(key: string, fallback: any = null): any {
    return this._value[key] || fallback;
  }

  /**
   * Count the number of filters.
   *
   * @return number
   */
  count(): number {
    return Object.keys(this._value).length;
  }

  /**
   * Add new item to the value.
   *
   * @param {string} key
   * @param {any} value
   * @return this
   */
  add(key: string, value: any): Filterer {
    this._value[key] = value;

    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => this._valueChange.next(this.value), 200);

    return this;
  }

  /**
   * Remove the values at the given keys.
   *
   * @param {string[]} keys
   * @return this
   */
  forget(...keys: string[]): Filterer {
    for (let i = 0, x = keys.length; i < x; i++) {
      delete this._value[keys[i]];
    }

    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => this._valueChange.next(this.value), 200);

    return this;
  }

  /**
   * Clear the value.
   *
   * @return this
   */
  clear(): Filterer {
    this._value = {};

    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => this._valueChange.next(this.value), 200);

    return this;
  }
}
