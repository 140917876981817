import {PrimeNGConfig} from "primeng/api";
import {TranslateService} from "@ngx-translate/core";
import {Injectable} from "@angular/core";

@Injectable({ providedIn: 'root' })
export class CalendarService
{
  /**
   * Create a new instance.
   *
   * @param {PrimeNGConfig} config
   * @param {TranslateService} translateService
   */
  constructor(private config: PrimeNGConfig, private translateService: TranslateService) {
  }

  setTranslation()
  {
    this.config.setTranslation(this.getTranslation());
  }

  getTranslation() : any
  {
    const langCode = this.translateService.currentLang;

    switch (langCode) {
      case 'pl': {
        return {
          monthNames: ["Styczeń","Luty","Marzec","Kwiecień","Maj","Czerwiec","Lipiec","Sierpień","Wrzesień","Październik","Listopad","Grudzień"],
          monthNamesShort: ["Sty", "Lut", "Mar", "Kwi", "Maj", "Cze","Lip", "Sie", "Wrz", "Paź", "Lis", "Gru"],
          dayNames: ["Niedziela", "Poniedziałek", "Wtorek", "Środa", "Czwartek", "Piątek", "Sobota"],
          dayNamesShort: ["Niedz","Pon","Wt","Śr","Czw","Pt","Sob"],
          dayNamesMin: ["Niedz","Pon","Wt","Śr","Czw","Pt","Sob"],
        };
      }
      case 'cs': {
        return {
          monthNames: ["Leden","Únor","Březen","Duben","Květen","Červen","Červenec","Srpen","Září","Říjen","Listopad","Prosinec"],
          monthNamesShort: ["Led", "Ún", "Brez", "Dub", "Kvet", "Cerv", "Cerven", "Srp", "Zár", "Ríj", "List", "Pros"],
          dayNames: ["Neděle", "Pondělí", "Úterý", "Středa", "Čtvrtek", "Pátek", "Sobota"],
          dayNamesShort: ["Ne","Po","Út","St","Čt","Pá","So"],
          dayNamesMin:  ["Ne","Po","Út","St","Čt","Pá","So"],
        };
      }
      case 'sk': {
        return {
          monthNames: ["Január","Február","Marec","April","Máj","Jún","Júl","August","September","Október","November","December"],
          monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "Máj", "Jún", "Júl", "Aug", "Sept", "Okt", "Nov", "Dec"],
          dayNames: ["Nedeľa", "Pondelok", "Utorok", "Streda", "Štvrtok", "Piatok", "Sobota"],
          dayNamesShort: ["Ne","Po","Ut","St","Št","Pi","So"],
          dayNamesMin:  ["Ne","Po","Ut","St","Št","Pi","So"],
        };
      }
      case 'hu': {
        return {
          monthNames: ["Január","Február","Március","Április","Május","Június","Július","Augusztus","Szeptember","Október","November","December"],
          monthNamesShort: ["Jan", "Feb", "Márc", "Ápr", "Máj", "Jún", "Júl", "Aug", "Zzept", "Okt", "Nov", "Dec"],
          dayNames: ["Vasárnap", "Hétfő", "Kedden", "Szerda", "Csütörtök", "Péntek", "Szombat"],
          dayNamesShort: ["Vas","H","K","Sze","Csüt","P","Szo"],
          dayNamesMin: ["Vas","H","K","Sze","Csüt","P","Szo"],
        };
      }
      default: {
        return {};
      }
    }
  }
}
