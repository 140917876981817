import { Component, Inject } from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {validateChoices} from "@rex/validators";
import {DialogRef, SnackBar, SnackBarType, UI_DIALOG_DATA} from "@a3l/utilities";
import {Dispatcher} from "@a3l/core";
import {TranslateService} from "@ngx-translate/core";
import {EvaluationCommand} from "@rex/evaluations/commands/evaluation.command";
import {Observable} from "rxjs";

@Component({
  templateUrl: './create-evaluation.dialog.html',
  providers: [
    EvaluationCommand
  ]
})
export class CreateEvaluationDialog {
  /**
   * @var {Observable<boolean>}
   */
  processing$: Observable<boolean> = this.command.pending$;

  /**
   * @var {FormGroup}
   */
  form: FormGroup;

  constructor(
    @Inject(UI_DIALOG_DATA) protected data: any,
    private snackbar: SnackBar,
    private dispatcher: Dispatcher,
    private translator: TranslateService,
    private dialogRef: DialogRef<CreateEvaluationDialog>,
    protected command: EvaluationCommand,
  ) {
    this.form =  new FormGroup({
      choice_answers: new FormControl([], [validateChoices(this.data.choices_required ? this.data.choices.length : 0)]),
      note: new FormControl(null),
    });
  }

  getChoiceSuggestions()
  {
    return this.data.choices;
  }

  getIconClass()
  {
    if (this.data.type == 1) {
      return 'sad_face';
    }

    return  null;
  }

  /**
   * Handle the action.
   *
   * @return void
   */
  process(): void {
    if (!this.form.validate()) return;

    const parameters = {
      choices: this.form.controls['choice_answers'].value,
      note: this.form.controls['note'].value,
      evaluation_id: this.data.id
    }

    this.command.save(parameters).subscribe(async () => {
      const message = await this.translator.get('evaluation::panel.modal.success_message').toPromise();

      this.snackbar.open(message, SnackBarType.Success);

      this.dialogRef.close();

      window.location.reload();
    });
  }

  /**
   * Close the dialog.
   *
   * @return void
   */
  close(): void {
    this.dialogRef.close();
  }
}
