import { Pipe, PipeTransform } from '@angular/core';
import {NumberSeparatorService} from "@rex/shared/services/number-separator.service";
import {MaskApplierService} from "ngx-mask";

@Pipe({ name: 'a3lToFixed' })
export class ToFixedPipe implements PipeTransform {
  /**
   * Create a new instance.
   */
  constructor(
    protected mask: MaskApplierService
  ) {
    //
  }

  /**
   * Transform string to formatted representation.
   *
   * @param {any} value
   * @param {number} digits
   * @return string
   */
  transform(value: any, digits: number = 0) {
    let val = (Math.round(value * 100) / 100).toFixed(digits);

    return this.mask.applyMask(val, 'separator.0');
  }
}
