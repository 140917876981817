import { Directive, AfterViewInit, Input, ElementRef, HostListener } from '@angular/core';

import { nextTick } from '../utils';

@Directive({ selector: '[a3l-ui-affix]' })
export class AffixDirective implements AfterViewInit {
  /**
   * @var {boolean}
   */
  @Input('a3l-ui-affix')
  enabled: boolean = true;

  /**
   * @var {number}
   */
  @Input('a3l-ui-affix-offset-top')
  offsetTop: number = 0;

  /**
   * @var {number}
   */
  protected top: number;

  /**
   * @var {number}
   */
  protected left: number;

  /**
   * @var {number}
   */
  protected width: number;

  /**
   * @var {boolean}
   */
  protected pinned: boolean = false;

  /**
   * Create a new instance.
   *
   * @param {ElementRef} elementRef
   */
  constructor(private elementRef: ElementRef) {
    //
  }

  /**
   * Initialization.
   */
  ngAfterViewInit() {
    nextTick(this.render.bind(this));
  }

  /**
   * Initialize position of the element on the screen.
   *
   * @return void
   */
  @HostListener('window:resize')
  protected render(): void {
    const el = this.elementRef.nativeElement;

    el.removeAttribute('style');

    const { top, left } = el.getBoundingClientRect();

    this.top = top + this.getScrollTop();
    this.left = left;

    this.width = el.offsetWidth;
  }

  /**
   * Listen for a scroll event on the window.
   *
   * @return void
   */
  @HostListener('window:scroll')
  protected onDOMScroll(): void {
    if (!this.enabled) {
      this.unstuck();

      return;
    }

    if (this.getScrollTop() > this.top - this.offsetTop) {
      this.stuck();

      return;
    }

    this.unstuck();
  }

  /**
   * Stuck the element.
   *
   * @return void
   */
  protected stuck(): void {
    if (this.pinned) return;

    Object.assign(this.elementRef.nativeElement.style, {
      position: 'fixed',
      top: `${this.top}px`,
      left: `${this.left}px`,
      width: `${this.width}px`,
    });

    this.pinned = true;
  }

  /**
   * Unstuck the element.
   *
   * @return void
   */
  protected unstuck(): void {
    if (!this.pinned) return;

    this.elementRef.nativeElement.removeAttribute('style');

    this.pinned = false;
  }

  /**
   * Get the scrollbar position.
   *
   * @return number
   */
  protected getScrollTop(): number {
    return window.pageYOffset || document.documentElement.scrollTop;
  }
}
