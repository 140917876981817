import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { Sorter } from './sorter';
import { Querier } from './querier';
import { Filterer } from './filterer';
import { Paginator } from './paginator';

import { ZoomsPipe } from './zooms.pipe';
import { LensPresenter } from './lens.presenter';
import { LensIndicator } from './lens.indicator';
import { ZoomPresenter } from './zoom.presenter';
import { ZoomIndicator } from './zoom.indicator';

import { FilterDirective } from './filter.directive';
import { FilterIndicator } from './filter.indicator';
import { FilterPresenter } from './filter.presenter';

import { DatasetComponent } from './dataset.component';

import { DatasetGridElement } from './dataset-grid-element';
import { DatasetGridPresenter } from './dataset-grid.presenter';
import { DatasetGridItemPresenter } from './dataset-grid-item.presenter';

import { DatasetTableColumn } from './dataset-table-column';
import { DatasetTableDElement } from './dataset-table-delement';
import { DatasetTableHElement } from './dataset-table-helement';
import { DatasetTablePresenter } from './dataset-table.presenter';
import { DatasetTableHCellPresenter } from './dataset-table-hcell.presenter';
import { DatasetTableDCellPresenter } from './dataset-table-dcell.presenter';

import { DatasetFiltererTrigger } from './dataset-filterer.trigger';
import {BadgeModule} from "../../../utilities/src/lib/badge";
import {CoreModule, IconModule} from "@a3l/utilities";
import {GroupableDirective} from "./GroupableDirective";
import {GroupableFactory} from "./GroupableFactory";

const PUBLIC_API = [
  Sorter,
  Querier,
  Filterer,
  Paginator,
  ZoomsPipe,
  LensPresenter,
  LensIndicator,
  ZoomPresenter,
  ZoomIndicator,
  FilterDirective,
  FilterIndicator,
  FilterPresenter,
  DatasetComponent,
  DatasetGridElement,
  DatasetGridPresenter,
  DatasetGridItemPresenter,
  DatasetTableColumn,
  DatasetTableDElement,
  DatasetTableHElement,
  DatasetTablePresenter,
  DatasetTableHCellPresenter,
  DatasetTableDCellPresenter,
  DatasetFiltererTrigger,
  GroupableDirective,
  GroupableFactory
];

const PRIVATE_API = [];

@NgModule({
  declarations: [...PUBLIC_API, ...PRIVATE_API],
  imports: [CommonModule, BadgeModule, IconModule, CoreModule],
  exports: [...PUBLIC_API],
})
export class DatasetModule {}
