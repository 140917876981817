import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'a3lJoin' })
export class JoinPipe implements PipeTransform {
  /**
   * Transform the given array.
   *
   * @param {any[]} values
   * @param {string} glue
   * @return string
   */
  transform(values: any[], glue: string): string {
    return values.join(glue);
  }
}
