import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OrderPipe } from './order.pipe';
import { OrderByPipe } from './orderby.pipe';

import { JoinPipe } from './join.pipe';
import { FindPipe } from './find.pipe';
import { MapByPipe } from './mapby.pipe';
import { ExceptPipe } from './except.pipe';

import { TeltoPipe } from './telto.pipe';
import { MailtoPipe } from './mailto.pipe';
import { DatetimePipe } from './datetime.pipe';
import { CurrencyPipe } from './currency.pipe';
import { TruncatePipe } from './truncate.pipe';
import { ToFixedPipe } from './to-fixed.pipe';
import { PrettyBytesPipe } from './pretty-bytes.pipe';

import { IconModule } from './icon';
import { ButtonModule } from './button';
import {CurrencyValuePipe} from "./currency-value.pipe";

const PUBLIC_API = [OrderPipe, OrderByPipe, JoinPipe, FindPipe, MapByPipe, ExceptPipe, TeltoPipe, MailtoPipe, DatetimePipe, CurrencyValuePipe,  CurrencyPipe,  TruncatePipe, ToFixedPipe, PrettyBytesPipe];

@NgModule({
  declarations: [...PUBLIC_API],
  imports: [CommonModule],
  exports: [...PUBLIC_API, IconModule, ButtonModule],
})
export class CoreModule {}
