<div class="font-semibold text-sm" *ngIf="items.length == 0">
  <span translate>Brak lokalizacji do wyświetlenia.</span>
  <button type="button" [ngClass]="{'button-focused': focused}" class="ml-1 underline font-semibold text-gray-dark hover:text-black" (click)="add()"  translate>Dodaj pierwszą</button>
</div>
<ng-container *ngIf="items.length != 0">
  <ul class="m-0 p-0 list-none">
    <li class="mb-2 pb-2 flex items-center border-b-2 border-solid border-gray-light last:border-0" *ngFor="let item of items; let i = index">
      <span class="w-3 flex-shrink-0">{{ i + 1 }}.</span>
      <span class="px-2 flex-grow" *ngIf="item | a3lFind:'bounded':true as localization">
        <ng-container [ngSwitch]="localization.type">
          <span translate *ngSwitchCase="'city'">Miasto</span>
          <span translate *ngSwitchCase="'district'">Powiat</span>
          <span translate *ngSwitchCase="'voivodeship'">Województwo</span>
          <span translate *ngSwitchCase="'country'">Kraj</span>
        </ng-container>
        <span>:&nbsp;</span>
        <span>{{ localization.name }}</span>
      </span>
      <div class="-mx-1 flex items-center">
        <button class="mx-1" type="button" a3l-ui-button icon small (click)="editAt(i)">
          <a3l-ui-icon icon="edit" class="text-xxs"></a3l-ui-icon>
        </button>
        <button class="mx-1" type="button" a3l-ui-button icon small (click)="removeAt(i)">
          <a3l-ui-icon icon="trash" class="text-xxs"></a3l-ui-icon>
        </button>
      </div>
    </li>
  </ul>
  <div class="mt-4" *ngIf="items.length < limit">
    <button type="button" [ngClass]="{'button-focused': focused}" a3l-ui-button small translate class="text-sm" (click)="add()">Dodaj kolejną lokalizację</button>
  </div>
</ng-container>
