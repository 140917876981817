import { Directive } from '@angular/core';

@Directive({
  selector: '[a3l-ui-snack-bar-icon]',
  host: {
    class: 'a3l-ui-snack-bar-icon',
  },
})
export class SnackBarIconDirective {
  /**
   * Create a new instance.
   */
  constructor() {
    //
  }
}
