import {Event} from "@a3l/core";

export class CvParserCandidateSkillsEvent extends Event {
  /**
   * @var {string}
   */
  static readonly type = 'CvParserCandidateSkillsEvent';

  /**
   * @var {string}
   */
  public readonly type = CvParserCandidateSkillsEvent.type;


  constructor(public value: any, public disabled: boolean ) {
    super();
  }
}
