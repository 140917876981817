import {Command, Dispatcher} from "@a3l/core";
import {Injectable} from "@angular/core";
import {ApiService} from "@a3l/api";
import {catchError, finalize, map, tap} from "rxjs/operators";
import {Observable, of} from "rxjs";
import {
  SaveEvaluationAnswersEvent
} from "@rex/evaluations/events/save-evaluation-answers.event";

@Injectable()
export class EvaluationCommand extends Command<void> {
  /**
   * Create a new instance.
   *
   * @param {ApiService} api
   * @param {Dispatcher} dispatcher
   */
  constructor(
    private api: ApiService,
    private dispatcher: Dispatcher,
  ) {
    super();
  }

  /**
   * Run the edit command.
   *
   * @param {any} parameters
   * @return Observable<void>
   */
  save(parameters) {
    this._pending$.next(true);

    return this.api.post('/evaluation', parameters).pipe(
      catchError((exception) => {
        this._pending$.next(false);
        this._errors$.next(exception);

        throw exception;
      }),
      finalize(() => this._pending$.next(false)),
      tap(() => this._complete$.next())
    );
  }

  update(parameters) {
    this._pending$.next(true);

    return this.api.patch('/evaluation', parameters).pipe(
      catchError((exception) => {
        this._pending$.next(false);
        this._errors$.next(exception);

        throw exception;
      }),
      finalize(() => this._pending$.next(false)),
      tap(() => this._complete$.next())
    );
  }

  saveUnauthorized(parameters) {
    this._pending$.next(true);

    return this.api.post('/evaluation-unauthorized', parameters).pipe(
      catchError((exception) => {
        this._pending$.next(false);
        this._errors$.next(exception);

        throw exception;
      }),
      finalize(() => this._pending$.next(false)),
      tap(() => this._complete$.next())
    );
  }

  /**
   * Run the edit command.
   *
   * @param {any} evaluationId
   * @return Observable<void>
   */
  show(evaluationId) {
    this._pending$.next(true);

    return this.api.get('/evaluation' + `/${evaluationId}`).pipe(
      catchError(() => of(null)),
      finalize(() => this._pending$.next(false)),
      map((data) => {
        if (data.evaluation) {
          this.dispatcher.emit(new SaveEvaluationAnswersEvent(data.evaluation));
        }
      })
    );
  }
}
