import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CommonModule } from '@angular/common';

import { ApiService } from './api.service';
import { ApiConfig, A3L_API_CONFIG } from './config';

export function ApiFactory(config: ApiConfig, client: HttpClient) {
  return new ApiService(config.endpoint, client);
}

@NgModule({
  imports: [CommonModule],
})
export class ApiModule {
  public static forRoot(config: ApiConfig): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        { provide: A3L_API_CONFIG, useValue: config },
        { provide: ApiService, useFactory: ApiFactory, deps: [A3L_API_CONFIG, HttpClient] },
      ],
    };
  }
}
