import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'rexCountry' })
export class CountryPipe implements PipeTransform {
  /**
   * Create a new instance.
   */
  constructor() {
    //
  }

  /**
   * Transform the given country code.
   *
   * @param {string} code
   * @param {any[]} countires
   * @return string
   */
  transform(code: string, countires: any[]): string {
    const country = countires[code] || countires[code.toUpperCase()];

    return country || code;
  }
}
