import { Directive, AfterViewInit, Input, TemplateRef, HostListener, OnInit, OnDestroy, AfterContentInit } from '@angular/core';

import { Portal } from './portal';

@Directive({ selector: '[a3l-portal-source]' })
export class PortalSource implements AfterContentInit, OnDestroy {
  /**
   * @var {string}
   */
  @Input('a3l-portal-source')
  to: string;

  /**
   * Create a new instance.
   *
   * @param {Portal} portal
   * @param {TemplateRef<any>} templateRef
   */
  constructor(private portal: Portal, private templateRef: TemplateRef<any>) {
    //
  }

  /**
   * Initialization.
   */
  ngAfterContentInit() {
    this.portal.attach(this.to, this.templateRef);
  }

  /**
   * Cleanup.
   */
  ngOnDestroy() {
    this.portal.detach(this.to);
  }
}
