import {Component, Input, ViewEncapsulation} from "@angular/core";

@Component({
  selector: 'rex-text-spinner',
  templateUrl: './text-spinner.component.html',
  styleUrls: ['./text-spinner.component.scss'],
  host: {
    class: 'rex-text-spinner',
    '[class.rex-text-spinner--processing]': "color == 'processing'",


  },
  encapsulation: ViewEncapsulation.None
})
export class TextSpinnerComponent {
  @Input()
  text: string;

  /**
   * @var {string}
   */
  @Input()
  color: 'processing' = 'processing';

}
