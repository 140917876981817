import { Subject } from 'rxjs';
import { filter, take } from 'rxjs/operators';

import { OverlayRef } from '@angular/cdk/overlay';
import { ESCAPE } from '@angular/cdk/keycodes';

import { DialogConfig } from './config';
import { DialogContainerComponent } from './dialog-container.component';

export class DialogRef<T, R = any> {
  /**
   * @var {any}
   */
  componentInstance: T;

  /**
   * @var {Subject<any>}
   */
  private afterClose = new Subject<void>();

  /**
   * @var {Subject<any>}
   */
  private afterCloseWithResult = new Subject<R>();

  /**
   * @var {any}
   */
  private result: R | undefined;

  /**
   * @var {boolean}
   */
  private locked: boolean = false;

  /**
   * Create a new instance.
   *
   * @param {OverlayRef} overlayRef
   * @param {DialogContainerComponent} containerInstance
   * @param {DialogConfig} config
   */
  constructor(private overlayRef: OverlayRef, private containerInstance: DialogContainerComponent, private config?: DialogConfig) {
    containerInstance.config = this.config || {};

    containerInstance.animationStateChanged
      .pipe(
        filter((event) => event.phaseName === 'done' && event.toState === 'exit'),
        take(1)
      )
      .subscribe(() => {
        this.overlayRef.dispose();

        this.afterClose.next();
        this.afterClose.complete();

        if (this.result) {
          this.afterCloseWithResult.next(this.result);
          this.afterCloseWithResult.complete();
        }

        this.componentInstance = null;
      });

    overlayRef
      .keydownEvents()
      .pipe(filter((event) => event.keyCode === ESCAPE && !this.locked))
      .subscribe(() => this.close());
  }

  /**
   * Close the dialog.
   *
   * @param {any} dialogResult
   * @return void
   */
  close(dialogResult?: R) {
    this.result = dialogResult;

    this.containerInstance.animationStateChanged
      .pipe(
        filter((event) => event.phaseName === 'start'),
        take(1)
      )
      .subscribe(() => {
        this.overlayRef.detachBackdrop();
      });

    this.containerInstance.startExitAnimation();
  }

  /**
   * Gets an observable that is notified when the dialog is finished closing.
   *
   * @return Observable
   */
  afterClosed() {
    return this.afterClose.asObservable();
  }

  /**
   * Gets an observable that is notified when the dialog is finished closing.
   *
   * @return Observable
   */
  afterClosedWithResult() {
    return this.afterCloseWithResult.asObservable();
  }

  /**
   * Gets an observable that emits when keydown events are targeted on the overlay.
   *
   * @return Observable
   */
  keydownEvents() {
    return this.overlayRef.keydownEvents();
  }

  /**
   * Disable the possibility of dialog close.
   *
   * @return void
   */
  lock(): void {
    this.containerInstance.showCloseButton = false;
    this.containerInstance.locked = this.locked = true;
  }

  /**
   * Enable the possibility of dialog close.
   *
   * @return void
   */
  unlock(): void {
    this.containerInstance.locked = this.locked = false;
  }
}
