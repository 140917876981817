import {Exception} from "@a3l/core";

export class AccountNeedsVerificationByOrganizationException extends Exception {
  /**
   * @var {string}
   */
  static readonly type = 'AccountNeedsVerificationByOrganizationException';

  /**
   * @var {string}
   */
  public readonly type = AccountNeedsVerificationByOrganizationException.type;
}
