import { Component, HostListener } from '@angular/core';

@Component({
  selector: 'a3l-ui-modal-content',
  template: '<ng-content></ng-content>',
  host: {
    class: 'a3l-ui-modal__content',
  },
})
export class ModalContentComponent {
  /**
   * @var {number}
   */
  maxContentHeight: number;

  /**
   * Set the content height limit.
   *
   * @return void
   */
  @HostListener('window:resize')
  onWindowResize() {
    this.maxContentHeight = window.innerHeight * 0.7;
  }

  /**
   * Create a new instance.
   */
  constructor() {
    this.onWindowResize();
  }
}
