import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { CookieDB } from '@a3l/core';

import { environment } from '@rex/environments/environment';

@Injectable({ providedIn: 'root' })
export class MaintenanceGuard implements CanActivate {
  /**
   * Create a new instance.
   *
   * @param {Router} router
   */
  constructor(private router: Router) {
    //
  }

  /**
   * Decide if a route can be activated.
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @return any
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    if (route.queryParamMap.has('i-know-but-let-me-in')) CookieDB.put('maintenance_bypass', true);

    if (environment.maintenance && CookieDB.get('maintenance_bypass', false) == false) {
      return this.router.parseUrl('/e/maintenance');
    }

    return true;
  }
}
