<a3l-ui-dialog-header>
  <h2 translate>Informacje o wyborze języka</h2>
</a3l-ui-dialog-header>
<a3l-ui-dialog-content>
  <p translate>System ReX automatycznie przetłumaczy wszystkie informacje na dowolny język innych użytkowników. Jeżeli chcesz sprawdzić poprawność tłumaczeń zmień tutaj język, a w razie konieczności możesz wprowadzić poprawki dla danego tłumaczenia.</p>
  <p translate>Pamiętaj, że wybrany język systemu jest językiem bazowym dla wprowadzanych informacji i to na jego bazie będą tłumaczone informacje.</p>
</a3l-ui-dialog-content>
<a3l-ui-dialog-footer class="text-center">
  <button type="button" a3l-ui-button color="primary" (click)="close()" translate>Rozumiem</button>
</a3l-ui-dialog-footer>
