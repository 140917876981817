import { APP_INITIALIZER, NgModule, ModuleWithProviders, ErrorHandler } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import * as Sentry from "@sentry/angular";
import { Router } from "@angular/router";
import { ErrorsHandler } from './errors.handler';
import { ErrorsInterceptor } from './errors.interceptor';

@NgModule()
export class ErrorsModule {
  static forRoot(): ModuleWithProviders<ErrorsModule> {
    return {
      ngModule: ErrorsModule,
      providers: [
        { provide: ErrorHandler, useClass: ErrorsHandler },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorsInterceptor, multi: true },
        {
          provide: Sentry.TraceService,
          deps: [Router],
        },
        {
          provide: APP_INITIALIZER,
          useFactory: () => () => {},
          deps: [Sentry.TraceService],
          multi: true,
        },
      ],
    };
  }
}
