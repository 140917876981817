import {Directive, HostListener, Input, ElementRef, ViewContainerRef, OnDestroy, OnInit} from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { take } from 'rxjs/operators';

import { HelperComponent } from './helper.component';
import {TranslateService} from "@ngx-translate/core";

export const UI_TOOLTIP_PANEL_CLASS = 'cdk-a3l-ui-helper-pane';

@Directive({
  selector: '[a3l-ui-helper]',
})
export class HelperDirective implements OnDestroy, OnInit {

  @Input('a3l-ui-helper')
  helperComponent: any;

  message: string;

  /**
   * @var {boolean}
   */
  @Input('a3l-ui-helper-disabled')
  disabled: boolean = false;

  /**
   * @var {OverlayRef}
   */
  private overlayRef: OverlayRef | null;

  /**
   * @var {HelperComponent}
   */
  private helperComponentInstance: HelperComponent | null;

  constructor(private overlay: Overlay, private elementRef: ElementRef, private viewContainerRef: ViewContainerRef, private translator: TranslateService) {
    //
  }

  ngOnInit() {
    this.translator.get('Kliknij, aby zobaczyć wskazówki').subscribe((message) => this.message = message);
  }

  /**
   * Listen for mouseover event.
   *
   * @return void
   */
  @HostListener('mouseover')
  show(): void {
    if (!this.message || this.disabled) return;

    if (this.helperComponentInstance) return;

    this.createHelperComponent();
  }

  /**
   * Hide the helper after the delay in ms.
   *
   * @return void
   */
  @HostListener('mouseleave')
  hide(): void {
    if (!this.helperComponentInstance) return;

    this.helperComponentInstance.hide();
  }

  /**
   * Create the helper to display.
   *
   * @return void
   */
  private createHelperComponent(): void {
    const strategy = this.overlay
      .position()
      .flexibleConnectedTo(this.elementRef)
      .withPositions([{ originX: 'center', originY: 'top', overlayX: 'center', overlayY: 'bottom' }])
      .withPush(true);

    this.overlayRef = this.overlay.create({
      panelClass: UI_TOOLTIP_PANEL_CLASS,
      positionStrategy: strategy,
    });

    this.overlayRef.backdropClick().pipe(take(1)).subscribe(() => this.hide()); // prettier-ignore

    const portal = new ComponentPortal(HelperComponent, this.viewContainerRef);

    this.helperComponentInstance = this.overlayRef.attach(portal).instance;

    this.helperComponentInstance.message = this.message;
    this.helperComponentInstance.markForCheck();
    this.helperComponentInstance.show();

    this.helperComponentInstance.afterHidden.subscribe(this.disposeHelperComponent.bind(this));
  }

  /**
   * Remove the helper and the overlay.
   *
   * @return void
   */
  private disposeHelperComponent(): void {
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }

    if (this.helperComponentInstance) {
      this.helperComponentInstance = null;
    }
  }

  /**
   * Dispose the helper when destroyed.
   */
  ngOnDestroy() {
    if (!this.helperComponentInstance) {
      return;
    }

    this.disposeHelperComponent();
  }
}
