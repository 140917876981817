import { Component, ElementRef } from '@angular/core';

@Component({
  selector: 'a3l-ui-tabs-bar',
  template: '',
  host: {
    class: 'a3l-ui-tabs-bar',
  },
})
export class TabsBarComponent {
  /**
   * Create a new instance.
   *
   * @param {ElementRef} elementRef
   */
  constructor(private elementRef: ElementRef) {
    //
  }

  /**
   * Calculates the styles from the provided element.
   *
   * @param {HTMLElement} element
   * @return void
   */
  alignToElement(element: HTMLElement): void {
    const bar: HTMLElement = this.elementRef.nativeElement;

    bar.style.left = element ? (element.offsetLeft || 0) + 'px' : '0';
    bar.style.width = element ? (element.offsetWidth || 0) + 'px' : '0';

    this.show();
  }

  /**
   * Shows the element.
   *
   * @return void
   */
  show(): void {
    this.elementRef.nativeElement.style.visibility = 'visible';
  }

  /**
   * Hides the element.
   *
   * @return void
   */
  hide(): void {
    this.elementRef.nativeElement.style.visibility = 'hidden';
  }
}
