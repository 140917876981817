import {Command} from "@a3l/core";
import {Injectable} from "@angular/core";
import {ApiService} from "@a3l/api";
import {Observable} from "rxjs";
import {catchError, finalize, tap} from "rxjs/operators";
import {IndustryTypeEnum} from "@rex/industries/enums/industry-type.enum";

@Injectable()
export class IndustriesCommand extends Command<void> {
  /**
   * Create a new instance.
   *
   * @param {ApiService} api
   */
  constructor(private api: ApiService) {
    super();
  }


  list(): Observable<any> {
    this._pending$.next(true);

    return this.api.get(`/industries`).pipe(
      catchError((exception) => {
        this._pending$.next(false);
        this._errors$.next(exception);

        throw exception;
      }),
      finalize(() => this._pending$.next(false)),
    );
  }

  /**
   * Run the edit command.
   *
   * @param {any} model
   * @return Observable<void>
   */
  add({ name , traffit_discipline}: { name: string, traffit_discipline: any }): Observable<void> {
    this._pending$.next(true);

    const parameters = {
      name: name,
      traffit_discipline: traffit_discipline,
    };

    return this.api.post(`/industries`, parameters).pipe(
      catchError((exception) => {
        this._pending$.next(false);
        this._errors$.next(exception);

        throw exception;
      }),
      finalize(() => this._pending$.next(false)),
      tap(() => this._complete$.next())
    );
  }

  edit(industryId, params): Observable<void> {
    this._pending$.next(true);

    return this.api.put(`/industries/${industryId}`, params).pipe(
      catchError((exception) => {
        this._pending$.next(false);
        this._errors$.next(exception);

        throw exception;
      }),
      finalize(() => this._pending$.next(false)),
      tap(() => this._complete$.next())
    );
  }

}
