import { SnackBarRef } from './ref';

export enum SnackBarType {
  Info = 'info',
  Warning = 'warning',
  Success = 'success',
  Error = 'error',
}

export enum SnackBarPersistable {
  Yes = 1,
  No = 0,
}

export interface SnackBarItem {
  ref: SnackBarRef;
  type: SnackBarType;
  message: string;
  persistable: SnackBarPersistable;
  duration?: number;
}
