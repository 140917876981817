<div class="a3l-ui-imageupload-preview" *ngIf="src || value">
  <div class="a3l-ui-imageupload-preview__image" [style.backgroundImage]="'url(' + src + ')'" *ngIf="src"></div>
  <button type="button" class="a3l-ui-imageupload-preview__clear" (click)="clear(); $event.stopPropagation()">
    <a3l-ui-icon icon="trash"></a3l-ui-icon>
  </button>
</div>

<ng-container *ngIf="!src && !value">
  <ng-container *ngTemplateOutlet="dragging ? drop : dragover"></ng-container>
</ng-container>

<ng-template #dragover>
  <div class="a3l-ui-imageupload-message">
    <a3l-ui-icon icon="dnd" class="a3l-ui-imageupload-message__icon"></a3l-ui-icon>
    <ng-content select="[a3l-ui-imageupload-text-dropzone]"></ng-content>
  </div>
</ng-template>

<ng-template #drop>
  <div class="a3l-ui-imageupload-message">
    <a3l-ui-icon icon="dnd" class="a3l-ui-imageupload-message__icon"></a3l-ui-icon>
    <ng-content select="[a3l-ui-imageupload-text-drop]"></ng-content>
  </div>
</ng-template>

<input type="file" (change)="byExplorer($event)" (click)="$event.stopPropagation()" #input />
