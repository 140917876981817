import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'a3lDatasetZooms' })
export class ZoomsPipe implements PipeTransform {
  /**
   * Transform the given array.
   *
   * @param {any[]} lenses
   * @param {string} key
   * @return any[]
   */
  transform(lenses: any[], key: string): any[] {
    const lense = (lenses || []).find((lens) => lens.key == key);

    if (lense == null) return [];

    return lense.zooms;
  }
}
