import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[click.stop]',
})
export class ClickStopDirective {
  /**
   * @var EventEmitter<Event>
   */
  @Output('click.stop')
  click: EventEmitter<Event> = new EventEmitter<Event>();

  /**
   * Listen for click event on host.
   *
   * @param {MouseEvent} event
   * @return void
   */
  @HostListener('click', ['$event'])
  onClick(event: MouseEvent): void {
    event.stopPropagation();

    this.click.emit(event);
  }

  /**
   * Create a new instance.
   */
  constructor() {
    //
  }
}
