<div [class]="{'bg-gray-light': !isOpened }" class="cv-filter flex justify-between text-sm font-medium items-center px-4" cdkOverlayOrigin #trigger="cdkOverlayOrigin" (click)="switchState()">
  <a3l-ui-icon icon="filter"></a3l-ui-icon>
  <span translate class="mx-3">Filtry</span>
  <a3l-ui-icon icon="chevron-up" class="text-xxxs" *ngIf="isOpened"></a3l-ui-icon>
  <a3l-ui-icon icon="chevron-down" class="text-xxxs" *ngIf="!isOpened"></a3l-ui-icon>
</div>
<ng-template
  cdkConnectedOverlay
  #cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isOpened"
>
  <div class="cv-filter__overlay p-5 bg-white rounded-xl z-10">
    <div class="flex justify-between">
      <form [formGroup]="form" class="cv-filter__left-column" #leftColumn>
        <div class="flex justify-between items-center mb-5">
          <h5 translate class="m-0">Filtry</h5>
          <button class="mx-1" type="button" a3l-ui-button icon small (click)="clearFilters()">
            <a3l-ui-icon icon="trash" class="text-xxs"></a3l-ui-icon>
          </button>
        </div>
        <a3l-ui-form-group class="mb-2" id="softSkills">
          <label a3l-ui-label translate #softSkills>candidate::panel.wizard.title.soft_skills</label>
          <a3l-ui-autocomplete formControlName="skills" [suggestions]="softSkillsSet" [disableInput]="true"></a3l-ui-autocomplete>
        </a3l-ui-form-group>
        <hr class="cv-filter__hr my-4 border border-solid border-gray-light">
        <a3l-ui-form-group class="mb-2" id="hardSkills">
          <label a3l-ui-label translate #hardSkills>candidate::panel.wizard.title.hard_skills</label>
          <a3l-ui-autocomplete formControlName="hard_skills" [suggestions]="hardSkillsSet" [disableInput]="true"></a3l-ui-autocomplete>
        </a3l-ui-form-group>
        <hr class="cv-filter__hr my-4 border border-solid border-gray-light">
        <a3l-ui-form-group class="mb-2" id="industries">
          <label a3l-ui-label translate #industries>candidate::panel.wizard.title.industries</label>
          <a3l-ui-autocomplete formControlName="industries" [suggestions]="industriesSet" [disableInput]="true"></a3l-ui-autocomplete>
        </a3l-ui-form-group>
        <hr class="cv-filter__hr my-4 border border-solid border-gray-light">
        <a3l-ui-form-group class="mb-2 " id="localization">
          <label a3l-ui-label translate #localization>candidate::panel.wizard.title.localization</label>
          <rex-single-localization-field formControlName="localization"></rex-single-localization-field>
        </a3l-ui-form-group>
        <hr class="cv-filter__hr my-4 border border-solid border-gray-light">
        <a3l-ui-form-group class="mb-2" id="salary">
          <label a3l-ui-label translate #salary>candidate::panel.wizard.title.salary</label>
          <a3l-ui-currency-input formControlName="salary" [currency]="'PLN'"></a3l-ui-currency-input>
        </a3l-ui-form-group>
        <hr class="cv-filter__hr my-4 border border-solid border-gray-light">
        <a3l-ui-form-group class="mb-2 font-medium" id="availability">
          <label a3l-ui-label class="mb-4 block" #availability translate>Okres wypowiedzenia</label>
          <a3l-ui-radio-group formControlName="availability">
            <a3l-ui-radio-item outlined class="mb-2" value="0" translate>Brak</a3l-ui-radio-item>
            <a3l-ui-radio-item outlined class="mb-2" value="14" translate>Dwa tygodnie</a3l-ui-radio-item>
            <a3l-ui-radio-item outlined class="mb-2" value="30" translate>Miesiąc</a3l-ui-radio-item>
            <a3l-ui-radio-item outlined class="mb-2" value="60" translate>Dwa miesiące</a3l-ui-radio-item>
            <a3l-ui-radio-item outlined value="90" translate>Trzy miesiące</a3l-ui-radio-item>
          </a3l-ui-radio-group>
          <ng-template a3l-ui-error-message let-key="key">{{ 'validation.' + key | translate }}</ng-template>
        </a3l-ui-form-group>
        <hr class="cv-filter__hr my-4 border border-solid border-gray-light">
        <a3l-ui-form-group class="mb-2" id="types_of_agreement">
          <label a3l-ui-label translate #types_of_agreement>Formy współpracy</label>
          <rex-agreements-field class="mt-4 block text-md" formControlName="types_of_agreement"></rex-agreements-field>
        </a3l-ui-form-group>
      </form>
      <div class="cv-filter__right-column ml-8 bg-gray-light p-5 rounded-xl pb-10">
        <span class="uppercase font-bold text-xxxs" translate >Szybki dostęp</span>
        <p translate class="pt-5" [class]="getQuickAccessClassRules('soft_skills')" (click)="changeFocus('soft_skills')">candidate::panel.wizard.title.soft_skills</p>
        <p translate [class]="getQuickAccessClassRules('hard_skills')" (click)="changeFocus('hard_skills')">candidate::panel.wizard.title.hard_skills</p>
        <p translate [class]="getQuickAccessClassRules('industries')" (click)="changeFocus('industries')">candidate::panel.wizard.title.industries</p>
        <p translate [class]="getQuickAccessClassRules('localization')" (click)="changeFocus('localization')">candidate::panel.wizard.title.localization</p>
        <p translate [class]="getQuickAccessClassRules('salary')" (click)="changeFocus('salary')">candidate::panel.wizard.title.salary</p>
        <p translate [class]="getQuickAccessClassRules('availability')" (click)="changeFocus('availability')">candidate::panel.wizard.title.availability</p>
        <p translate [class]="getQuickAccessClassRules('types_of_agreement')" (click)="changeFocus('types_of_agreement')">candidate::panel.wizard.title.typeOfAgreement</p>
      </div>
    </div>
    <div class="cv-filter__bottom-row mt-4">
      <button a3l-ui-button [color]="'primary'" [small]="true" class="text-sm" (click)="applyFilters()" translate>Zastosuj filtr</button>
      <button a3l-ui-button [small]="true" class="ml-2 text-sm" translate (click)="cancel()">Anuluj</button>
    </div>
  </div>
</ng-template>
