<ng-container [ngSwitch]="column.key">
  <ng-container *ngSwitchCase="'actions'">
    <div class="flex gap-1">
      <ng-template
        [rex-recruiter-organization-dataset-action]="action"
        [rex-recruiter-organization-dataset-action-item]="item"
        [rex-recruiter-organization-dataset-action-view]="view"
        [rex-recruiter-organization-dataset-action-entity]="entity"
        [rex-recruiter-organization-dataset-action-additionalData]="additionalData()"
        *ngFor="let action of item.actions"
      ></ng-template>
    </div>
  </ng-container>
  <ng-container a3l-dataset-table-dcell *ngSwitchCase="'organization_name'">
    <div class="mt-2 font-medium text-xs text-gray-dark">
      <b *ngIf="item.organization_type == 'company'">{{ item.organization_name}}</b>
      <b *ngIf="item.organization_type == 'personal'">{{ 'Umowa z osobą fizyczną' | translate}}</b>
    </div>
  </ng-container>
  <ng-container a3l-dataset-table-dcell *ngSwitchCase="'organization_experience'">
    <span class="font-medium text-xs text-gray-dark">{{ item.in_organization_since | a3lDatetime:'fromNow' }}&nbsp;{{ 'organization::dataset.fields.in_organization_since' | translate }}</span>
  </ng-container>
  <ng-container a3l-dataset-table-dcell *ngSwitchCase="'organization_last_seen_at'">
    <div>
      <a3l-ui-badge color="active" *ngIf="!dateOlderThan30Days(item.last_seen_at)" translate>Aktywny</a3l-ui-badge>
      <a3l-ui-badge color="danger" *ngIf="dateOlderThan30Days(item.last_seen_at)" translate>Nieaktywny</a3l-ui-badge>
    </div>
  </ng-container>
  <ng-container a3l-dataset-table-dcell *ngSwitchCase="'organization_recruitments'">
    <div>
      <rex-recruiter-recruitments-counter-column [item]="item"></rex-recruiter-recruitments-counter-column>
    </div>
  </ng-container>
  <ng-container a3l-dataset-table-dcell *ngSwitchCase="'organization_candidates'">
    <div>
      <rex-recruiter-candidate-column [item]="item" [view]="view"></rex-recruiter-candidate-column>
    </div>
  </ng-container>
  <ng-container a3l-dataset-table-dcell *ngSwitchDefault><div>{{ item[column.key] }}</div></ng-container>
</ng-container>
