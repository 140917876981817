import { HttpClient } from '@angular/common/http';
import { Observable, EMPTY, zip } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { TranslateLoader } from '@ngx-translate/core';

import { environment } from '@rex/environments/environment';

export class TranslateHttpLoader implements TranslateLoader {
  constructor(private http: HttpClient) {
    //
  }

  public getTranslation(key: string): Observable<Object> {
    return zip(this.http.get(`${environment.api.endpoint}/translations`)).pipe(
      catchError(() => EMPTY),
      map(([external]: [any[]]) => {
        const { content = {} } = external.find(({ language }) => language == key) || {};

        return { ...content };
      })
    );
  }
}

export function HttpTranslationsLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}
