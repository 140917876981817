import {Component, Input} from "@angular/core";
import {GroupableComponentInterface} from "../../../../../../libs/dataset/src/lib/GroupableComponentInterface";
import {OrganizationUserStatusEnum} from "@rex/organization/enums/organization-user-status.enum";

@Component({
  templateUrl: './organization-groupable.component.html',
})
export class OrganizationGroupableComponent implements GroupableComponentInterface {
  organizationUserStatusEnum: typeof OrganizationUserStatusEnum = OrganizationUserStatusEnum;

  @Input() public item: any;
  @Input() public column: any;
  @Input() public view: any;
  @Input() public entity: any;

  additionalData() {
    return {
      organization_id: this.item.organization_id
    };
  }

  dateOlderThan30Days(date) {
    let monthAgo = new Date(new Date().setDate((new Date()).getDate() - 30));
    return (new Date(date)).getTime() < monthAgo.getTime();
  }
}
