import { Directive, HostListener, Input, ElementRef, ViewContainerRef, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { Overlay, OverlayRef, OverlayConfig, VerticalConnectionPos, HorizontalConnectionPos, FlexibleConnectedPositionStrategy } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { Subscription, merge } from 'rxjs';

import { PopoverComponent } from './popover.component';

export const POPOVER_PANEL_CLASS = 'cdk-a3l-ui-popover-pane';

@Directive({ selector: '[a3l-ui-popover]' })
export class PopoverDirective implements OnInit, OnDestroy {
  /**
   * @var {PopoverComponent}
   */
  @Input('a3l-ui-popover')
  popover: PopoverComponent;

  /**
   * @var {OverlayRef}
   */
  private overlayRef: OverlayRef | null;

  /**
   * @var {Subscription}
   */
  private closeSubscription: Subscription = Subscription.EMPTY;

  /**
   * @var {Subscription}
   */
  private popoverDestroySubscription: Subscription = Subscription.EMPTY;

  /**
   * Create a new instance.
   *
   * @param {Overlay} overlay
   * @param {ElementRef} elementRef
   * @param {ViewContainerRef} viewContainerRef
   */
  constructor(private overlay: Overlay, private elementRef: ElementRef, private renderer: Renderer2, private viewContainerRef: ViewContainerRef) {
    //
  }

  /**
   * Initialization.
   *
   * @return void
   */
  ngOnInit() {
    this.popoverDestroySubscription = this.popover.afterDestroyed().subscribe(this.hide.bind(this));
  }

  /**
   * Listen for click event and toggle the popover.
   *
   * @return void
   */
  @HostListener('click', ['$event'])
  onClick(event: MouseEvent): void {
    event.preventDefault();

    this.show();
  }

  /**
   * Show the popover.
   *
   * @return void
   */
  show(): void {
    const strategy = this.overlay.position().flexibleConnectedTo(this.elementRef).withLockedPosition();

    const overlayConfig: OverlayConfig = {
      positionStrategy: strategy,
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
      disposeOnNavigation: true,
      panelClass: POPOVER_PANEL_CLASS,
      scrollStrategy: this.overlay.scrollStrategies.close(),
    };

    this.overlayRef = this.overlay.create(overlayConfig);

    this.setPosition(overlayConfig.positionStrategy as FlexibleConnectedPositionStrategy);

    this.overlayRef.attach(new TemplatePortal(this.popover.templateRef, this.viewContainerRef));

    this.closeSubscription = merge(this.overlayRef.backdropClick(), this.overlayRef.detachments()).subscribe(this.hide.bind(this));

    if (this.popover.positionOrigin) {
      const rect = this.popover.positionOrigin.elementRef.nativeElement.getBoundingClientRect();

      this.renderer.setStyle(this.overlayRef.hostElement, 'marginTop', `-${rect.top}px`);
      this.renderer.setStyle(this.overlayRef.hostElement, 'marginLeft', `-${rect.left}px`);
    }

    this.popover.show();
  }

  /**
   * Set the appropriate position.
   *
   * @param {FlexibleConnectedPositionStrategy} strategy
   * @return void
   */
  private setPosition(strategy: FlexibleConnectedPositionStrategy): void {
    let [originX, originFallbackX]: HorizontalConnectionPos[] = ['start', 'end'];

    let [overlayY, overlayFallbackY]: VerticalConnectionPos[] = ['top', 'bottom'];

    let [originY, originFallbackY] = [overlayY, overlayFallbackY];
    let [overlayX, overlayFallbackX] = [originX, originFallbackX];
    let offsetY = 0;

    strategy.withPositions([
      { originX, originY, overlayX, overlayY, offsetY },
      { originX: originFallbackX, originY, overlayX: overlayFallbackX, overlayY, offsetY },
      {
        originX,
        originY: originFallbackY,
        overlayX,
        overlayY: overlayFallbackY,
        offsetY: -offsetY,
      },
      {
        originX: originFallbackX,
        originY: originFallbackY,
        overlayX: overlayFallbackX,
        overlayY: overlayFallbackY,
        offsetY: -offsetY,
      },
    ]);
  }

  /**
   * Hide the popover.
   *
   * @return void
   */
  hide(): void {
    this.popover.hide();

    this.closeSubscription.unsubscribe();

    this.overlayRef && this.overlayRef.dispose();
  }

  /**
   * Dispose the popover when destroyed.
   */
  ngOnDestroy() {
    this.hide();

    this.popoverDestroySubscription.unsubscribe();
  }
}
