import { Injectable } from '@angular/core';
import {CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import { Observable } from 'rxjs';
import { take, map } from 'rxjs/operators';

import { Store } from '@ngrx/store';

import { State, getLoggedInUser } from '@rex/state';

@Injectable({ providedIn: 'root' })
export class RoleGuard implements CanActivate {
  /**
   * Create a new instance.
   *
   * @param {Router} router
   * @param {Store<State>} store
   */
  constructor(private router: Router, private store: Store<State>) {
    //
  }

  /**
   * Decide if a route can be activated.
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @return Observable<any>
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    const { roles = [] } = route.data;

    return this.store.select(getLoggedInUser).pipe(
      take(1),
      map((user) => {
        if (user && roles.indexOf(user.role) != -1) return true;

        if(user.role == 'recruiter' && !state.url.includes('organization')) {
          return this.router.parseUrl('/organization' + state.url );
        }

        return this.router.parseUrl('/' );
      })
    );
  }

  getConfiguredUrl(route: ActivatedRouteSnapshot): string {
    return '/' + route.pathFromRoot
      .filter(v => v.routeConfig)
      .map(v => v.routeConfig!.path)
      .join('/');
  }
}
