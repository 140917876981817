import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IconComponent } from './icon.component';
import {TraffitIcon} from "./traffit-icon";

const PUBLIC_API = [IconComponent, TraffitIcon];

@NgModule({
  declarations: [...PUBLIC_API],
  imports: [CommonModule],
  exports: [...PUBLIC_API],
})
export class IconModule {}
