import { HttpException } from './exception';

import { HttpResponse } from './response';

export class HttpUnprocessableEntityException extends HttpException {
  /**
   * @var {string}
   */
  static readonly type: string = 'HttpUnprocessableEntityException';

  /**
   * @var {string}
   */
  public readonly type: string = HttpUnprocessableEntityException.type;

  /**
   * @param {string}
   */
  static readonly status: number = HttpResponse.UNPROCESSABLE_ENTITY;

  /**
   * @param {any}
   */
  get validationErrors(): any {
    return this.data.errors;
  }
}
