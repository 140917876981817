import { HttpException } from './exception';

import { HttpResponse } from './response';

export class HttpNotFoundException extends HttpException {
  /**
   * @var {string}
   */
  static readonly type: string = 'HttpNotFoundException';

  /**
   * @var {string}
   */
  public readonly type: string = HttpNotFoundException.type;

  /**
   * @param {string}
   */
  static readonly status: number = HttpResponse.NOT_FOUND;
}
