import { Directive, Input } from '@angular/core';

@Directive({
  selector: '[a3l-dataset-lens-indicator]',
  host: {
    class: 'a3l-dataset-lens-indicator',
    '[class.a3l-dataset-lens-indicator--loading]': 'loading',
  },
})
export class LensIndicator {
  /**
   * @var {boolean}
   */
  @Input('a3l-dataset-lens-indicator')
  get loading(): boolean {
    return this._loading;
  }
  set loading(value: boolean) {
    this._loading = value !== false;
  }

  /**
   * @var {boolean}
   */
  _loading: boolean = false;

  /**
   * Create a new instance.
   */
  constructor() {
    //
  }
}
