<div class="-mx-1 mb-2 flex items-center items-center" [formGroup]="form">
  <a3l-ui-form-group class="mx-1 w-2/6" [showLockIcon]="showLockIcon">
    <label a3l-ui-label translate>Kierunkowy</label>
    <a3l-ui-select [placeholder]="'Wybierz' | translate" [searcher]="true" formControlName="prefix">
      <a3l-ui-select-option [value]="country.key" *ngFor="let country of countries | keyvalue | a3lOrderBy:'value'"> ({{ country.value | uppercase }}) +{{ country.key }} </a3l-ui-select-option>
    </a3l-ui-select>
    <ng-template a3l-ui-error-message let-key="key">{{ 'validation.' + key | translate }}</ng-template>
  </a3l-ui-form-group>
  <a3l-ui-form-group class="mx-1 w-4/6" [showLockIcon]="showLockIcon">
    <label a3l-ui-label>{{ 'Numer telefonu' | translate }}</label>
    <input type="text" a3l-ui-input [autocomplete]="autocomplete ? 'tel' : ''" [placeholder]="placeholder"  formControlName="number" #input (focus)="focused = true" (blur)="focused = false" />
    <ng-template a3l-ui-error-message let-key="key">{{ 'validation.' + key | translate }}</ng-template>
  </a3l-ui-form-group>
</div>
