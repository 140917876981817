import { Directive, TemplateRef } from '@angular/core';

@Directive({ selector: '[a3l-dataset-grid-element]' })
export class DatasetGridElement {
  /**
   * Create a new instance.
   *
   * @param {TemplateRef<any>} templateRef
   */
  constructor(public templateRef: TemplateRef<any>) {
    //
  }
}
