import {Component, forwardRef, Input} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Control } from '@a3l/utilities';
import {Subject} from "rxjs";
import {GenerateStrongPasswordCommand} from "@rex/pages/auth/client-registration/generate-strong-password.command";

@Component({
  selector: 'rex-password-field',
  templateUrl: 'password.field.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PasswordField),
      multi: true
    },
    {
      provide: Control,
      useExisting: PasswordField
    },
    GenerateStrongPasswordCommand
  ]
})
export class PasswordField extends Control implements ControlValueAccessor {
  /**
   * @var {boolean}
   */
  @Input()
  protected showValidationMessageOnInput: boolean = false;

  /**
   * @var {boolean}
   */
  get canShowValidationMessage(): boolean {
    return this.focused || this.showValidationMessageOnInput;
  }

  /**
   * @var {string}
   */
  value: string;

  /**
   * @var {Subject<string>}
   */
  valueChanged: Subject<string> = new Subject<string>();

  /**
   * @var {boolean}
   */
  isPasswordVisible: boolean = false;

  /**
   * Create a new instance.
   * @param {GenerateStrongPasswordCommand} generatePasswordCommand
   */
  constructor(private generatePasswordCommand: GenerateStrongPasswordCommand) {
    super();
  }

  /**
   * @var {any}
   */
  protected propagateChange: any = () => {};

  /**
   * Write a new value from the form model.
   *
   * @param {string} value
   * @return void
   */
  writeValue(value: string): void {
    this.value = value;

    this.propagateChange(this.value);
  }

  /**
   * Register handler.
   *
   * @param {any} fn
   * @return void
   */
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  /**
   * Register handler.
   *
   * @param {any} fn
   * @return void
   */
  registerOnTouched(fn: any): void {}

  /**
   * Handle an action.
   *
   * @return void
   */
  generate(): void {
    this.generatePasswordCommand.run().subscribe((password) => {
      this.writeValue(password);

      this.isPasswordVisible = true;
    });
  }

  protected readonly event = event;
}
