<a3l-ui-checkbox [checked]="checked">
  <ng-content></ng-content>
</a3l-ui-checkbox>
<a3l-ui-select class="rex-languages-item-field__level" [placeholder]="'Wybierz poziom' | translate" [(ngModel)]="level" (ngModelChange)="addLevel()" small *ngIf="checked">
  <a3l-ui-select-option value="A1">A1</a3l-ui-select-option>
  <a3l-ui-select-option value="A2">A2</a3l-ui-select-option>
  <a3l-ui-select-option value="B1">B1</a3l-ui-select-option>
  <a3l-ui-select-option value="B2">B2</a3l-ui-select-option>
  <a3l-ui-select-option value="C1">C1</a3l-ui-select-option>
  <a3l-ui-select-option value="C2">C2</a3l-ui-select-option>
  <a3l-ui-select-option value="native" translate>Język ojczysty</a3l-ui-select-option>
</a3l-ui-select>
