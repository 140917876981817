import {Languages} from "@rex/common/languages.enum";
import {LocalizedContent} from "./localized-content";
import {LocalizedContentField} from "./localized-content-field";

export class LocalizedContentFieldCreator {
  constructor(
    public data: any
  ) {
  }

  public getArray()
  {
    if(this.data instanceof LocalizedContent) {
      return this.data;
    }

    let mappedFields = [];
    if(this.data == undefined || this.data == null) {
       mappedFields = Object.values(Languages).map((key) => {
        return new LocalizedContentField(key, null);
      })
    } else {
      mappedFields = this.data.map((array) => {
        return new LocalizedContentField(array.language_code, array.content);
      })
    }

    return new LocalizedContent(mappedFields);
  }
}
