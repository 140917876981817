import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PortalModule } from '@angular/cdk/portal';
import { OverlayModule } from '@angular/cdk/overlay';
import { A11yModule } from '@angular/cdk/a11y';

import { CoreModule } from '../core';

import { DialogContainerComponent } from './dialog-container.component';

import { DialogHeaderComponent } from './dialog-header.component';
import { DialogContentComponent } from './dialog-content.component';
import { DialogFooterComponent } from './dialog-footer.component';

const PUBLIC_API = [DialogHeaderComponent, DialogContentComponent, DialogFooterComponent];

@NgModule({
    declarations: [DialogContainerComponent, ...PUBLIC_API],
    imports: [CommonModule, PortalModule, OverlayModule, A11yModule, CoreModule],
    exports: [...PUBLIC_API]
})
export class DialogModule {}
