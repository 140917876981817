import {Component, ElementRef, forwardRef, Input, ViewChild, ViewEncapsulation} from '@angular/core';

import {GoogleMap} from "../../../../../libs/core/src/lib/GoogleMap";
import {Control} from "@a3l/utilities";
import {NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
  selector: 'rex-single-localization-field',
  templateUrl: './single-localization.field.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SingleLocalizationField),
      multi: true,
    },
    { provide: Control, useExisting: SingleLocalizationField }
  ],
})
export class SingleLocalizationField extends Control {
  /**
   * @var {any}
   */
  @Input()
  trigger: any = null;

  /**
   * @var {ElementRef}
   */
  @ViewChild('input', { static: true })
  input: ElementRef;

  protected propagateChange: any = () => {};

  /**
   * @var {string}
   */
  get address(): string {
    return this.place?.address || null;
  }

  /**
   * @var {any}
   */
  protected place: { lat: string; lng: string; address: string } = null;


  /**
   * Create a new instance.
   *
   * @param {GoogleMap} googleMap
   */
  constructor(private googleMap: GoogleMap) {
    super();
    //
  }

  /**
   * Initialization.
   */
  ngOnInit() {
    this.googleMap.loader.load().then(() => {
      const google = (window as any).google;

      const autocomplete = new google.maps.places.Autocomplete(this.input.nativeElement, { types: ['(regions)'] });

      autocomplete.addListener('place_changed', () => {
        const { formatted_address: address, geometry } = autocomplete.getPlace(); // prettier-ignore

        this.place = { lat: geometry.location.lat(), lng: geometry.location.lng(), address };
        this.propagateChange(this.place);
      });
    });
  }

  /**
   * @var {string}
   */
  key(): string {
    return this.trigger?.key;
  }

  /**
   * Handle the action.
   *
   * @return any
   */
  value(): any {
    return this.place;
  }

  /**
   * Handle the action.
   *
   * @param {any} place
   * @return void
   */
  consume(place: any): void {
    if (place == null) return;

    this.place = place;
  }

  writeValue(value: any) {
    this.place = value;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: any): void {}

  /**
   * Handle the action.
   *
   * @return void
   */
  clear(): void {
    this.place = null;
  }
}
