import { Injectable } from '@angular/core';
import { map, switchMap } from 'rxjs/operators';

import { Actions, ofType, createEffect } from '@ngrx/effects';

import { Notifications } from './actions';

import { ApiService } from '@a3l/api';

@Injectable()
export class Effects {
  notifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(Notifications.load),
      switchMap(() => this.api.get('/me/notifications')),
      map((notifications) => Notifications.loaded(notifications))
    )
  );

  constructor(private actions$: Actions, private api: ApiService) {
    //
  }
}
