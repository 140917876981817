import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable()
export class Store {
  /**
   * @var {any[]}
   */
  protected _state: any[] = [];

  /**
   * @var {any[]}
   */
  protected state: BehaviorSubject<any[]> = new BehaviorSubject(this._state);

  /**
   * @var {Observable<State>}
   */
  readonly state$: Observable<any[]> = this.state.asObservable();

  /**
   * Create a new instance.
   */
  constructor() {
    //
  }

  /**
   * Update the state.
   *
   * @param {any[]} state
   * @return void
   */
  consume(state: any[] = []): void {
    this.state.next((this._state = [...state]));
  }
}
