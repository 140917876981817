import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'a3lExcept' })
export class ExceptPipe implements PipeTransform {
  /**
   * Transform the given array.
   *
   * @param {any[]} values
   * @param {string} property
   * @param {any} value
   * @return string
   */
  transform(values: any[], property: string, value: any): any[] {
    return values.filter((item) => item[property] != value);
  }
}
