import { Component, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { take } from 'rxjs/operators';

import { Control, Dialog } from '@a3l/utilities';
import {LocalizationWizardDialog} from "./localization-wizard.dialog";
import {Localization} from "./localizator";

@Component({
  selector: 'rex-localization-field',
  templateUrl: './localization.field.html',
  styleUrls: ['./localization.field.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LocalizationField),
      multi: true,
    },
    { provide: Control, useExisting: LocalizationField },
  ],
  host: {
    '(mouseover)': '_canShowValidationMessage = true',
    '(mouseleave)': '_canShowValidationMessage = false',
  },
})
export class LocalizationField extends Control implements ControlValueAccessor {
  /**
   * @var {number}
   */
  limit: number = 3;

  /**
   * @var {any[]}
   */
  items: any[] = [];

  /**
   * @var {boolean}
   */
  get canShowValidationMessage(): boolean {
    return this._canShowValidationMessage;
  }

  /**
   * @var {any}
   */
  protected propagateChange: any = () => {};

  /**
   * @var {boolean}
   */
  protected _canShowValidationMessage: boolean = false;

  /**
   * Create a new instance.
   *
   * @param {Dialog} dialog
   */
  constructor(private dialog: Dialog) {
    super();
  }

  /**
   * Open the dialog to add new localization.
   *
   * @return void
   */
  add(): void {
    const ref = this.dialog.open(LocalizationWizardDialog);

    ref
      .afterClosedWithResult()
      .pipe(take(1))
      .subscribe((localization) => {
        this.items.push(localization);

        this.propagateChange(this.items);
      });
  }

  /**
   * Open the dialog to edit a localization at the given index.
   *
   * @param {number} index
   * @return void
   */
  editAt(index: number): void {
    const ref = this.dialog.open(LocalizationWizardDialog, this.items[index]);

    ref
      .afterClosedWithResult()
      .pipe(take(1))
      .subscribe((localization) => {
        this.items[index] = localization;

        this.propagateChange(this.items);
      });
  }

  /**
   * Remove the localization at the given index.
   *
   * @param {number} index
   * @return void
   */
  removeAt(index: number): void {
    this.items.splice(index, 1);

    this.propagateChange(this.items);
  }

  /**
   * Write a new value from the form model.
   *
   * @param {any} values
   * @return void
   */
  writeValue(values: any): void {
    if (values == null) return;

    this.items = [];

    for (let i = 0, x = values.length; i < x; i++) {
      const localizations = values[i];

      this.items.push(localizations.map(({ name, type, geometry, bounded }) => new Localization(name, type, bounded, geometry)));
    }
  }

  /**
   * Register handler.
   *
   * @param {any} fn
   * @return void
   */
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  /**
   * Register handler.
   *
   * @param {any} fn
   * @return void
   */
  registerOnTouched(fn: any): void {}
}
