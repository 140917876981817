import {ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR} from "@angular/forms";
import {Component, forwardRef, Input, OnInit, ViewEncapsulation} from "@angular/core";
import {Control} from "@a3l/utilities";
import {DictionaryOfLanguagesQuery} from "@rex/common/dictionary-of-languages.query";
import {Observable} from "rxjs";
import {LanguageService} from "@rex/shared/services/language.service";
import {$e} from "@angular/compiler/src/chars";
import {isArray} from "rxjs/internal-compatibility";

interface AutoCompleteEvent {
  originalEvent: Event;
  query: string;
}

@Component({
  selector: 'rex-language-choice-rating',
  styleUrls: ['./language-choice-rating.component.scss'],
  templateUrl: './language-choice-rating.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LanguageChoiceRatingComponent),
      multi: true,
    },
    {
      provide: Control,
      useExisting: LanguageChoiceRatingComponent
    }
  ],
  host: {
    class: 'a3l-ui-autocomplete-rating',
  },
  encapsulation: ViewEncapsulation.None
})
export class LanguageChoiceRatingComponent extends Control implements ControlValueAccessor, OnInit {
  @Input()
  values: any = {};

  formattedValues: any = {};

  /**
   * @var {Observable<any[]>}
   */
  dictionaryItems$: Observable<any[]> = this.query.value$;

  /**
   * @var {Observable<boolean>}
   */
  loading$: Observable<boolean> = this.query.pending$;

  /**
   * @var {string}
   */
  @Input()
  placeholder: string;

  /**
   * @var {any}
   */
  @Input()
  suggestions: any = [];

  filteredSuggestions: any[] | undefined;

  editedData: any;
  current: any;

  /**
   * @var {FormGroup}
   */
  languageItemForm: FormGroup = new FormGroup({
    value: new FormControl(null),
    rate: new FormControl(null),
  });

  /**
   * @var {any}
   */
  protected propagateChange: any = () => {};

  /**
   * Create a new instance.
   *
   * @param {DictionaryOfLanguagesQuery} query
   * @param {LanguageService} languagesService
   */
  constructor(private query: DictionaryOfLanguagesQuery, public languagesService: LanguageService) {
    super();
  }

  /**
   * Initialization.
   */
  ngOnInit() {
    this.query.execute();

    this.query.value$.subscribe((dictionaryItems) => {
      this.suggestions = dictionaryItems;

      this.updateSuggestions();
    });
  }

  /**
   * Focus on the input.
   *
   * @return void
   */
  focusin(): void {
    this.focused = false;
  }

  /**
   * Focusout of the input.
   *
   * @return void
   */
  focusout(): void {
    this.focused = false;
  }

  /**
   * Write a new value from the form model.
   *
   * @param {any} values
   * @return void
   */
  writeValue(values: any): void {
    this.values = values;

    this.formattedValues = this.languagesService.formattedValues(this.values)

    this.updateSuggestions();

    this.propagateChange(this.values);
  }

  /**
   * Register handler.
   *
   * @param {any} fn
   * @return void
   */
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  /**
   * Register handler.
   *
   * @param {any} fn
   * @return void
   */
  registerOnTouched(fn: any): void {}

  protected updateSuggestions()
  {
    if (this.formattedValues && this.formattedValues.length && this.suggestions) {
      this.formattedValues.forEach((languageValue) => {
        this.suggestions = this.suggestions.filter(item => item.id != languageValue.id);
      });
    }
  }

  filterSuggestions(event: AutoCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < (this.suggestions as any[]).length; i++) {
      let suggestion = (this.suggestions as any[])[i];

      if (suggestion.content.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(suggestion.content);
      }
    }

    this.filteredSuggestions = filtered;
  }

  setEditData(data) {
    this.editedData = data;
  }

  saveRate($event) {
    this.editedData.rate = $event.value;
    this.editedData.level = this.languagesService.getLevelByRate($event.value);

    this.values[this.editedData.id] = {
      level: this.editedData.level,
      key: this.editedData.key,
      value: this.editedData.value,
      rate: this.editedData.rate
    }

    this.writeValue(this.values);
  }

  delete(data) {
    delete this.values[data.id];

    this.writeValue(this.values);

    this.suggestions.push({
      id: data.id,
      key: data.key,
      content: data.value
    });

    this.updateSuggestions();
  }

  addData() {
    if (!this.languageItemForm.get('value').value) {
      this.languageItemForm.clear();
      return;
    }

    if (this.values == null || (isArray(this.values) && !this.values.length)) {
      this.values = {}
    }

    for (let i = 0; i < (this.suggestions as any[]).length; i++) {
      let suggestion = (this.suggestions as any[])[i];

      if (suggestion.content.toLowerCase().indexOf(this.languageItemForm.get('value').value.toLowerCase()) == 0) {
        this.values[suggestion.id] = {
          key: suggestion.key,
          value: this.languageItemForm.get('value').value,
          rate: this.languageItemForm.get('rate').value,
          level: this.languagesService.getLevelByRate(this.languageItemForm.get('rate').value)
        };

        this.writeValue(this.values);

        this.updateSuggestions();
      }
    }

    this.languageItemForm.clear();
  }

  protected readonly JSON = JSON;
}
