import { Component, ViewEncapsulation } from '@angular/core';

import { SnackBarItem } from './config';

@Component({
  templateUrl: './snack-bar-container.component.html',
  styleUrls: ['./snack-bar-container.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    tabindex: '-1',
    role: 'dialog',
    'aria-modal': 'true',
  },
})
export class SnackBarContainerComponent {
  /**
   * @var {SnackBarItem[]}
   */
  get items(): SnackBarItem[] {
    return this.stack;
  }

  /**
   * @var {SnackBarItem[]}
   */
  protected stack: SnackBarItem[] = [];

  /**
   * Create a new instance.
   */
  constructor() {
    //
  }

  /**
   * Add new item to the container.
   *
   * @param {SnackBarItem} item
   * @return void
   */
  push(item: SnackBarItem): void {
    this.stack.push(item);
  }

  /**
   * Remove an item at the given index
   *
   * @param {number} index
   * @return void
   */
  removeAt(index: number): void {
    this.stack.splice(index, 1);
  }

  /**
   * Close all items.
   *
   * @return void
   */
  closeAll(): void {
    this.stack = this.stack.filter(({ persistable }) => persistable);
  }
}
