export abstract class Control {
  /**
   * @var {boolean}
   */
  get canShowValidationMessage(): boolean {
    return false;
  }

  loading = false;
  /**
   * @var {boolean}
   */
  focused: boolean = false;

  /**
   * @var {boolean}
   */
  disabled: boolean = false;

  /**
   * Focus on the input.
   *
   * @return void
   */
  focusin(): void {
    //
  }

  /**
   * Focusout of the input.
   *
   * @return void
   */
  focusout(): void {
    //
  }

  load(){
    return this.loading = true;
  }
}
