import { Exception } from './exception';

export class FileUploadUnsupportedException extends Exception {
  /**
   * @var {string}
   */
  static readonly type = 'FileUploadUnsupportedException';

  /**
   * @var {string}
   */
  public readonly type = FileUploadUnsupportedException.type;
}
