import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[a3l-ui-popover-position-origin]',
})
export class PopoverPositionOriginDirective {
  /**
   * Create a new instance.
   *
   * @param {ElementRef} elementRef
   */
  constructor(public elementRef: ElementRef) {
    //
  }
}
