import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'rex-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
  host: {
    class: 'rex-logo',
    '[class.rex-logo--short]': 'short',
    '[class.rex-logo--small]': 'small',
  },
  encapsulation: ViewEncapsulation.None,
})
export class LogoComponent {
  /**
   * @var {boolean}
   */
  @Input()
  get short(): boolean {
    return this._short;
  }
  set short(value: boolean) {
    this._short = value !== false;
  }

  /**
   * @var {boolean}
   */
  protected _short: boolean = false;


  /**
   * @var {boolean}
   */
  @Input()
  get small(): boolean {
    return this._small;
  }
  set small(value: boolean) {
    this._small = value !== false;
  }

  /**
   * @var {boolean}
   */
  protected _small: boolean = false;

  /**
   * Create a new instance.
   */
  constructor() {
    //
  }
}
