import { Exception } from '@a3l/core';

export class AccountIsBlockedException extends Exception {
  /**
   * @var {string}
   */
  static readonly type = 'AccountIsBlockedException';

  /**
   * @var {string}
   */
  public readonly type = AccountIsBlockedException.type;
}
