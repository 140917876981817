import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { Button } from './button';
import { ButtonSpinner } from './button-spinner.directive';

import { ClickStopDirective } from './click-stop.directive';
import { ClickPreventDirective } from './click-prevent.directive';

const PUBLIC_API = [Button, ButtonSpinner, ClickStopDirective, ClickPreventDirective];

@NgModule({
  declarations: [...PUBLIC_API],
  imports: [CommonModule],
  exports: [...PUBLIC_API],
})
export class ButtonModule {}
