import { Directive, Input, HostListener, Optional } from '@angular/core';

import { Filterer } from './filterer';

@Directive({
  selector: '[a3l-dataset-filterer-trigger]',
  host: {
    class: 'a3l-dataset-filterer-trigger',
  },
})
export class DatasetFiltererTrigger {
  /**
   * @var {string}
   */
  @Input('a3l-dataset-filterer-trigger')
  key: string = null;

  /**
   * @var {any}
   */
  @Input('a3l-dataset-filterer-trigger-value')
  value: any = null;

  /**
   * Create a new instance.
   *
   * @param {Filterer} filterer
   */
  constructor(@Optional() private filterer: Filterer) {
    //
  }

  /**
   * Handle the click event.
   *
   * @param {MouseEvent} event
   * @return void
   */
  @HostListener('click', ['$event'])
  onDOMClick(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();

    let value = this.value

    if (!Array.isArray(this.value)) {
      value = isNaN(this.value) ? this.value : +this.value;
    }

    this.filterer.add(this.key, value);
  }
}
