import { CookieDB } from '@a3l/core';
import { Injectable } from '@angular/core';

import { Token } from './token';

@Injectable({ providedIn: 'root' })
export class AccessTokenService {
  /**
   * @var {string}
   */
  protected cookieName: string = 'access_token';

  protected parentCookieName: string = 'parent_access_token';

  /**
   * Create a new instance.
   */
  constructor() {
    //
  }

  /**
   * Get the token's value.
   *
   * @return {Token}
   */
  get(): Token {
    const value = CookieDB.get(this.cookieName);

    return new Token(value);
  }

  /**
   * Get the parent token's value.
   *
   * @return {Token}
   */
  getParent(): Token {
    const value = CookieDB.get(this.parentCookieName);

    return new Token(value);
  }

  /**
   * Returns whether the token exists.
   *
   * @return boolean
   */
  has(): boolean {
    const token = this.get();

    return token.exists();
  }

  /**
   * Returns whether the parent token exists.
   *
   * @return boolean
   */
  hasParent(): boolean {
    const token = this.getParent();

    return token.exists();
  }

  /**
   * Set the token value.
   *
   * @param {string} value
   * @return {void}
   */
  set(value: string) {
    CookieDB.put(this.cookieName, value);
  }

  /**
   * Set the token value for parent.
   *
   * @param {string} value
   * @return {void}
   */
  setParent(value: string) {
    CookieDB.put(this.parentCookieName, value);
  }

  /**
   * Remove the token.
   *
   * @return {void}
   */
  clear(): void {
    CookieDB.forget(this.cookieName);
  }

  /**
   * Remove the token.
   *
   * @return {void}
   */
  clearParent(): void {
    CookieDB.forget(this.parentCookieName);
  }

  /**
   * Remove all tokens.
   *
   * @return {void}
   */
  clearAll(): void {
    CookieDB.forget(this.cookieName);
    CookieDB.forget(this.parentCookieName);
  }

}
