import { Component, ViewChild, EventEmitter, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { animate, state, style, transition, trigger, AnimationEvent } from '@angular/animations';

import { ComponentPortal, CdkPortalOutlet } from '@angular/cdk/portal';
import { Subject } from 'rxjs';

import { DialogConfig, DialogSize } from './config';

@Component({
  templateUrl: './dialog-container.component.html',
  styleUrls: ['./dialog-container.component.scss'],
  animations: [
    trigger('state', [
      state('enter', style({ transform: 'none', opacity: 1 })),
      state('void', style({ transform: 'translate3d(0, 25%, 0) scale(0.9)', opacity: 0 })),
      state('exit', style({ transform: 'translate3d(0, 25%, 0)  scale(0.9)', opacity: 0 })),
      transition('* => enter', animate('400ms cubic-bezier(0.25, 0.8, 0.25, 1)')),
      transition('* => exit', animate('200ms cubic-bezier(0.25, 0.8, 0.25, 1)')),
    ]),
  ],
  encapsulation: ViewEncapsulation.None,
  host: {
    '[class]': "'a3l-ui-dialog a3l-ui-dialog--' + size + ' ' + classname",
    tabindex: '-1',
    role: 'dialog',
    'aria-modal': 'true',
    '[@state]': 'state',
    '(@state.start)': 'onAnimationStart($event)',
    '(@state.done)': 'onAnimationDone($event)',
  },
})
export class DialogContainerComponent {
  /**
   * @var {CdkPortalOutlet}
   */
  @ViewChild(CdkPortalOutlet, { static: true })
  portalOutlet: CdkPortalOutlet;

  /**
   * @var {DialogConfig}
   */
  config: DialogConfig = {};

  /**
   * @var {Subject}
   */
  onClose = new Subject();

  /**
   * @var {string}
   */
  state: 'void' | 'enter' | 'exit' = 'enter';

  /**
   * @var {EventEmitter<AnimationEvent>}
   */
  animationStateChanged: EventEmitter<AnimationEvent> = new EventEmitter<AnimationEvent>();

  /**
   * @var {boolean}
   */
  locked: boolean = false;

  /**
   * @var {DialogSize}
   */
  get size(): DialogSize {
    return this.config.size || DialogSize.Default;
  }

  /**
   * @var {string}
   */
  get classname(): string {
    return this.config.class || '';
  }

  /**
   * @var {boolean}
   */
  get showCloseButton(): boolean {
    return this.config.showCloseButton;
  }

  /**
   * @var {boolean}
   */
  set showCloseButton(show: boolean) {
    this.config.showCloseButton = show;
  }


  /**
   * Create a new instance.
   *
   * @param {ChangeDetectorRef} changeDetectorRef
   */
  constructor(private changeDetectorRef: ChangeDetectorRef) {
    //
  }

  /**
   * Attach a ComponentPortal as content to this dialog container.
   *
   * @return {ComponentPortal<T>} portal
   * @return ComponentRef<any>
   */
  attachComponentPortal<T>(portal: ComponentPortal<T>) {
    return this.portalOutlet.attachComponentPortal(portal);
  }

  /**
   * Callback, invoked when an animation on the host starts.
   *
   * @return {AnimationEvent} event
   * @return void
   */
  onAnimationStart(event: AnimationEvent) {
    this.animationStateChanged.emit(event);
  }

  /**
   * Callback, invoked whenever an animation on the host completes.
   *
   * @return {AnimationEvent} event
   * @return void
   */
  onAnimationDone(event: AnimationEvent) {
    this.animationStateChanged.emit(event);
  }

  /**
   * Starts the dialog exit animation.
   *
   * @return void
   */
  startExitAnimation() {
    this.state = 'exit';

    this.changeDetectorRef.markForCheck();
  }

  /**
   * Close the dialog.
   *
   * @return void
   */
  close() {
    this.onClose.next();
  }
}
