import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AccessTokenService } from './access-token.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  /**
   * Create a new instance.
   *
   * @param {AccessTokenService} accessTokenService
   */
  constructor(private accessTokenService: AccessTokenService) {
    //
  }

  /**
   * Intercept the http request.
   *
   * @param {HttpRequest<any>} request
   * @param {HttpHandler} next
   * @return {Observable<HttpEvent<any>>}
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.accessTokenService.get();

    if (token.exists()) {
      request = request.clone({
        headers: request.headers.set('Authorization', `Bearer ${token.get()}`),
      });
    }

    return next.handle(request);
  }
}
