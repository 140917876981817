import { Event } from '@a3l/core';

export class ClearSearchEvent extends Event {
  /**
   * @var {string}
   */
  static readonly type = 'ClearSearchEvent';

  /**
   * @var {string}
   */
  public readonly type = ClearSearchEvent.type;
}
