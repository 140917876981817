import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SegmentComponent, SegmentButtonComponent } from './segment';

const PUBLIC_API = [SegmentComponent, SegmentButtonComponent];

@NgModule({
  declarations: [...PUBLIC_API],
  imports: [CommonModule],
  exports: [...PUBLIC_API],
})
export class SegmentModule {}
