export const uuid = () => {
  return 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;

    return v.toString(16);
  });
};

export const nextTick = (callback, minOrTimeout = 0, max = 0) => {
  if (max != 0) {
    return setTimeout(callback, Math.floor(Math.random() * (max - minOrTimeout) + minOrTimeout));
  }

  return setTimeout(callback, minOrTimeout);
};
