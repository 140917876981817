<ng-container *ngIf="total > 1">
  <button type="button" class="a3l-paginator__arrow" (click)="page = page - 1" [disabled]="page === 1">
    <svg width="11px" height="6px" viewBox="0 0 11 6" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <g id="[VD]-On-boarding" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="on-boarding--step-2-2" transform="translate(-600.000000, -1649.000000)" fill="#A6A6AD" fill-rule="nonzero">
          <g id="main" transform="translate(448.000000, 189.000000)">
            <g id="btn" transform="translate(152.000000, 1454.000000)">
              <path
                d="M2.98917749,12 C3.22753423,12 3.44133902,11.9064604 3.60247114,11.7453283 C3.75895682,11.5888426 3.85714286,11.3784439 3.85714286,11.1536797 L3.84914535,11.0426777 C3.82279474,10.8602801 3.73287587,10.6924358 3.60247114,10.562031 L2.8979697,9.858 L9.85064935,9.85714286 C10.3215969,9.85714286 10.6969697,9.48177008 10.6969697,9.01082251 L10.6904336,8.90396625 C10.6386545,8.48434642 10.2853702,8.16450216 9.85064935,8.16450216 L2.8979697,8.165 L3.60247114,7.459614 C3.9232576,7.08762615 3.9232576,6.57545817 3.60247114,6.25467172 C3.37511309,6.07787162 3.19712083,6 2.98917749,6 L2.88739117,6.00464338 C2.68788165,6.02342694 2.51415648,6.10045181 2.39151731,6.2584051 L2.3449697,6.33 L0.254671718,8.41917388 C0.09818604,8.57565956 1.16351373e-13,8.78605822 1.16351373e-13,9.01082251 C1.16351373e-13,9.2355868 0.09818604,9.44598546 0.254671718,9.60247114 L2.39752886,11.7453283 C2.55401454,11.901814 2.7644132,12 2.98917749,12 Z"
                id="arrow-right"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </button>
  <div class="a3l-paginator-presenter">
    <input type="number" [value]="page" (input)="update($event.target.value)" class="a3l-paginator-presenter__input" />
    <span>/ {{ total }}</span>
  </div>
  <button type="button" class="a3l-paginator__arrow" (click)="page = page + 1" [disabled]="page === total">
    <svg width="11px" height="6px" viewBox="0 0 18 10" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <g id="[VD]-On-boarding" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="on-boarding--step-2-2" transform="translate(-905.000000, -1571.000000)" fill="#1D1D1F" fill-rule="nonzero">
          <g id="main" transform="translate(448.000000, 189.000000)">
            <g id="input-copy-2" transform="translate(0.000000, 1357.000000)">
              <path
                d="M469.84632,25 C469.449059,25 469.092718,25.1558993 468.824164,25.4244529 C468.563355,25.6852623 468.399711,26.0359268 468.399711,26.4105339 L468.413041,26.5955371 C468.456958,26.8995332 468.606823,27.1792737 468.824164,27.396615 L469.998333,28.57 L458.410534,28.5714286 C457.625621,28.5714286 457,29.1970499 457,29.9819625 L457.010893,30.1600562 C457.097192,30.8594226 457.685999,31.3924964 458.410534,31.3924964 L469.998333,31.3916667 L468.824164,32.56731 C468.28952,33.1872897 468.28952,34.040903 468.824164,34.5755471 C469.203094,34.870214 469.499748,35 469.84632,35 L470.015964,34.992261 C470.34848,34.9609551 470.638022,34.8325803 470.842421,34.5693248 L470.92,34.45 L474.40383,30.9680435 C474.664639,30.7072341 474.828283,30.3565696 474.828283,29.9819625 C474.828283,29.6073553 474.664639,29.2566909 474.40383,28.9958814 L470.832401,25.4244529 C470.571592,25.1636434 470.220928,25 469.84632,25 Z"
                id="arrow-right"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </button>
</ng-container>
