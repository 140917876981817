<div class="a3l-ui-selectpicker-input">
  <div class="a3l-ui-selectpicker-input__control">
    <input type="text" [placeholder]="placeholder" a3l-ui-input (focus)="open()" (blur)="close()" [(ngModel)]="query" (ngModelChange)="initKeyManager()" />
  </div>
  <div *ngIf="empty; else elseBlock">
    <div class="a3l-ui-selectpicker-input__icon" *ngIf="!locked">
      <a3l-ui-icon icon="chevron-down" size="8"></a3l-ui-icon>
    </div>
  </div>
  <ng-template #elseBlock>
    <div class="a3l-ui-selectpicker-input__reset" (click)="resetInput()" *ngIf="!locked">
      <div class="a3l-ui-selectpicker-input__icon">
        <a3l-ui-icon *ngIf="!emptyQuery" icon="cross" size="8"></a3l-ui-icon>
        <a3l-ui-icon *ngIf="emptyQuery" icon="chevron-down" size="8"></a3l-ui-icon>
      </div>
    </div>
  </ng-template>
</div>

<ng-template #pane>
  <ul class="a3l-ui-selectpicker-panel" [@panel]="state" #panel>
    <li
      class="a3l-ui-selectpicker-panel__option"
      [class]="{ 'a3l-ui-selectpicker-panel__option--active': option.active }"
      (mousedown)="select(option); $event.preventDefault(); $event.stopPropagation()"
      *ngFor="let option of options"
    >
      {{ option.label }}
    </li>
  </ul>
</ng-template>

<ng-template>
  <ng-content></ng-content>
</ng-template>
