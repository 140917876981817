import { Component, ViewChild, ViewEncapsulation, HostListener, forwardRef, Input, ElementRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { FileUploadMaxSizeException, FileUploadUnsupportedException } from '@a3l/core';

export interface UploadedFile {
  id?: string;
  name: string;
  size: number;
  extension: string;
  file?: any;
}

export const extension = (file) => /(?:\.([^.]+))?$/.exec(file)[1];

export const filename = (file) => file.replace(new RegExp(`.${extension(file)}$$`), '');

@Component({
  selector: 'a3l-ui-imageupload',
  templateUrl: './imageupload.component.html',
  styleUrls: ['./imageupload.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'a3l-ui-imageupload',
    '[class.a3l-ui-imageupload--dragover]': 'dragging',
    '[class.a3l-ui-imageupload--with-preview]': 'src',
  },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ImageuploadComponent),
      multi: true,
    },
  ],
})
export class ImageuploadComponent implements ControlValueAccessor {
  /**
   * @var {string}
   */
  @Input()
  src: string;

  /**
   * @var {string}
   */
  @Input()
  accepts: string = '';

  /**
   * @var {number}
   */
  @Input('max-file-size-in-mb')
  maxFileSizeInMB: number = null;

  /**
   * @var {any}
   */
  value: any;

  /**
   * @var {boolean}
   */
  dragging: boolean = false;

  /**
   * @var {ElementRef}
   */
  @ViewChild('input', { static: false })
  protected input: ElementRef;

  /**
   * @var {any}
   */
  protected propagateChange: any = () => {};

  /**
   * Create a new instance.
   */
  constructor() {
    //
  }

  /**
   * Open the system explorer.
   *
   * @param {MouseEvent} event
   * @return void
   */
  @HostListener('click', ['$event'])
  protected explorer(event: MouseEvent): void {
    event.preventDefault();

    this.input.nativeElement.click();
  }

  /**
   * Handle the 'dragover' event.
   *
   * @param {MouseEvent} event
   * @return void
   */
  @HostListener('dragover', ['$event'])
  protected onDOMDragover(event: MouseEvent): void {
    event.preventDefault();

    this.dragging = true;
  }

  /**
   * Handle the 'dragleave' event.
   *
   * @param {MouseEvent} event
   * @return void
   */
  @HostListener('dragleave', ['$event'])
  protected onDOMDragleave(event: MouseEvent): void {
    event.preventDefault();

    this.dragging = false;
  }

  /**
   * Handle the 'drop' event.
   *
   * @param {DragEvent} event
   * @return void
   */
  @HostListener('drop', ['$event'])
  protected byDragEvent(event: DragEvent): void {
    event.preventDefault();

    this.dragging = false;

    Array.from(event.dataTransfer.files).forEach((file: File) => this.add(file));
  }

  /**
   * Handle file selection.
   *
   * @param {Event} event
   * @return void
   */
  byExplorer(event: Event): void {
    Array.from(this.input.nativeElement.files).forEach((file: File) => this.add(file));
  }

  /**
   * Add the file to the value.
   *
   * @param {File} file
   * @return void
   */
  add(file: File): void {
    const { name, size } = file;

    if (this.maxFileSizeInMB && size / 1024 / 1024 > this.maxFileSizeInMB) throw new FileUploadMaxSizeException();

    const ext = extension(name).toLowerCase();

    const accepts = this.accepts.split(',').filter((i) => i);
    if (accepts.length != 0 && accepts.indexOf(ext) == -1) {
      throw new FileUploadUnsupportedException();
    }

    const uploaded: UploadedFile = { name: filename(name), extension: ext, size, file };

    const reader = new FileReader();
    reader.onload = () => (this.src = reader.result as string);
    reader.readAsDataURL(file);

    this.propagateChange((this.value = uploaded));
  }

  /**
   * Clear all files.
   *
   * @return void
   */
  clear(): void {
    this.src = null;

    this.propagateChange((this.value = null));
  }

  /**
   * Write a new value from the form model.
   *
   * @param {any} value
   * @return void
   */
  writeValue(value: any): void {
    this.value = value;
  }

  /**
   * Register handler.
   *
   * @param {any} fn
   * @return void
   */
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  /**
   * Register handler.
   *
   * @param {any} fn
   * @return void
   */
  registerOnTouched(fn: any): void {}
}
