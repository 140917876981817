import {Pipe, PipeTransform} from '@angular/core';

@Pipe({ name: 'a3lMapBy' })
export class MapByPipe implements PipeTransform {
  /**
   * Transform the given array.
   *
   * @param {any} values
   * @param {string} property
   * @return string
   */
  transform(values: any, property: string): any[] {
    if (typeof(values) == 'object') {
      let valuesArray = Object.keys(values).map(function(personNamedIndex){
        return values[personNamedIndex];
      });

      return valuesArray.map((value) => value[property]);
    }

    return values.map((value) => value[property]);
  }
}
