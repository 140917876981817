import {LocalizedContentField} from "./localized-content-field";

export class LocalizedContent {

  constructor(
    public fields: LocalizedContentField[],
  ) {
  }

  public toString(): string {
    return JSON.stringify(this.fields);
  }

  getContentForLang(lang: string) {
    let element = this.fields.find(field => field.language_code == lang);
    if(!element) {
      return '';
    }
    if(element.content == null) {
      return '';
    }

    return element.content;
  }

  setContentForLang(lang: string, content: string) {
    let existingElement = this.fields.indexOf(this.fields.find(field => field.language_code == lang));
    let newField = new LocalizedContentField(lang, content);
    if(existingElement != -1) {
      this.fields[existingElement] = newField;
    } else {
      this.fields.push(newField);
    }
  }
}
