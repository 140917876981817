<p-calendar
  class="a3l-datetime"
  [placeholder]="placeholder"
  [(ngModel)]="value"
  [showIcon]="true"
  [icon]="'a3l-ui-icon-calendar'"
  (onFocus)="focusin()"
  (onClose)="focusout()"
  (onClickOutside)="focusout()"
  (onSelect)="writeValue($event)"
  (onInput)="writeInputValue()"
  [showTime]="showTime"
  firstDayOfWeek="1"
  dateFormat="dd/mm/yy"
></p-calendar>
