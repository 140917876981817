import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';

import { CookieDB } from '@a3l/core';

import { LanguagesQuery } from './languages.query';

import { LanguageSwitcherCommand } from "@rex/common/language.switcher.command";

import { AuthuserQuery } from "@rex/auth";

@Component({
  selector: 'rex-language-switcher',
  templateUrl: './language.switcher.html',
  styleUrls: ['./language.switcher.scss'],
  host: {
    class: 'rex-language-switcher',
  },
  encapsulation: ViewEncapsulation.None,
  providers: [LanguageSwitcherCommand],
})
export class LanguageSwitcher implements OnInit, OnDestroy {
  /**
   * @var {string}
   */
  code: string = this.translator.currentLang;

  /**
   * @var {Observable<any>}
   */
  readonly languages$: Observable<any> = this.query.value$;

  /**
   * @var {Observable<any>}
   */
  protected readonly authuser$: Observable<any> = this.authuser.value$;

  protected isAuthUser: boolean = false;

  /**
   * @var {Subscription}
   */
  protected subscription: Subscription = Subscription.EMPTY;

  /**
   * Create a new instance.
   *
   * @param {LanguagesQuery} query
   * @param {AuthuserQuery} authuser
   * @param {TranslateService} translator
   * @param {LanguageSwitcherCommand} command
   */
  constructor(private query: LanguagesQuery, private authuser: AuthuserQuery, private translator: TranslateService, private command: LanguageSwitcherCommand) {
    //
  }

  /**
   * Initialization.
   */
  ngOnInit() {
    this.query.execute();

    this.subscription = this.translator.onLangChange.subscribe(({ lang }) => (this.code = lang));

    this.authuser$.subscribe((user) => { this.isAuthUser = !!user });
  }

  /**
   * Handle the action.
   *
   * @param {string} language
   * @return void
   */
  process(language: string): void {
    if (this.code == language) return;

    CookieDB.put('language', language);

    if (this.isAuthUser) {
      this.command.execute(language);
    } else {
      window.location.reload();
    }
  }

  /**
   * Cleanup.
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
