import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'a3lOrder' })
export class OrderPipe implements PipeTransform {
  /**
   * Order given items by field.
   *
   * @param {any[]} items
   * @param {'asc' | 'desc'} direction
   * @return any[]
   */
  transform(items: any[], direction: 'asc' | 'desc' = 'asc'): any[] {
    if (!items) return [];

    items.sort((a, b) => (a > b ? 1 : -1) * (direction == 'asc' ? 1 : -1));

    return items;
  }
}
