import {Component, Input, OnInit, ViewChild} from "@angular/core";
import {GroupableDirective} from "./GroupableDirective";
import {OrganizationGroupableComponent} from "@rex/core/organization/organization-groupable.component";
import {GroupableComponentInterface} from "./GroupableComponentInterface";
import {OrderRequestGroupableComponent} from "@rex/shared/order-requests/dataset/order-request-groupable.component";
import {ClientCompanyGroupableComponent} from "@rex/client-companies/datasets/client-company-groupable.component";


@Component({
  selector: 'groupable-factory',
  template: `<ng-template groupableDirective></ng-template>`
})
export class GroupableFactory implements OnInit {
  @Input() dataset: string;
  @Input() item: any;
  @Input() column: any;
  @Input() view: any;
  @Input() entity: any;

  @ViewChild(GroupableDirective, {static: true}) groupableDirective!: GroupableDirective;

  protected groupableComponents = {
    organization: OrganizationGroupableComponent,
    order_request: OrderRequestGroupableComponent,
    client_company: ClientCompanyGroupableComponent
  };

  ngOnInit(): void {
    this.loadComponent();
  }

  loadComponent() {
    const viewContainerRef = this.groupableDirective.viewContainerRef;
    viewContainerRef.clear();
    let componentRef;
    if(this.dataset == 'recruiters') {
      componentRef = viewContainerRef.createComponent<GroupableComponentInterface>(this.groupableComponents.organization);
    }
    if(this.dataset == 'client_company_recruiters') {
      componentRef = viewContainerRef.createComponent<GroupableComponentInterface>(this.groupableComponents.organization);
    }

    if(this.dataset == 'recruitment_recruiters') {
      componentRef = viewContainerRef.createComponent<GroupableComponentInterface>(this.groupableComponents.organization);
    }

    if(this.dataset == 'order_requests') {
      componentRef = viewContainerRef.createComponent<GroupableComponentInterface>(this.groupableComponents.order_request);
    }
    if(this.dataset == 'client_companies') {
      componentRef = viewContainerRef.createComponent<GroupableComponentInterface>(this.groupableComponents.client_company);
    }
    componentRef.instance.item = this.item;
    componentRef.instance.column = this.column;
    componentRef.instance.view = this.view;
    componentRef.instance.entity = this.entity;
  }

}
