import {Directive, HostListener, Input} from '@angular/core';
import {Clipboard} from "@angular/cdk/clipboard";
import {SnackBar, SnackBarType} from "@a3l/utilities";

@Directive({ selector: '[rex-clipboard]' })
export class ClipboardDirective {
  /**
   * @var {any}
   */
  @Input('rex-clipboard')
  link: any;

  @Input('rex-clipboard-message')
  message: any;


  constructor(private clipboard: Clipboard, private snackbar: SnackBar) {
    //
  }

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent): void {
    this.clipboard.copy(this.link)

    if(this.message) {
      this.snackbar.open(this.message, SnackBarType.Success)
    }
  }
}
