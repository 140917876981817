import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'a3l-ui-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    '[class]': "'a3l-ui-icon a3l-ui-icon-' + icon",
    '[style.fontSize.px]': 'size',
  },
})
export class IconComponent {
  /**
   * @var {string}
   */
  @Input()
  icon: string;

  /**
   * @var {number}
   */
  @Input()
  size: number;

  /**
   * Create a new instance.
   */
  constructor() {
    //
  }
}
