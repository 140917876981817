import { Component, OnInit, ContentChild, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { LayoutSidenavItemHandler } from './layout-sidenav-item-handler';
import { LayoutSidenavItemSubmenu } from './layout-sidenav-item-submenu';

@Component({
  selector: '[a3l-layout-sidenav-item]',
  template: '<ng-content></ng-content>',
  host: {
    class: 'a3l-layout-sidenav-item',
    '[class.a3l-layout-sidenav-item--with-submenu]': 'submenu',
  },
})
export class LayoutSidenavItem implements OnInit, OnDestroy {
  /**
   * @var {LayoutSidenavItemHandler}
   */
  @ContentChild(LayoutSidenavItemHandler, { static: true })
  handler: LayoutSidenavItemHandler;

  /**
   * @var {LayoutSidenavItemSubmenu}
   */
  @ContentChild(LayoutSidenavItemSubmenu, { static: true })
  submenu: LayoutSidenavItemSubmenu;

  /**
   * @var {Subscription}
   */
  protected subscription: Subscription = Subscription.EMPTY;

  /**
   * Create a new instance.
   */
  constructor() {
    //
  }

  /**
   * Initialization.
   */
  ngOnInit() {
    if (!this.submenu) return;

    this.subscription = this.handler.change.subscribe(() => this.submenu.toggle());
  }

  /**
   * Cleanup.
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
