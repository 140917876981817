import { createReducer, on } from '@ngrx/store';

import { LoggedInUser } from './actions';

import { INITIAL_STATE } from './state';

export const reducer = createReducer(
  INITIAL_STATE,
  on(LoggedInUser.loaded, (state, { user }) => ({ ...state, entity: user })),
  on(LoggedInUser.clear, (state) => ({ ...state, entity: null }))
);
