import { Directive, TemplateRef, ViewContainerRef, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';

import { AuthuserQuery } from './authuser.query';

@Directive({ selector: '[rex-auth-not-logged-in]' })
export class NotLoggedInDirective implements OnInit, OnDestroy {
  /**
   * @var {boolean}
   */
  protected embedded: boolean = false;

  /**
   * @var {Subscription}
   */
  protected subscription: Subscription = Subscription.EMPTY;

  /**
   * Create a new instance.
   *
   * @param {AuthuserQuery} query
   * @param {TemplateRef} templateRef
   * @param {ViewContainerRef} viewContainer
   */
  constructor(private query: AuthuserQuery, private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef) {
    //
  }

  /**
   * Add the template content to the DOM.
   */
  ngOnInit() {
    this.subscription = this.query.value$.subscribe((user) => {
      if (!user && !this.embedded) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.embedded = true;
      } else if (user && this.embedded) {
        this.viewContainer.clear();
        this.embedded = false;
      }
    });
  }

  /**
   * Cleanup.
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
