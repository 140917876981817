// @dynamic
export class ClipboardDB {
  /**
   * Copy the given value to the user's system clipboard.
   *
   * @param {string} value
   * @return Promise
   */
  static copy(value: string): Promise<string> {
    return new Promise((resolve) => {
      let textarea = null;

      try {
        textarea = window.document.createElement('textarea');
        textarea.style.height = '0px';
        textarea.style.left = '-100px';
        textarea.style.opacity = '0';
        textarea.style.position = 'fixed';
        textarea.style.top = '-100px';
        textarea.style.width = '0px';

        window.document.body.appendChild(textarea);

        textarea.value = value;
        textarea.select();

        window.document.execCommand('copy');

        resolve(value);
      } finally {
        if (textarea && textarea.parentNode) {
          textarea.parentNode.removeChild(textarea);
        }
      }
    });
  }
}
