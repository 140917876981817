<form>
  <p-autoComplete
    a3l-ui-input
    class="text-input w-full"
    type="text"
    [ngModelOptions]="{standalone: true}"
    *ngIf="industriesDictionary"
    [(ngModel)]="value.value"
    (change)="updateValue($event)"
    [placeholder]="'Wpisz' | translate"
    [suggestions]="filteredSuggestions"
    (completeMethod)="filterSuggestions($event)"
  >
  </p-autoComplete>
</form>
