import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize} from 'rxjs/operators';
import {Command, CookieDB} from '@a3l/core';
import { ApiService } from '@a3l/api';

@Injectable({ providedIn: 'root' })
export class TranslateCommand extends Command<any> {
  /**
   * Create a new instance.
   *
   * @param {ApiService} api
   */
  constructor(private api: ApiService) {
    super();
  }

  /**
   * Run the command.
   *
   * @param {string} language
   * @return Observable<string>
   */
  run(fromLang: string, toLang: string, content: string): Observable<any> {
    return this.api.post(`/translate`, {
      fromLang: fromLang,
      toLang: toLang,
      content: content
    })
  }

}
