<div class="mb-2 flex items-center justify-between">
  <label class="mb-0" a3l-ui-label translate>Hasło</label>
  <button type="button" class="as-link text-gray text-sm hover:text-primary" tabindex="-1" a3l-ui-form-group-on-focusout (click)="generate(); $event.stopPropagation()" translate>
    passwords.generate
  </button>
</div>
<input [type]="isPasswordVisible ? 'text': 'password'" a3l-ui-input [placeholder]="'Wpisz' | translate" [(ngModel)]="value" (ngModelChange)="valueChanged.next($event)" (input)="writeValue(value)" (focus)="focused = true" (blur)="focused = false"/>
<div class="pr-4 absolute top-0 right-0 h-full flex items-center" a3l-ui-form-group-on-focus>
  <button type="button" a3l-ui-button icon (mousedown)="isPasswordVisible = !isPasswordVisible; $event.preventDefault()">
    <a3l-ui-icon [icon]="isPasswordVisible ? 'eye-hidden' : 'eye'"></a3l-ui-icon>
  </button>
</div>

