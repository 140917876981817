import {
  Component,
  Input, OnInit,
} from "@angular/core";
import {LanguageSwitcherCommand} from "@rex/common/language.switcher.command";
import {TranslateService} from "@ngx-translate/core";
import {AutoTranslateLanguagesHelperDialog} from "./dialog/auto-translate-languages-helper.dialog";

@Component({
  selector: 'localized-language-label',
  templateUrl: './localized-language-label.component.html',
  styleUrls: ['./localized-language-label.component.scss'],
})
export class LocalizedLanguageLabelComponent implements OnInit {
  /**
   * @var {string}
   */
  @Input()
  code: string;


  @Input()
  disabled: boolean;

  @Input()
  tooltipActive = true;

  /**
   * Create a new instance.
   *
   * @param {TranslateService} translator
   * @param {LanguageSwitcherCommand} command
   */
  constructor(private translator: TranslateService) {
  }

  ngOnInit() {
    if(this.code == null) {
      this.code = this.translator.currentLang;
    }
  }

  public readonly LanguagesHelperDialog = AutoTranslateLanguagesHelperDialog;
}
