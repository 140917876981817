import { Component, ContentChild, Input, ViewEncapsulation } from '@angular/core';

import { Store } from './store';

import { DatasetLayout } from './dataset-layout';

import { DatasetTablePresenter } from './dataset-table.presenter';

@Component({
  selector: 'a3l-dataset',
  templateUrl: './dataset.component.html',
  styleUrls: ['./dataset.component.scss'],
  host: {
    class: 'a3l-dataset',
  },
  encapsulation: ViewEncapsulation.None,
  providers: [Store],
})
export class DatasetComponent {
  /**
   * @var {any[]}
   */
  @Input()
  set dataset(value: any[]) {
    //if groupable
    if(value instanceof Object) {
      value = Object.values(value);
    }

    this.store.consume(value || []);
  }

  /**
   * @var {DatasetLayout}
   */
  @Input('datalayout')
  layout: DatasetLayout = 'table';

  /**
   * @var {DatasetTablePresenter}
   */
  @ContentChild(DatasetTablePresenter, { static: true })
  table: DatasetTablePresenter;

  /**
   * Create a new instance.
   *
   * @param {Store} store
   */
  constructor(private store: Store) {
    //
  }
}
