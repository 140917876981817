import {Control, nextTick} from "@a3l/utilities";
import {Component, EventEmitter, forwardRef, OnDestroy, OnInit, Output, ViewEncapsulation} from "@angular/core";
import {ControlValueAccessor, Form, FormGroup, NG_VALUE_ACCESSOR} from "@angular/forms";
import {Observable, Subscription} from "rxjs";
import {Dispatcher} from "@a3l/core";
import {LanguageSkillForm} from "@rex/common/language-skills/language-skill.form";
import {LanguageService} from "@rex/shared/services/language.service";
import {DictionaryOfLanguagesQuery} from "@rex/common/dictionary-of-languages.query";
import {CvParserCandidateLanguagesEvent} from "@rex/shared/recruitments/events/cv-parser-candidate-languages.event";
import {CvParserRecruitmentLanguagesEvent} from "@rex/shared/recruitments/events/cv-parser-recruitment-languages.event";

@Component({
  selector: 'rex-language-skills-field',
  templateUrl: 'language-skills.field.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LanguageSkillsField),
      multi: true,
    },
    { provide: Control, useExisting: LanguageSkillsField }
  ],
  encapsulation: ViewEncapsulation.None
})
export class LanguageSkillsField extends Control implements OnInit, OnDestroy, ControlValueAccessor {
  isValid = true;
  isLoading = false;

  value: any = [];

  @Output()
  formatedValue: EventEmitter<any> =  new EventEmitter<any>();

  suggestions: any = [];
  suggestionsPerForm: any = [];

  /**
   * @var {Observable<any[]>}
   */
  languagesDictionary$: Observable<any[]> = this.query.value$;

  public languagesDictionary: any;

  /**
   * @var {any}
   */
  protected propagateChange: any = () => {
    this.setAvailableSuggestions();
  };

  /**
   * @var {Subscription}
   */
  protected subscription: Subscription = Subscription.EMPTY;

  constructor(
    protected dispatcher: Dispatcher,
    protected languageSkillForm: LanguageSkillForm,
    public languagesService: LanguageService,
    private query: DictionaryOfLanguagesQuery
  ) {
    super();
  }

  addNew() {
    if (this.value.length) this.validate();

    if (!this.isValid) return;

    let newFormGroup = this.languageSkillForm.emptyForm();

    this.value.push(newFormGroup);

    this.setAvailableSuggestions();

    this.propagateChange(this.value);
  }

  validate()
  {
    this.value.forEach((formGroup: FormGroup) => {
      this.isValid = formGroup.validate();

      if (!this.isValid) return;
    });
  }

  removePosition(uuid) {
    this.value.splice(this.getItemIndexByUuid(uuid), 1);
    this.propagateChange(this.value)
    this.formatedValue.emit(this.languagesService.parseFormGroupToValue(this.value, this.suggestions));

    this.validate();
  }

  getItemIndexByUuid(uuid) {
    let foundIndex = undefined;
    this.value.forEach((item, index) => {
      if(item.controls['uuid'].value == uuid) {
        foundIndex = index;
      }
    });

    return foundIndex;
  }

  update($event) {
    if(!$event) return;
    this.value[this.getItemIndexByUuid($event.controls['uuid'].value)] = $event;

    this.formatedValue.emit(this.languagesService.parseFormGroupToValue(this.value, this.suggestions));

    this.setAvailableSuggestions();

    this.propagateChange(this.value)
  }

  identify(index, item)
  {
    return item.controls['uuid'].value;
  }

  ngOnInit() {
    this.query.execute();

    this.query.value$.subscribe((dictionaryItems) => {
      this.languagesDictionary = dictionaryItems;
      this.suggestions = dictionaryItems;
      this.setAvailableSuggestions();

      if(this.suggestions) {
        this.formatedValue.emit(this.languagesService.parseFormGroupToValue(this.value, this.suggestions));
      }
    });

    this.dispatcher.listen(CvParserCandidateLanguagesEvent).subscribe((values) => this.parse(values));
    this.dispatcher.listen(CvParserRecruitmentLanguagesEvent).subscribe((values) => {
      this.parse(values)
    });
  }

  parse ({value, disabled}) {
    if(value == null || value == undefined) return;

    this.query.value$.subscribe((dictionaryItems) => {
      this.languagesDictionary = dictionaryItems;
      this.suggestions = dictionaryItems;

      value.forEach((language) => {
        let newLanguage = this.languageSkillForm.formFromArray(language);
        if(newLanguage) this.value.push(newLanguage);
      });
      this.setAvailableSuggestions();

      this.propagateChange(this.value);
      this.formatedValue.emit(this.languagesService.parseFormGroupToValue(this.value, this.suggestions));

      disabled ? this.isLoading = true : this.isLoading = false;
    });
  }

  setAvailableSuggestions(): void
  {
    if(this.value == null || this.value == undefined) return;

    this.value.forEach((item: FormGroup) => {
      let availableSuggestions = this.suggestions;

      if(!availableSuggestions) {
        return;
      }
      let valuesToCheck = this.value.filter(value => item.get('uuid').value != value.get('uuid').value);

      valuesToCheck.forEach((value: FormGroup) => {
        availableSuggestions = availableSuggestions.filter(element => (element.id != value.get('id').value));
      })

      this.suggestionsPerForm[item.get('uuid').value] = availableSuggestions;
    });
  }

  /**
   * Write a new value from the form model.
   *
   * @param {any} value
   * @return void
   */
  writeValue(value: any): void {
    if (!value) return;
    this.value = value;
    if (!this.suggestions) return;

    this.formatedValue.emit(this.languagesService.parseFormGroupToValue(this.value, this.suggestions));
    this.setAvailableSuggestions();
  }

  /**
   * Register handler.
   *
   * @param {any} fn
   * @return void
   */
  registerOnChange(fn: any): void {
    this.setAvailableSuggestions();

    this.propagateChange = fn;
  }

  /**
   * Register handler.
   *
   * @param {any} fn
   * @return void
   */
  registerOnTouched(fn: any): void {}

  /**
   * Initialization.
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
