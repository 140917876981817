<div [ngClass]="{'a3l-ui-form-group__label': true,
                   'extra-line': labelExtraLine
   }">
  <ng-content select="[a3l-ui-label]"></ng-content>
</div>
<div class="a3l-ui-form-group-control">
  <div class="a3l-ui-form-group-control__wrapper">
    <ng-content></ng-content>
  </div>
  <div class="a3l-ui-form-group-control__addon" *ngIf="showLockIcon">
    <a3l-ui-icon icon="lock" *ngIf="locked"></a3l-ui-icon>
    <ng-content select="[a3l-ui-form-addon]" *ngIf="!locked"></ng-content>
  </div>

  <div class="a3l-ui-form-group-control__addon" *ngIf="loader">
    <a3l-ui-spinner size="20" *ngIf="loading"></a3l-ui-spinner>
    <ng-content select="[a3l-ui-form-addon]" *ngIf="!loading"></ng-content>
  </div>
</div>
<div class="a3l-ui-form-group-error" *ngIf="errorMessageWrapper && canShowValidationMessage && !shownErrorOnInput">
  <ng-template [ngTemplateOutlet]="errorMessageWrapper.templateRef" [ngTemplateOutletContext]="{ key: error.key, message: error.message, error: error }"></ng-template>
</div>
<div class="a3l-ui-form-group-error-in-input" *ngIf="errorMessageWrapper && canShowValidationMessage && shownErrorOnInput">
  <ng-template [ngTemplateOutlet]="errorMessageWrapper.templateRef" [ngTemplateOutletContext]="{ key: error.key, message: error.message, error: error }"></ng-template>
</div>
