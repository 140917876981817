import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: '[a3l-ui-scrollbar]',
  template: '<ng-content></ng-content>',
  styleUrls: ['./scrollbar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ScrollbarComponent {
  /**
   * Create a new instance.
   */
  constructor() {
    //
  }
}
