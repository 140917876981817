import {Event} from "@a3l/core";
export class SaveEvaluationAnswersEvent extends Event {
  /**
   * @var {string}
   */
  static readonly type = 'SaveEvaluationAnswersEvent';

  /**
   * @var {string}
   */
  public readonly type = SaveEvaluationAnswersEvent.type;

  /**
   * Create a new instance.
   *
   * @param {number} data
   */
  constructor(public data: { choices: any, id: any, type: any, choices_required: boolean}) {
    super();
  }
}
