import { Directive, EventEmitter, Output } from '@angular/core';

@Directive({ selector: '[a3l-dataset-sorter]' })
export class Sorter {
  /**
   * @var {EventEmitter<any>}
   */
  @Output()
  sortChange: EventEmitter<any> = new EventEmitter();

  /**
   * @var {string}
   */
  by: string;

  /**
   * @var {'asc' | 'desc'}
   */
  order: 'asc' | 'desc' = null;

  reversableOrder: boolean = false;

  /**
   * @var {boolean}
   */
  disableNullOption = false;

  /**
   * Create a new instance.
   */
  constructor() {
    //
  }

  /**
   * Set the active sort id and determine the new order.
   *
   * @param {string} by
   * @param {'asc' | 'desc'} order
   * @return void
   */
  sort(by: string, order: 'asc' | 'desc' = null, reversableOrder: boolean = false): void {
    if (this.order == null && order == null) order = 'asc';

    if (this.disableNullOption) {
      if (this.by == by && this.order != null && order == null) order = this.order == 'asc' ? 'desc' : 'asc';
    } else {
      if (this.by == by && this.order != null && order == null) order = this.order == 'asc' ? 'desc' : null;
    }

    this.by = by;
    this.order = order;
    this.reversableOrder = reversableOrder;

    this.sortChange.emit({ by: this.by, order: this.order, reversableOrder: reversableOrder });
  }

  /**
   * Clear the sort and order.
   *
   * @return void
   */
  clear(): void {
    this.by = null;
    this.order = null;

    this.sortChange.emit({ by: this.by, order: this.order });
  }
}
