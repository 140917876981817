import { Component, ContentChild } from '@angular/core';
import { Observable } from 'rxjs';

import { Store } from './store';

import { DatasetGridElement } from './dataset-grid-element';

@Component({
  selector: 'a3l-dataset-grid',
  templateUrl: './dataset-grid.presenter.html',
  host: {
    class: 'a3l-dataset-grid',
  },
})
export class DatasetGridPresenter {
  /**
   * @var {Observable<any[]>}
   */
  readonly dataset$: Observable<any[]> = this.store.state$;

  /**
   * @var {DatasetGridElement}
   */
  @ContentChild(DatasetGridElement, { static: true })
  element: DatasetGridElement;

  /**
   * Create a new instance.
   *
   * @param {Store} store
   */
  constructor(private store: Store) {
    //
  }
}
