import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

export interface State extends EntityState<any> {
  //
}

export const adapter: EntityAdapter<any> = createEntityAdapter<any>({
  sortComparer: false,
});

export const INITIAL_STATE: State = adapter.getInitialState({
  //
});
