<a3l-ui-dialog-header>
  <h2 translate>Zasięg lokalizacji</h2>
</a3l-ui-dialog-header>
<a3l-ui-dialog-content>
  <a3l-ui-form-group>
    <label a3l-ui-label translate>Lokalizacja</label>
    <input type="text" autofocus a3l-ui-input [placeholder]="'Wpisz' | translate" rex-candidate-wizard-localization-searcher (done)="consume($event)" />
  </a3l-ui-form-group>
  <div class="mt-6" *ngIf="localizations.length">
    <strong class="mb-2 block" translate>Wybierz zasięg lokalizacji</strong>
    <a3l-ui-form-group class="font-medium" plain>
      <a3l-ui-radio-group [(ngModel)]="type">
        <a3l-ui-radio-item outlined class="mb-2" [value]="localization.type" *ngFor="let localization of localizations">
          <div>
            <span class="mb-2 block text-sm text-gray" [ngSwitch]="localization.type">
              <span translate *ngSwitchCase="'city'">Miasto</span>
              <span translate *ngSwitchCase="'district'">Powiat</span>
              <span translate *ngSwitchCase="'voivodeship'">Województwo</span>
              <span translate *ngSwitchCase="'country'">Kraj</span>
            </span>
            <span class="block">{{ localization.name }}</span>
          </div>
        </a3l-ui-radio-item>
      </a3l-ui-radio-group>
    </a3l-ui-form-group>
  </div>
</a3l-ui-dialog-content>
<a3l-ui-dialog-footer class="text-center">
  <button class="w-full" type="button" a3l-ui-button color="primary" [disabled]="type == null" [processing]="processing" (click)="process()" translate>Zapisz</button>
  <button type="button" class="mt-7 as-link no-underline text-gray text-sm hover:text-primary" (click)="close()" translate>Anuluj</button>
</a3l-ui-dialog-footer>
