import {
  AfterContentChecked, ChangeDetectorRef,
  Component, EventEmitter,
  forwardRef,
  Input, OnChanges, SimpleChanges
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {Control} from './../control';
import {LocalizedContentFieldCreator} from "./localized-content-field-creator";
import {LocalizedContent} from "./localized-content";
import {nextTick} from "@a3l/utilities";
import {Subject} from "rxjs";
import {TranslateCommand} from "@rex/common/translate.command";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'localized-wysiwyg-editor',
  templateUrl: './localized-wysiwyg-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LocalizedWysiwygInputComponent),
      multi: true,
    },
    {provide: Control, useExisting: LocalizedWysiwygInputComponent},
  ],
  host: {
    class: 'localized-wysiwyg-editor',
    '(onFocus)': 'focused = true',
    '(onBlur)': 'focused = false',
  },
})
export class LocalizedWysiwygInputComponent extends Control implements ControlValueAccessor, OnChanges {

  /**
   * @var {any}
   */
  @Input()
  value: LocalizedContent;

  @Input()
  disabled = false;

  @Input()
  activeLanguage: string;

  systemLanguage: string = this.translator.currentLang;

  shouldRefreshHtml: boolean = false;

  originalContentBeforeTranslation: Array<Boolean> = [];

  public loading = false;

  /**
   * @var {any}
   */
  protected propagateChange: any = () => {
  };

  constructor(
    protected translate: TranslateCommand,
    protected translator: TranslateService,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  contentChanged($event) {
    this.value.setContentForLang(this.activeLanguage, $event);
    this.propagateChange(this.value);

    if(this.activeLanguage == this.systemLanguage) {
      this.translator.langs.forEach((lang) => this.originalContentBeforeTranslation[lang] = true)
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if(this.value && changes.activeLanguage && changes.activeLanguage.currentValue) {
      if(this.value.getContentForLang(this.activeLanguage).length == 0 && this.value.getContentForLang(this.systemLanguage).length > 0) {
        this.makeTranslation();
      }
      this.refreshHtml();
    }
  }

  canRefreshTranslation() {
    if(!this.originalContentBeforeTranslation) return false;
    if(this.systemLanguage == this.activeLanguage) return false;
    if(!this.originalContentBeforeTranslation[this.activeLanguage]) return false;

    return true;
  }

  makeTranslation() {
    this.loading = true;
    this.originalContentBeforeTranslation[this.activeLanguage] = false;
    this.translate.run(this.systemLanguage, this.activeLanguage, this.value.getContentForLang(this.systemLanguage)).subscribe((response) => {
      this.value.setContentForLang(this.activeLanguage, response.content);
      this.loading = false;
      this.refreshHtml();
    })
  }

  /**
   * @var {boolean}
   */
  get canShowValidationMessage(): boolean {
    return this.focused;
  }

  /**
   * Focus on the input.
   *
   * @return void
   */
  focusin(): void {
    this.focused = true;
  }

  /**
   * Focusout of the input.
   *
   * @return void
   */
  focusout(): void {
    this.focused = false;
  }

  /**
   * Write a new value from the form model.
   *
   * @param {any} value
   * @return void
   */
  writeValue(value: any): void {
    let creator = new LocalizedContentFieldCreator(value);
    this.value = creator.getArray();
    if(value) {
      this.refreshHtml();
    }
  }

  /**
   * Register handler.
   *
   * @param {any} fn
   * @return void
   */
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  /**
   * Register handler.
   *
   * @param {any} fn
   * @return void
   */
  registerOnTouched(fn: any): void {
  }

  refreshHtml()
  {
    this.shouldRefreshHtml = true;
    nextTick(() => this.shouldRefreshHtml = false);
    this.cdr.detectChanges();
  }
}
