import { Injectable } from '@angular/core';
import { of, zip, Observable } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { Store } from '@ngrx/store';

import {CookieDB, Query} from '@a3l/core';
import { ApiService } from '@a3l/api';

import { State, LoggedInUser } from '@rex/state';
import {AccessTokenService} from "@rex/auth";

@Injectable({ providedIn: 'root' })
export class MeQuery extends Query<any> {
  /**
   * Create a new instance.
   *
   * @param {ApiService} api
   * @param {Store<State>} store
   * @param {AccessTokenService} accessToken
   */
  constructor(private api: ApiService, private store: Store<State>, private accessToken: AccessTokenService) {
    super();
  }
  /**
   * Run the query.
   *
   * @return Observable<any>
   */
  run(): Observable<any> {
    return this.api.get('/me').pipe(
      catchError(() => of(null)),
      switchMap((me) => {
        if (me == null || me.role == 'superadmin') return of(me);

        if (me.role == 'leader') {
          return this.api.get('/me/profile').pipe(map((profile) => ({ ...me, profile })));
        }

        if (me.role == 'client_company_user') {
          return of(me);
        }

        if (me.role == 'candidate_user') {
          return this.api.get('/me/candidate').pipe(map((candidate) => ({ ...me, candidate })));
        }

        return zip(this.api.get('/me/profile'), this.api.get('/me/organization'), this.api.get('/me/agreement')).pipe(
          map(([profile, organization, agreement]) => ({ ...me, profile, organization, agreement }))
        );
      }),
      map((me) => {
        this.store.dispatch(LoggedInUser.loaded(me));
        if(me && !CookieDB.get('language')) {
          CookieDB.put('language', me.language);
        }


        if(me === null) {
          this.accessToken.clearAll()
        }

        return me;
      })
    );
  }

  /**
   * Run the query.
   *
   * @return void
   */
  execute(): void {
    //
  }
}
