<p-autoComplete
  [placeholder]="'Wpisz' | translate"
  [(ngModel)]="this.value"
  [multiple]="true"
  [suggestions]="filteredSuggestions"
  (completeMethod)="filterSuggestions($event)"
  (onKeyUp)="onKeyUp($event)"
  (onBlur)="onBlur($event)"
  (onUnselect)="this.updateValue()"
  (onSelect)="this.updateValue()"
  class="rex-scroll"
></p-autoComplete>
