<div class="rex-tutorial-dialog-slider">
  <div class="rex-tutorial-dialog-slider__slides">
    <div *ngIf="displayStandardSlide()" [style]="getSlide()" (click)="nextSlide()" class="rex-tutorial-dialog-slider__slide"></div>

    <!-- Rodo slide -->
    <div *ngIf="displayCustomRodoSlide()" (click)="nextSlide()" class="rex-tutorial-dialog-slider__slide rex-tutorial-dialog-slider__slide--custom">
      <p translate class="text-xl font-semibold mb-12">Przydatne linki</p>
      <ng-container *ngIf="currentLang === 'pl'">
        <div class="link">
          <a3l-ui-icon icon="check" class="text-xxs pt-1"></a3l-ui-icon>
          <div>
            <p translate class="text-base text-black font-medium">Tekst rozporządzenia RODO</p>
            <a class="text-gray-dark font-semibold text-sm underline" href="https://giodo.gov.pl/234/id_art/9276/j/pl" target="_blank">https://giodo.gov.pl/234/id_art/9276/j/pl</a>
          </div>
        </div>

        <div class="link">
          <a3l-ui-icon icon="check" class="text-xxs pt-1"></a3l-ui-icon>
          <div>
            <p translate class="text-base text-black font-medium">Tekst nowej ustawy dot. danych osobowych</p>
            <a class="text-gray-dark font-semibold text-sm underline" href="https://www.giodo.gov.pl/144/id_art/386/j/pl" target="_blank">https://www.giodo.gov.pl/144/id_art/386/j/pl</a>
          </div>
        </div>

        <div class="link">
          <a3l-ui-icon icon="check" class="text-xxs pt-1"></a3l-ui-icon>
          <div>
            <p translate class="text-base text-black font-medium">Ochrona danych osobowych w miejscu pracy. Poradnik dla pracodawców</p>
            <a class="text-gray-dark font-semibold text-sm underline" href="https://uodo.gov.pl/pl/138/545  " target="_blank">https://uodo.gov.pl/pl/138/545</a>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="currentLang === 'en'">
        <p class="font-semibold">Text of the GDPR Regulation</p>
        <p style="width: 1000px">
          REGULATION (EU) 2016/ 679 OF THE EUROPEAN PARLIAMENT AND OF THE COUNCIL - of 27 April 2016 - on the protection of natural persons with regard to the processing of personal data and on the
          free movement of such data, and repealing Directive 95/ 46/ EC (General Data Protection Regulation) (europa.eu)
        </p>
        <p class="font-semibold mr-2">Data protection in the workplace. A guide for employers</p>
        <a href="https://gdpr.eu/article-88-processing-of-employees-personal-data/" target="_blank">https://gdpr.eu/article-88-processing-of-employees-personal-data/</a>
        <a href="https://gdpr.eu/recital-155-processing-in-theemployment-context/" target="_blank">https://gdpr.eu/recital-155-processing-in-theemployment-context/</a>
      </ng-container>

      <ng-container *ngIf="currentLang === 'hu'">
        <p style="width: 1000px">
          Az Európai Parlament és a Tanács (EU) 2016/679 rendelete (2016. április 27.) a természetes személyeknek a személyes adatok kezelése tekintetében történő védelméről és az ilyen adatok szabad
          áramlásáról, valamint a 95/46/EK rendelet hatályon kívül helyezéséről (általános adatvédelmi rendelet) (EGT-vonatkozású szöveg)
        </p>
        <p class="font-semibold mr-2">Adatvédelem a munkahelyen. Útmutató a munkáltatóknak</p>
        <a href="https://gdpr.eu/article-88-processing-of-employees-personal-data/" target="_blank">https://gdpr.eu/article-88-processing-of-employees-personal-data/</a>
        <a href="https://gdpr.eu/recital-155-processing-in-theemployment-context/" target="_blank">https://gdpr.eu/recital-155-processing-in-theemployment-context/</a>
      </ng-container>

      <ng-container *ngIf="currentLang === 'sk'">
        <p style="width: 1000px">
          Nariadenie Európskeho parlamentu a Rady (EÚ) 2016/679 z 27. apríla 2016 o ochrane fyzických osôb prispracúvaní osobných údajov a o voľnompohybe takýchto údajov, ktorýmsa zrušuje smernica
          95/46/ES (všeobecné nariadenie o ochrane údajov) (Text s významom pre EHP)
        </p>
        <p class="font-semibold mr-2">Ochrana údajov na pracovisku. Príručka pre zamestnávateľov</p>
        <a href="https://gdpr.eu/article-88-processing-of-employees-personal-data/" target="_blank">https://gdpr.eu/article-88-processing-of-employees-personal-data/</a>
        <a href="https://gdpr.eu/recital-155-processing-in-theemployment-context/" target="_blank">https://gdpr.eu/recital-155-processing-in-theemployment-context/</a>
      </ng-container>

      <ng-container *ngIf="currentLang === 'cs'">
        <p style="width: 1000px">
          Nařízení Evropského parlamentu a Rady (EU) 2016/679 ze dne 27. dubna 2016 o ochraně fyzických osob v souvislosti se zpracováním osobních údajů a o volném pohybu těchto údajů a o zrušení
          směrnice 95/46/ES (obecné nařízení o ochraně osobních údajů) (Text s významem pro EHP)
        </p>
        <p class="font-semibold mr-2">Ochrana údajů na pracovišti. Průvodce pro zaměstnavatele</p>
        <a href="https://gdpr.eu/article-88-processing-of-employees-personal-data/" target="_blank">https://gdpr.eu/article-88-processing-of-employees-personal-data/</a>
        <a href="https://gdpr.eu/recital-155-processing-in-theemployment-context/" target="_blank">https://gdpr.eu/recital-155-processing-in-theemployment-context/</a>
      </ng-container>

      <p translate class="font-semibold mt-8">Polityka prywatności</p>
      <p translate class="text-base text-black font-medium">Więcej dodatkowych informacji znajdziesz tutaj:</p>
      <a class="text-gray-dark font-semibold text-sm underline" href="https://antal.pl/polityka-prywatnosci" target="_blank">https://antal.pl/polityka-prywatnosci</a>
    </div>

    <!-- Recruitment slide -->
    <div *ngIf="displayCustomSlide()" (click)="nextSlide()" class="rex-tutorial-dialog-slider__slide rex-tutorial-dialog-slider__slide--custom">
      <p translate class="text-xl font-semibold mb-12">Polecane linki</p>
      <ng-container *ngIf="currentLang === 'pl'">
        <div class="link">
          <a3l-ui-icon icon="check" class="text-xxs pt-1"></a3l-ui-icon>
          <div>
            <p class="text-base text-black font-medium">Badania Antal</p>
            <a class="text-gray-dark font-semibold text-sm underline" href="https://antal.pl/wiedza/raporty" target="_blank">https://antal.pl/wiedza/raporty</a>
          </div>
        </div>

        <div class="link">
          <a3l-ui-icon icon="check" class="text-xxs pt-1"></a3l-ui-icon>
          <div>
            <p class="text-base text-black font-medium">Artykuły ekspertów Antal</p>
            <a class="text-gray-dark font-semibold text-sm underline" href="https://antal.pl/pressroom" target="_blank">https://antal.pl/pressroom</a>
          </div>
        </div>

        <div class="link">
          <a3l-ui-icon icon="check" class="text-xxs pt-1"></a3l-ui-icon>
          <div>
            <p class="text-base text-black font-medium">Source Con- jedno z największych repozytoriów wiedzy o sourcingu w formie bloga (w języku angielskim)</p>
            <a class="text-gray-dark font-semibold text-sm underline" href="https://www.sourcecon.com/Recruitment" target="_blank">https://www.sourcecon.com/Recruitment</a>
          </div>
        </div>

        <div class="link">
          <a3l-ui-icon icon="check" class="text-xxs pt-1"></a3l-ui-icon>
          <div>
            <p class="text-base text-black font-medium">Open Community - najwieksza polska grupa rekruterów</p>
            <a class="text-gray-dark font-semibold text-sm underline" href="https://www.facebook.com/groups/polandsourcingcommunity/" target="_blank"
              >https://www.facebook.com/groups/polandsourcingcommunity/</a
            >
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="currentLang === 'en'">
        <div class="link">
          <a3l-ui-icon icon="check" class="text-xxs pt-1"></a3l-ui-icon>
          <div>
            <p class="text-base text-black font-medium">Antal surveys</p>
            <a class="text-gray-dark font-semibold text-sm underline" href="https://antal.pl/wiedza/raporty" target="_blank">https://antal.pl/wiedza/raporty</a>
          </div>
        </div>

        <div class="link">
          <a3l-ui-icon icon="check" class="text-xxs pt-1"></a3l-ui-icon>
          <div>
            <p translate class="text-base text-black font-medium">Articles by Antal experts</p>
            <a class="text-gray-dark font-semibold text-sm underline" href="https://antal.pl/pressroom" target="_blank">https://antal.pl/pressroom</a>
          </div>
        </div>

        <div class="link">
          <a3l-ui-icon icon="check" class="text-xxs pt-1"></a3l-ui-icon>
          <div>
            <p translate class="text-base text-black font-medium">Source Con - One of the largest repositores of knowledge about sourcing in the form of blog (in English)</p>
            <a class="text-gray-dark font-semibold text-sm underline" href="https://www.sourcecon.com/" target="_blank">https://www.sourcecon.com/</a>
          </div>
        </div>

        <div class="link">
          <a3l-ui-icon icon="check" class="text-xxs pt-1"></a3l-ui-icon>
          <div>
            <p translate class="text-base text-black font-medium">Recruitment Open Community - the biggest Polish group of recruiters</p>
            <a class="text-gray-dark font-semibold text-sm underline" href="https://www.facebook.com/groups/polandsourcingcommunity/" target="_blank"
              >https://www.facebook.com/groups/polandsourcingcommunity/</a
            >
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="currentLang === 'hu'">
        <div class="link">
          <a3l-ui-icon icon="check" class="text-xxs pt-1"></a3l-ui-icon>
          <div>
            <p translate class="text-base text-black font-medium">Enloyd surveys and articles</p>
            <a class="text-gray-dark font-semibold text-sm underline" href="https://enloyd.hu/bepillantas/" target="_blank">https://enloyd.hu/bepillantas/</a>
          </div>
        </div>

        <div class="link">
          <a3l-ui-icon icon="check" class="text-xxs pt-1"></a3l-ui-icon>
          <div>
            <p translate class="text-base text-black font-medium">HR articles</p>
            <a class="text-gray-dark font-semibold text-sm underline" href="https://hrportal.hu/" target="_blank">https://hrportal.hu/</a>
          </div>
        </div>

        <div class="link">
          <a3l-ui-icon icon="check" class="text-xxs pt-1"></a3l-ui-icon>
          <div>
            <p translate class="text-base text-black font-medium">Source Con - One of the largest repositores of knowledge about sourcing in the form of blog (in English)</p>
            <a class="text-gray-dark font-semibold text-sm underline" href="https://www.sourcecon.com/" target="_blank">https://www.sourcecon.com/</a>
          </div>
        </div>

        <div class="link">
          <a3l-ui-icon icon="check" class="text-xxs pt-1"></a3l-ui-icon>
          <div>
            <p translate class="text-base text-black font-medium">Recruitment Open Community - the biggest Hungarian group of recruiters</p>
            <a class="text-gray-dark font-semibold text-sm underline" href="https://www.facebook.com/groups/1038416069506028" target="_blank">https://www.facebook.com/groups/1038416069506028</a>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="currentLang === 'sk'">
        <div class="link">
          <a3l-ui-icon icon="check" class="text-xxs pt-1"></a3l-ui-icon>
          <div>
            <p translate class="text-base text-black font-medium">Articles by Antal experts</p>
            <a class="text-gray-dark font-semibold text-sm underline" href="https://antal.cz/know-how/" target="_blank">https://antal.cz/know-how/</a>
          </div>
        </div>

        <div class="link">
          <a3l-ui-icon icon="check" class="text-xxs pt-1"></a3l-ui-icon>
          <div>
            <p translate class="text-base text-black font-medium">Source Con - One of the largest repositores of knowledge about sourcing in the form of blog (in English)</p>
            <a class="text-gray-dark font-semibold text-sm underline" href="https://www.sourcecon.com/" target="_blank">https://www.sourcecon.com/</a>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="currentLang === 'cs'">
        <div class="link">
          <a3l-ui-icon icon="check" class="text-xxs pt-1"></a3l-ui-icon>
          <div>
            <p translate class="text-base text-black font-medium">Articles by Antal experts</p>
            <a class="text-gray-dark font-semibold text-sm underline" href="https://antal.cz/know-how/" target="_blank">https://antal.cz/know-how/</a>
          </div>
        </div>

        <div class="link">
          <a3l-ui-icon icon="check" class="text-xxs pt-1"></a3l-ui-icon>
          <div>
            <p translate class="text-base text-black font-medium">Source Con - One of the largest repositores of knowledge about sourcing in the form of blog (in English)</p>
            <a class="text-gray-dark font-semibold text-sm underline" href="https://www.sourcecon.com/" target="_blank">https://www.sourcecon.com/</a>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="rex-tutorial-dialog-slider-navigation flex flex-col justify-between">
    <div class="mt-10 ml-10">
      <p class="text-2xl font-semibold mb-3">{{ tutorialName }}</p>
      <span class="text-base text-gray-dark font-semibold"> {{ slide }} / {{ lastSlide }}</span>
    </div>

    <div>
      <nav class="bg-white rounded-t-lg py-7 mx-2 mb-1">
        <ul class="list-none">
          <li class="">
            <rex-tutorial-go-to-step-button (click)="this.type = 'RODO'; setLastSlideValue();" [as-done]="doneSteps.tutorialRODO" [disabled]="false" [manualCurrent]="type === 'RODO'">
              <span translate>Szkolenie - RODO</span>
            </rex-tutorial-go-to-step-button>
          </li>
          <li class="my-5">
            <rex-tutorial-go-to-step-button
              (click)="this.type = 'payments'; setLastSlideValue();"
              [as-done]="doneSteps.tutorialPayments"
              [disabled]="!doneSteps.tutorialRecruitment"
              [manualCurrent]="type === 'payments'"
            >
              <span translate>Szkolenie - Proces płatności</span>
            </rex-tutorial-go-to-step-button>
          </li>
          <li *ngIf="mode === 'tutorialWithQuiz'" class="">
            <rex-tutorial-go-to-step-button [as-done]="doneSteps.quizRodoStep" [disabled]="!doneSteps.tutorialRecruitment">Quiz - <span translate>RODO</span></rex-tutorial-go-to-step-button>
          </li>
        </ul>
      </nav>

      <div class="bg-white rounded-b-lg mx-2 mb-2 flex flex-col items-center justify-center">
        <button [disabled]="slide < 2" type="button" class="as-link w-full flexijc font-semibold no-underline text-xs text-gray-dark mt-7" (click)="previousSlide()">
          <a3l-ui-icon icon="arrow-left"></a3l-ui-icon>
          <span class="ml-2" translate>Poprzedni slajd</span>
        </button>

        <button *ngIf="slide < lastSlide" class="mt-8 px-6 m-5 sm:w-60 w-full h-12 text-sm sm:text-base" type="button" a3l-ui-button large color="primary" (click)="nextSlide()">
          <span class="flex-grow" translate>Następny slajd</span>
          <a3l-ui-icon icon="arrow-right"></a3l-ui-icon>
        </button>

        <button *ngIf="slide === lastSlide && mode === 'tutorialWithQuiz'" class="mt-8 px-6 m-5 sm:w-60 w-full h-12 text-sm sm:text-base" type="button" a3l-ui-button large color="primary" (click)="finish()">
          <span class="flex-grow" translate>{{ type === 'payments' ? 'Przejdź do quizu' : 'Następne szkolenie' }}</span>
          <a3l-ui-icon icon="arrow-right"></a3l-ui-icon>
        </button>

        <button
          *ngIf="slide === lastSlide && mode === 'tutorial' && (type === 'RODO' || type === 'recruitment')"
          class="mt-8 px-6 m-5 w-60 h-12"
          type="button"
          a3l-ui-button
          large
          color="primary"
          (click)="nextSlide()"
        >
          <span class="flex-grow" translate>Następne szkolenie</span>
          <a3l-ui-icon icon="arrow-right"></a3l-ui-icon>
        </button>

        <button *ngIf="slide === lastSlide && mode === 'tutorial' && type === 'payments'" class="mt-8 px-6 m-5 w-60 h-12" type="button" a3l-ui-button large color="primary" (click)="close()">
          <span class="flex-grow" translate>Zakończ</span>
          <a3l-ui-icon icon="arrow-right"></a3l-ui-icon>
        </button>
      </div>
    </div>
  </div>
</div>
