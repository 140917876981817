import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {DictionaryOfLanguagesQuery} from "@rex/common/dictionary-of-languages.query";
import {FormGroup} from "@angular/forms";
import {isArray, isObject} from "rxjs/internal-compatibility";

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  getRateByLevel(languageLevel: string): number {
    switch (languageLevel) {
      case 'A1': {
        return 1;
      }
      case 'A2': {
        return 2;
      }
      case 'B1': {
        return 3;
      }
      case 'B2': {
        return 4;
      }
      case 'C1': {
        return 5;
      }
      case 'C2': {
        return 6;
      }
      case 'native': {
        return this.getNativeLanguageRate();
      }
      default: {
        return 5
      }
    }
  }

  getNativeLanguageRate() {
    return 7;
  }

  getLevelByRate(rate: number): string {
    switch (rate) {
      case 1: {
        return 'A1';
      }
      case 2: {
        return 'A2';
      }
      case 3: {
        return 'B1';
      }
      case 4: {
        return 'B2';
      }
      case 5: {
        return 'C1';
      }
      case 6: {
        return 'C2';
      }
      case 7: {
        return 'native';
      }
      default: {
        return 'B2'
      }
    }
  }

  patchLanguages(languages: any)
  {
    if(!languages.length ) {
      return languages;
    }

    return languages.reduce((acc, { id, key, value, attributes }) => ((acc[id] = { level: attributes.level, key: key, value: value, rate: this.getRateByLevel(attributes.level) }), acc), {});
  }

  patchLanguagesByLevel(languages: any)
  {
    if(!languages.length ) {
      return languages;
    }

    return languages.reduce((acc, { id, key, name, level }) => ((acc[id] = { level: level, key: key, value: name, rate: this.getRateByLevel(level) }), acc), {});
  }

  formattedValues(languages)
  {
    if (!languages) {
      return null;
    }

    return Object.keys(languages)
      .map(id => (
        {
          id: id,
          level: languages[id].level,
          key: languages[id].key,
          value: languages[id].value,
          rate: languages[id].rate,
        })
      );
  }

  parseFormGroupToValue(items, suggestions) {
    if (!items || (isObject(items) && Object.keys(items).length === 0)) {
      return {};
    }

    let parsed = {};

    items.forEach((item: FormGroup) => {
      if (!item.get('id').value || !item.get('level').value) {
        return;
      }

      for (let i = 0; i < (suggestions as any[]).length; i++) {
        let suggestion = (suggestions as any[])[i];

        if (suggestion.id == item.get('id').value) {
          parsed[suggestion.id] = {
            key: suggestion.key,
            id: suggestion.id,
            level: item.get('level').value,
          };
        }
      }
    });

    return parsed;
  }

  formGroupToValue(items) {
    if (!items || (isObject(items) && Object.keys(items).length === 0)) {
      return {};
    }

    let values = {};

    items.forEach((item: FormGroup) => {
      if (!item.get('id').value || !item.get('level').value) {
        return;
      }

      values[item.get('id').value] = {
        id: item.get('id').value,
        level: item.get('level').value,
      }
    });

    return values;
  }
}
