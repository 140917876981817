import { Routes } from '@angular/router';

import { IsGuestGuard, IsLoggedInGuard, HasActivatedAccountGuard, HasUnActivatedAccountGuard, CompletedTutorialGuard, LanguageGuard, MaintenanceGuard } from '@rex/guards';

import { SimpleLayout } from './simple.layout';
import {CompletedAgreementGuard} from "@rex/guards/completed-agreement.guard";
import {NotLoggedInGuard} from "@rex/guards/not-logged-in.guard";
import {CandidateStartDateModule} from "@rex/candidate/candidate-start-date/candidate-start-date.module";
import {CandidateExchangeReturnModule} from "@rex/candidate/candidate-exchange-return/candidate-exchange-return.module";

export const routes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  { path: 'order-request', redirectTo: 'new-order', pathMatch: 'full' },

  /* Pages with simple layout (header, main and footer)
  -------------------------------------------------------------------------- */
  {
    path: '',
    component: SimpleLayout,
    canActivate: [LanguageGuard, MaintenanceGuard],
    children: [
      /* Auth
      -------------------------------------------------------------------------- */
      {
        path: 'signin',
        canActivate: [IsGuestGuard],
        loadChildren: () => import('./pages/auth/sign-in/sign-in.module').then((m) => m.SignInModule),
      },
      {
        path: 'password',
        canActivate: [IsGuestGuard],
        loadChildren: () => import('./pages/auth/password/password.module').then((m) => m.PasswordModule),
      },
      {
        path: 'signup',
        canActivate: [IsGuestGuard],
        loadChildren: () => import('./pages/auth/sign-up/sign-up.module').then((m) => m.SignUpModule),
      },
      {
        path: 'client-registration',
        canActivate: [IsGuestGuard],
        loadChildren: () => import('./pages/auth/client-registration/client-registration.module').then((m) => m.ClientRegistrationModule),
      },
      {
        path: 'recruiter-registration',
        canActivate: [IsGuestGuard],
        loadChildren: () => import('./pages/auth/recruiter-registration/recruiter-registration.module').then((m) => m.RecruiterRegistrationModule),
      },
      {
        path: 'candidate-registration',
        canActivate: [IsGuestGuard],
        loadChildren: () => import('./pages/auth/candidate-registration/candidate-registration.module').then((m) => m.CandidateRegistrationModule),
      },
      {
        path: 'activation/:token',
        canActivate: [],
        loadChildren: () => import('./pages/auth/activation/activation.module').then((m) => m.ActivationModule),
      },
      {
        path: 'authenticate-by-provider/:provider/:role/:method',
        canActivate: [IsGuestGuard],
        loadChildren: () => import('./pages/auth/authenticate-by-provider/authenticate-by-provider.module').then((m) => m.AuthenticateByProviderModule),
      },

      /* Onboarding
      -------------------------------------------------------------------------- */
      {
        path: 'onboarding',
        canActivate: [IsLoggedInGuard, HasUnActivatedAccountGuard, CompletedTutorialGuard],
        loadChildren: () => import('./onboarding/onboarding.module').then((m) => m.OnboardingModule),
      },
      {
        path: 'upgrade',
        canActivate: [IsLoggedInGuard, HasActivatedAccountGuard, CompletedTutorialGuard],
        loadChildren: () => import('./onboarding/upgrade/upgrade.module').then((m) => m.UpgradeModule),
      },
      {
        path: 'organization-onboarding',
        canActivate: [IsLoggedInGuard, HasUnActivatedAccountGuard, CompletedTutorialGuard],
        loadChildren: () => import('@rex/organization/organization-onboarding/organization-onboarding.module').then((m) => m.OrganizationOnboardingModule),
      },
      {
        path: 'organization',
        canActivate: [IsLoggedInGuard, CompletedTutorialGuard],
        loadChildren: () => import('./organization/organization.module').then((m) => m.OrganizationModule),
      },

      {
        path: 'password-needs-to-be-updated',
        canActivate: [IsLoggedInGuard],
        loadChildren: () => import('./pages/password-needs-to-be-updated/password-needs-to-be-updated.module').then((m) => m.PasswordNeedsToBeUpdatedModule),
      },

      {
        path: 'tutorial-and-quiz',
        canActivate: [IsLoggedInGuard],
        loadChildren: () => import('./pages/tutorial-and-quiz/tutorial-and-quiz.module').then((m) => m.TutorialAndQuizModule),
      },
    ],
  },

  /* Panel
  -------------------------------------------------------------------------- */
  {
    path: '',
    canActivate: [IsLoggedInGuard, HasActivatedAccountGuard, CompletedTutorialGuard, LanguageGuard, MaintenanceGuard],
    loadChildren: () => import('./pages/panel/panel.module').then((m) => m.PanelModule),
  },

  {
    path: 'job-advertisements',
    loadChildren: () => import('@rex/job-advertisement/job-advertisement.module').then((m) => m.JobAdvertisementModule),
  },

  {
    path: 'job-board',
    loadChildren: () => import('@rex/job-board/job-board.module').then((m) => m.JobBoardModule),
  },


  /* Misc
  -------------------------------------------------------------------------- */
  {
    path: 'terms',
    loadChildren: () => import('./pages/terms/terms.module').then((m) => m.TermsModule),
  },

  {
    path: 'gdpr',
    loadChildren: () => import('./pages/gdpr/gdpr.module').then((m) => m.GdprModule),
  },

  {
    path: 'privacy-policy',
    loadChildren: () => import('./pages/privacy-policy/privacy-policy.module').then((m) => m.PrivacyPolicyModule),
  },

  {
    path: 'candidate',
    canActivate: [MaintenanceGuard],
    loadChildren: () => import('./pages/candidate-by-application/candidate-by-application.module').then((m) => m.CandidateByApplicationModule),
  },

  {
    path: 'application',
    canActivate: [MaintenanceGuard],
    loadChildren: () => import('./pages/application/application.module').then((m) => m.ApplicationModule),
  },
  {
    path: 'agreement',
    canActivate: [MaintenanceGuard],
    loadChildren: () => import('./pages/agreement/agreement.module').then((m) => m.AgreementModule),
  },
  {
    path: 'new-order',
    canActivate: [MaintenanceGuard, NotLoggedInGuard],
    loadChildren: () => import('./pages/order-request/order-request.module').then((m) => m.OrderRequestModule),
  },

  {
    path: 'recommendation',
    canActivate: [MaintenanceGuard],
    loadChildren: () => import('./pages/recommendation/recommendation.module').then((m) => m.RecommendationModule),
  },
  {
    path: 'feedback',
    canActivate: [IsLoggedInGuard],
    loadChildren: () => import('./feedback/feedback.module').then((m) => m.FeedbackModule),
  },

  {
    path: 'evaluations',
    canActivate: [IsGuestGuard],
    loadChildren: () => import('./evaluations/evaluations.module').then((m) => m.EvaluationsModule),
  },

  {
    path: 'request-gdpr',
    canActivate: [MaintenanceGuard],
    loadChildren: () => import('./candidate/candidate-request-gdpr/candidate-request-gdpr.module').then((m) => m.CandidateRequestGdprModule),
  },

  {
    path: 'candidate-hired',
    canActivate: [MaintenanceGuard],
    loadChildren: () => import('./candidate/candidate-hired/candidate-hired.module').then((m) => m.CandidateHiredModule),
  },

  {
    path: 'candidate-start-date',
    canActivate: [MaintenanceGuard],
    loadChildren: () => import('./candidate/candidate-start-date/candidate-start-date.module').then((m) => m.CandidateStartDateModule),
  },

  {
    path: 'candidate-exchange-return',
    canActivate: [MaintenanceGuard],
    loadChildren: () => import('./candidate/candidate-exchange-return/candidate-exchange-return.module').then((m) => m.CandidateExchangeReturnModule),
  },

  /* Logout
  -------------------------------------------------------------------------- */
  {
    path: 'logout',
    canActivate: [IsLoggedInGuard],
    loadChildren: () => import('./pages/logout/logout.module').then((m) => m.LogoutModule),
  },

  /* Errors
  -------------------------------------------------------------------------- */
  {
    path: 'e',
    component: SimpleLayout,
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/errors/errors.module').then((m) => m.ErrorsModule),
      },
    ],
  },

  { path: '**', redirectTo: '/e/not-found' },
];
