import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'a3l-ui-badge',
  template: '<ng-content></ng-content>',
  styleUrls: ['./badge.component.scss'],
  host: {
    class: 'a3l-ui-badge',
    '[class.a3l-ui-badge--info]': "color == 'info'",
    '[class.a3l-ui-badge--danger]': "color == 'danger'",
    '[class.a3l-ui-badge--success]': "color == 'success'",
    '[class.a3l-ui-badge--warning]': "color == 'warning'",
    '[class.a3l-ui-badge--dark]': "color == 'dark'",
    '[class.a3l-ui-badge--waiting]': "color == 'waiting'",
    '[class.a3l-ui-badge--processing]': "color == 'processing'",
    '[class.a3l-ui-badge--onboarding]': "color == 'onboarding'",
    '[class.a3l-ui-badge--active]': "color == 'active'",
    '[class.a3l-ui-badge--inactive]': "color == 'inactive'",
    '[class.a3l-ui-badge--blocked]': "color == 'blocked'",
    '[class.a3l-ui-badge--rejected]': "color == 'rejected'",
    '[class.a3l-ui-badge--unverified]': "color == 'unverified'",
    '[class.a3l-ui-badge--suspended]': "color == 'suspended'",
    '[class.a3l-ui-badge--private]': "color == 'private'",
    '[class.a3l-ui-badge--sm]': 'small',
  },
  encapsulation: ViewEncapsulation.None,
})
export class BadgeComponent {
  /**
   * @var {string}
   */
  @Input()
  color: string = null;

  /**
   * @var {boolean}
   */
  @Input()
  get small(): boolean {
    return this._small;
  }
  set small(value: boolean) {
    this._small = value !== false;
  }

  /**
   * @var {boolean}
   */
  protected _small: boolean = false;

  /**
   * Create a new instance.
   */
  constructor() {
    //
  }
}
