import { Directive, ElementRef, AfterViewInit, OnDestroy, HostListener, Input } from '@angular/core';

import PerfectScrollbar from 'perfect-scrollbar';

import { ResizeSensor } from 'css-element-queries';

@Directive({
  selector: '[a3l-ui-scrollbar]',
  host: {
    '[class.a3l-ui-scrollbar]': 'enabled',
  },
})
export class ScrollbarDirective implements AfterViewInit, OnDestroy {
  /**
   * @var {boolean}
   */
  @Input('a3l-ui-scrollbar')
  get enabled(): boolean {
    return this._enabled;
  }
  set enabled(value: boolean) {
    this._enabled = value !== false;

    if (this.enabled == true) this.render();
    if (this.enabled == false) this.clear();
  }

  /**
   * @var {boolean}
   */
  protected _enabled: boolean = true;

  /**
   * @var {any}
   */
  protected resizeSensor: any;

  /**
   * @var {any}
   */
  protected perfectScrollbar: any;

  /**
   * Create a new instance.
   *
   * @param {ElementRef} elementRef
   */
  constructor(private elementRef: ElementRef) {
    //
  }

  /**
   * Initialization.
   */
  ngAfterViewInit() {
    if (!this.enabled) return;

    this.render();
  }

  /**
   * Initialization.
   */
  protected render(): void {
    if (!this.enabled) return;

    if (this.perfectScrollbar) return;

    this.perfectScrollbar = new PerfectScrollbar(this.elementRef.nativeElement);

    this.resizeSensor = new ResizeSensor(this.elementRef.nativeElement.children[0], () => this.perfectScrollbar.update());
  }

  /**
   * Update perfect scrollbar when the window size changed.
   *
   * @return void
   */
  @HostListener('window:resize')
  onWindowResize(): void {
    if (!this.enabled) return;

    this.perfectScrollbar && this.perfectScrollbar.update();
  }

  /**
   * Cleanup.
   *
   * @return void
   */
  protected clear(): void {
    this.resizeSensor && this.resizeSensor.detach();

    this.perfectScrollbar && this.perfectScrollbar.destroy();

    this.resizeSensor = null;
    this.perfectScrollbar = null;
  }

  /**
   * Cleanup.
   */
  ngOnDestroy() {
    this.clear();
  }
}
