import {
  Component,
  AfterContentInit,
  ViewChild,
  ViewChildren,
  ContentChildren,
  QueryList,
  ElementRef,
  ViewEncapsulation,
  OnInit
} from '@angular/core';

import { nextTick } from '../utils';

import { TabComponent } from './tab.component';
import { TabsBarComponent } from './tabs-bar.component';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'a3l-ui-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
  host: {
    class: 'a3l-ui-tabs',
  },
  encapsulation: ViewEncapsulation.None,
})
export class TabsComponent implements AfterContentInit {
  /**
   * @var {QueryList<TabComponent>}
   */
  @ContentChildren(TabComponent)
  tabs: QueryList<TabComponent>;

  /**
   * @var {TabsBarComponent}
   */
  @ViewChild(TabsBarComponent, { static: true })
  private bar: TabsBarComponent;

  /**
   * @var {QueryList<ElementRef>}
   */
  @ViewChildren('label')
  private labels: QueryList<ElementRef>;

  /**
   * Create a new instance.
   */
  constructor(
    private route: ActivatedRoute,
  ) {
    //
  }

  /**
   * Select the first tab.
   */
  ngAfterContentInit() {
    this.bar.hide();

    let index = this.tabs.toArray().findIndex((tab) => tab.active);

    this.route.fragment.subscribe((fragment: string) => {
      this.tabs.forEach((tab, i) => {
        if(tab.id != undefined && tab.id == fragment) {
          index = i;
        }
      })
    })

    nextTick(() => this.select(index > -1 ? index : 0));
  }

  /**
   * Select the tab with by index.
   *
   * @param {number} index
   * @return void
   */
  select(index: number): void {
    if (this.tabs.get(index).disabled) return;

    this.tabs.toArray().forEach((tab, i) => {
      tab.position = i - index;
      tab.active = i === index;
    });

    if (this.labels && this.labels.length) {
      const label = this.labels.find((l, i) => i === index);

      this.bar.alignToElement(label.nativeElement);
    }
  }
}
