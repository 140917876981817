import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { zip } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { DialogRef, UI_DIALOG_DATA } from '@a3l/utilities';

import { Localization } from './localizator';

@Component({
  templateUrl: './localization-wizard.dialog.html',
  styleUrls: ['./localization-wizard.dialog.scss'],
  host: {
    class: 'a3l-ui-dialog-wrapper',
  },
  encapsulation: ViewEncapsulation.None,
})
export class LocalizationWizardDialog {
  /**
   * @var {any}
   */
  type: any = null;

  /**
   * @var {boolean}
   */
  processing: boolean = false;

  /**
   * @var {Localization[]}
   */
  localizations: Localization[] = [];

  /**
   * Create a new instance.
   *
   * @param {any[]} localizations
   * @param {DialogRef<LocalizationWizardDialog>} dialogRef
   */
  constructor(@Inject(UI_DIALOG_DATA) localizations: any[] = [], private dialogRef: DialogRef<LocalizationWizardDialog>) {
    if (!Array.isArray(localizations)) return;

    this.consume(localizations);
  }

  /**
   * Set the localizations attribute.
   *
   * @param {Localization[]} localizations
   * @return void
   */
  consume(localizations: Localization[]): void {
    this.localizations = localizations || [];

    this.type = (() => {
      const type = this.localizations.find(({ bounded }) => bounded)?.type;

      if (type != null) return type;

      return localizations ? localizations[0].type : null;
    })();
  }

  /**
   * Handle the action.
   *
   * @return void
   */
  process(): void {
    this.processing = true;

    this.localizations.forEach((localization) => localization.markBoundedAs(localization.type == this.type));

    zip(...this.localizations.map((localization) => localization.geocode()))
      .pipe(finalize(() => (this.processing = false)))
      .subscribe(() => this.dialogRef.close(this.localizations));
  }

  /**
   * Close the dialog.
   *
   * @return void
   */
  close(): void {
    this.dialogRef.close();
  }
}
