import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BehaviorSubject, Subject } from "rxjs";

@Injectable()
export class TutorialAndQuizService {

  /**
   * @var {BehaviorSubject<any>}
   */
  private doneSteps = new BehaviorSubject({
    tutorialRODO: false,
    tutorialRODOStep: false,
    tutorialRecruitment: false,
    tutorialRecruitmentStep: false,
    tutorialPayments: false,
    tutorialPaymentsStep: false,
    quizRodoStep: false,
  });

  sharedDoneSteps = this.doneSteps.asObservable();

  /**
   * Create a new instance.
   *
   * @param {ActivatedRoute} route
   * @param {Router} router
   */
  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) { }

  /**
   * add filter
   *
   * @return void
   */
  completeStep(value: any): void {
    this.doneSteps.next({ ...this.doneSteps.value, ...value });
  }
}
