import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OverlayModule } from '@angular/cdk/overlay';

import { TooltipComponent } from './tooltip.component';
import { TooltipDirective } from './tooltip.directive';

const PUBLIC_API = [TooltipComponent];

const PRIVATE_API = [TooltipDirective];

@NgModule({
    declarations: [...PUBLIC_API, ...PRIVATE_API],
    imports: [CommonModule, OverlayModule],
    exports: [TooltipDirective]
})
export class TooltipModule {}
