import {
  Component,
  AfterContentInit,
  ViewChild,
  ViewChildren,
  ContentChildren,
  QueryList,
  ElementRef,
  ViewEncapsulation,
  OnInit, Input, Renderer2, HostListener, AfterViewInit
} from '@angular/core';

import { nextTick } from '../utils';

import { TabComponent } from './tab.component';
import { TabsBarComponent } from './tabs-bar.component';
import {ActivatedRoute} from "@angular/router";
import { debounceTime } from 'rxjs/operators';
import { fromEvent } from 'rxjs';

@Component({
  selector: 'a3l-ui-quick-tab',
  templateUrl: './quick-tab.component.html',
  styleUrls: ['./quick-tab.component.scss'],
})
export class QuickTabComponent implements OnInit, AfterViewInit {
 @Input() element
 @Input() title
  @ViewChild('ref') ref: ElementRef

  constructor(private renderer: Renderer2) {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.onWindowScroll();
  }

  scrollToElement() {
    if(this.element) {
      const offsetTop = this.element.getBoundingClientRect().top + window.scrollY;

      window.scrollTo({
        top: offsetTop - 200,
        behavior: 'smooth'
      });
    }
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

    if(this.element && this.ref) {
      const offsetTop = this.element.getBoundingClientRect().top + window.scrollY;
      const offsetBottom = this.element.getBoundingClientRect().bottom + window.scrollY;
      const offset = 200;

      if(scrollPosition >= (offsetTop - offset - 20) && scrollPosition <= offsetBottom - offset + 20) {
        this.renderer.addClass(this.ref.nativeElement, 'active');
      } else {
        this.renderer.removeClass(this.ref.nativeElement, 'active');
      }
    }
  }
}
