import {
  Component, EventEmitter,
  Output,
  OnInit
} from '@angular/core';
import {debounceTime, distinctUntilChanged} from "rxjs/operators";
import {FormControl, FormGroup} from "@angular/forms";


@Component({
  selector: 'a3l-ui-select-search-input',
  templateUrl: './select-search-input.component.html',
})
export class SelectSearchInputComponent implements OnInit {

  @Output()
  searchChanged = new EventEmitter;

  public form: FormGroup = new FormGroup({
    search: new FormControl(null),
  })

  ngOnInit() {
    this.form.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe(input => {
        this.searchChanged.emit(input.search)
      });
  }
}
