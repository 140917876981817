<form [formGroup]="form" class="" id="industry_item">
  <a3l-ui-form-group clear>
    <div class="p-inputgroup">
      <p-autoComplete
        a3l-ui-input
        formControlName="value"
        class="text-input w-full"
        type="text"
        [placeholder]="'Wpisz' | translate"
        [suggestions]="filteredSuggestions"
        (completeMethod)="filterSuggestions($event)"
      >
      </p-autoComplete>
      <ng-template a3l-ui-error-message let-key="key">{{ 'validation.' + key | translate }}</ng-template>
    </div>
  </a3l-ui-form-group>
</form>
