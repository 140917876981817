import { Component, ViewEncapsulation, Input } from '@angular/core';

@Component({
  selector: '[a3l-dataset-zoom]',
  template: '<ng-content></ng-content>',
  host: {
    class: 'a3l-dataset-zoom',
    '[class.a3l-dataset-zoom--active]': 'active',
    '[class.a3l-dataset-zoom--important]': 'item.important || important',
    '[class.a3l-dataset-zoom-invitation]': "key == 'invitation' && datasetKey === 'candidates'",
    '[class.a3l-dataset-zoom-verification]': "key == 'verification' && datasetKey === 'candidates'",
    '[class.a3l-dataset-zoom-presentation]': "key == 'presentation' && datasetKey === 'candidates'",
    '[class.a3l-dataset-zoom-meeting]': "key == 'meeting' && datasetKey === 'candidates'",
    '[class.a3l-dataset-zoom-rejected]': "key == 'rejected' && datasetKey === 'candidates'",
    '[class.a3l-dataset-zoom-hired]': "key == 'hired' && datasetKey === 'candidates'",
    '[class.a3l-dataset-zoom-resigned]': "key == 'resigned' && datasetKey === 'candidates'",

  },
  styleUrls: ['./zoom.presenter.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ZoomPresenter {
  /**
   * @var {any}
   */
  @Input('a3l-dataset-zoom')
  item: any = {};

  /**
   * @var {string}
   */
  @Input('a3l-dataset-zoom-key')
  key: string = null;
  /**
   * @var {string}
   */
  @Input('a3l-dataset-key')
  datasetKey: string = null;
  /**
   * @var {boolean}
   */
  @Input('a3l-dataset-zoom-active')
  get active(): boolean {
    return this._active;
  }
  set active(value: boolean) {
    this._active = value !== false;
  }

  /**
   * @var {boolean}
   */
  @Input('a3l-dataset-zoom-important')
  get important(): boolean {
    return this._important;
  }
  set important(value: boolean) {
    this._important = value !== false;
  }

  /**
   * @var {boolean}
   */
  _active: boolean = false;

  /**
   * @var {boolean}
   */
  _important: boolean = false;

  /**
   * Create a new instance.
   */
  constructor() {
  }
}
