import { Pipe, PipeTransform } from '@angular/core';

import * as moment from 'moment';

@Pipe({ name: 'a3lCurrency' })
export class CurrencyPipe implements PipeTransform {
  /**
   * Create a new instance.
   */
  constructor() {
    //
  }

  /**
   * Transform the given date to the relative time format.
   *
   * @param {string} value
   * @return string
   */
  transform(value: string): string {
    return value + ' pln';
  }
}
