import { Component, Input, AfterContentInit, ViewEncapsulation, HostListener } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { animate, state, style, transition, trigger } from '@angular/animations';

import { WebStorageDB } from '@a3l/core';

@Component({
  selector: 'a3l-layout-sidenav',
  template: '<ng-content></ng-content>',
  styleUrls: ['./layout-sidenav.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'a3l-layout-sidenav',
    '[@sidenav]': 'state',
  },
  animations: [
    trigger('sidenav', [
      state('open, open-instant', style({ transform: 'none' })),
      state('void', style({ transform: 'translateX(-100%)' })),
      transition('void => open-instant', animate('0ms')),
      transition('void <=> open, open-instant => void', animate('250ms ease-in-out')),
    ]),
  ],
})
export class LayoutSidenav implements AfterContentInit {
  /**
   * @var {'open' | 'void'}
   */
  @Input()
  state: 'open' | 'open-instant' | 'void' = 'void';

  /**
   * @var {boolean}
   */
  @Input()
  opened: boolean = true;

  /**
   * @var {string}
   */
  @Input()
  wkey: string;

  /**
   * @var {boolean}
   */
  @Input()
  get minimized(): boolean {
    return this._minimized;
  }
  set minimized(value: boolean) {
    this._minimized = value;

    this.wkey && WebStorageDB.put(this.wkey, value);
  }

  /**
   * @var {boolean}
   */
  protected _minimized: boolean = false;

  /**
   * Create a new instance.
   *
   * @param {MediaMatcher} mediaMatcher
   */
  constructor(private mediaMatcher: MediaMatcher) {
    //
  }

  /**
   * Initialization.
   */
  ngAfterContentInit() {
    const media = this.mediaMatcher.matchMedia('(max-width: 992px)');

    this.opened = media.matches ? false : true;

    this.state = this.opened ? 'open-instant' : 'void';

    this.minimized = this.mediaMatcher.matchMedia('(max-width: 1440px)').matches ? true : WebStorageDB.get(this.wkey, false);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (event.target.innerWidth < 1440) {
      this.minimized = true;
    }
  }

  /**
   * Open the sidenav.
   *
   * @return void
   */
  open(): void {
    this.state = 'open';

    this.opened = true;
  }

  /**
   * Close the sidenav.
   *
   * @return void
   */
  close(): void {
    this.state = 'void';

    this.opened = false;
  }

  /**
   * Toggle the sidenav.
   *
   * @return void
   */
  toggle(): void {
    this.opened = !this.opened;

    this.state = this.opened ? 'open' : 'void';
  }

  /**
   * Minimize the sidenav.
   *
   * @return void
   */
  minimize(): void {
    this.minimized = true;
  }

  /**
   * Maximize the sidenav.
   *
   * @return void
   */
  maximize(): void {
    this.minimized = false;
  }
}
