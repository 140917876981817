import { Subject, Observable } from 'rxjs';

export class SnackBarRef {
  /**
   * @var {Subject<any>}
   */
  onClose = new Subject<void>();

  /**
   * Create a new instance.
   */
  constructor() {
    //
  }

  /**
   * Gets an observable that is notified when the snack bar is finished closing.
   *
   * @return Observable
   */
  afterClosed(): Observable<any> {
    return this.onClose.asObservable();
  }
}
