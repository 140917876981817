import {Control} from "@a3l/utilities";
import {Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output, ViewEncapsulation} from "@angular/core";
import {ControlValueAccessor, FormGroup, NG_VALUE_ACCESSOR} from "@angular/forms";
import {Subscription} from "rxjs";
import {Dispatcher} from "@a3l/core";
import {SkillForm} from "@rex/common/skills/skill.form";
import {SkillsCommand} from "@rex/skills/commands/skills.command";

@Component({
  selector: 'rex-skills-field',
  templateUrl: 'skills.field.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SkillsField),
      multi: true,
    },
    { provide: Control, useExisting: SkillsField },
    SkillsCommand
  ],
  encapsulation: ViewEncapsulation.None
})
export abstract class SkillsField extends Control implements OnInit, OnDestroy, ControlValueAccessor {
  addNewButtonText = 'candidate::panel.wizard.navigate.add_new_skill';
  titleText = 'candidate::panel.wizard.title.soft_skills';
  infoText = 'messages.soft_skills_info'
  isValid = true;
  isLoading = false;

  /**
   * @var {any}
   */
  value: any = [];

  @Output()
  skillsCount: EventEmitter<any> = new EventEmitter();

  suggestions: any = [];
  hidedSuggestions: any = [];

  /**
   * @var {any}
   */
  protected propagateChange: any = () => {};

  /**
   * @var {Subscription}
   */
  protected subscription: Subscription = Subscription.EMPTY;

  protected constructor(
    protected dispatcher: Dispatcher,
    protected skillForm: SkillForm,
    protected skillsCommand: SkillsCommand,
  ) {
    super();
  }

  addNew() {
    if (this.value.length) this.validate();

    if (!this.isValid) return;

    this.value.push(this.skillForm.emptyForm());
    this.propagateChange(this.value);
    this.skillsCount.emit(this.value.length);
  }

  validate()
  {
    this.value.forEach((formGroup: FormGroup) => {
      this.isValid = formGroup.validate();

      if (!this.isValid) return;
    });
  }

  removePosition(uuid) {
    this.value.splice(this.getItemIndexByUuid(uuid), 1);
    this.propagateChange(this.value)
    this.skillsCount.emit(this.value.length);

    this.showSuggestions();
    this.validate();
  }

  getItemIndexByUuid(uuid) {
    let foundIndex = undefined;
    this.value.forEach((item, index) => {
      if(item.controls['uuid'].value == uuid) {
        foundIndex = index;
      }
    });

    return foundIndex;
  }

  update($event) {
    this.value[this.getItemIndexByUuid($event.controls['uuid'].value)] = $event;
    this.propagateChange(this.value);
    this.hideSuggestion($event.get('value').value);
  }

  identify(index, item)
  {
    return item.controls['uuid'].value;
  }

  ngOnInit() {
  }

  /**
   * Write a new value from the form model.
   *
   * @param {any} value
   * @return void
   */
  writeValue(value: any): void {
    this.value = value;

    if (!this.value) return;
  }

  /**
   * Register handler.
   *
   * @param {any} fn
   * @return void
   */
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  /**
   * Register handler.
   *
   * @param {any} fn
   * @return void
   */
  registerOnTouched(fn: any): void {}

  /**
   * Initialization.
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  hideSuggestion(enteredSuggestion: any)
  {
    if(enteredSuggestion && this.suggestions.length && this.suggestions.includes(enteredSuggestion)) {
      this.suggestions.pop(enteredSuggestion);
      this.hidedSuggestions.push(enteredSuggestion);
    }
  }

  showSuggestions()
  {
    if (!this.hidedSuggestions.length) return;

    this.hidedSuggestions.forEach((suggestion) => {
      const index = this.value.findIndex((item: FormGroup) => item.get('value').value == suggestion);

      if(index === -1) {
        this.suggestions.push(suggestion);
        this.hidedSuggestions.pop(suggestion);
      }
    });
  }
}
