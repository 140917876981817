import { Component, ViewEncapsulation, TemplateRef, ViewChild, ElementRef, ContentChildren, QueryList } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Subject } from 'rxjs';

import { MenuItemDirective } from './menu-item.directive';

@Component({
  selector: 'a3l-ui-menu',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  animations: [trigger('visibility', [state('void', style({ opacity: 0 })), state('enter', style({ opacity: 1 })), transition('* => void', animate('100ms 25ms linear', style({ opacity: 0 })))])],
})
export class MenuComponent {
  /**
   * @var {ElementRef}
   */
  @ViewChild('panel', { static: false })
  panel: ElementRef;

  /**
   * @var {TemplateRef<any>}
   */
  @ViewChild(TemplateRef)
  templateRef: TemplateRef<any>;

  /**
   * @var {'void' | 'enter'}
   */
  state: 'void' | 'enter' = 'void';

  /**
   * @var {QueryList<MenuItemDirective>}
   */
  @ContentChildren(MenuItemDirective)
  items?: QueryList<MenuItemDirective>;

  /**
   * @var {Subject<any>}
   */
  protected afterDestroy = new Subject<any>();

  /**
   * Create a new instance.
   */
  constructor() {
    //
  }

  /**
   * Show the menu.
   *
   * @return void
   */
  show(): void {
    this.state = 'enter';
  }

  /**
   * Hide the menu.
   *
   * @return void
   */
  hide(): void {
    this.state = 'void';
  }

  /**
   * Destroy the menu.
   *
   * @return void
   */
  destroy(): void {
    this.afterDestroy.next();
  }

  /**
   * Gets an observable that is notified when the menu is destroyed.
   *
   * @return Observable
   */
  afterDestroyed() {
    return this.afterDestroy.asObservable();
  }
}
