import { Pipe, PipeTransform } from '@angular/core';

import * as moment from 'moment';
import {MaskApplierService} from "ngx-mask";

@Pipe({ name: 'currencyValue' })
export class CurrencyValuePipe implements PipeTransform {
  /**
   * Create a new instance.
   */
  constructor(private maskApplier: MaskApplierService) {
    //
  }

  /**
   * Transform the given date to the relative time format.
   *
   * @param {string} value
   * @return string
   */
  transform(value: any): string {
    return value ? this.maskApplier.applyMask(String(value.value), 'separator.0') + " " + value.currency.toUpperCase() : '';
  }
}
