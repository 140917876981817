<ng-container [ngSwitch]="column.key">
  <ng-container *ngSwitchCase="'actions'">
    <div class="flex gap-1">
      <ng-template
        [rex-order-request-dataset-action]="action"
        [rex-order-request-dataset-action-item]="item"
        [rex-order-request-dataset-action-view]="view"
        [rex-order-request-dataset-action-entity]="entity"
        *ngFor="let action of item.actions"
      ></ng-template>
    </div>
  </ng-container>
  <ng-container a3l-dataset-table-dcell *ngSwitchCase="'name'">
    <h3><b>{{ item.position}}</b><br/></h3>
    <p><b>Łącznie: {{ item.total_value }}</b><span *ngIf="item.warranty_months > 0 || item.kam_value > 0 || item.kam_included">, w tym:</span></p>
    <p *ngIf="item.warranty_months > 0">{{ 'Gwarancja' | translate }}: {{ item.warranty_months + "m / " + (item.warranty_value) }}</p>

    <p *ngIf="item.kam_value.value > 0 || item.kam_included">
    {{ 'Opieka KAM' | translate }}:
    <span *ngIf="item.kam_value.value == 0 && item.kam_included">{{ "Tak - wliczona" | translate }}</span>
    <span *ngIf="item.kam_value.value > 0">{{ "Tak - " | translate }} {{ item.kam_value }}</span>
    <br/>
  </ng-container>
  <ng-container a3l-dataset-table-dcell *ngSwitchCase="'number'">
      <span *ngIf="item.number; else empty">{{ item.number}}</span>
  </ng-container>
  <ng-container a3l-dataset-table-dcell *ngSwitchCase="'contact'">
    <ng-container *ngIf="item.recruitment_contact_persons"><p *ngFor="let user of item.recruitment_contact_persons"><rex-contact-person-component [contactPerson]="user"></rex-contact-person-component></p></ng-container>
  </ng-container>
  <ng-container a3l-dataset-table-dcell *ngSwitchCase="'vacancies'">
    <ng-container>{{ item.vacancies }}</ng-container>
  </ng-container>
  <ng-container a3l-dataset-table-dcell *ngSwitchCase="'projects'">
  </ng-container>
  <ng-container a3l-dataset-table-dcell *ngSwitchCase="'project_status'">
    <rex-recruitment-status *ngIf="item.recruitment" [item]="item.recruitment"></rex-recruitment-status>
  </ng-container>
  <ng-container a3l-dataset-table-dcell *ngSwitchCase="'empty'">
  </ng-container>
  <ng-container a3l-dataset-table-dcell *ngSwitchCase="'empty2'">
  </ng-container>
  <ng-container a3l-dataset-table-dcell *ngSwitchDefault><div>{{ item[column.key] }}</div></ng-container>
</ng-container>
<ng-template #empty>---</ng-template>
