<div>
  <div class="flexic mb-4">
    <h3 class="mb-0" translate>candidate::panel.wizard.title.industries_fieldset</h3>
    <ng-container *ngIf="this.isLoading">
      <button class="ml-3" type="button" a3l-ui-button icon small color="gray-revert" disabled>
        <a3l-ui-spinner color="gray" size="25"></a3l-ui-spinner>
      </button>
    </ng-container>
    <ng-container *ngIf="!this.isLoading">
      <button *ngIf="!this.value.length" class="ml-3" type="button" a3l-ui-button icon small color="gray-revert" (click)="addNew();">
        <a3l-ui-icon icon="plus" class="text-xs"></a3l-ui-icon>
      </button>
      <button *ngIf="this.value.length" class="ml-3" type="button" a3l-ui-button icon small color="gray-revert" disabled>
        <span class="text-xs">{{ this.value.length }}</span>
      </button>
    </ng-container>
  </div>

  <ng-container *ngIf="industriesDictionary$ | async as industriesDictionary">
    <div *ngFor="let item of value; trackBy:identify; let last = last">
      <div class="flex justify-between w-full a3l-ui-form-group py-2 pr-0 pl-2 mb-2">
        <div class="w-full">
          <rex-industry-item [form]="item" (itemChangedEvent)="update($event)" [suggestions]="industriesDictionary"></rex-industry-item>
        </div>
        <div class="flexijc">
          <button type="button" a3l-ui-button icon small plain color="gray" class="px-6" (click)="removePosition(item.controls['uuid'].value)">
            <a3l-ui-icon icon="trash" class="text-xxs text-gray-dark"></a3l-ui-icon>
          </button>
        </div>
      </div>
    </div>

    <a *ngIf="value.length >= 1" (click)="addNew()" type="button" class="text-xs pl-3 align-middle" a3l-ui-button small color="default" translate>
      <a3l-ui-icon icon="plus" class="mr-2 text-xxxs"></a3l-ui-icon>
      {{  'candidate::panel.wizard.navigate.add_new_industry'| translate }}
    </a>
  </ng-container>

</div>
