import { Localization } from './localizaton';

export const TYPES_OF_PLACES: any[] = ['locality', 'administrative_area_level_2', 'administrative_area_level_1', 'country'];

export const TYPES_BY_PLACES: any = { locality: 'city', administrative_area_level_2: 'district', administrative_area_level_1: 'voivodeship', country: 'country' };

export class PlaceToLocalizationConverter {
  /**
   * Handle convertion.
   *
   * @param {any} place
   * @return Localization
   */
  static convert(place: any): Localization {
    if (!PlaceToLocalizationConverter.convertable(place)) return null;

    const { long_name: name, types } = place;

    const typeOfPlace = types.find((type) => TYPES_OF_PLACES.indexOf(type) != -1);

    return new Localization(name, TYPES_BY_PLACES[typeOfPlace]);
  }

  /**
   * Deterime if the given place can be converted.
   *
   * @param {any} place
   * @return boolean
   */
  static convertable({ types }: any): boolean {
    return types.some((type) => TYPES_OF_PLACES.includes(type));
  }
}
