import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'rex-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  host: {
    class: 'rex-avatar',
    '[class.rex-avatar--small]': 'small',
    '[class.rex-avatar--medium]': 'medium',
    '[class.rex-avatar--large]': 'large',
  },
  encapsulation: ViewEncapsulation.None,
})
export class AvatarComponent {
  /**
   * @var {string}
   */
  @Input()
  src: string;

  /**
   * @var {boolean}
   */
  @Input()
  get small(): boolean {
    return this._small;
  }
  set small(value: boolean) {
    this._small = value !== false;
  }

  /**
   * @var {boolean}
   */
  @Input()
  get medium(): boolean {
    return this._medium;
  }
  set medium(value: boolean) {
    this._medium = value !== false;
  }

  /**
   * @var {boolean}
   */
  @Input()
  get large(): boolean {
    return this._large;
  }
  set large(value: boolean) {
    this._large = value !== false;
  }

  /**
   * @var {boolean}
   */
  protected _small: boolean = false;

  /**
   * @var {boolean}
   */
  protected _medium: boolean = false;

  /**
   * @var {boolean}
   */
  protected _large: boolean = false;

  /**
   * Create a new instance.
   */
  constructor() {
    //
  }
}
