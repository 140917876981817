import { Directive } from '@angular/core';

@Directive({
  selector: '[a3l-ui-form-group-on-focusout]',
})
export class OnFocusoutDirective {
  /**
   * Create a new instance.
   */
  constructor() {
    //
  }
}
