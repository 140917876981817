export class QuillConfig {
  protected defaultModules = {
    keyboard: {
      bindings: {
        tab: {
          key: 9,
          handler: function (range, context) {
            return true;
          },
        },
      },
    },
    clipboard: {
      matchVisual: false,
      matchers: [
        [
          Node.ELEMENT_NODE,
          (node, delta) => {
            let ops = [];
            delta.ops.forEach((op) => {
              if (op.attributes && op.insert) {
                if (Object.keys(op.attributes).length === 1 && (op.attributes.bold || op.attributes.italic || op.attributes.list)) {
                  ops.push(op);
                } else {
                  if (op.insert && typeof op.insert === 'string') {
                    ops.push({
                      insert: op.insert,
                    });
                  }
                }
              }
              if (!op.attributes  && op.insert && typeof op.insert === 'string') {
                ops.push({
                  insert: op.insert,
                });
              }
            });

            delta.ops = ops;
            return delta;
          },
        ],
      ],
    },
    toolbar: [
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'bullet' }, { list: 'ordered' }],
    ],
  };

  public getDefaultModules() {
    return this.defaultModules;
  }

  public getToolbarWithAdditionalOptions(toolbarOptions: [string[]], options: any) {
    let newConfig = this.defaultModules;
    newConfig.toolbar = [...this.defaultModules.toolbar, ...toolbarOptions];

    return {
      ...newConfig,
      ...options,
    };
  }
}
