import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'a3lFind', pure: false })
export class FindPipe implements PipeTransform {
  /**
   * Transform the given array.
   *
   * @param {any[]} items
   * @param {string} property
   * @param {any} value
   * @return string
   */
  transform(items: any[], property: string, value: any): any[] {
    return (items || []).find((item) => item[property] == value);
  }
}
