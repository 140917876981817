import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'a3lTelto' })
export class TeltoPipe implements PipeTransform {
  /**
   * Create a new instance.
   */
  constructor() {
    //
  }

  /**
   * Transform the given email.
   *
   * @param {string} value
   * @return string
   */
  transform(value: string): string {
    return `tel:${value}`;
  }
}
