import { AfterContentInit, Component, ElementRef, Renderer2 } from '@angular/core';

@Component({
  selector: 'a3l-ui-nl2br',
  template: '<ng-content></ng-content>',
})
export class Nl2brComponent implements AfterContentInit {
  /**
   * Create a new instance.
   *
   * @param {Renderer2} renderer
   * @param {ElementRef} elementRef
   */
  constructor(private renderer: Renderer2, private elementRef: ElementRef) {
    //
  }

  /**
   * Initialization
   */
  ngAfterContentInit() {
    const content = this.elementRef.nativeElement.textContent || this.elementRef.nativeElement.innerHTML;

    this.renderer.setProperty(this.elementRef.nativeElement, 'innerHTML', content.replace(/\n/g, '<br/>'));
  }
}
