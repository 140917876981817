import { Directive, TemplateRef, ViewContainerRef, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';

import { AuthuserQuery } from './authuser.query';
import {UserRoleEnum} from "@rex/core/enums/user-role.enum";
import {ClientCompanyRoleEnum} from "@rex/core/enums/client-company-role.enum";

@Directive({ selector: '[rex-auth-role-in-client-company]' })
export class RoleInClientCompanyDirective implements OnInit, OnDestroy {
  userRoleEnum: typeof UserRoleEnum = UserRoleEnum;

  /**
   * @var {any}
   */
  @Input('rex-auth-role-in-client-company')
  roles: any;

  /**
   * @var {boolean}
   */
  protected embedded: boolean = false;

  /**
   * @var {Subscription}
   */
  protected subscription: Subscription = Subscription.EMPTY;

  /**
   * Create a new instance.
   *
   * @param {AuthuserQuery} query
   * @param {TemplateRef} templateRef
   * @param {ViewContainerRef} viewContainer
   */
  constructor(private query: AuthuserQuery, private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef) {
    //
  }

  /**
   * Add the template content to the DOM.
   */
  ngOnInit() {
    this.subscription = this.query.value$.subscribe((user) => {
      const roles = Array.isArray(this.roles) ? this.roles : [this.roles];

      if (user && !this.embedded && [this.userRoleEnum.SUPERADMIN, this.userRoleEnum.LEADER].indexOf(user.role) != -1) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.embedded = true;
      }

      if (user && user.client_company && !this.embedded && roles.indexOf(user.client_company_role) != -1) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.embedded = true;
      } else if (!user && this.embedded) {
        this.viewContainer.clear();
        this.embedded = false;
      }
    });
  }

  /**
   * Cleanup.
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
