import { Component, Directive, Input, forwardRef, ViewEncapsulation, Injector, EventEmitter, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';

import { Control } from './control';

let uniqueId = 0;

@Component({
  selector: 'a3l-ui-simple-checkbox',
  templateUrl: './simple-checkbox.component.html',
  styleUrls: ['./simple-checkbox.component.scss'],
  host: {
    class: 'a3l-ui-simple-checkbox',
    '[class.a3l-ui-simple-checkbox--invalid]': '!valid',
    '[class.a3l-ui-simple-checkbox--checked]': 'checked',
    '[class.a3l-ui-simple-checkbox--disabled]': 'disabled',
    '(mouseover)': '_canShowValidationInTooltip = true',
    '(mouseleave)': '_canShowValidationInTooltip = false',
  },
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SimpleCheckboxComponent),
      multi: true,
    },
    { provide: Control, useExisting: SimpleCheckboxComponent },
  ],
})
export class SimpleCheckboxComponent extends Control implements ControlValueAccessor {
  /**
   * @var {any}
   */
  @Input()
  get checked(): boolean {
    return this._checked;
  }
  set checked(checked: boolean) {
    if (this.checked != checked) {
      this._checked = checked;

      this.propagateChange(this.checked);
    }
  }

  /**
   * @var {EventEmitter<boolean>}
   */
  @Output()
  checkedChange: EventEmitter<boolean> = new EventEmitter();

  /**
   * @var {boolean}
   */
  @Input()
  disabled: boolean = false;

  /**
   * @var {sting}
   */
  @Input()
  additionalClass: string = null;

  /**
   * @var {NgControl}
   */
  get control(): NgControl {
    try {
      return this.injector.get(NgControl);
    } catch {
      //
    }

    return null;
  }

  /**
   * @var {string}
   */
  id = `simple-checkbox-${++uniqueId}`;

  /**
   * @var {boolean}
   */
  get valid(): boolean {
    if (this.control == null) return true;

    return !(this.control.invalid && this.control.dirty);
  }

  /**
   * @var {boolean}
   */
  get canShowValidationInTooltip(): boolean {
    return this._canShowValidationInTooltip;
  }

  /**
   * @var {boolean}
   */
  protected _checked: boolean = false;

  /**
   * @var {any}
   */
  protected propagateChange: any = () => {};

  /**
   * @var {boolean}
   */
  protected _canShowValidationInTooltip: boolean = false;

  /**
   * Create a new instance.
   *
   * @param {Injector} injector
   */
  constructor(private injector: Injector) {
    super();
  }

  /**
   * On input value change.
   *
   * @return void
   */
  toggle(): void {
    this.checked = !this.checked;

    this.checkedChange.emit(this.checked);
  }

  /**
   * Write a new value from the form model.
   *
   * @param {any} value
   * @return void
   */
  writeValue(value: any): void {
    this.checked = !!value;
  }

  /**
   * Register handler.
   *
   * @param {any} fn
   * @return void
   */
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  /**
   * Register handler.
   *
   * @param {any} fn
   * @return void
   */
  registerOnTouched(fn: any): void {}
}

@Directive({
  selector: 'a3l-ui-simple-checkbox[outlined]',
  host: {
    class: 'a3l-ui-simple-checkbox--outlined',
  },
})
export class SimpleCheckboxAsOutlinedDirective {
  //
}
