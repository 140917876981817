export enum DialogSize {
  Default = 'default',
  Medium = 'medium',
  Large = 'large',
  Fullscreen = 'fullscreen',
}

export class DialogConfig {
  class?: string;
  size?: DialogSize;
  showCloseButton?: boolean;
}
