import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PortalModule } from '@angular/cdk/portal';
import { OverlayModule } from '@angular/cdk/overlay';
import { A11yModule } from '@angular/cdk/a11y';

import { CoreModule } from '../core';

import { ModalContainerComponent } from './modal-container.component';

import { ModalHeaderComponent } from './modal-header.component';
import { ModalContentComponent } from './modal-content.component';
import { ModalFooterComponent } from './modal-footer.component';

const PUBLIC_API = [ModalHeaderComponent, ModalContentComponent, ModalFooterComponent];

@NgModule({
    declarations: [ModalContainerComponent, ...PUBLIC_API],
    imports: [CommonModule, PortalModule, OverlayModule, A11yModule, CoreModule],
    exports: [...PUBLIC_API]
})
export class ModalModule {}
