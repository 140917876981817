import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'rexRecruiterContract' })
export class RecruiterContractPipe implements PipeTransform {
  /**
   * Transform organization and agreements of the recruiter into string.
   *
   * @param {any} recruiter
   * @return string
   */
  transform(recruiter: any): string {
    if (!recruiter) return null;

    const { organization = {}, agreements = [] } = recruiter;

    const { type = null } = agreements.find(({ organization_id: organizationId }) => organizationId == organization.id) || {};

    return type;
  }
}
