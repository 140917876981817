import { Injectable, Injector } from '@angular/core';
import { Router, ResolveStart } from '@angular/router';
import { filter } from 'rxjs/operators';

import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType, PortalInjector } from '@angular/cdk/portal';

import { ModalRef } from './modal-ref';
import { ModalConfig } from './config';
import { ModalContainerComponent } from './modal-container.component';
import { UI_MODAL_DATA, UI_MODAL_CONFIG } from './token';
import { ModalModule } from './modal.module';

export const UI_MODAL_PANEL_CLASS = 'cdk-a3l-ui-modal-pane';

@Injectable({ providedIn: ModalModule })
export class Modal {
  /**
   * @var {Modal<any>[]}
   */
  protected openModals: ModalRef<any>[] = [];

  /**
   * Create a new instance.
   *
   * @param {Router} router
   * @param {Overlay} overlay
   * @param {Injector} injector
   */
  constructor(private router: Router, private overlay: Overlay, private injector: Injector) {
    this.router.events.pipe(filter((event) => event instanceof ResolveStart)).subscribe(() => {
      this.closeAll();
    });
  }

  /**
   * Opens a modal containing the given component.
   *
   * @param {ComponentType<T>} componentRef
   * @param {any} data
   * @param {ModalConfig} userConfig
   * @return ModalRef
   */
  open<T = any>(componentRef: ComponentType<T>, data?: any, userConfig?: ModalConfig): ModalRef<T, any> {
    const overlayRef = this.overlay.create({
      panelClass: UI_MODAL_PANEL_CLASS,
      hasBackdrop: true,
      disposeOnNavigation: true,
      scrollStrategy: this.overlay.scrollStrategies.block(),
    });

    const container = this.attachContainer(overlayRef);

    const config = { showCloseButton: true, ...userConfig };

    const ref = this.attachContent<T>(componentRef, container, overlayRef, data, config);

    this.openModals.push(ref);

    ref.afterClosed().subscribe(() => this.removeOpenModal(ref));

    return ref;
  }

  /**
   * Close all modals.
   *
   * @return void
   */
  closeAll(): void {
    let i = this.openModals.length;

    while (i--) {
      this.openModals[i].close();
    }
  }

  /**
   * Attaches an ModalContainerComponent to a modal's already-created overlay.
   *
   * @param {OverlayRef} overlay
   * @return ModalContainerComponent
   */
  private attachContainer(overlay: OverlayRef) {
    const containerPortal = new ComponentPortal(ModalContainerComponent);
    const containerRef = overlay.attach(containerPortal);

    return containerRef.instance;
  }

  /**
   * Attaches the user-provided component to the already-created ModalContainerComponent.
   *
   * @param {ComponentType<any>} componentRef
   * @param {ModalContainerComponent} container
   * @param {OverlayRef} overlayRef
   * @param {any} data
   * @param {ModalConfig} config
   * @return any
   */
  private attachContent<T>(componentRef: ComponentType<T>, container: ModalContainerComponent, overlayRef: OverlayRef, data?: any, config?: ModalConfig) {
    const ref = new ModalRef<T>(overlayRef, container, config);

    container.onClose.subscribe(() => ref.close());

    const injector = this.createInjector<T>(ref, container, config, data || {});
    const contentRef = container.attachComponentPortal<T>(new ComponentPortal(componentRef, undefined, injector));

    ref.componentInstance = contentRef.instance;

    return ref;
  }

  /**
   * Creates a custom injector to be used inside the modal. This allows a component loaded inside
   * of a modal to close itself and, optionally, to return a value.
   *
   * @param {ModalRef<any>} ref
   * @param {ModalContainerComponent} container
   * @param {ModalConfig} config
   * @param {any} data
   * @return PortalInjector
   */
  private createInjector<T>(ref: ModalRef<T>, container: ModalContainerComponent, config: ModalConfig, data?: any) {
    const injectionTokens = new WeakMap();
    injectionTokens.set(ModalRef, ref);

    injectionTokens.set(UI_MODAL_DATA, data);
    injectionTokens.set(UI_MODAL_CONFIG, config);

    return new PortalInjector(this.injector, injectionTokens);
  }

  /**
   * Removes a modal from the array of open modals.
   *
   * @param {Modal<any>} ref
   * @return void
   */
  private removeOpenModal(ref: ModalRef<any>) {
    const index = this.openModals.indexOf(ref);

    if (index > -1) {
      this.openModals.splice(index, 1);
    }
  }
}
