import { Component, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { trigger, state, style, transition, animate, AnimationEvent } from '@angular/animations';
import { Subject, Observable } from 'rxjs';

export type Visibility = 'initial' | 'visible' | 'hidden';

@Component({
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './helper.component.html',
  styleUrls: ['./helper.component.scss'],
  animations: [
    trigger('state', [
      state(
        'initial, void, hidden',
        style({
          transform: 'scale(0.5)',
          opacity: 0,
        })
      ),
      state(
        'visible',
        style({
          transform: 'scale(1)',
          opacity: 1,
        })
      ),
      transition('* => visible', animate('150ms cubic-bezier(0.0, 0.0, 0.2, 1)')),
      transition('* => hidden', animate('150ms cubic-bezier(0.4, 0.0, 1, 1)')),
    ]),
  ],
})
export class HelperComponent {
  /**
   * @var {string}
   */
  message: string;

  /**
   * @var {boolean}
   */
  get visibled(): boolean {
    return this.visibility === 'visible';
  }

  /**
   * @var {Observable<void>}
   */
  get afterHidden(): Observable<void> {
    return this.hidden.asObservable();
  }

  /**
   * @var {Visibility}
   */
  visibility: Visibility = 'initial';

  /**
   * @var {Subject<any>}
   */
  private hidden: Subject<any> = new Subject();

  /**
   * Create a new instance.
   *
   * @param {ChangeDetectorRef} changeDetectorRef
   */
  constructor(private changeDetectorRef: ChangeDetectorRef) {
    //
  }

  /**
   * Show the helper.
   *
   * @return void
   */
  show(): void {
    this.visibility = 'visible';

    this.markForCheck();
  }

  /**
   * Begin the animation to hide after the delay.
   *
   * @return void
   */
  hide(): void {
    this.visibility = 'hidden';

    this.markForCheck();
  }

  /**
   * Emit event when the animation ended.
   *
   * @return void
   */
  onAnimationDone(event: AnimationEvent): void {
    const toState = event.toState as Visibility;

    if (toState === 'hidden' && !this.visibled) {
      this.hidden.next();
    }
  }

  /**
   * Marks that the helper needs to be checked in the next change detection run.
   *
   * @return void
   */
  markForCheck(): void {
    this.changeDetectorRef.markForCheck();
  }
}
