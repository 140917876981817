import { createReducer, on } from '@ngrx/store';

import { Notifications } from './actions';

import { INITIAL_STATE, adapter } from './state';

export const reducer = createReducer(
  INITIAL_STATE,
  on(Notifications.load, (state) => state),
  on(Notifications.loaded, (state, { entities }) => adapter.setAll(entities, state)),
  on(Notifications.markAsRead, (state, { uuid }) => adapter.updateOne({ id: uuid, changes: { read_at: new Date() } }, state)),
  on(Notifications.deleteAll, (state) => adapter.removeAll(state)),
  on(Notifications.markAsDeleted, (state, { uuid }) => adapter.removeOne(uuid, state))
);
