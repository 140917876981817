import { Component, ElementRef, forwardRef, Input, ViewChild, ViewEncapsulation, OnInit, OnDestroy, Injector } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl, FormGroup, Validators, AbstractControl, NgControl } from '@angular/forms';
import { Subscription } from 'rxjs';

import * as intlTelInput from 'intl-tel-input';
import { parsePhoneNumberFromString, getCountryCallingCode, CountryCode } from 'libphonenumber-js/max';

import { Control } from '@a3l/utilities';

import { CountryQuery } from './country.query';

export function phoneValidator(control: AbstractControl) {
  if (!control.parent) return null;

  const prefix = control.parent.get('prefix').value;

  const phoneNumber = parsePhoneNumberFromString(`+${prefix}${control.value}`);

  if (!phoneNumber || !phoneNumber.isValid()) {
    return { phone: false };
  }

  return null;
}

@Component({
  selector: 'rex-phone-field',
  templateUrl: './phone.field.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PhoneField),
      multi: true,
    },
    { provide: Control, useExisting: PhoneField },
  ],
  encapsulation: ViewEncapsulation.None,
  host: {
    '(mouseover)': '_canShowValidationMessage = true',
    '(mouseleave)': '_canShowValidationMessage = false',
  },
})
export class PhoneField extends Control implements OnInit, ControlValueAccessor, OnDestroy {
  /**
   * @var {string}
   */
  @Input()
  placeholder: string = '';

  @Input()
  autocomplete: boolean = false;

  @Input()
  showLockIcon: boolean = true;

  @Input('canBeEdited') canBeEdited: boolean = true;

  /**
   * @var {FormGroup}
   */
  form: FormGroup = new FormGroup({
    prefix: new FormControl(null, Validators.required),
    number: new FormControl(null, [Validators.required, phoneValidator]),
  });

  /**
   * @var {Map<string, any>}
   */
  countries: Map<string, any>;

  /**
   * @var {boolean}
   */
  get valid(): boolean {
    if (this.ngControl === undefined) return true;

    return !(this.ngControl.invalid && this.ngControl.dirty);
  }

  /**
   * @var {boolean}
   */
  get canShowValidationMessage(): boolean {
    return !this.focused && this._canShowValidationMessage;
  }

  /**
   * @var {ElementRef}
   */
  @ViewChild('input', { static: true })
  protected input: ElementRef;

  /**
   * @var {NgControl}
   */
  protected ngControl: NgControl;

  /**
   * @var {any}
   */
  protected propagateChange: any = () => {};

  /**
   * @var {Subscription}
   */
  protected subscription: Subscription = Subscription.EMPTY;

  /**
   * @var {boolean}
   */
  _canShowValidationMessage: boolean = false;

  /**
   * Create a new instance.
   *
   * @param {Injector} injector
   * @param {CountryQuery} query
   */
  constructor(private injector: Injector, private query: CountryQuery) {
    super();
  }

  /**
   * Initialization.
   */
  ngOnInit() {
    this.ngControl = this.injector.get(NgControl);

    if(!this.canBeEdited) {
      this.form.disable();
    } else {
      this.form.enable();
    }

    const intl = intlTelInput(this.input.nativeElement, { allowDropdown: false });

    this.countries = new Map(
      (window as any).intlTelInputGlobals.getCountryData().map(({ iso2, dialCode }) => {
        return [dialCode, iso2];
      })
    );

    this.subscription = this.form.valueChanges.subscribe(({ prefix, number }) => {
      if (!prefix || !number) return;

      number = number.replaceAll(' ', '');
      this.form.controls['number'].patchValue(number, {emitEvent: false});
      if(number[0] == '+' && number.length > 3) {
        this.countries.forEach((iso2, dialCode) => {
          if(number[1].toString() + number[2].toString() + number[3].toString() == dialCode) {
            this.form.controls['prefix'].patchValue(dialCode, {emitEvent: false});
            this.form.controls['number'].patchValue(number.substring(4), {emitEvent: false});
            this.propagateChange(`+${dialCode + number.substring(4)}`);
          } else if(number[1].toString() + number[2].toString() == dialCode) {
            this.form.controls['prefix'].patchValue(dialCode, {emitEvent: false});
            this.form.controls['number'].patchValue(number.substring(3), {emitEvent: false});
            this.propagateChange(`+${dialCode + number.substring(3)}`);
          }
        });
      } else {
        this.propagateChange(`+${prefix + number}`);
      }
    });
  }


  /**
   * Write a new value from the form model.
   *
   * @param {any} value
   * @return Promise<void>
   */
  async writeValue(value: any): Promise<void> {
    try {
      if (value !== true) {
        const phoneNumber = parsePhoneNumberFromString(value);

        this.form.patchValue({
          prefix: phoneNumber.countryCallingCode as string,
          number: phoneNumber.nationalNumber as string,
        });
      }
    } catch (e) {
      const code = (await this.query.value$.toPromise()).toUpperCase() as CountryCode;

      this.form.get('prefix').patchValue(getCountryCallingCode(code) as string);
    }
  }

  /**
   * Register handler.
   *
   * @param {any} fn
   * @return void
   */
  registerOnChange(fn: any): void {
    this.propagateChange = (value) => {
      const phoneNumber = parsePhoneNumberFromString(value, this.countries.get(this.form.get('prefix').value).toUpperCase());

      if (!phoneNumber || !phoneNumber.isValid()) {
        fn(null);

        return;
      }

      fn(value);
    };
  }

  /**
   * Register handler.
   *
   * @param {any} fn
   * @return void
   */
  registerOnTouched(fn: any): void {}

  /**
   * Cleanup.
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
