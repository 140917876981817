import {Event} from "@a3l/core";

export class CvParserRecruitmentLanguagesEvent extends Event {
  /**
   * @var {string}
   */
  static readonly type = 'CvParserRecruitmentLanguagesEvent';

  /**
   * @var {string}
   */
  public readonly type = CvParserRecruitmentLanguagesEvent.type;


  constructor(public value: any, public disabled: boolean ) {
    super();
  }
}
