import { NgModule } from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { CoreModule } from '../core';

import { CalendarModule } from "primeng/calendar";

import { InputDirective } from './input.directive';
import { LabelDirective } from './label.directive';

import { FormDirective } from './form.directive';

import { AddonDirective } from './addon.directive';
import { GroupComponent } from './group.component';

import { CheckboxComponent, CheckboxAsOutlinedDirective } from './checkbox';

import { RadioGroupComponent, RadioItemComponent, RadioItemAsOutlinedDirective } from './radio';

import { SelectComponent, SelectOptionComponent, SelectAsSmallDirective } from './select';
import { SelectpickerComponent, SelectpickerOptionComponent } from './selectpicker';

import { FileuploadComponent } from './fileupload';
import { ImageuploadComponent } from './imageupload';

import { OnFocusDirective } from './on-focus.directive';
import { OnFocusoutDirective } from './on-focusout.directive';
import { AutofocusDirective } from './autofocus.directive';
import { ErrorMessageDirective } from './error-message.directive';
import {QuillModule} from "ngx-quill";
import {TranslateModule} from "@ngx-translate/core";
import {FlagModule} from "../flag";
import {DropdownModule} from "../dropdown";
import {DatetimeComponent} from "./datetime.component";
import {QuillRichComponent} from "./quill-rich.component";
import {QuillConfig} from "./quill.config";
import Quill from "quill";
import * as Emoji from "quill-emoji";
import {SwitcherComponent} from "./switcher/switcher.component";
import {CurrencyInputComponent} from "./currency-input.component";
import {NgxMaskModule} from "ngx-mask";
import {SimpleCheckboxComponent} from "./simple-checkbox";
import {AutocompleteComponent} from "./autocomplete.component";
import {AutoCompleteModule} from "primeng/autocomplete";
import {ExperienceDateComponent} from "./experience-date.component";
import {SpinnerModule} from "../spinner";
import {InputTextModule} from "primeng/inputtext";
import {RatingModule} from "primeng/rating";
import {TokenModule} from "../token";
import {DialogModule} from "primeng/dialog";
import {ConfirmPopupModule} from "primeng/confirmpopup";
import {OverlayPanelModule} from "primeng/overlaypanel";
import {OverlayModule} from "@angular/cdk/overlay";
import {RangeInputComponent} from "./range/range-input.component";
import {NgxSliderModule} from "@angular-slider/ngx-slider";
import {SelectSearchInputComponent} from "./select-search-input.component";
import {BadgeModule} from "../badge";

const PUBLIC_API = [
  FormDirective,
  InputDirective,
  LabelDirective,
  AddonDirective,
  GroupComponent,
  CheckboxComponent,
  SimpleCheckboxComponent,
  CheckboxAsOutlinedDirective,
  RadioGroupComponent,
  RadioItemComponent,
  RadioItemAsOutlinedDirective,
  SelectComponent,
  SelectSearchInputComponent,
  SelectOptionComponent,
  SelectAsSmallDirective,
  SelectpickerComponent,
  SelectpickerOptionComponent,
  FileuploadComponent,
  ImageuploadComponent,
  OnFocusDirective,
  OnFocusoutDirective,
  AutofocusDirective,
  ErrorMessageDirective,
  SwitcherComponent,
  DatetimeComponent,
  CurrencyInputComponent,
  QuillRichComponent,
  AutocompleteComponent,
  ExperienceDateComponent,
  RangeInputComponent,
];


const modules = (new QuillConfig()).getDefaultModules();
Quill.register("modules/emoji", Emoji);

@NgModule({
  declarations: [...PUBLIC_API],
  imports: [CommonModule, FormsModule, CoreModule, QuillModule, TranslateModule, FlagModule, DropdownModule, CalendarModule, ReactiveFormsModule,
    QuillModule.forRoot({
      modules,
      theme: "bubble",
      sanitize: false,
      strict: false
    }), NgxMaskModule, AutoCompleteModule, SpinnerModule, InputTextModule, RatingModule, TokenModule, DialogModule, ConfirmPopupModule, OverlayPanelModule, OverlayModule, NgxSliderModule, BadgeModule
  ],
  exports: [...PUBLIC_API],
})
export class FormModule {}
