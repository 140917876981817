import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from "@angular/core";
import {FormGroup} from "@angular/forms";
import {DictionaryOfLanguagesQuery} from "@rex/common/dictionary-of-languages.query";
import {LanguageService} from "@rex/shared/services/language.service";
import {Observable, Subject} from "rxjs";

interface AutoCompleteEvent {
  originalEvent: Event;
  query: string;
}

@Component({
  selector: 'rex-language-skill-item',
  templateUrl: './language-skill-item.html',
  styleUrls: ['language-skill-item.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LanguageSkillItem implements OnInit {
  @Input() form: FormGroup;
  @Output() itemChangedEvent = new EventEmitter<FormGroup>();

  /**
   * @var {any}
   */
  @Input()
  suggestions: any = [];

  /**
   * Create a new instance.
   *
   * @param {LanguageService} languagesService
   */
  constructor(public languagesService: LanguageService) {
  }

  ngOnInit() {
    this.form.valueChanges.subscribe((values) => {
      this.itemChangedEvent.emit(this.form);
    })
  }

  getScoreBadge()
  {
    if (this.languagesService.getRateByLevel(this.form.get('level').value)) {
      return  this.languagesService.getRateByLevel(this.form.get('level').value) + "/" + this.languagesService.getNativeLanguageRate();
    }
    return null;
  }
}
