import { Component, Output, OnInit, EventEmitter, ElementRef } from '@angular/core';

import { Loader } from '@googlemaps/js-api-loader';

import { environment } from '@rex/environments/environment';
import {GoogleMap} from "../../../../../libs/core/src/lib/GoogleMap";

@Component({
  selector: 'rex-google-map',
  template: '',
  host: {
    class: 'rex-google-map',
  },
})
export class GoogleMapComponent implements OnInit {
  /**
   * @var {EventEmitter<any>}
   */
  @Output()
  init: EventEmitter<any> = new EventEmitter();

  /**
   * @var {EventEmitter<any>}
   */
  @Output()
  mapclick: EventEmitter<any> = new EventEmitter();

  /**
   * @var {any}
   */
  map: any;

  /**
   * Create a new instance.
   *
   * @param {ElementRef} elementRef
   *
   * @param {GoogleMap} googleMap
   */
  constructor(private elementRef: ElementRef, private googleMap: GoogleMap) {
    //
  }

  /**
   * Initialization.
   */
  ngOnInit() {
    this.googleMap.loader.load().then(() => {
      const google = (window as any).google;

      this.map = new google.maps.Map(this.elementRef.nativeElement, {
        zoom: 1,
        center: { lat: 0, lng: 0 },
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        fullscreenControl: false,
      });

      this.map.addListener('click', () => this.mapclick.emit());

      this.init.emit(this.map);
    });
  }
}
