import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class NumberSeparatorService {
  toInt(string: string) {
    return parseInt(new String(string).replace(/\s/g, ''))
  }
}
