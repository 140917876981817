import { Directive, HostListener, Input, ElementRef, ViewContainerRef, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { Overlay, OverlayRef, OverlayConfig } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { Subscription, merge } from 'rxjs';

import { nextTick } from '../utils';

import { MenuComponent } from './menu.component';

export const UI_MENU_PANEL_CLASS = 'cdk-a3l-ui-menu-pane';

@Directive({ selector: '[a3l-ui-menu]' })
export class MenuDirective implements OnInit, OnDestroy {
  /**
   * @var {MenuComponent}
   */
  @Input('a3l-ui-menu')
  menu: MenuComponent;

  /**
   * @var {'start' | 'below'}
   */
  @Input('a3l-ui-menu-position')
  position: 'start' | 'below' = 'start';

  /**
   * @var {OverlayRef}
   */
  private overlayRef: OverlayRef | null;

  /**
   * @var {Subscription}
   */
  private closeSubscription: Subscription = Subscription.EMPTY;

  /**
   * @var {Subscription}
   */
  private menuDestroySubscription: Subscription = Subscription.EMPTY;

  /**
   * Create a new instance.
   *
   * @param {Overlay} overlay
   * @param {Renderer2} renderer
   * @param {ElementRef} elementRef
   * @param {ViewContainerRef} viewContainerRef
   */
  constructor(private overlay: Overlay, private renderer: Renderer2, private elementRef: ElementRef, private viewContainerRef: ViewContainerRef) {
    //
  }

  /**
   * Initialization.
   *
   * @return void
   */
  ngOnInit() {
    this.menuDestroySubscription = this.menu.afterDestroyed().subscribe(this.hide.bind(this));
  }

  /**
   * Listen for click event and toggle the menu.
   *
   * @return void
   */
  @HostListener('click', ['$event'])
  onClick(event: MouseEvent): void {
    event.preventDefault();

    this.show();
  }

  /**
   * Show the menu.
   *
   * @return void
   */
  show(): void {
    const positions: any = [
      {
        originX: 'start',
        originY: this.position == 'start' ? 'top' : 'bottom',
        overlayX: 'start',
        overlayY: 'top',
      },
    ];

    const strategy = this.overlay.position().flexibleConnectedTo(this.elementRef).withPositions(positions).withPush(true);

    const overlayConfig: OverlayConfig = {
      positionStrategy: strategy,
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
      disposeOnNavigation: true,
      panelClass: UI_MENU_PANEL_CLASS,
      scrollStrategy: this.overlay.scrollStrategies.close(),
    };

    this.overlayRef = this.overlay.create(overlayConfig);

    this.overlayRef.attach(new TemplatePortal(this.menu.templateRef, this.viewContainerRef));

    this.menu.show();

    this.closeSubscription = merge(this.overlayRef.backdropClick(), this.overlayRef.detachments(), ...this.menu.items.map((item) => item.clicked)).subscribe(this.hide.bind(this));

    nextTick(() => this.renderer.setStyle(this.menu.panel.nativeElement, 'minWidth', `${this.elementRef.nativeElement.offsetWidth + 20}px`));
  }

  /**
   * Hide the menu.
   *
   * @return void
   */
  hide(): void {
    this.menu.hide();

    this.closeSubscription.unsubscribe();

    this.overlayRef && this.overlayRef.dispose();
  }

  /**
   * Dispose the menu when destroyed.
   */
  ngOnDestroy() {
    this.hide();

    this.menuDestroySubscription.unsubscribe();
  }
}
