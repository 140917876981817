<div class="mt-2 p-4 border-3 border-solid border-gray-light rounded font-medium text-sm text-gray-dark">
  <p class="flex">
    <a3l-ui-icon class="mt-1 mr-2 text-xs" icon="info"></a3l-ui-icon>
    <span class="leading-5" translate>Pamiętaj, że silne hasło powinno zawierać:</span>
  </p>
  <ul class="m-0 p-0 list-none">
    <li class="mb-3 flex items-center">
        <span
          class="as-circle mr-3 h-5 w-5 bg-gray-light"
          [ngClass]="{'bg-success-light text-success ': checkMinLengthMatch(password), 'bg-accent-light text-accent ': !checkMinLengthMatch(password) && password}"
        >
          <a3l-ui-icon icon="check" [style.fontSize.px]="7" *ngIf="checkMinLengthMatch(password)"></a3l-ui-icon>
          <a3l-ui-icon icon="cross" [style.fontSize.px]="6" *ngIf="!checkMinLengthMatch(password) && password"></a3l-ui-icon>
        </span>
      <span translate>minimum 8 znaków</span>
    </li>

    <li class="mb-3 flex items-center">
        <span
          class="as-circle mr-3 h-5 w-5 bg-gray-light"
          [ngClass]="{'bg-success-light text-success ': checkLowerAndUpperMatch(password), 'bg-accent-light text-accent ': !checkLowerAndUpperMatch(password) && password}"
        >
          <a3l-ui-icon icon="check" [style.fontSize.px]="7" *ngIf="checkLowerAndUpperMatch(password)"></a3l-ui-icon>
          <a3l-ui-icon icon="cross" [style.fontSize.px]="6" *ngIf="!checkLowerAndUpperMatch(password) && password"></a3l-ui-icon>
        </span>
      <span translate>małe i duże litery</span>
    </li>
  </ul>
</div>
