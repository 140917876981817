import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { Query } from '@a3l/core';
import { ApiService } from '@a3l/api';
import {TranslateService} from "@ngx-translate/core";

@Injectable({ providedIn: 'root' })
export class LanguagesQuery extends Query<any> {
  /**
   * Create a new instance.
   *
   * @param {ApiService} api
   */
  constructor(private api: ApiService, private translator: TranslateService) {
    super();
  }

  /**
   * Run the query.
   *
   * @return void
   */
  execute(): void {
    this._pending$.next(true);

    this.api
      .get('/translations')
      .pipe(map((transitions) => transitions.map(({ language: code }) => code)))
      .subscribe((values) => {
        this.translator.addLangs(values);
        this._pending$.next(false);

        this._value$.next(values);
      });
  }
}
