import {Control} from "./control";
import {AbstractControl, ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR} from "@angular/forms";
import {Component, ElementRef, forwardRef, Input, ViewChild, ViewEncapsulation} from "@angular/core";

interface AutoCompleteCompleteEvent {
  originalEvent: Event;
  query: string;
}

@Component({
  selector: 'a3l-ui-autocomplete-rating',
  styleUrls: ['./autocomplete-rating.component.scss'],
  templateUrl: './autocomplete-rating.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutocompleteRatingComponent),
      multi: true,
    },
    {
      provide: Control,
      useExisting: AutocompleteRatingComponent
    }
  ],
  host: {
    class: 'a3l-ui-autocomplete-rating',
  },
  encapsulation: ViewEncapsulation.None
})
export class AutocompleteRatingComponent extends Control implements ControlValueAccessor {
  @Input()
  values: any[] = [];


  /**
   * @var {string}
   */
  @Input()
  placeholder: string;

  /**
   * @var {any}
   */
  @Input()
  suggestions: any = [];

  filteredSuggestions: any[] | undefined;

  editedData: any;
  current: any;

  /**
   * @var {FormGroup}
   */
  newItemForm: FormGroup = new FormGroup({
    value: new FormControl(null),
    rate: new FormControl(null),
  });

  /**
   * @var {any}
   */
  protected propagateChange: any = () => {};

  /**
   * Focus on the input.
   *
   * @return void
   */
  focusin(): void {
    this.focused = false;
  }

  /**
   * Focusout of the input.
   *
   * @return void
   */
  focusout(): void {
    this.focused = false;
  }

  /**
   * Write a new value from the form model.
   *
   * @param {any} values
   * @return void
   */
  writeValue(values: any): void {
    this.values = values;

    this.propagateChange(this.values);
  }

  /**
   * Register handler.
   *
   * @param {any} fn
   * @return void
   */
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  /**
   * Register handler.
   *
   * @param {any} fn
   * @return void
   */
  registerOnTouched(fn: any): void {}

  filterSuggestions(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < (this.suggestions as any[]).length; i++) {
      let suggestion = (this.suggestions as any[])[i];

      if (suggestion.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(suggestion);
      }
    }

    this.filteredSuggestions = filtered;
  }

  setEditData(data) {
    this.editedData = data;
  }

  saveRate($event) {
    let clonedValues = [];

    if (this.values && this.values.length) {
      this.values.forEach(val => clonedValues.push(Object.assign({}, val)));

      clonedValues.find(item => item.value == this.editedData.value).level = $event.value;

      this.values = clonedValues;
    }

    this.writeValue(this.values);
  }

  delete(data) {
    this.values = this.values.filter(item => item.value !== data.value);
    this.writeValue(this.values);
  }

  addData() {
    if (!this.newItemForm.get('value').value) {
      this.newItemForm.clear();
      return;
    }

    let newValue = { value: this.newItemForm.get('value').value, level: this.newItemForm.get('rate').value};

    if(!this.values) {
      this.values = [];
    }
    let newArray = this.values.filter(() => true);
    newArray.push(newValue);
    this.values = newArray
    this.writeValue(this.values);

    this.newItemForm.clear();
  }
}
