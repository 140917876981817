import { Specification } from '@a3l/core';

export class AccountIsRejectedSpecification extends Specification<any> {
  isSatisfiedBy(candidate: any): boolean {
    const { role } = candidate;

    if (role == 'superadmin' || role == 'leader') return false;

    const { status } = candidate;

    return status == 'rejected';
  }
}
