import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'a3l-ui-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'a3l-ui-progress-bar',
    '[class.a3l-ui-progress-bar--determinate]': "mode == 'determinate'",
    '[class.a3l-ui-progress-bar--indeterminate]': "mode == 'indeterminate'",
  },
})
export class ProgressBarComponent {
  /**
   * @var {number}
   */
  @Input()
  get value(): number {
    return this._value;
  }
  set value(value: number) {
    this._value = Math.min(100, value);
  }

  /**
   * @var {'determinate' | 'indeterminate'}
   */
  @Input()
  mode: 'determinate' | 'indeterminate' = 'determinate';

  /**
   * @var {number}
   */
  protected _value: number = 0;

  /**
   * Create a new instance.
   */
  constructor() {
    //
  }
}
