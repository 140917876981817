import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BreadcrumbComponent } from './breadcrumb.component';
import { BreadcrumbItemComponent } from './breadcrumb-item.component';

import { BreadcrumbCollection } from './breadcrumb.collection';
import {RouterModule} from "@angular/router";
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { DatasetModule } from '@a3l/dataset';
import { TranslateModule } from '@ngx-translate/core';
import { IconModule } from '@a3l/utilities';

const PUBLIC_API = [BreadcrumbComponent, BreadcrumbItemComponent];

@NgModule({
  declarations: [...PUBLIC_API],
  imports: [CommonModule, RouterModule, DropdownModule, FormsModule, DatasetModule, TranslateModule, IconModule],
  exports: [...PUBLIC_API],
})
export class BreadcrumbModule {
  public static forRoot(): ModuleWithProviders<BreadcrumbModule> {
    return {
      ngModule: BreadcrumbModule,
      providers: [BreadcrumbCollection],
    };
  }
}
