import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Observable, of, Subscription } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

import { Dispatcher, WebCacheDB } from '@a3l/core';

import { BreadcrumbItem } from './breadcrumb-item';
import { BreadcrumbCollection } from './breadcrumb.collection';
import { DatasetLensChangedEvent } from '@rex/shared/DatasetLensChangedEvent';

@Component({
  selector: 'a3l-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  host: {
    class: 'a3l-breadcrumb',
  },
  encapsulation: ViewEncapsulation.None,
})
export class BreadcrumbComponent implements OnInit, OnDestroy {
  /**
   * @var {BreadcrumbItem[]}
   */
  readonly items$: Observable<BreadcrumbItem[]> = this.collection.all();

  /**
   * @var {any[]}
   */
  protected history: any[] = WebCacheDB.get('breacrumb', []);

  /**
   * @var {string}
   */
  protected previous: string = null;

  /**
   * @var {Subscription}
   */
  protected subscription: Subscription = new Subscription();

  public currentLens
  public currentZoom
  public currentZoomLenses
  public currentZoomCount;

  constructor(private router: Router,
              private collection: BreadcrumbCollection,
              private dispatcher: Dispatcher
  ) {
    //
  }

  /**
   * Initialization.
   */
  ngOnInit() {
    this.previous = this.router.url;

    const whenItemsChange = this.items$.subscribe(() => {
      const { omittable = false } = this.collection.findBy('link', this.previous) || {};

      this.previous = omittable ? null : this.previous;
    });

    const tree = this.router.parseUrl(this.previous);
    const queryParams = tree.queryParams;
    this.currentLens = queryParams.lens;
    this.currentZoom = queryParams.zoom;

    const whenRouterChange = this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      const url = event.urlAfterRedirects;
      const tree = this.router.parseUrl(url);
      const queryParams = tree.queryParams;

      this.currentLens = queryParams.lens;
      this.currentZoom = queryParams.zoom;

      if (this.previous != null && this.previous.split('#').length == 1) {
        this.history.push(this.previous);

        WebCacheDB.put('breacrumb', this.history);
      }
      this.previous = event.url;
    });

    this.subscription.add(whenItemsChange);

    this.subscription.add(whenRouterChange);
  }

  /**
   * Go back in the navigation.
   *
   * @return void
   */
  goBack(): void {
    const target = this.history.pop();

    WebCacheDB.put('breacrumb', this.history);

    if (target) {
      this.previous = null;

      this.router.navigateByUrl(target);

      return;
    }

    let index = this.collection.count() - 1, item = this.collection.getAt(index); // prettier-ignore

    while (item && item.link == this.previous) {
      item = this.collection.getAt(--index);
    }

    if (item == null) return;

    this.previous = null;

    this.router.navigate(Array.isArray(item.link) ? item.link : [item.link]);
  }

  /**
   * Cleanup.
   */
  ngOnDestroy() {
    WebCacheDB.forget('breadcrumb');

    this.subscription.unsubscribe();
  }

  changeLens(dataset, $event) {
    this.currentLens = $event.value;
    dataset.lenses$.pipe(take(1)).subscribe((lenses) => lenses.forEach((lens) => {
      if(lens.key == this.currentLens) {
        dataset.changeLens(lens)
        this.currentZoomCount = lens.count;
        this.currentZoomLenses = null;
      }
    }))
  }

  changeZoom(dataset, $event) {
    this.currentZoom = $event.value;
    let foundZoom = false;
    dataset.lenses$.pipe(take(1)).subscribe((lenses) => lenses.forEach((lens) => {
      if(lens.key == this.currentLens) {
        lens.zooms.forEach((zoom) => {
          if(zoom.key == this.currentZoom) {
            foundZoom = true;
            dataset.changeZoom($event)
            this.currentZoomCount = zoom.count;
          }
        });
      }
    }))

    if(!foundZoom) {
      dataset.changeZoom('')
    }
  }

  getZooms(lenses$: Observable<any[]>, zooms: any[]): Observable<any[]> {
    if(this.currentZoomLenses) return of(this.currentZoomLenses)
    return lenses$.pipe(
      map((lenses: any[]) => {
        for (const lens of lenses) {
          if (lens.key === this.currentLens) {
            let newZooms = structuredClone(zooms);
            newZooms.unshift({
              key: '',
              label: 'Wszystkie rodzaje',
              count: lens.count,
            });
            this.currentZoomLenses = newZooms;
            return newZooms
          }
        }
        return zooms; // Zwróć oryginalną tablicę, jeśli nie znaleziono
      })
    );
  }
}
