import { StorageDB } from './storage-db';

export class WebStorageDB extends StorageDB {
  /**
   * @var {string}
   */
  protected static readonly PREFIX: string = 'g7r';

  /**
   * @var {Storage}
   */
  protected static storage: Storage = localStorage;
}
