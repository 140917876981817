import { Exception } from '@a3l/core';

export class AgreementNotCompletedException extends Exception {
  /**
   * @var {string}
   */
  static readonly type = 'AgreementNotCompletedException';

  /**
   * @var {string}
   */
  public readonly type = AgreementNotCompletedException.type;
}
