<form [formGroup]="form" class="p-2">
  <div class="mb-2 -mx-1 flex flex-col md:flex-row">
    <a3l-ui-form-group clear class="mx-1 mb-2 md:mb-0 flex-1">
      <label a3l-ui-label translate>candidate::panel.wizard.education.name</label>
      <localized-input-editor [activeLanguage]="activeLanguage" type="text" a3l-ui-input formControlName="name"></localized-input-editor>
      <ng-template a3l-ui-error-message let-key="key">{{ 'validation.' + key | translate }}</ng-template>
    </a3l-ui-form-group>
  </div>
  <div class="mb-2 -mx-1 flex flex-col md:flex-row">
    <a3l-ui-form-group clear class="mx-1 flex-1">
      <label a3l-ui-label translate>candidate::panel.wizard.education.field</label>
      <localized-input-editor [activeLanguage]="activeLanguage" type="text" a3l-ui-input formControlName="field"></localized-input-editor>
      <ng-template a3l-ui-error-message let-key="key">{{ 'validation.' + key | translate }}</ng-template>
    </a3l-ui-form-group>
  </div>
  <div class="-mx-1 flex flex-col md:flex-row">
    <a3l-ui-form-group clear class="mx-1 mb-2 md:mb-0 flex-1">
      <label a3l-ui-label translate>candidate::panel.wizard.education.from</label>
      <rex-experience-date formControlName="start_date" [placeholder]="'_ _ / _ _ _ _'"></rex-experience-date>
      <ng-template a3l-ui-error-message let-key="key">{{ 'validation.' + key | translate }}</ng-template>
    </a3l-ui-form-group>
    <a3l-ui-form-group clear class="mx-1 flex-1">
      <label a3l-ui-label translate>candidate::panel.wizard.education.to</label>
      <rex-experience-date formControlName="end_date" [showPresent]="true" [placeholder]="'_ _ / _ _ _ _'"></rex-experience-date>
      <ng-template a3l-ui-error-message let-key="key">{{ 'validation.' + key | translate }}</ng-template>
    </a3l-ui-form-group>
  </div>
</form>
