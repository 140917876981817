import { Injectable } from '@angular/core';
import { Router, ResolveStart } from '@angular/router';
import { filter } from 'rxjs/operators';

import { ComponentPortal } from '@angular/cdk/portal';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';

import { SnackBarRef } from './ref';
import { SnackBarType, SnackBarPersistable } from './config';

import { SnackBarContainerComponent } from './snack-bar-container.component';

export const UI_SNACK_BAR_PANEL_CLASS = 'cdk-a3l-ui-snack-bar-pane';

import { SnackBarModule } from './snack-bar.module';

@Injectable({ providedIn: SnackBarModule })
export class SnackBar {
  /**
   * @var {OverlayRef}
   */
  protected ref: OverlayRef = null;

  /**
   * @var {SnackBarContainerComponent}
   */
  protected container: SnackBarContainerComponent = null;

  /**
   * Create a new instance.
   *
   * @param {Router} router
   * @param {Overlay} overlay
   */
  constructor(private router: Router, private overlay: Overlay) {
    this.router.events.pipe(filter((event) => event instanceof ResolveStart)).subscribe(() => this.container && this.container.closeAll());
  }

  /**
   * Opens a snack bar with a message and an optional action.
   *
   * @param {string} message
   * @param {SnackBarType} type
   * @param {SnackBarPersistable} persistable
   * @param {number} duration
   * @return SnackBarRef
   */
  open(message: string, type: SnackBarType = SnackBarType.Info, persistable: SnackBarPersistable = 0, duration: number = 0): SnackBarRef {
    if (typeof message !== 'string') return;

    this.container = this.createOverlayWithContainer();

    const ref = new SnackBarRef();

    this.container.push({ ref, type, message, persistable, duration });

    return ref;
  }

  /**
   * Creates an SnackBarContainerComponent and the overlay.
   *
   * @return SnackBarContainerComponent
   */
  protected createOverlayWithContainer(): SnackBarContainerComponent {
    this.ref = this.overlay.create({
      maxWidth: '100%',
      panelClass: UI_SNACK_BAR_PANEL_CLASS,
      positionStrategy: this.overlay.position().global().top('70px').right('0'),
    });

    const portal = new ComponentPortal(SnackBarContainerComponent);
    const container = this.ref.attach(portal);

    return container.instance;
  }
}
