import { Specification } from '@a3l/core';

export class AgreementIsInSigningProcessSpecification extends Specification<any> {
  isSatisfiedBy(user: any): boolean {
    const { role } = user;

    if (role == 'superadmin' || role == 'leader' || role == 'client_company_user' || role == 'candidate_user') return false;

    let agreement = user.organization.agreement;

    if (!agreement) return false;

    return agreement.status != null && agreement.status != 'draft' && agreement.status != 'completed';
  }
}
