import {
  AfterContentChecked, AfterViewInit,
  Component, ElementRef,
  forwardRef,
  Input, OnChanges, OnInit, Renderer2,
  ViewChild
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {Control} from './control';
import {NumberSeparatorService} from "@rex/shared/services/number-separator.service";
import {MaskApplierService} from "ngx-mask";

@Component({
  selector: 'a3l-ui-currency-input',
  templateUrl: './currency-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CurrencyInputComponent),
      multi: true,
    },
    {provide: Control, useExisting: CurrencyInputComponent},
  ],
  host: {
    class: 'a3l-ui-currency-input',
    '(focus)': 'focused = true',
    '(blur)': 'focused = false',
  },
})
export class CurrencyInputComponent extends Control implements ControlValueAccessor, OnChanges {

  /**
   * @var {any}
   */
  @Input()
  value: any;

  @Input()
  currency: string

  @Input()
  disabled: boolean = false

  @ViewChild('input') input: ElementRef;

  constructor(
    protected separatorService: NumberSeparatorService,
    protected mask: MaskApplierService
  ) {
    super();
  }

  ngOnChanges(changes) {
    if(changes.currency.currentValue != changes.currency.previousValue) {
      this.value = this.toString(this.value);
    }
  }

  /**
   * @var {any}
   */
  protected propagateChange: any = () => {
  };

  change($event) {
    if($event.target.value.length == 0) {
      this.value = null;
      this.propagateChange(null);
      return ;
    }
    this.value = this.toString($event.target.value);
    this.propagateChange(this.toInt(this.value));
  }

  /**
   * @var {boolean}
   */
  get canShowValidationMessage(): boolean {
    return this.focused;
  }

  focusin(): void {
    if(!this.value) return;

    let maxLength = this.value.length - 1 - this.currency.length;
    if(this.input.nativeElement.selectionStart > maxLength) {
      this.input.nativeElement.setSelectionRange(maxLength, maxLength);
    }
    this.focused = true;
  }

  /**
   * Focusout of the input.
   *
   * @return void
   */
  focusout(): void {
    this.focused = false;
  }

  /**
   * Write a new value from the form model.
   *
   * @param {any} value
   * @return void
   */
  writeValue(value: any): void {
    this.value = this.toString(value);
  }

  /**
   * Register handler.
   *
   * @param {any} fn
   * @return void
   */
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  /**
   * Register handler.
   *
   * @param {any} fn
   * @return void
   */
  registerOnTouched(fn: any): void {
  }

  toInt(value) {
    return this.separatorService.toInt(value);
  }

  toString(value) {
    if(!value && value !== 0) {
      return '';
    }
    return this.mask.applyMask(value.toString(), 'separator.0') + " " + (this.currency ? this.currency.toUpperCase() : '');
  }

  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;

    if(this.disabled) {
      event.preventDefault();
      return;
    }

    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
      this.input.nativeElement.value = this.toString(this.input.nativeElement.value);
      this.value = this.toString(this.input.nativeElement.value);
    }
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }
}
