import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { Query } from '@a3l/core';
import { ApiService } from '@a3l/api';

@Injectable({ providedIn: 'root' })
export class DictionaryOfIndustriesQuery extends Query<any> {
  /**
   * Create a new instance.
   *
   * @param {ApiService} service
   */
  constructor(private api: ApiService) {
    super();
  }

  /**
   * Run the query.
   *
   * @return void
   */
  execute(): void {
    this._pending$.next(true);

    this.api
      .get('/dictionaries/industries')
      .pipe(
        map((values) =>
          values.map((item) => {
            const { content } = item;

            return { ...item, content };
          })
        )
      )
      .subscribe((values) => {
        this._pending$.next(false);

        this._value$.next(values);
      });
  }
}
