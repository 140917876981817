import { Component, Input } from "@angular/core";

@Component({
  selector: 'rex-recruiter-candidate-column',
  templateUrl: './candidates.component.html',
  styles: ['li + li::before {\n' +
  '    content: " / ";\n' +
  '}']

})
export class CandidatesColumnComponent {
  /**
   * @var {string}
   */
  @Input()
  view: string = null;

  /**
   * @var {any}
   */
  @Input()
  item: any = null;
}
