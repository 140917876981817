<div class="rex-file-presenter__icon">
  <a3l-ui-icon icon="file"></a3l-ui-icon>
</div>
<div class="rex-file-presenter__content">
  <div class="rex-file-presenter__summary" *ngIf="file.collection_name == 'recommendation'; else standard">
    <span class="rex-file-presenter__name">{{ (file.project_name || file.name) | a3lTruncate: 25 }}</span>
    <ng-container *rex-auth-role="['superadmin', 'leader', 'recruiter']">
      <span *ngIf="file.recruiter_name" class="rex-file-presenter__parameter">{{ 'Nazwa rekrutera' | translate}}: <strong class="rex-file-presenter__extension">{{ file.recruiter_name }}</strong> </span>
    </ng-container>
    <span class="rex-file-presenter__parameter">{{ 'Data rekomendacji' | translate }}: <strong class="rex-file-presenter__size">{{ file.recommendation_date | a3lDatetime }}</strong> </span>
  </div>
  <ng-template #standard>
    <div class="rex-file-presenter__summary" >
      <span class="rex-file-presenter__name">{{ file.name | a3lTruncate: 25 }}</span>
      <span class="rex-file-presenter__parameter" *ngIf="file.extension"> Format: <strong class="rex-file-presenter__extension">{{ file.extension }}</strong> </span>
      <span class="rex-file-presenter__parameter" *ngIf="file.size"> Rozmiar: <strong class="rex-file-presenter__size">{{ file.size | a3lPrettyBytes }}</strong> </span>
    </div>
  </ng-template>
  <div class="rex-file-presenter-cta">
    <div class="rex-file-presenter-cta__item">
      <button [rex-file-download]="file" [rex-file-preview]="false" (loading)="progress($event)" a3l-ui-button small translate [processing]="processing">Pobierz</button>
    </div>
  </div>
</div>
