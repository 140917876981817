import { Component, ViewEncapsulation, ContentChild } from '@angular/core';

import { LayoutSidenav } from './layout-sidenav';

@Component({
  selector: 'a3l-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'a3l-layout',
    '[class.has-sidenav-opened]': 'sidenav && sidenav.opened',
    '[class.has-sidenav-minimized]': 'sidenav && sidenav.minimized',
  },
})
export class LayoutComponent {
  /**
   * @var {LayoutSidenav}
   */
  @ContentChild(LayoutSidenav)
  sidenav: LayoutSidenav;

  /**
   * Create a new instance.
   */
  constructor() {
    //
  }
}
