import {Query} from "@a3l/core";
import {ApiService} from "@a3l/api";
import {Injectable} from "@angular/core";


@Injectable({ providedIn: 'root' })
export class OrganizationsOnPlatformQuery extends Query<any> {
  /**
   * Create a new instance.
   *
   * @param {ApiService} api
   */
  constructor(private api: ApiService) {
    super();
  }

  /**
   * Run the query.
   *
   * @return void
   */
  execute(): void {
    this._pending$.next(true);

    this.api
      .get('/organization/organizations-on-platform')
      .subscribe((values) => {
        this._pending$.next(false);

        this._value$.next(values);
      });
  }
}
