import {Injectable} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {uuid} from "@a3l/utilities";

@Injectable({providedIn: 'root'})
export class SkillForm {
  public emptyForm = (params = null) => {
    return new FormGroup({
      uuid: new FormControl(uuid()),
      value: new FormControl(null, Validators.required),
      level: new FormControl(null, Validators.required),
    });
  }

  public formFromArray = (array) => {
    let form = this.emptyForm(array.parameters);
    form.controls['uuid'].setValue(array.uuid ? array.uuid : uuid());
    form.controls['value'].setValue(array.value);
    form.controls['level'].setValue(array.level);

    return form;
  }
}
