import { Component, Input, ContentChild } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

import { TabTitleDirective } from './tab-title.directive';

type Position = 'left' | 'center' | 'right';

@Component({
  selector: 'a3l-ui-tab',
  template: '<ng-content></ng-content>',
  host: {
    class: 'a3l-ui-tabs-item',
    '[class.a3l-ui-tabs-item--active]': 'active',
    '[style.zIndex]': 'zIndex',
    '[@position]': 'positionOnScreen',
    '[class.a3l-ui-tabs-item--right]': 'toRight',
  },
  animations: [
    trigger('position', [
      state('center, void', style({ transform: 'none' })),
      state('left', style({ transform: 'translate3d(-100%, 0, 0)' })),
      state('right', style({ transform: 'translate3d(100%, 0, 0)' })),
      transition('* => left, * => right, left => center, right => center', animate('500ms cubic-bezier(0.35, 0, 0.25, 1)')),
    ]),
  ],
})
export class TabComponent {
  /**
   * @var {boolean}
   */
  @Input()
  get active(): boolean {
    return this._active;
  }
  set active(value: boolean) {
    this._active = value !== false;
  }
  /**
   * @var {boolean}
   */
  @Input()
  get toRight(): boolean {
    return this._toRight;
  }
  set toRight(value: boolean) {
    this._toRight = value !== false;
  }

  @Input()
  id: string;

  /**
   * @var {number}
   */
  @Input()
  set position(position: number) {
    if (position < 0) {
      this.zIndex = position * -1;
      this.positionOnScreen = 'left';
    } else if (position > 0) {
      this.zIndex = position;
      this.positionOnScreen = 'right';
    } else {
      this.positionOnScreen = 'center';
    }
  }

  /**
   * @var {boolean}
   */
  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = value !== false;
  }

  /**
   * @var {TabTitleDirective}
   */
  @ContentChild(TabTitleDirective, { static: true })
  templateLabel: TabTitleDirective;

  /**
   * @var {number}
   */
  zIndex: number = 0;

  /**
   * @var {Position}
   */
  positionOnScreen: Position;

  /**
   * @var {boolean}
   */
  protected _active: boolean = false;
  protected _toRight: boolean = false;

  /**
   * @var {boolean}
   */
  protected _disabled: boolean = false;

  /**
   * Create a new instance.
   */
  constructor() {
    //
  }
}
