import {Control} from "@a3l/utilities";
import {Component, EventEmitter, forwardRef, OnDestroy, OnInit, Output, ViewEncapsulation} from "@angular/core";
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import {Observable, Subscription} from "rxjs";
import {Dispatcher} from "@a3l/core";
import {IndustryForm} from "@rex/common/industries/industry.form";
import {DictionaryArrayOfIndustriesQuery} from "@rex/common/dictionary-array-of-industries.query";
import {CvParserCandidateIndustriesEvent} from "@rex/shared/recruitments/events/cv-parser-candidate-industries.event";
import {IndustriesQuery} from "@rex/common/industries.query";

interface AutoCompleteCompleteEvent {
  originalEvent: Event;
  query: string;
}

@Component({
  selector: 'rex-industry-field',
  templateUrl: 'industry.field.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IndustryField),
      multi: true,
    },
    { provide: Control, useExisting: IndustryField }
  ],
  encapsulation: ViewEncapsulation.None
})
export class IndustryField extends Control implements OnInit, OnDestroy, ControlValueAccessor {
  isValid = true;
  isLoading = false;

  public value = new FormControl();

  industriesDictionary;

  /**
   * @var {any}
   */
  protected propagateChange: any = () => {};

  /**
   * @var {Subscription}
   */
  protected subscription: Subscription = Subscription.EMPTY;

  filteredSuggestions: any[] | undefined;

  constructor(
    protected dispatcher: Dispatcher,
    private industriesQuery: IndustriesQuery,
  ) {
    super();
  }


  filterSuggestions(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < (this.industriesDictionary as any[]).length; i++) {
      let suggestion = (this.industriesDictionary as any[])[i];

      if (suggestion.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(suggestion);
      }
    }

    this.filteredSuggestions = filtered;
  }

  ngOnInit() {
    this.industriesQuery.execute();

    this.industriesQuery.value$.subscribe((items) => this.industriesDictionary = items);
  }

  updateValue($event)
  {
    this.value.patchValue($event.target.value);
    this.propagateChange($event.target.value)
  }

  /**
   * Write a new value from the form model.
   *
   * @param {any} value
   * @return void
   */
  writeValue(value: any): void {
    if (!value ) return;
    this.value.patchValue(value);
  }

  /**
   * Register handler.
   *
   * @param {any} fn
   * @return void
   */
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  /**
   * Register handler.
   *
   * @param {any} fn
   * @return void
   */
  registerOnTouched(fn: any): void {}

  /**
   * Initialization.
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
