import { Component, ViewChild, ViewEncapsulation, HostListener, forwardRef, Input, ElementRef, Injector, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';

import { FileUploadMaxSizeException, FileUploadUnsupportedException } from '@a3l/core';

import { Control } from './control';

export interface UploadedFile {
  id?: string;
  name: string;
  size: number;
  extension: string;
  file?: any;
}

export const extension = (file) => /(?:\.([^.]+))?$/.exec(file)[1];

export const filename = (file) => file.replace(new RegExp(`.${extension(file)}$$`), '');

@Component({
  selector: 'a3l-ui-fileupload',
  templateUrl: './fileupload.component.html',
  styleUrls: ['./fileupload.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'a3l-ui-fileupload',
    '[class.a3l-ui-fileupload--dragover]': 'dragging',
    '[class.a3l-ui-fileupload--invalid]': '!valid',
    '(mouseover)': '_canShowValidationInTooltip = true',
    '(mouseleave)': '_canShowValidationInTooltip = false',
  },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileuploadComponent),
      multi: true,
    },
    { provide: Control, useExisting: FileuploadComponent },
  ],
})
export class FileuploadComponent extends Control implements OnInit, ControlValueAccessor {
  /**
   * @var {string}
   */
  @Input()
  accepts: string = '';

  /**
   * @var {number}
   */
  @Input('max-file-size-in-mb')
  maxFileSizeInMB: number = null;

  /**
   * @var {boolean}
   */
  @Input()
  get multiple(): boolean {
    return this._multiple;
  }
  set multiple(value: boolean) {
    this._multiple = value !== false;
  }

  /**
   * @var {boolean}
   */
  @Input()
  disabled: boolean = false;

  /**
   * @var {any}
   */
  value: any;

  /**
   * @var {boolean}
   */
  dragging: boolean = false;

  /**
   * @var {boolean}
   */
  get valid(): boolean {
    if (this.ngControl === undefined) return true;

    return !(this.ngControl.invalid && this.ngControl.dirty);
  }

  /**
   * @var {boolean}
   */
  get canShowValidationInTooltip(): boolean {
    return !this.dragging && this._canShowValidationInTooltip;
  }

  /**
   * @var {ElementRef}
   */
  @ViewChild('input', { static: false })
  protected input: ElementRef;

  /**
   * @var {NgControl}
   */
  protected ngControl: NgControl;

  /**
   * @var {boolean}
   */
  protected _multiple: boolean = false;

  /**
   * @var {any}
   */
  protected propagateChange: any = () => {};

  /**
   * @var {boolean}
   */
  _canShowValidationInTooltip: boolean = false;

  /**
   * Create a new instance.
   *
   * @param {Injector} injector
   */
  constructor(private injector: Injector) {
    super();
  }

  /**
   * Initialization.
   */
  ngOnInit() {
    this.ngControl = this.injector.get(NgControl);
  }

  /**
   * Open the system explorer.
   *
   * @param {MouseEvent} event
   * @return void
   */
  @HostListener('click', ['$event'])
  protected explorer(event: MouseEvent): void {
    event.preventDefault();

    if (!this.disabled) {
      if(event.target instanceof Element && event.target.nodeName == 'BUTTON') {
        return;
      }
      this.input.nativeElement.click();
    }
  }

  /**
   * Handle the 'dragover' event.
   *
   * @param {MouseEvent} event
   * @return void
   */
  @HostListener('dragover', ['$event'])
  protected onDOMDragover(event: MouseEvent): void {
    event.preventDefault();

    this.dragging = true;
  }

  /**
   * Handle the 'dragleave' event.
   *
   * @param {MouseEvent} event
   * @return void
   */
  @HostListener('dragleave', ['$event'])
  protected onDOMDragleave(event: MouseEvent): void {
    event.preventDefault();

    this.dragging = false;
  }

  /**
   * Handle the 'drop' event.
   *
   * @param {DragEvent} event
   * @return void
   */
  @HostListener('drop', ['$event'])
  protected byDragEvent(event: DragEvent): void {
    event.preventDefault();

    this.dragging = false;

    Array.from(event.dataTransfer.files).forEach((file: File) => this.add(file));
  }

  /**
   * Handle file selection.
   *
   * @param {Event} event
   * @return void
   */
  byExplorer(event: Event): void {
    Array.from(this.input.nativeElement.files).forEach((file: File) => this.add(file));
  }

  /**
   * Add the file to the value.
   *
   * @param {File} file
   * @return void
   */
  add(file: File): void {
    const { name, size } = file;

    if (this.maxFileSizeInMB && size / 1024 / 1024 > this.maxFileSizeInMB) throw new FileUploadMaxSizeException();

    const ext = extension(name).toLowerCase();

    const accepts = this.accepts.split(',').filter((i) => i);
    if (accepts.length != 0 && accepts.indexOf(ext) == -1) {
      throw new FileUploadUnsupportedException();
    }

    const uploaded: UploadedFile = { name: filename(name), extension: ext, size, file };

    if (this.multiple) {
      this.value.push(uploaded);

      this.propagateChange(this.value);

      return;
    }

    this.value = uploaded;

    this.propagateChange(this.value);
  }

  /**
   * Remove the file on the given index.
   *
   * @param {number} index
   * @return void
   */
  removeAt(index: number): void {
    this.value.splice(index, 1);

    this.propagateChange(this.value);
  }

  /**
   * Clear all files.
   *
   * @return void
   */
  clear(): void {
    if (!this.disabled) {
      this.value = this.multiple ? [] : null;

      this.propagateChange(this.value);
    }
  }

  /**
   * Write a new value from the form model.
   *
   * @param {any} value
   * @return void
   */
  writeValue(value: any): void {
    this.value = value;

    if (this.value != null && this.multiple) {
      this.value = [...value];
    }

    if (this.value == null && this.multiple) {
      this.value = [];
    }
  }

  /**
   * Register handler.
   *
   * @param {any} fn
   * @return void
   */
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  /**
   * Register handler.
   *
   * @param {any} fn
   * @return void
   */
  registerOnTouched(fn: any): void {}
}
