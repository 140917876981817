<ng-container *ngIf="value">
  <ng-container *ngTemplateOutlet="multiple ? files : file; context: { $implicit: value }"></ng-container>
</ng-container>

<ng-container *ngIf="!value || multiple">
  <ng-container *ngTemplateOutlet="dragging ? drop : dragover; context: { $implicit: multiple ? value.length == 0 : true }"></ng-container>
</ng-container>

<ng-template #dragover let-withicon>
  <div class="a3l-ui-fileupload-message">
    <a3l-ui-icon icon="dnd" class="a3l-ui-fileupload-message__icon" *ngIf="withicon"></a3l-ui-icon>
    <ng-content select="[a3l-ui-fileupload-text-dropzone]"></ng-content>
  </div>
</ng-template>

<ng-template #drop let-withicon>
  <div class="a3l-ui-fileupload-message">
    <a3l-ui-icon icon="dnd" class="a3l-ui-fileupload-message__icon" *ngIf="withicon"></a3l-ui-icon>
    <ng-content select="[a3l-ui-fileupload-text-drop]"></ng-content>
  </div>
</ng-template>

<ng-template #file let-file>
  <div class="a3l-ui-fileupload-file">
    <div class="a3l-ui-fileupload-file__icon">
      <a3l-ui-icon icon="file"></a3l-ui-icon>
    </div>
    <span class="a3l-ui-fileupload-file__name">{{ file.name | a3lTruncate: 25 }}</span>
    <div class="a3l-ui-fileupload-file__summary">
      <span class="a3l-ui-fileupload-file__parameter">
        <ng-content select="[a3l-ui-fileupload-text-file-extension]"></ng-content>: <strong class="a3l-ui-fileupload-file__extension">{{ file.extension }}</strong>
      </span>
      <span class="a3l-ui-fileupload-file__parameter">
        <ng-content select="[a3l-ui-fileupload-text-file-size]"></ng-content>: <strong class="a3l-ui-fileupload-file__size">{{ file.size | a3lPrettyBytes }}</strong>
      </span>
      <span class="a3l-ui-fileupload-files-item__parameter">
        <ng-content select="[a3l-ui-fileupload-text-file-download]"></ng-content>
      </span>
      <button type="button" class="a3l-ui-fileupload-file__clear" (click)="clear(); $event.stopPropagation()">
        <a3l-ui-icon icon="trash"></a3l-ui-icon>
      </button>
    </div>
  </div>
</ng-template>

<ng-template #files let-files>
  <ul class="a3l-ui-fileupload-files">
    <li class="a3l-ui-fileupload-files-item" *ngFor="let file of files; let i = index">
      <div class="a3l-ui-fileupload-files-item__icon">
        <a3l-ui-icon icon="file"></a3l-ui-icon>
      </div>
      <div class="a3l-ui-fileupload-files-item__content">
        <div class="a3l-ui-fileupload-files-item__summary">
          <span class="a3l-ui-fileupload-files-item__name">{{ file.name | a3lTruncate: 25 }}</span>
          <span class="a3l-ui-fileupload-files-item__parameter">
            <ng-content select="[a3l-ui-fileupload-text-file-extension]"></ng-content>: <strong class="a3l-ui-fileupload-files-item__extension">{{ file.extension }}</strong>
          </span>
          <span class="a3l-ui-fileupload-files-item__parameter">
            <ng-content select="[a3l-ui-fileupload-text-file-size]"></ng-content>: <strong class="a3l-ui-fileupload-files-item__size">{{ file.size | a3lPrettyBytes }}</strong>
          </span>
          <span class="a3l-ui-fileupload-files-item__parameter">
            <ng-content select="[a3l-ui-fileupload-text-file-download]"></ng-content>
          </span>
        </div>
        <button *ngIf="!disabled" type="button" class="a3l-ui-fileupload-files-item__clear" (click)="removeAt(i); $event.stopPropagation()">
          <a3l-ui-icon icon="trash"></a3l-ui-icon>
        </button>
      </div>
    </li>
  </ul>
</ng-template>

<input type="file" [multiple]="multiple" (change)="byExplorer($event)" (click)="$event.stopPropagation()" #input />
