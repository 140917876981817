<a3l-ui-spinner size="30" class="mx-auto my-6 block" *ngIf="loading$ | async"></a3l-ui-spinner>
<ng-container *ngIf="!(loading$ | async)">
  <ng-container *ngIf="items$ | async as items">
    <ng-template let-c [ngTemplateOutlet]="t" #t>
      <ng-multiselect-dropdown class="rex-industries-select"
        [placeholder]="'Wybierz' | translate"
        [settings]="getMultiSelectSetting()"
        [data]="items"
        [ngModel]="getSelectedItems(items)"
        (onSelect)="toggle($event)"
        (onDeSelect)="toggle($event)"
        [disabled]="!canBeEdited">
      </ng-multiselect-dropdown>
    </ng-template>
  </ng-container>
</ng-container>
