import {Component, Input, OnInit} from "@angular/core";

@Component({
  selector: 'rex-bar-loader',
  templateUrl: './bar-loader.html'
})
export class BarLoader implements OnInit {
  @Input()
  text: string;

  @Input()
  styles: string = 'py-16 px-10 relative items-center justify-center';

  analyzingDataLoadingTime: number = 0;

  ngOnInit() {
    setInterval(() => this.analyzingDataLoadingTime += 2, 50);
  }
}
