import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { Query } from '@a3l/core';
import { ApiService } from '@a3l/api';

@Injectable({ providedIn: 'root' })
export class IndustriesQuery extends Query<any> {
  /**
   * Create a new instance.
   *
   * @param {ApiService} service
   */
  constructor(private api: ApiService) {
    super();
  }

  /**
   * Run the query.
   *
   * @return void
   */
  execute(): void {
    this._pending$.next(true);

    this.api
      .get('/industries')
      .pipe(
        map((values) =>
          values.industries.filter(n => n)
        )
      )
      .subscribe((values) => {
        this._pending$.next(false);

        this._value$.next(values);
      });
  }
}
