import * as url from 'url';
import * as psl from 'psl';

export const hostname = (location: string = window.location.href): string => {
  return url.parse(location).hostname;
};

export const domain = (location: string = window.location.href) => {
  const host = hostname(location);

  return psl.parse(host).domain;
};

export const subdomain = (location: string = window.location.href) => {
  const host = hostname(location);

  return psl.parse(host).subdomain;
};
