import {Injectable} from "@angular/core";
import {Loader} from "@googlemaps/js-api-loader";
import {environment} from "@rex/environments/environment";
import {TranslateService} from "@ngx-translate/core";
@Injectable({ providedIn: 'root' })
export class GoogleMap {

  public loader;

  constructor(private translate: TranslateService) {
    const lang: string = this.translate ? this.translate.currentLang : environment.services.google.maps.language
    this.loader = new Loader({ apiKey: environment.services.google.maps.key, libraries: ['drawing', 'places'], language: lang});
  }
}
