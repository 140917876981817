import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'traffit-icon',
  templateUrl: './traffit-icon.html',
  encapsulation: ViewEncapsulation.None,
})
export class TraffitIcon {

  /**
   * @var {number}
   */
  @Input()
  size: number;

  /**
   * @var {string}
   */
  @Input()
  link: string;

}
