<form [formGroup]="form" class="p-2">
  <div class="mb-2 -mx-1 flex flex-col md:flex-row">
    <a3l-ui-form-group clear class="mx-1 flex-1">
      <label a3l-ui-label translate>candidate::panel.wizard.work_experience.position</label>
      <localized-input-editor [activeLanguage]="activeLanguage" formControlName="position_name"></localized-input-editor>
      <ng-template a3l-ui-error-message let-key="key">{{ 'validation.' + key | translate }}</ng-template>
    </a3l-ui-form-group>
  </div>
  <div class="mb-2 -mx-1 flex flex-col md:flex-row">
    <a3l-ui-form-group clear class="mx-1 mb-2 md:mb-0 flex-1">
      <label a3l-ui-label translate>candidate::panel.wizard.work_experience.company_name</label>
      <input type="text" a3l-ui-input formControlName="company_name" [placeholder]="'Wpisz' | translate"/>
      <ng-template a3l-ui-error-message let-key="key">{{ 'validation.' + key | translate }}</ng-template>
    </a3l-ui-form-group>
    <a3l-ui-form-group clear class="mx-1 flex-1">
      <label a3l-ui-label translate>candidate::panel.wizard.title.industry</label>
      <rex-industry-field formControlName="industry"></rex-industry-field>
      <ng-template a3l-ui-error-message let-key="key">{{ 'validation.' + key | translate }}</ng-template>
    </a3l-ui-form-group>
  </div>
  <div class="mb-2 -mx-1 flex flex-col md:flex-row">
    <a3l-ui-form-group clear class="mx-1 mb-2 md:mb-0 flex-1">
      <label a3l-ui-label translate>candidate::panel.wizard.work_experience.from</label>
      <rex-experience-date formControlName="from" [placeholder]="'_ _ / _ _ _ _'"></rex-experience-date>
      <ng-template a3l-ui-error-message let-key="key">{{ 'validation.' + key | translate }}</ng-template>
    </a3l-ui-form-group>
    <a3l-ui-form-group clear class="mx-1 flex-1">
      <label a3l-ui-label translate>candidate::panel.wizard.work_experience.to</label>
      <rex-experience-date formControlName="to" [showPresent]="true" [placeholder]="'_ _ / _ _ _ _'"></rex-experience-date>
      <ng-template a3l-ui-error-message let-key="key">{{ 'validation.' + key | translate }}</ng-template>
    </a3l-ui-form-group>
  </div>
  <div class="-mx-1 flex flex-col md:flex-row">
    <a3l-ui-form-group clear class="mx-1 flex-1">
      <label a3l-ui-label translate>candidate::panel.wizard.title.work_duties</label>
      <localized-wysiwyg-editor [activeLanguage]="activeLanguage" formControlName="responsibilities"></localized-wysiwyg-editor>
      <ng-template a3l-ui-error-message let-key="key">{{ 'validation.' + key | translate }}</ng-template>
    </a3l-ui-form-group>
  </div>
</form>
