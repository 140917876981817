import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PortalTarget } from './portal-target';
import { PortalSource } from './portal-source';

const PUBLIC_API = [PortalTarget, PortalSource];

@NgModule({
  declarations: [...PUBLIC_API],
  imports: [CommonModule],
  exports: [...PUBLIC_API],
})
export class PortalModule {}
