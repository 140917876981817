export abstract class Event {
  /**
   * @var {string}
   */
  static readonly type: string;

  /**
   * @var {string}
   */
  public readonly type: string;
}
