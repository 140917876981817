import { Injectable, Injector } from '@angular/core';
import { Router, ResolveStart } from '@angular/router';
import { filter } from 'rxjs/operators';

import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType, PortalInjector } from '@angular/cdk/portal';

import { DialogRef } from './dialog-ref';
import { DialogConfig } from './config';
import { DialogContainerComponent } from './dialog-container.component';
import { UI_DIALOG_DATA, UI_DIALOG_CONFIG } from './token';
import { DialogModule } from './dialog.module';

export const UI_DIALOG_PANEL_CLASS = 'cdk-a3l-ui-dialog-pane';

@Injectable({ providedIn: DialogModule })
export class Dialog {
  /**
   * @var {Dialog<any>[]}
   */
  protected openDialogs: DialogRef<any>[] = [];

  protected showCLoseButton = true;

  /**
   * Create a new instance.
   *
   * @param {Router} router
   * @param {Overlay} overlay
   * @param {Injector} injector
   */
  constructor(private router: Router, private overlay: Overlay, private injector: Injector) {
    this.router.events.pipe(filter((event) => event instanceof ResolveStart)).subscribe(() => {
      this.closeAll();
    });
  }

  hideCloseButton()
  {
    this.showCLoseButton = false;
  }

  /**
   * Opens a dialog containing the given component.
   *
   * @param {ComponentType<T>} componentRef
   * @param {any} data
   * @param {DialogConfig} userConfig
   * @return DialogRef
   */
  open<T = any>(componentRef: ComponentType<T>, data?: any, userConfig?: DialogConfig): DialogRef<T, any> {
    const overlayRef = this.overlay.create({
      panelClass: UI_DIALOG_PANEL_CLASS,
      hasBackdrop: true,
      disposeOnNavigation: true,
      scrollStrategy: this.overlay.scrollStrategies.block(),
    });

    const container = this.attachContainer(overlayRef);

    const config = { showCloseButton: this.showCLoseButton, ...userConfig };

    const ref = this.attachContent<T>(componentRef, container, overlayRef, data, config);

    this.openDialogs.push(ref);

    ref.afterClosed().subscribe(() => this.removeOpenDialog(ref));

    return ref;
  }


  /**
   * Close all dialog.
   *
   * @return void
   */
  closeAll(): void {
    let i = this.openDialogs.length;

    while (i--) {
      this.openDialogs[i].close();
    }
  }

  /**
   * Attaches an DialogContainerComponent to a dialog's already-created overlay.
   *
   * @param {OverlayRef} overlay
   * @return DialogContainerComponent
   */
  private attachContainer(overlay: OverlayRef) {
    const containerPortal = new ComponentPortal(DialogContainerComponent);
    const containerRef = overlay.attach(containerPortal);

    return containerRef.instance;
  }

  /**
   * Attaches the user-provided component to the already-created DialogContainerComponent.
   *
   * @param {ComponentType<any>} componentRef
   * @param {DialogContainerComponent} container
   * @param {OverlayRef} overlayRef
   * @param {any} data
   * @param {DialogConfig} config
   * @return any
   */
  private attachContent<T>(componentRef: ComponentType<T>, container: DialogContainerComponent, overlayRef: OverlayRef, data?: any, config?: DialogConfig) {
    const ref = new DialogRef<T>(overlayRef, container, config);

    container.onClose.subscribe(() => ref.close());

    const injector = this.createInjector<T>(ref, container, config, data || {});
    const contentRef = container.attachComponentPortal<T>(new ComponentPortal(componentRef, undefined, injector));

    ref.componentInstance = contentRef.instance;

    return ref;
  }

  /**
   * Creates a custom injector to be used inside the dialog. This allows a component loaded inside
   * of a dialog to close itself and, optionally, to return a value.
   *
   * @param {DialogRef<any>} ref
   * @param {DialogContainerComponent} container
   * @param {DialogConfig} config
   * @param {any} data
   * @return PortalInjector
   */
  private createInjector<T>(ref: DialogRef<T>, container: DialogContainerComponent, config: DialogConfig, data?: any) {
    const injectionTokens = new WeakMap();
    injectionTokens.set(DialogRef, ref);

    injectionTokens.set(UI_DIALOG_DATA, data);
    injectionTokens.set(UI_DIALOG_CONFIG, config);

    return new PortalInjector(this.injector, injectionTokens);
  }

  /**
   * Removes a dialog from the array of open dialog.
   *
   * @param {Dialog<any>} ref
   * @return void
   */
  private removeOpenDialog(ref: DialogRef<any>) {
    const index = this.openDialogs.indexOf(ref);

    if (index > -1) {
      this.openDialogs.splice(index, 1);
    }
  }
}
