import {
  AfterContentChecked,
  Component, EventEmitter,
  forwardRef,
  Input, OnChanges, Output, SimpleChanges,
  ViewChild
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {Control} from './control';
import {QuillViewComponent} from "ngx-quill";
import {QuillConfig} from "./quill.config";
import EmojiBlot from "./emoji-blot";
import {nextTick} from "@a3l/utilities";

@Component({
  selector: 'quill-rich',
  templateUrl: './quill-rich.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => QuillRichComponent),
      multi: true,
    },
    {provide: Control, useExisting: QuillRichComponent},
  ],
  host: {
    class: 'quill-rich',
    '(onFocus)': 'focused = true',
    '(onBlur)': 'focused = false',
  },
})
export class QuillRichComponent extends Control implements ControlValueAccessor, AfterContentChecked, OnChanges {

  /**
   * @var {any}
   */
  @Input()
  value: any;

  @Input()
  disabled = false;

  @Output()
  onContentChanged = new EventEmitter;

  hasAlreadyFilled: boolean = false;

  /**
   * @var {any}
   */
  @Input()
  shouldRefreshHtml: boolean = false;

  editorModules = new QuillConfig().getToolbarWithAdditionalOptions([
    ['link', 'emoji'],
  ], {
    'emoji-toolbar': true,
    'emoji-shortname': true
  })

  @ViewChild('editor') editor!: QuillViewComponent;

  ngAfterContentChecked() {
    if (this.editor && this.editor.quillEditor && !this.hasAlreadyFilled) {
      this.hasAlreadyFilled = true;
      this.editor.quillEditor.pasteHTML(this.value);
    }
  }


  ngOnChanges(changes: SimpleChanges) {
    if(this.editor) {
      if(this.editor.quillEditor) {
        if (this.disabled) {
          this.editor.quillEditor.disable()
        } else {
          this.editor.quillEditor.enable()
        }
      }

      if(changes.shouldRefreshHtml && changes.shouldRefreshHtml.currentValue) {
        this.editor.quillEditor.pasteHTML(this.value);
      }
    }
  }

  /**
   * @var {any}
   */
  protected propagateChange: any = () => {
  };

  contentChanged($event) {
    let html = $event.html;

    html = this.processEmojies(html);

    this.value = html;
    this.propagateChange(this.value);
    this.onContentChanged.emit(this.value);
  }

  /**
   * @var {boolean}
   */
  get canShowValidationMessage(): boolean {
    return this.focused;
  }

  /**
   * Focus on the input.
   *
   * @return void
   */
  focusin(): void {
    this.focused = true;
  }

  /**
   * Focusout of the input.
   *
   * @return void
   */
  focusout(): void {
    this.focused = false;
  }

  /**
   * Write a new value from the form model.
   *
   * @param {any} value
   * @return void
   */
  writeValue(value: any): void {
    this.value = value;
  }

  /**
   * Register handler.
   *
   * @param {any} fn
   * @return void
   */
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  /**
   * Register handler.
   *
   * @param {any} fn
   * @return void
   */
  registerOnTouched(fn: any): void {
  }

  protected processEmojies(html) {
    html = this.processEmoji(html, ':D', 'grinning')
    html = this.processEmoji(html, ':d', 'grinning')
    html = this.processEmoji(html, ':)', 'blush')
    html = this.processEmoji(html, ':(', 'worried')
    html = this.processEmoji(html, ';(', 'cry')
    html = this.processEmoji(html, '&lt;3', 'heart')

    return html;
  }

  protected processEmoji(html, shortCode, mapCode) {
    if (html && html.includes(shortCode)) {
      html = html.replace(shortCode, EmojiBlot.create(mapCode).outerHTML);
      this.value = html;
      let currentSelection = this.editor.quillEditor.getSelection().index;
      this.editor.quillEditor.pasteHTML(this.value);
      nextTick(() => this.editor.quillEditor.setSelection({
        length: 0,
        index: currentSelection
      }));
    }

    return html;
  }
}
