<thead class="a3l-dataset-table-header" *ngIf="headerable">
  <ng-container [ngTemplateOutlet]="column.header && column.header.templateRef" *ngFor="let column of columns; trackBy:identify"></ng-container>
</thead>
<tbody class="a3l-dataset-table-renderer">
  <ng-container *ngFor="let item of dataset$ | async">
    <tr [className]="'a3l-dataset-table-renderer-item' + isGroupable(item) && item.length > 1 && !isCollapsed(item[0].id) ? ' subitem' : ''">
      <ng-container *ngFor="let column of columns">
        <ng-container *ngIf="showGroupActions && column.name == 'actions' && item[1] && item[1].actions.length > 0; else regularColumn">
          <td a3l-dataset-table-dcell>
            <div class="flex gap-1">
              <div (click)="collapse(item[0].id)">
                <ng-container *ngIf="isCollapsed(item[0].id)">
                  <button type="button" class="a3l-ui-button--default" a3l-ui-button small translate>Pokaż akcje</button>
                </ng-container>
                <ng-container *ngIf="!isCollapsed(item[0].id)" >
                  <button type="button" class="a3l-ui-button--default" a3l-ui-button small translate>Ukryj akcje</button>
                </ng-container>
              </div>
            </div>
          </td>
        </ng-container>
        <ng-template #regularColumn>
          <ng-container [ngTemplateOutlet]="column.header && column.cell.templateRef" [ngTemplateOutletContext]="{ $implicit: getItem(item)}">
          </ng-container>
        </ng-template>
      </ng-container>
    </tr>
    <div class="collapse-overlay" *ngIf="isGroupable(item) && item.length > 1">
      <div class="collapse-button">
        <a3l-ui-badge (click)="collapse(item[0].id)" [style]="!isCollapsed(item[0].id) ? {'background-color': 'white', 'color': '#a6a6ad'} : {}" >
          <ng-container *ngIf="isCollapsed(item[0].id)">
            <a3l-ui-icon icon="chevron-down" size="6"></a3l-ui-icon>&nbsp;
            <span>{{ expandText }}</span> <span class="as-indicator">+{{ item.length - 1 }}</span>
          </ng-container>
          <ng-container *ngIf="!isCollapsed(item[0].id)" >
            <a3l-ui-icon icon="chevron-up" size="6"></a3l-ui-icon>&nbsp;
            <span>{{ collapseText }}</span>
          </ng-container>
        </a3l-ui-badge>
      </div>
    </div>
    <ng-container *ngIf="isGroupable(item) && item.length > 1">
      <tr class="a3l-dataset-table-renderer-item subitem" *ngFor="let single of item.slice(1, item.length)" [@detailExpand]="isCollapsed(item[0].id) ? 'collapsed' : 'expanded'">
        <ng-container *ngIf="groupableColumns.length > 0">
          <ng-container *ngFor="let groupableColumn of groupableColumns">
            <td a3l-dataset-table-dcell>
              <groupable-factory
                [dataset]="key"
                [item]="single"
                [column]="groupableColumn"
                [view]="view"
                [entity]="entity"
              ></groupable-factory>
            </td>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="groupableColumns.length == 0">
          <ng-container *ngFor="let column of columns">
            <ng-container *ngIf="!ignoredGroupableColumns.includes(column.name); else empty">
              <ng-container [ngTemplateOutlet]="column.header && column.cell.templateRef" [ngTemplateOutletContext]="{ $implicit: single }"></ng-container>
            </ng-container>
          </ng-container>
          <ng-template #empty>
            <td a3l-dataset-table-dcell></td>
          </ng-template>
        </ng-container>
      </tr>
    </ng-container>
  </ng-container>
</tbody>
