import { Injectable } from '@angular/core';

import * as moment from 'moment';

import { TranslateService } from '@ngx-translate/core';

import { environment } from '@rex/environments/environment';

import { MeQuery } from './me.query';

@Injectable()
export class Initializer {
  /**
   * Create a new instance.
   *
   * @param {MeQuery} meQuery
   * @param {TranslateService} translator
   */
  constructor(private meQuery: MeQuery, private translator: TranslateService) {
    //
  }

  /**
   * Fetch the data about logged user and workspace.
   *
   * @return Promise<void>
   */
  load(): Promise<void> {
    return new Promise((resolve) => {
      this.meQuery.run().subscribe(resolve);
    });
  }
}

export function initialize(initializer: Initializer): Function {
  return () => initializer.load();
}
