import { AfterContentInit, Component, Input } from '@angular/core';
import { trigger, transition, animate, style, state } from '@angular/animations';

@Component({
  selector: '[a3l-layout-sidenav-item-submenu]',
  template: '<ng-content></ng-content>',
  host: {
    class: 'a3l-layout-sidenav-item-submenu',
    '[@state]': 'visibility',
  },
  // prettier-ignore
  animations: [
    trigger('state', [
      state('hidden', style({ height: 0 })),
      state('visible', style({ height: '*' })),
      transition('* => *', animate(200)),
    ]),
  ],
})
export class LayoutSidenavItemSubmenu implements AfterContentInit {
  /**
   * @var {boolean}
   */
  @Input()
  opened: boolean = false;

  /**
   * @var {'hidden' | 'visible'}
   */
  visibility: 'hidden' | 'visible' = 'hidden';

  /**
   * Create a new instance.
   */
  constructor() {
    //
  }

  /**
   * Initialization.
   */
  ngAfterContentInit() {
    setTimeout(() => (this.visibility = this.opened ? 'visible' : 'hidden'));
  }

  /**
   * Toggle the menu visibility.
   *
   * @return void
   */
  toggle(): void {
    this.opened = !this.opened;
    this.visibility = this.opened ? 'visible' : 'hidden';
  }
}
