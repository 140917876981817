export class IndexedDB {
  /**
   * @var {string}
   */
  protected static readonly PREFIX: string = 'VL';

  /**
   * @var {string}
   */
  database: string;

  /**
   * @var {number}
   */
  version: number;

  /**
   * @var {any}
   */
  private db: any;

  /**
   * @var {IDBFactory}
   */
  private get engine(): IDBFactory {
    return window.indexedDB || (<any>window).mozIndexedDB || (<any>window).webkitIndexedDB || (<any>window).msIndexed;
  }

  /**
   * Create a new instance.
   *
   * @param {string} database
   * @param {number} version
   */
  constructor(database: string, version: number = 1) {
    this.database = `${IndexedDB.PREFIX}-${database.toUpperCase()}`;
    this.version = version;
  }

  /**
   * Connect to the database.
   *
   * @param {any} configuration
   * @return void
   */
  connect(...configuration: any): Promise<void> {
    return new Promise((resolve, reject) => {
      const request = this.engine.open(this.database, this.version);

      request.onsuccess = () => {
        this.db = request.result;

        resolve();
      };

      request.onerror = (e) => reject(e);

      request.onupgradeneeded = (e: any) => {
        const db = e.currentTarget.result;

        for (let i = 0, x = configuration.length; i < x; i++) {
          const { name, keyPath = null, autoIncrement = false } = configuration[i];

          if (db.objectStoreNames.contains(name)) continue;

          db.createObjectStore(name, { keyPath, autoIncrement });
        }
      };
    });
  }

  /**
   * Get the store object.
   *
   * @param {string} name
   * @return IndexedDBStore
   */
  store(name: string): IndexedDBStore {
    return new IndexedDBStore(this.db, name);
  }
}

export enum IndexedDBMode {
  READ = 'readonly',
  WRITE = 'readwrite',
}

export class IndexedDBStore {
  /**
   * @var {any}
   */
  private db: any;

  /**
   * @var {string}
   */
  private name: string;

  /**
   * Create a new instance.
   *
   * @param {any} db
   * @param {name} string
   */
  constructor(db: any, name: string) {
    this.db = db;
    this.name = name;
  }

  /**
   * Get all entires.
   *
   * @return Promise<any[]>
   */
  getAll(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      const transaction = this.createTransaction(IndexedDBMode.READ, (e) => reject(e));

      const object = transaction.objectStore(this.name);

      const request = object.openCursor();

      const result = [];

      request.onsuccess = (e: Event) => {
        const cursor = (<IDBOpenDBRequest>e.target).result;

        if (cursor) {
          result.push(cursor['value']);

          cursor['continue']();

          return;
        }

        resolve(result);
      };

      request.onerror = (e) => reject(e);
    });
  }

  /**
   * Find the entry by a key.
   *
   * @param {any} value
   * @param {any} fallbackValue
   * @return Promise<any>
   */
  find(value: any, fallbackValue?: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const transaction = this.createTransaction(IndexedDBMode.READ, (e) => reject(e));

      const object = transaction.objectStore(this.name);

      const request = object.get(value);

      request.onsuccess = (e: Event) => resolve((<any>e.target).result || fallbackValue);
    });
  }

  /**
   * Save the given value in the storage.
   *
   * @param {any} value
   * @param {any} key
   * @return Promise<any>
   */
  add(value: any, key?: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const transaction = this.createTransaction(
        IndexedDBMode.WRITE,
        (e) => reject(e),
        (e) => resolve({ key: key, value: value })
      );

      const object = transaction.objectStore(this.name);

      const request = object.add(value, key);

      request.onsuccess = (e: any) => (key = e.target.result);
    });
  }

  /**
   * Update the given value in the storage.
   *
   * @param {any} value
   * @param {any} key
   * @return Promise<any>
   */
  update(value: any, key?: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const transaction = this.createTransaction(
        IndexedDBMode.WRITE,
        (e) => reject(e),
        (e) => resolve(value),
        (e) => reject(e)
      );

      const object = transaction.objectStore(this.name);

      object.put(value, key);
    });
  }

  /**
   * Delete the entry with given key.
   *
   * @param {any} key
   * @return Promise<void>
   */
  delete(key: any): Promise<void> {
    return new Promise((resolve, reject) => {
      const transaction = this.createTransaction(
        IndexedDBMode.WRITE,
        (e) => reject(e),
        (e) => resolve(),
        (e) => reject(e)
      );

      const object = transaction.objectStore(this.name);

      object['delete'](key);
    });
  }

  /**
   * Clear the store.
   *
   * @return Promise<void>
   */
  clear(): Promise<void> {
    return new Promise((resolve, reject) => {
      const transaction = this.createTransaction(
        IndexedDBMode.WRITE,
        (e) => reject(e),
        (e) => resolve(),
        (e) => reject(e)
      );

      const object = transaction.objectStore(this.name);

      object.clear();

      resolve();
    });
  }

  /**
   * Create the transaction.
   *
   * @param {IDBTransactionMode} mode
   * @param {Function} error
   * @param {Function} complete
   * @param {Function} abort
   * @return any
   */
  private createTransaction(
    mode: IDBTransactionMode,
    error?: (e: Event) => any,
    complete?: (e: Event) => any,
    abort?: (e: Event) => any
  ): any {
    let transaction: IDBTransaction = this.db.transaction(this.name, mode);

    transaction.onerror = error;
    transaction.oncomplete = complete;
    transaction.onabort = abort;

    return transaction;
  }
}
