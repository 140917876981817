import { Component } from '@angular/core';

@Component({
  selector: 'a3l-layout-copyrights',
  templateUrl: './layout-copyrights.component.html',
  host: {
    class: 'a3l-layout-copyrights',
  },
})
export class LayoutCopyrightsComponent {
  /**
   * @var {number}
   */
  get year(): number {
    return new Date().getFullYear();
  }

  /**
   * Create a new instance.
   */
  constructor() {
    //
  }
}
