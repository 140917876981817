import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";

import {TokenComponent} from "./token.component";

const  PUBLIC_API = [
  TokenComponent
];

@NgModule({
  declarations: [...PUBLIC_API],
  imports: [CommonModule],
  exports: [...PUBLIC_API]
})
export class TokenModule {}
