<div class="whitespace-nowrap">
  <ul class="m-0 p-0 list-none flex text-sm font-semibold">
    <li class="px-1"
        [ngClass]="{'text-black': true}"
        [a3l-ui-tooltip]="'candidate::dataset.tooltip.status.recommended' | translate"
    >
      {{ item.recommendations_count || 0 }}
    </li>
  </ul>
</div>
<div class="mt-2 text-sm min-h-sm">
  <ul class="m-0 min-h-sm p-0 list-none flex text-gray-dark whitespace-nowrap">
    <ng-container *rex-auth-role="['superadmin', 'leader', 'recruiter']">
      <ng-container *ngIf="item.recommendations_invited_count || 0 > 0">
        <li class="px-1"
            [a3l-ui-tooltip]="'candidate::dataset.tooltip.status.invited' | translate"
        >
          {{ item.recommendations_invited_count || 0 }}
        </li>
      </ng-container>
      <ng-container *ngIf="item.recommendations_verification_count || 0 > 0">
        <li class="px-1"
            [ngClass]="{'text-warning': true}"
            [a3l-ui-tooltip]="'candidate::dataset.tooltip.status.verification' | translate"
        >
          {{ item.recommendations_verification_count || 0 }}
        </li>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="item.recommendations_presentation_count || 0 > 0">
      <li class="px-1"
          [ngClass]="{'text-purple': true}"
          [a3l-ui-tooltip]="'candidate::dataset.tooltip.status.presentation' | translate"
      >
        {{ item.recommendations_presentation_count || 0 }}
      </li>
    </ng-container>
    <ng-container *ngIf="item.recommendations_meeting_count || 0 > 0">
      <li class="px-1"
          [ngClass]="{'text-purple': true}"
          [a3l-ui-tooltip]="'candidate::dataset.tooltip.status.meeting' | translate"
      >
        {{ item.recommendations_meeting_count || 0 }}
      </li>
    </ng-container>
    <ng-container *ngIf="item.recommendations_rejected_count || 0 > 0">
      <li class="px-1"
          [ngClass]="{'text-accent': true}"
          [a3l-ui-tooltip]="'candidate::dataset.tooltip.status.rejected' | translate"
      >
        {{ item.recommendations_rejected_count || 0 }}
      </li>
    </ng-container>
    <ng-container *ngIf="item.recommendations_resigned_count || 0 > 0">
      <li class="px-1"
          [a3l-ui-tooltip]="'candidate::dataset.tooltip.status.resigned' | translate"
      >
        {{ item.recommendations_resigned_count || 0 }}
      </li>
    </ng-container>
    <ng-container *ngIf="item.recommendations_hired_count || 0 > 0">
      <li class="px-1"
          [ngClass]="{'text-success': true}"
          [a3l-ui-tooltip]="'candidate::dataset.tooltip.status.hired' | translate"
      >
        {{ item.recommendations_hired_count || 0 }}
      </li>
    </ng-container>
  </ul>
</div>
