import {Component, ContentChildren, HostListener, Input, QueryList} from '@angular/core';
import { Observable } from 'rxjs';

import { Store } from './store';

import {state, style, trigger} from '@angular/animations';
import { DatasetTableColumn } from '@a3l/dataset';

@Component({
  selector: 'table[a3l-dataset-table]',
  templateUrl: './dataset-table.presenter.html',
  host: {
    class: 'a3l-dataset-table',
  },
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({display: 'none'})),
      state('expanded', style({display: 'revert'})),
    ]),
  ],
})
export class DatasetTablePresenter {
  /**
   * @var {Observable<any[]>}
   */
  readonly dataset$: Observable<any[]> = this.store.state$;

  @Input('ignoredGroupableColumns')
  ignoredGroupableColumns: Array<string> = [];

  collapseStates: Array<boolean> = [];

  /**
   * @var {DatasetTableColumn[]}
   */
  get columns(): DatasetTableColumn[] {
    const result = {}, columns = this._columns.toArray(); // prettier-ignore

    for (let i = 0, x = columns.length; i < x; i++) {
      const column = columns[i], { name } = column; // prettier-ignore
      if (result.hasOwnProperty(name)) {
        result[name].cell = column.cell || result[name].cell;

        continue;
      }

      result[name] = column;
    }

    return Object.values(result);
  }

  /**
   * @var {DatasetTableColumn[]}
   */
  get groupableColumns(): DatasetTableColumn[] {
    const result = {}, columns = this._groupableColumns; // prettier-ignore

    if(columns == null) {
      return [];
    }

    for (let i = 0, x = columns.length; i < x; i++) {
      const column = columns[i];
      const name = column['key'];
      if (result.hasOwnProperty(name)) {
        result[name].cell = column.cell || result[name].cell;

        continue;
      }

      result[name] = column;
    }

    return Object.values(result);
  }

  /**
   * @var {boolean}
   */
  get headerable(): boolean {
    for (let i = 0, x = this.columns.length; i < x; i++) {
      const column: DatasetTableColumn = this.columns[i];

      if (column && column.header) return true;
    }

    return false;
  }

  /**
   * @var {QueryList<DatasetTableColumn>}
   */
  @ContentChildren(DatasetTableColumn)
  protected _columns: QueryList<DatasetTableColumn>;

  /**
   * @var {QueryList<DatasetTableColumn>}
   */
  @Input()
  protected _groupableColumns: any;

  @Input()
  protected entity: any;

  @Input()
  protected view: any;

  @Input()
  protected key: any;

  /**
   * Create a new instance.
   *
   * @param {Store} store
   */
  constructor(private store: Store
  ) {
    //
  }

  @Input('expandText')
  expandText: string;
  @Input('collapseText')
  collapseText: string;
  @Input('showGroupActions')
  showGroupActions: boolean = false;


  /**
   * Get the trackBy element.
   *
   * @param {number} index
   * @param {any} item
   * @return string
   */
  identify(index: number, item: any): string {
    return item.key;
  }

  @HostListener('click')
  collapse(id: any) {
    if(!id) {
      return null;
    }

    this.collapseStates.forEach((item, thisId) => {
      if(id != thisId) {
        this.collapseStates[thisId] = true;
      }
    });

    if(this.collapseStates[id] !== undefined) {
      this.collapseStates[id] = !this.collapseStates[id];
    } else {
      this.collapseStates[id] = false;
    }
  }

  isCollapsed(id: any) {
    return this.collapseStates[id] == undefined || this.collapseStates[id] == true
  }

  isGroupable(item: any) {
    return item instanceof Array;
  }

  getItem(item: any)
  {
    if(this.isGroupable(item)) {
      return item[0];
    }

    return item;
  }
}
