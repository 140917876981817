import { NgModule } from '@angular/core';
import { AuthModule } from '@rex/auth';
import { CommonModule } from '@rex/common';
import { UtilitiesModule } from '@a3l/utilities';
import { TutorialOpenDirective } from './tutorial-open.directive';
import { TutorialAndQuizService } from '../pages/tutorial-and-quiz/tutorial-and-quiz.service';
import { TutorialDialog } from './tutorial.dialog';
import { GoToStepButtonComponent } from './go-to-step-button.component';

@NgModule({
  declarations: [TutorialOpenDirective, TutorialDialog, GoToStepButtonComponent],
  imports: [CommonModule, UtilitiesModule, AuthModule],
  exports: [TutorialOpenDirective, GoToStepButtonComponent],
  providers: [TutorialAndQuizService],
})
export class TutorialModule {}
