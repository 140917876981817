
export class LocalizedContentField {
  constructor(
    public language_code: string,
    public content: string
  ) {
  }

  public toString(): string {
    return JSON.stringify({
      language_code: this.language_code,
      content: this.content
    });
  }
}
