<div class="a3l-ui-tabs-nav">
  <ul>
    <li
      class="a3l-ui-tabs-nav-item"
      [ngClass]="{
      'a3l-ui-tabs-nav-item--active': tab.active,
      'a3l-ui-tabs-nav-item--disabled': tab.disabled,
      'a3l-ui-tabs-nav-item--right': tab.toRight
      }"
      #label
      [id]="tab.id"
      (click)="select(i)"
      *ngFor="let tab of tabs; let i = index"
    >
      <ng-template [cdkPortalOutlet]="tab.templateLabel"></ng-template>
    </li>
  </ul>
  <a3l-ui-tabs-bar></a3l-ui-tabs-bar>
</div>
<div class="a3l-ui-tabs__content">
  <ng-content></ng-content>
</div>
